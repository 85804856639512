import {AnalyticsRecipeDto} from "src/app/core/analytics/dto/common/analytics-recipe-dto";

export class UpdateAnalyticsRecipeDto
{
    constructor(
        public analyticsRecipeId: number,
        public recipe: AnalyticsRecipeDto,
        public closedAt?: Date) {
    }
}
