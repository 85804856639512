import { Component, OnDestroy, OnInit } from '@angular/core';
import { MixingNavigationService } from "../../../services/mixing-navigation.service";
import { ApMixingContainer } from "../../../models/mixing-data/ancillary-product/ap-mixing-container";
import { IMixingContainer } from "../../../interfaces/i-mixing-container";
import { ContainerType } from "../../../enums/container-type";
import { AncillaryProductData } from "../../../../formulation-ancillary-product/models/ancillary-product-data";
import { Unit } from "../../../../formulation/models/unit";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { Options } from '@angular-slider/ngx-slider';
import { LocalizationService } from "../../../../core/localization/localization.service";
import { IsMobileViewPipe } from "../../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-ap-mixing-information',
    templateUrl: './ap-mixing-information.component.html',
    styleUrls: ['./ap-mixing-information.component.scss']
})
export class ApMixingInformationComponent extends FormField<any> implements OnInit, OnDestroy {
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    sliderValue1 = 0;
    sliderOptions1: Options = {
        floor: 0,
        ceil: 100
    };
    sliderValue2 = 0;
    sliderOptions2: Options = {
        floor: 0,
        ceil: 100
    };
    decimalSeparator: string;
    units: Unit[];
    container: ApMixingContainer;
    containerSubscription: Subscription;
    productSubscription: Subscription;
    productName: string;
    selectedUnit: Unit;
    selectedQuantity = 1;
    isHardener1: boolean;
    isThinner1: boolean;
    isThinner2: boolean;
    isThinner1Fix: boolean;
    isThinner2Fix: boolean;
    thinner1Relation: number;
    thinner2Relation: number;

    constructor(
        private data: AncillaryProductData,
        private navigation: MixingNavigationService,
        private localizationService: LocalizationService,
        private mobileViewPipe: IsMobileViewPipe) {
        super();
        this.units = this.data.units;
        this.decimalSeparator = this.localizationService.selectedLanguage.getDecimalSeparator();
    }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.onContainerChanged(this.navigation.currentContainer);
        this.containerSubscription = this.navigation.containerChanged.subscribe(x => this.onContainerChanged(x.newValue));
    }

    onContainerChanged(newContainer: IMixingContainer) {
        this.unsubscribeOnProductChanged();
        if (newContainer && newContainer.containerType === ContainerType.AncillaryProduct) {
            this.initializeContainer(newContainer as ApMixingContainer);
        }
        else {
            this.uninitializeContainer();
        }

        this.subscribeOnProductChanged();
        this.setRelations();
    }

    initializeContainer(container: ApMixingContainer) {
        this.container = container;
        this.productName = this.container.mixingCriteira.product.name;
        this.selectedUnit = this.container.mixingCriteira.unit;
        this.selectedQuantity = this.container.mixingCriteira.quantity;
    }

    uninitializeContainer() {
        this.container = null;
        this.productName = null;
        this.selectedUnit = null;
        this.selectedQuantity = null;
    }

    subscribeOnProductChanged() {
        if (this.container) {
            this.container.selectedProductChanged.subscribe(() => this.setRelations());
        }
    }

    unsubscribeOnProductChanged() {
        if (this.productSubscription) {
            this.productSubscription.unsubscribe();
        }
    }

    recalculate() {
        this.container.mixingCriteira.unit = this.selectedUnit;
        this.container.mixingCriteira.quantity = this.selectedQuantity;
        this.container.selectedProductChanged.emit(this.container.selectedProduct);
    }

    setRelations() {
        if (!this.container) {
            return;
        }

        this.isHardener1 = this.container && this.container.selectedProduct && this.container.selectedProduct.hardenerComponentId != null;
        this.isThinner1 = this.container && this.container.selectedProduct && this.container.selectedProduct.thinnerComponent1Id != null;
        this.isThinner2 = this.container && this.container.selectedProduct && this.container.selectedProduct.thinnerComponent2Id != null;
        this.isThinner1Fix = this.container && this.container.selectedProduct &&
            (this.container.selectedProduct.thinnerRelation12 == null && this.container.selectedProduct.thinnerRelation13 == null
                || this.container.selectedProduct.thinnerRelation12 === 0 && this.container.selectedProduct.thinnerRelation13 === 0);
        this.isThinner2Fix = this.container && this.container.selectedProduct &&
            (this.container.selectedProduct.thinnerRelation22 == null && this.container.selectedProduct.thinnerRelation23 == null
                || this.container.selectedProduct.thinnerRelation22 === 0 && this.container.selectedProduct.thinnerRelation23 === 0);
        this.sliderOptions1 = {
            floor: this.container.selectedProduct.thinnerRelation12,
            ceil: this.container.selectedProduct.thinnerRelation13
        };
        this.sliderValue1 = this.container.selectedProduct.thinnerRelation11;
        this.sliderOptions2 = {
            floor: this.container.selectedProduct.thinnerRelation22,
            ceil: this.container.selectedProduct.thinnerRelation23
        };
        this.sliderValue2 = this.container.selectedProduct.thinnerRelation21;
    }

    ngOnDestroy(): void {
        this.containerSubscription.unsubscribe();
        if (this.productSubscription) {
            this.productSubscription.unsubscribe();
        }

        this.resizeSubscription.unsubscribe();
    }

    onFocus(event: any) {
        event.target.select();
    }

    onChange() {
        this.container.selectedProduct.thinnerRelation11 = this.sliderValue1;
        this.container.selectedProductChanged.emit(this.container.selectedProduct);
    }

    onChange2() {
        this.container.selectedProduct.thinnerRelation21 = this.sliderValue2;
        this.container.selectedProductChanged.emit(this.container.selectedProduct);
    }

    onKeyUp(args: any) {
        this.selectedQuantity = +args.value;
    }

    set display(val: string) {
        this.decimalSeparator = val.includes(',') ? ',' : val.includes('.') ? '.' : '';
        this.selectedQuantity = +val.replace(',', '.');
    }

    get display(): string {
        const val = this.selectedQuantity ? this.selectedQuantity : 0;
        let result: string = val.toString().replace('.', this.decimalSeparator);
        result = result.replace(',', this.decimalSeparator);
        return result;
    }
}
