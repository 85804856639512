import { ContrastColorRow } from "./contrast-color-row";
import { IDeserialize } from "../../shared/interfaces/deserialize";

export class ModelRow implements IDeserialize {
    colorVariantId: number;
    model: string;
    buildYearFrom: number;
    buildYearTo: number;
    contrastColors: ContrastColorRow[];
    contrastColorsLoaded: boolean;

    deserialize(input: any) {
        Object.assign(this as any, input);
        return this;
    }
}
