import { Component, OnInit, ElementRef } from '@angular/core';
import { CustomerBundle } from "../models/customer-bundle";
import { UsersService } from "../services/users-service";
import { NotificationDialogService } from "../../core/dialogs/notification-dialog.service";
import { DeleteCustomerRequest } from "../models/delete-customer-request";
import { TranslateService } from "@ngx-translate/core";
import { ToggleBanRequest } from "../models/toggle-ban-request";

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

    readonly itemsPerPage = 40;

    customers: CustomerBundle[] = [];
    filteredCustomers: CustomerBundle[];
    verticalOffset: number;
    columnsWithSearch: string[] = ["firstName", "lastName", "company"];
    totalCount: number;
    searchTerm?: string;

    orderBy?: string;
    orderAscending = true;

    isLoading: boolean;
    isLoadingError: boolean;
    isInitialLoad: boolean;

    constructor(
        private usersService: UsersService,
        private notificationDialogService: NotificationDialogService,
        private translateService: TranslateService) { }

    async ngOnInit() {
        this.isInitialLoad = true;
        await this.loadUsers(0, this.itemsPerPage);
        this.isInitialLoad = false;
    }

    async loadUsers(skip: number, take: number): Promise<any> {
        try {
            this.isLoading = true;
            const response = await this.usersService.getCustomers(skip, take, this.searchTerm, this.orderBy, this.orderAscending);

            if (response == null) {
                this.isLoadingError = true;
            }
            else {
                if(this.isInitialLoad){
                    this.customers = response.customers;
                }
                else{
                    const customers = [...this.customers, ...response.customers];
                    this.customers = customers;
                }

                this.filteredCustomers = this.customers;
                this.totalCount = response.totalCount;
            }
        }
        catch
        {
            this.isLoadingError = true;
        }
        finally {
            this.isLoading = false;
        }
    }

    // filters results
    async filterDatatable(event) {
        this.isInitialLoad =true;
        this.searchTerm = event.target.value;
        await this.loadUsers(0, this.itemsPerPage);
        this.isInitialLoad= false;
    }

    getRowHeight() {
        return 34;
    }

    async onScroll(event) {
        if (event && event.offsetY) {
            this.verticalOffset = event.offsetY;
        }
        if(this.isLoading){
            return;
        }
        if(this.customers.length === this.totalCount){
            return;
        }

        const div = event.currentTarget;
        if ((div.clientHeight / (div.scrollHeight - div.scrollTop)) < 0.9){
            return;
        }
        await this.loadUsers(this.customers.length, this.itemsPerPage);
    }

    confirmDelete(customer: CustomerBundle) {
        this.notificationDialogService.showConfirmDialog(() => this.delete(customer), this.translateService.instant('Customers.Delete.Question'));
    }

    async delete(customerBundle: CustomerBundle) {
        if (!customerBundle || !customerBundle.customer || customerBundle.customer.isProcessing) { return; }

        let success: boolean;

        try {
            customerBundle.customer.isProcessing = true;

            const response = await this.usersService.deleteCustomer(new DeleteCustomerRequest(customerBundle.customer.id));

            if (response != null && response.success) {
                this.customers.splice(this.customers.indexOf(customerBundle), 1);
                this.filteredCustomers.splice(this.customers.indexOf(customerBundle), 1);
                success = true;
            }
        }
        catch
        {
        }
        finally {
            customerBundle.customer.isProcessing = false;
        }

        const message = this.translateService.instant(success ? 'Customers.Delete.Success' : 'Customers.Delete.Failed');

        this.notificationDialogService.showDialog(message);
    }

    confirmBan(customerBundle: CustomerBundle) {
        this.notificationDialogService.showConfirmDialog(() => this.toggleBan(customerBundle, true), this.translateService.instant('Customers.Ban.Question'));
    }

    confirmUnban(customerBundle: CustomerBundle) {
        this.notificationDialogService.showConfirmDialog(() => this.toggleBan(customerBundle, false), this.translateService.instant('Customers.Unban.Question'));
    }

    async toggleBan(customerBundle: CustomerBundle, isBanned: boolean) {
        if (!customerBundle || !customerBundle.customer || customerBundle.customer.isProcessing) { return; }

        let success: boolean;

        try {
            customerBundle.customer.isProcessing = true;

            const response = await this.usersService.toggleCustomerBan(new ToggleBanRequest(isBanned, customerBundle.customer.id));

            if (response != null && response.success) {
                customerBundle.customer.isBanned = isBanned;
                success = true;
            }
        }
        catch
        {
        }
        finally {
            customerBundle.customer.isProcessing = false;
        }

        let message: string;

        if (isBanned) {
            message = this.translateService.instant(success ? 'Customers.Ban.Success' : 'Customers.Ban.Failed');
        }
        else {
            message = this.translateService.instant(success ? 'Customers.Unban.Success' : 'Customers.Unban.Failed');
        }

        this.notificationDialogService.showDialog(message);
    }

    async onSort(event) {
        if (event && event.column)
        {
            this.orderAscending = event.newValue === 'asc';
            this.orderBy = event.column.name;

            this.isInitialLoad = true;
            await this.loadUsers(0, this.itemsPerPage);
            this.isInitialLoad = false;
        }
    }
}
