import {
    AfterViewInit,
    Component,
    EventEmitter,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { sections } from "../../../shared/configuration/sections";
import { Router } from "@angular/router";
import { IFormField } from "../../../shared/components/base/forms/form-field-interface";
import { CompanyEmailField } from "./fields/company-email.component";
import { isEmailValid } from "../../../core/utils/email-validation";
import { CountryField } from "./fields/country.component";
import { LanguageField } from "./fields/language.component";
import { FirstNameField } from "./fields/first-name.component";
import { LastNameField } from "./fields/last-name.component";
import { CompanyNameField } from "./fields/company-name.component";
import { Address1Field } from "./fields/address1.component";
import { Address2Field } from "./fields/address2.component";
import { Address3Field } from "./fields/address3.component";
import { CityField } from "./fields/city.component";
import { ZipField } from "./fields/zip.component";
import { PhoneField } from "./fields/phone.component";
import { DistributorNameField } from "./fields/distributor-name.component";
import { CustomerNumberField } from "./fields/customer-number.component";

@Component({
    selector: 'app-register-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements AfterViewInit {

    @Output() step1To2Emitter = new EventEmitter<any>();


    //@Output() nextStep = new EventEmitter<any>();

    @ViewChildren('required') requiredFields: QueryList<IFormField>;

    @ViewChild('country', { static: true }) country: CountryField;
    @ViewChild('language', { static: true }) language: LanguageField;
    @ViewChild('firstName', { static: true }) firstName: FirstNameField;
    @ViewChild('lastName', { static: true }) lastName: LastNameField;
    @ViewChild('companyName', { static: true }) companyName: CompanyNameField;
    @ViewChild('address1', { static: true }) address1: Address1Field;
    @ViewChild('address2', { static: true }) address2: Address2Field;
    @ViewChild('address3', { static: true }) address3: Address3Field;
    @ViewChild('city', { static: true }) city: CityField;
    @ViewChild('zip', { static: true }) zip: ZipField;
    @ViewChild('companyEmail', { static: true }) companyEmail: CompanyEmailField;
    @ViewChild('phone', { static: true }) phone: PhoneField;
    @ViewChild('distributorName', { static: true }) distributorName: DistributorNameField;
    @ViewChild('customerNumber', { static: true }) customerNumber: CustomerNumberField;


    hasRequirementsError: boolean;
    isEmailInvalid: boolean;

    constructor(private router: Router, private renderer: Renderer2) {
        this.renderer.addClass(document.body, 'auth-body');
    }

    ngAfterViewInit(): void {
        this.registerRequiredFieldsEvents();
    }

    // async goToNextStep()
    // {
    //     if(!this.validate()) return;

    //     this.nextStep.emit(null);
    // }

    async goToStep2() {
        if (!this.validate()) { return; }

        this.step1To2Emitter.emit(null);
    }

    public validate(): boolean {
        this.clearErrors();

        if (!this.validateRequirements()) { return false; }

        if (!this.validateEmail()) { return false; }

        return true;
    }

    registerRequiredFieldsEvents(): void {
        this.requiredFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onRequiredFieldFilled(isfilled));
        });
    }

    validateEmail(): boolean {
        this.isEmailInvalid = !this.companyEmail.isFilled || !isEmailValid(this.companyEmail.value);

        return !this.isEmailInvalid;
    }

    onRequiredFieldFilled(isFilled: boolean) {
        if (this.hasRequirementsError || this.isEmailInvalid) {
            this.validate();
        }
        //if(this.hasRequirementsError && isFilled)
        //{
        //   this.hasRequirementsError = false;
        //}
    }

    private validateRequirements(): boolean {
        this.hasRequirementsError = this.requiredFields.some(x => !x.isFilled);

        return !this.hasRequirementsError;
    }

    private clearErrors(): void {
        this.hasRequirementsError = false;
        this.isEmailInvalid = false;
    }

    cancel() {
        this.router.navigate([`/${sections.login}`]);
    }
}
