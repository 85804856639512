import { Quality } from "../../../../formulation/models/quality";
import { AncillaryProductItem } from "../../../../formulation-ancillary-product/models/ancillary-product-item";
import { Unit } from "../../../../formulation/models/unit";
import { TemperatureRange } from "../../../../formulation-ancillary-product/models/temperature-range";
import { AncillaryProductGlossLevel } from "../../../../formulation-ancillary-product/models/ancillary-product-gloss-level";
import { VocConformity } from "../../../../formulation-ancillary-product/models/voc-conformity";
import { QualityType } from "../../../../formulation/enums/quality-type";

export class ApMixingCriteria {
    quality: Quality;
    product: AncillaryProductItem;
    quantity: number;
    unit: Unit;
    temperature: TemperatureRange;
    glossLevel: AncillaryProductGlossLevel;
    voc: VocConformity;
    vocCountry: string;

    availableQualityTypes: QualityType[];
}
