<div #logodiv class="auth-section request-password-reset" Auth>
    <div></div>
    <app-standard-page>
        <div class="container d-flex flex-column">

            <div class="auth">
                <div [hidden]="isCompleted" class="request-form">
                    <h2 class="black-text">{{ 'RequestPasswordReset.Page.Title' | translate }}</h2>
                    <div class="reset-password-desc">{{'RequestPasswordReset.Page.Description' | translate}}</div>
                    <form (keydown)="onKeyDown($event)" #form="ngForm" novalidate>
                        <div class="customer-no">
                            <app-reset-password-username #username #required></app-reset-password-username>
                        </div>
                        <div *ngIf="hasRequirementsError" class="requirements-error">{{'Authentication.Validation.UsernameRequired' | translate }}</div>
                        <div *ngIf="isProcessingError" class="requirements-error">{{processingError}}</div>
                        <div *ngIf="isProcessing" class="auth-loader">
                            <dots-loader class="black-text"
                                         caption="{{'General.Text.PleaseWaitProcessing' | translate}}"></dots-loader>
                        </div>
                        <button *ngIf="!isProcessing" type="button" (click)="requestPasswordReset()"
                                class="btn btn-block btn-default btn-reset">{{ 'RequestPasswordReset.Button.Caption' | translate
                            }}</button>
                    </form>
                </div>
                <div [hidden]="!isCompleted" class="request-success">
                    <h2 class="black-text">{{ 'RequestPasswordReset.Success.Page.Title' | translate }}</h2>
                    <div class="reset-password-success-desc">{{'RequestPasswordReset.Success.Page.Description' | translate}}</div>
                </div>
                <div *ngIf="!isProcessing" class="links bg-white text-center">
                    <a class="register" (click)="goToLogin()" href="javascript:void(0)">{{'RequestPasswordReset.BackToLogin.Link.Caption'| translate}}</a>
                </div>
            </div>
        </div>
    </app-standard-page>
</div>
