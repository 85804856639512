import { Component, Input, OnInit } from '@angular/core';
import { Unit } from "../../../../formulation/models/unit";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { AncillaryProductData } from "../../../models/ancillary-product-data";

@Component({
    selector: 'ancillary-unit',
    templateUrl: './unit.component.html',
    styles: []
})
export class AncillaryUnitField extends FormField<Unit> implements OnInit {

    @Input() units: Unit[];

    _defaultUnitId: number;

    get defaultUnitId() { return this._defaultUnitId; }

    @Input()
    set defaultUnitId(value) {
        this._defaultUnitId = value;
        this.setDefaultUnit();
    }

    constructor(public data: AncillaryProductData) {
        super();
    }

    ngOnInit() {
    }

    setDefaultUnit() {
        if (this.defaultUnitId && this.units) {
            this.value = this.units.find(x => x.id == this.defaultUnitId);
        }
    }
}
