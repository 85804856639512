import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import steps from '../constants/steps';
import { Step1Component } from "./step1/step1.component";
import { Step2Component } from "./step2/step2.component";
import { LicenseComponent } from "./license/license.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import RegisterRequest from "../models/register.request";
import { RegisterService } from "../handlers/register.service";
import { sections } from "../../shared/configuration/sections";
import { NotificationDialogService } from "../../core/dialogs/notification-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements AfterViewInit {
    public readonly steps = steps;
    public step: number = steps.step1;

    @ViewChild('step1', { static: true }) step1: Step1Component;
    @ViewChild('step2', { static: true }) step2: Step2Component;
    @ViewChild('step3', { static: true }) step3: LicenseComponent;
    @ViewChild('step4', { static: true }) step4: PrivacyComponent;

    public processingErrorMessage: string;
    public isProcessingError: boolean;
    public isProcessing: boolean;

    constructor(
        private registerService: RegisterService,
        private router: Router,
        private notificationDialogService: NotificationDialogService,
        private translateService: TranslateService) {
    }

    ngAfterViewInit(): void {
    }

    goToStep2() {
        this.step = steps.step2;
    }

    goToStep1() {
        this.step = steps.step1;
    }

    goToStep3() {
        this.step = steps.step3;
    }

    goToStep4() {
        this.step = steps.step4;
    }

    nextStep() {
        this.step = steps.step2;
    }

    previousStep() {
        this.step = steps.step1;
    }

    async register() {
        if (!this.step1.validate() || !this.step2.validate()) { return; }

        try {
            this.isProcessingError = false;
            this.processingErrorMessage = null;
            this.isProcessing = true;

            const request = this.getRequest();

            const response = await this.registerService.register(request);

            if (response && response.success) {
                this.notificationDialogService.showDialog(this.translateService.instant('Register.Successful.Message'));
                await this.router.navigate([`/${sections.login}`]);
            }
            else {
                this.isProcessingError = true;

                if (response && response.errorMessage) {
                    this.processingErrorMessage = response.errorMessage;
                }
                else {
                    this.processingErrorMessage = "Registration failed. An unknown error has occurred.";
                }
            }
        }
        catch (ex) {
            this.processingErrorMessage = "Registration failed. An unknown error has occurred.";
            this.isProcessingError = true;
        }
        finally {
            this.isProcessing = false;
        }
    }

    getRequest(): RegisterRequest {
        return new RegisterRequest(
            this.step2.username.value,
            this.step2.password.value,
            this.step1.country.value.code,
            this.step1.language.value.code,
            this.step1.firstName.value,
            this.step1.lastName.value,
            this.step1.companyName.value,
            this.step1.address1.value,
            this.step1.address2.value,
            this.step1.address3.value,
            this.step1.city.value,
            this.step1.zip.value,
            this.step1.companyEmail.value,
            this.step1.phone.value,
            this.step1.customerNumber.isFilled ? this.step1.customerNumber.value : null,
            this.step1.distributorName.value
        );
    }

}
