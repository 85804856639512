<div class="list-view">
    <ngx-datatable #table [ngStyle]="{'font-size': fontsize+'px', 'height': height}" [rows]="colors"
        [columnMode]="'force'" [headerHeight]="headerHeight" class="colors-table" [rowHeight]="rowHeight"
        [scrollbarV]="true" [rowClass]="getRowClass" [virtualization]="true" [selectionType]="'single'"
        (scroll)="onScroll($event)" (select)='onSelect($event)'>

        <!-- Row Detail Template Desktop -->
        <ngx-datatable-row-detail [rowHeight]="detailHeight" #detailRow>
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <app-search-row-details (click)="onActivate($event)" #rowDetails [color]="row" [voc]="voc"
                    [searchType]="searchType" [isExpanded]="expanded"></app-search-row-details>
            </ng-template>
        </ngx-datatable-row-detail>

        <!-- Color Desktop View-->
        <ngx-datatable-column cellClass="color-cell" headerClass="results-header" prop="colorId"
            name="{{'FormulationSearch.Column.Color' | translate }}" [flexGrow]="1">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div>
                    <a class="expand" *ngIf="searchType === searchTypes.Normal" href="javascript:void(0)"
                        [class.datatable-icon-right]="!row.isExpanded" [class.datatable-icon-down]="row.isExpanded"
                        [class.expand-two-tone]="row.isTwoTone" title="Expand/Collapse Row"
                        (click)="toggleExpandRow(row)">
                    </a>

                    <div [ngStyle]="{'margin-left':searchType === searchTypes.Normal ? '10px' : '0px' }">
                        <div class="color-none color-box" *ngIf="!row.hasColor && !row.isTwoTone">
                            <svg style='width: 100%; height: 100%;'>
                                <line x1="0" y1="100%" x2="100%" y2="0"></line>
                                <line x1="0" y1="0" x2="100%" y2="100%"></line>
                            </svg>
                        </div>
                        <div class="color-solid color-box" *ngIf="row.hasColor && !row.isTwoTone"
                            [ngStyle]="{'background-color': 'rgb(' + row.color45 + ')'}"></div>
                        <div class="color-gradient color-box" *ngIf="row.isPb"
                            [ngStyle]="{'background-image': 'linear-gradient(to right, rgb(' + row.color45 + ') 0%, rgb(' + row.color25 + ') 60%, rgb(' + row.color75 + ') 100%)'}">
                        </div>
                        <div class="color-two-tone color-box" *ngIf="row.isTwoTone">
                            <div class="two-tone-img"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <!-- Var -->
        <ngx-datatable-column headerClass="results-header" prop="var"
            name="{{'FormulationSearch.Column.Var' | translate }}" *ngIf="searchType === searchTypes.Normal">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div container="body" openDelay="250" closeDelay="0" ngbTooltip="{{row.varTooltip}}">
                    {{value}}
                </div>
            </ng-template>
        </ngx-datatable-column>

        <!-- Var or Flop-->
        <ngx-datatable-column headerClass="results-header" prop="fVar"
            name="{{'FormulationSearch.Column.VarOfFlop' | translate }}"
            *ngIf="(searchType === searchTypes.Normal || searchType === searchTypes.Formulation)">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div container="body" openDelay="250" closeDelay="0" ngbTooltip="{{row.fVarTooltip}}">
                    {{value}}
                </div>
            </ng-template>
        </ngx-datatable-column>

        <!-- SF -->
        <ngx-datatable-column headerClass="results-header" prop="sf"
            name="{{'FormulationSearch.Column.SF' | translate }}" *ngIf="searchType === searchTypes.Formulation">
        </ngx-datatable-column>

        <!-- Formulation No -->
        <ngx-datatable-column [width]="formulationWidth" headerClass="results-header" prop="formulationNo"
            name="{{'FormulationSearch.Column.FormulationNo' | translate }}"
            *ngIf="searchType === searchTypes.Formulation">
        </ngx-datatable-column>

        <!-- Manufacturer -->
        <ngx-datatable-column [width]="manufacturerWidth" headerClass="results-header" prop="manufacturer"
            name="{{'FormulationSearch.Column.Manufacturer' | translate }}" *ngIf="searchType === searchTypes.Normal">
        </ngx-datatable-column>

        <!-- Year From Desktop only -->
        <ngx-datatable-column headerClass="results-header" prop="buildYearFrom"
            name="{{'FormulationSearch.Column.From' | translate }}"
            *ngIf="searchType === searchTypes.Normal && isDesktopView">
        </ngx-datatable-column>

        <!-- Year To Desktop only -->
        <ngx-datatable-column headerClass="results-header" prop="buildYearTo"
            name="{{'FormulationSearch.Column.To' | translate }}"
            *ngIf="searchType === searchTypes.Normal && isDesktopView">
        </ngx-datatable-column>

        <!-- Color Number -->
        <ngx-datatable-column headerClass="results-header" prop="colorNumber"
            name="{{'FormulationSearch.Column.ColorNumber' | translate }}" *ngIf="searchType === searchTypes.Normal">
        </ngx-datatable-column>

        <!-- Color Name Desktop -->
        <ngx-datatable-column [width]="colorNameWidth" headerClass="results-header" prop="colorName"
            name="{{'FormulationSearch.Column.ColorName' | translate }}" *ngIf="searchType === searchTypes.Normal">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <ng-template #tipContent>
                    <div [innerHTML]="row.synonymColorNameAndHeaderString"></div>
                </ng-template>
                <div container="body" openDelay="250" closeDelay="0" [ngbTooltip]="row.synonymColorNameString !== null ? tipContent : ''">
                    {{value}}
                </div>
            </ng-template>
        </ngx-datatable-column>

        <!-- MCS2 -->
        <ngx-datatable-column headerClass="results-header" prop="mcs2"
            name="{{'FormulationSearch.Column.McsNo2' | translate }}"
            *ngIf="searchType !== searchTypes.Formulation && searchSettings.mcsType === 2">
        </ngx-datatable-column>

        <!-- MCS3 -->
        <ngx-datatable-column [width]="mcs3Width" headerClass="results-header" prop="mcs3"
            name="{{'FormulationSearch.Column.McsNo3' | translate }}"
            *ngIf="searchType !== searchTypes.Formulation && searchSettings.mcsType === 3">
            <ng-template let-value="value" let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <div><span>{{row.mcs3}}</span><span *ngIf="row.compartmentNo"> ({{row.compartmentNo}})</span></div>
            </ng-template>
        </ngx-datatable-column>

        <!-- OC -->
        <ngx-datatable-column headerClass="results-header" prop="oc"
            name="{{'FormulationSearch.Column.OC' | translate }}" *ngIf="isOCQuality">
            <ng-template let-value="value" let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <quality-button (click)="showFormulationDataDialog(row, qualityTypes.OC)" [value]="row.oc"
                    *ngIf="row.oc !== null"></quality-button>
            </ng-template>
        </ngx-datatable-column>

        <!-- BC -->
        <ngx-datatable-column headerClass="results-header" prop="bc"
            name="{{'FormulationSearch.Column.BC' | translate }}" *ngIf="isBCQuality">
            <ng-template let-value="value" let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <quality-button (click)="showFormulationDataDialog(row, qualityTypes.BC)" [value]="row.bc"
                    *ngIf="row.bc !== null"></quality-button>
            </ng-template>
        </ngx-datatable-column>

        <!-- PUR -->
        <ngx-datatable-column headerClass="results-header" prop="pur"
            name="{{'FormulationSearch.Column.PUR' | translate }}" *ngIf="isPURQuality">
            <ng-template let-value="value" let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <quality-button (click)="showFormulationDataDialog(row, qualityTypes.PUR)" [value]="row.pur"
                    *ngIf="row.pur !== null"></quality-button>
            </ng-template>
        </ngx-datatable-column>

        <!-- WBC -->
        <ngx-datatable-column headerClass="results-header" prop="wbc"
            name="{{'FormulationSearch.Column.WBC' | translate }}" *ngIf="isWBCQuality">
            <ng-template let-value="value" let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <quality-button (click)="showFormulationDataDialog(row, qualityTypes.WBC)" [value]="row.wbc"
                    *ngIf="row.wbc !== null"></quality-button>
            </ng-template>
        </ngx-datatable-column>

        <!-- PURHS -->
        <ngx-datatable-column headerClass="results-header" prop="purhs"
            name="{{'FormulationSearch.Column.PURHS' | translate }}" *ngIf="isPURHSQuality">
            <ng-template let-value="value" let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <quality-button (click)="showFormulationDataDialog(row, qualityTypes.PURHS)" [value]="row.purhs"
                    *ngIf="row.purhs !== null"></quality-button>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <div class="no-results-found" *ngIf="noResultsFound">{{'FormulationSearch.Text.NoResultsFound' | translate}}</div>
</div>
