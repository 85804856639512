import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Endpoints {
    apiSearch: string;
    apiModels: string;
    apiCoatings: string;
    apiContrastColors: string;
    apiSearchFormData: string;
    apiFormulationData: string;
    apiMixing: string;
    apiAncillaryProductData: string;
    apiAncillaryProductContainers: string;
    apiAncillaryProductComponents: string;
    apiColorByContrast: string;
    apiLogin: string;
    apiRefreshAccess: string;
    apiAuthenticated: string;
    analytics: string;
    apiFormulationDataQualityInfo: string;
    apiRegister: string;
    apiCustomersGet: string;
    apiCustomersBan: string;
    apiCustomersDelete: string;
    apiRequestPasswordReset: string;
    apiResetPassword: string;
    apiAnalyticsOptions: string;
    apiCreateAnalyticsRecipe: string;
    apiUpdateAnalyticsRecipe: string;
    apiCreateAnalyticsAncillaryProduct: string;
    apiUpdateAnalyticsAncillaryProduct: string;
    apiStartSession: string;
}
