import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { ColorRow } from "../../../../formulation/models/color-row";
import { FormulationDataCriteria } from "../../../../formulation-data/models/formulation-data-criteria";
import { SearchType } from "../../../enums/search-type";
import { FormulationDataDialogService } from "../../../../formulation-data/services/formulation-data-dialog.service";
import { VirtualScrollerComponent } from "ngx-virtual-scroller";
import { NavigationEnd, Router } from "@angular/router";
import { String as StringOperations } from "typescript-string-operations";
import { sections } from "../../../../shared/configuration/sections";
import { SearchSettings } from "../../../models/search-settings";
import { IsMobileViewPipe } from "../../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search-results-tile-view',
    templateUrl: './tile-view.component.html',
    styleUrls: ['./tile-view.component.scss']
})
export class TileViewComponent implements OnInit, OnDestroy {
    @Input() noResultsFound: boolean;
    @Input() voc: string;
    @Input() isSearchFormInvisible: boolean;
    @Input() searchType: SearchType;
    @Input() searchTabIsSelected: boolean;
    @Output() selectedColorChanged = new EventEmitter<ColorRow>();
    @ViewChild('scroll', { static: true }) scroll: VirtualScrollerComponent;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    navigationSub: Subscription;
    verticalOffset: number;
    selectedColor: ColorRow;

    someColorsNotAvailable: boolean;

    private _colors: ColorRow[];

    constructor(
        private formulationDataDialogService: FormulationDataDialogService,
        private router: Router,
        public searchSettings: SearchSettings,
        private mobileViewPipe: IsMobileViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.selectedColor = null;
        this.selectedColorChanged.emit(null);
        this.navigationSub = this.router.events.subscribe(x => {
            if (x && x instanceof NavigationEnd && !StringOperations.IsNullOrWhiteSpace(x.url) && x.url === `/${sections.search}`) {
                this.onNavigatedTo();
            }
        });
    }

    ngOnDestroy() {
        this.navigationSub.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

    selectColor(color: ColorRow) {
        if (color) {
            this.selectedColor = color;
            this.selectedColorChanged.emit(color);
        }
        if (this.isMobileView) {
            this.onTileDoubleClick();
        }
    }

    onTileDoubleClick() {
        const criteria = FormulationDataCriteria.fromColor(this.selectedColor, this.searchType, this.voc);
        if (criteria.color.isTwoTone) {
            return;
        }

        this.formulationDataDialogService.showDialog(criteria, false);
    }

    onScroll() {
        this.verticalOffset = this.scroll.viewPortInfo.scrollStartPosition;
    }

    onNavigatedTo() {
        // Only scroll if the back button brings you straight to the search tab - otherwise the scroll just invalidates the vertical offset.
        if (this.searchTabIsSelected) {
            this.scroll.scrollToPosition(this.verticalOffset);
        }
    }

    @Input() set colors(value: ColorRow[]) {
        if (value != null && value.length > 0) {
            this._colors = value.filter(x => x.sf === 'TT' || x.oc && x.oc < 3 || x.bc && x.bc < 3 || x.pur && x.pur < 3 || x.wbc && x.wbc < 3 || x.purhs && x.purhs < 3);
            this.someColorsNotAvailable = this._colors.length !== value.length;
        }
        else {
            this._colors = value;
            this.someColorsNotAvailable = false;
        }

    }

    get colors(): ColorRow[] {
        return this._colors;
    }

    get isFormulationSearch(): boolean {
        return this.searchType === SearchType.Formulation;
    }
}
