import { IDeserialize } from "../../shared/interfaces/deserialize";

export class RelationContainer implements IDeserialize {
    relation: number;
    relationMaximum: number;
    relationMinimum: number;
    name: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
