<ngx-datatable #tableModels [ngStyle]="{'font-size': fontsize+'px', 'height': height}" class="models-table"
    [rows]="color?.models" [columnMode]="'force'" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
    [scrollbarV]="true" [virtualization]="true" [selectionType]="'single'" (select)='onSelect($event)'>
    <!-- Model -->
    <ngx-datatable-column headerClass="models-header" prop="model" [flexGrow]="2"
        name="{{'FormulationSearch.Column.Model' | translate }}">
    </ngx-datatable-column>

    <!-- From -->
    <ngx-datatable-column headerClass="models-header" prop="buildYearFrom" [flexGrow]="1"
        name="{{'FormulationSearch.Column.From' | translate }}">
    </ngx-datatable-column>

    <!-- To -->
    <ngx-datatable-column headerClass="models-header" prop="buildYearTo" [flexGrow]="1"
        name="{{'FormulationSearch.Column.To' | translate }}">
    </ngx-datatable-column>
</ngx-datatable>
<div class="models-data-error row-details-data-error" *ngIf="isModelsLoadError">
    <p>{{'General.Text.LoadRequiredDataError' | translate}}</p>
    <a class="click-message" href="javascript:void(0)" (click)="loadModels()">{{'General.Text.ClickToRetry' |
        translate}}</a>
</div>
<div *ngIf="isProcessing" class="row-details-data-loader">
    <dots-loader></dots-loader>
</div>
