export class AnalyticsRecipeDto
{
    manufacturer?: string;

    mcs?: string;

    updateStatus?: string;

    colorNumber?: string;

    colorName?: string;

    variant?: string;

    flopVariant?: string;

    model?: string;

    yearOfBuild?: string;

    colorGroup?: string;

    colorCategoryId?: number;

    quality?: string;

    glossLevel?: string;

    quantity?: number;

    isNew?: boolean;

    creationDate?: string;

    dateOfChange?: string;

    vocConcentration?: string;

    manufacturerLongName?: string;

    formulationNo?: string;

    public constructor(init?: Partial<AnalyticsRecipeDto>)
    {
        Object.assign(this, init);
    }
}
