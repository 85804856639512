import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'formulation-number-field',
    templateUrl: './formulation-number.component.html',
    styles: []
})

export class FormulationNumberComponent extends FormField<string> implements OnInit {

    ngOnInit() {
    }

}
