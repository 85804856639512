import { IDeserialize } from "../../shared/interfaces/deserialize";

export class Quality implements IDeserialize {
    id: number;
    unitId: number;
    shortcut: string;
    name: string;
    qualityNo: number;
    sort: number;
    isPbActive: boolean;
    hasCoatings: boolean;

    deserialize(input: any) {
        Object.assign(<any>this, input);
        return this;
    }
}
