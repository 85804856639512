import { Injectable } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import {Endpoints} from "src/app/shared/models/endpoints";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {HttpService} from "src/app/core/http-service";

@Injectable({
    providedIn: 'root'
})
export class SessionService
{

    constructor( private http: HttpService,
                 private logger: NGXLogger,
                 private endpoints: Endpoints)
    {
    }

    async startSession(): Promise<void>
    {
        await this.http.post(this.endpoints.apiStartSession)
            .pipe(
                catchError(err => {
                    this.logger.error('An error has occurred while starting the session.', err);
                    return throwError(err);
                }),
            ).toPromise();
    }
}
