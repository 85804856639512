import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { MixingCriteria } from "../models/criteria/default/mixing-criteria";
import { MixingContainer } from "../models/mixing-data/default/mixing-container";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { Endpoints } from "../../shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class MixingDataService {

    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints) { }

    async getMixingContainer(criteria: MixingCriteria): Promise<MixingContainer> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return await this.http.post<MixingContainer>(this.endpoints.apiMixing, JSON.stringify(criteria), { headers })
            .pipe(
                map(x => new MixingContainer().deserialize(x)),
                catchError(err => {
                    this.logger.error("Failed to retrieve the mixing data.", err);
                    return throwError(err);
                }),
            ).toPromise();
    }
}
