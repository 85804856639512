import { IDeserialize } from "../../shared/interfaces/deserialize";

export class ColorCategory implements IDeserialize {
    public id: number;
    public name: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
