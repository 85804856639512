import {
    AfterViewInit,
    Component,
    EventEmitter, Input,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { sections } from "../../../shared/configuration/sections";
import { Router } from "@angular/router";
import { IFormField } from "../../../shared/components/base/forms/form-field-interface";
import { TermsField } from "./fields/terms.component";
import { PrivacyField } from "./fields/privacy-field.component";
import { PasswordField } from "./fields/password.component";
import { PasswordConfirmField } from "./fields/password-confirm.component";
import { UsernameField } from "./fields/username.component";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-register-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss']
})
export class Step2Component implements AfterViewInit {

    passwordMinLength = 8;

    @Output() step2To3Emitter = new EventEmitter<any>();

    @Output() step2To1Emitter = new EventEmitter<any>();

    @Output() step2To4Emitter = new EventEmitter<any>();

    @Output() register = new EventEmitter<any>();

    @ViewChildren('required') requiredFields: QueryList<IFormField>;

    @ViewChild('username', { static: true }) username: UsernameField;
    @ViewChild('password', { static: true }) password: PasswordField;
    @ViewChild('passwordConfirm', { static: true }) passwordConfirm: PasswordConfirmField;
    @ViewChild('terms', { static: true }) terms: TermsField;
    @ViewChild('privacy', { static: true }) privacy: PrivacyField;

    @Input() isProcessing: boolean;
    @Input() processingErrorMessage: string;
    @Input() isProcessingError: boolean;

    hasRequirementsError: boolean;
    termsNotAgreed: boolean;
    privacyNotAgreed: boolean;
    passwordNotMaching: boolean;
    passwordMinLengthError: boolean;

    termOfUseFromAssetsFolder: any;

    get isDyoMode() {
        return environment.theme.id === "dyo";
    }

    constructor(private router: Router, private renderer: Renderer2) {
        this.renderer.addClass(document.body, 'auth-body');
        this.loadScripts();
    }

    loadScripts() {
        const dynamicScripts = [
            'https://widget.preeco.de/v2/loader.js'
        ];
        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = true;
            node.defer = true;
            node.prefix;
            node.setAttribute("data-preeco-loader", "");
            //node.data-preeco-loader = true;
            //node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }


    ngAfterViewInit(): void {
        this.registerRequiredFieldsEvents();
    }

    goToStep1() {
        this.step2To1Emitter.emit(null);
    }

    goToStep3() {

        this.step2To3Emitter.emit(null);
    }

    goToStep4() {

        this.step2To4Emitter.emit(null);
    }

    submit() {
        if (!this.validate()) { return; }

        this.register.emit(null);

        return;
    }

    public validate(): boolean {
        this.clearErrors();

        if (!this.validateRequirements()) { return false; }

        if (!this.validateTerms()) { return false; }

        if (this.isDyoMode === false) {
            if (!this.validatePrivacy()) { return false; }
        }

        if (!this.validatePasswordLength()) { return false; }

        if (!this.validatePasswordMatch()) { return false; }

        return true;
    }

    validateTerms() {
        this.termsNotAgreed = !this.terms.value || this.terms.value != true;

        return !this.termsNotAgreed;
    }

    validatePrivacy() {
        this.privacyNotAgreed = !this.privacy.value || this.privacy.value != true;

        return !this.privacyNotAgreed;
    }

    validatePasswordLength() {
        this.passwordMinLengthError = !this.password.isFilled || this.password.value.length < this.passwordMinLength;

        return !this.passwordMinLengthError;
    }

    validatePasswordMatch() {
        this.passwordNotMaching = !this.password.isFilled || !this.passwordConfirm.isFilled || this.password.value != this.passwordConfirm.value;

        return !this.passwordNotMaching;
    }

    registerRequiredFieldsEvents(): void {
        this.requiredFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onRequiredFieldFilled(isfilled));
        });
    }

    onRequiredFieldFilled(isFilled: boolean) {
        if (this.hasRequirementsError || this.termsNotAgreed || this.passwordMinLengthError || this.passwordNotMaching) {
            this.validate();
        }
    }

    private validateRequirements(): boolean {
        if (!this.isDyoMode) {
            this.hasRequirementsError = this.requiredFields.some(x => !x.isFilled);
        }
        else {
            for (const field of this.requiredFields) {
                if (PrivacyField.name === field.constructor.name) {
                    continue;
                }
                this.hasRequirementsError = !field.isFilled;
            }
        }
        return !this.hasRequirementsError;
    }

    private clearErrors(): void {
        this.hasRequirementsError = false;
        this.termsNotAgreed = false;
        this.passwordNotMaching = false;
        this.passwordMinLengthError = false;
    }

    cancel() {
        this.router.navigate([`/${sections.login}`]);
    }
}
