export abstract class ValueEventArgs<T>
{
    oldValue: T;
    newValue: T;

    constructor(oldValue: T, newValue: T) {
        this.oldValue = oldValue;
        this.newValue = newValue;
    }
}
