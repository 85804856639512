import { IDeserialize } from "../../shared/interfaces/deserialize";

export class FormulationHistoryItem implements IDeserialize {
    isSelected: boolean;
    date: string;
    formulationNo: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
