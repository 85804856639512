<div class="actions d-flex">

    <button type="button" (click)="printFormulation()" class="btn-mixing-action btn-print d-inline-flex">
        <span class="img-container">
            <img class="img-print" src="/assets/images/printer.png" />
        </span>
        <span *ngIf="!isMobileView" class="text">{{'Formulation.Button.FormulationPrint' | translate}}</span>
    </button>

    <button type="button" [disabled]="!isAncillaryProductEnabled" [hidden]="isDyoMode" (click)="showAncillaryProductDialog()"
        class="btn-mixing-action btn-ancillary-product d-inline-flex">
        <span class="img-container">
            <img class="img-drop" src="/assets/images/spray-gun.png" />
        </span>
        <span *ngIf="!isMobileView" class="text">{{'Formulation.Button.AncillaryProduct' | translate}}</span>
    </button>

    <div class="flex-grow-1"></div>

    <button type="button" (click)="navigateBack()" class="btn-mixing-action d-inline-flex btn-back">
        <span class="img-container">
            <img src="/assets/images/chevron-left.png" />
        </span>
        <span *ngIf="!isMobileView" class="text">{{'FormulationSearch.Button.Back' | translate}}</span>
    </button>

    <button type="button" (click)="navigateToSearch()"
        class="btn-mixing-action d-inline-flex btn-back btn-back-formulation">
        <span class="img-container">
            <img src="/assets/images/magnify.png" />
        </span>
        <span *ngIf="!isMobileView" class="text">{{'FormulationSearch.Button.FormulationSearch' | translate}}</span>
    </button>
</div>
