<div #logodiv class="auth-section" Auth>
    <div></div>
    <app-standard-page>
        <div class="container d-flex flex-column">

            <div class="auth">
                <h2 class="black-text">{{ 'Authentication.Label.Header' | translate }}</h2>

                <form (keydown)="onKeyDown($event)" #form="ngForm" novalidate>

                    <div class="customer-no">
                        <auth-username #username #required></auth-username>
                    </div>
                    <div class="reg-no">
                        <auth-password #password #required></auth-password>
                    </div>
                    <div *ngIf="hasRequirementsError" class="requirements-error">{{
                        'Authentication.Validation.RequirementsError' | translate }}</div>
                    <div *ngIf="isAuthError" class="requirements-error">{{authError}}</div>
                    <div *ngIf="isAuthenticating" class="auth-loader">
                        <dots-loader class="black-text"
                            caption="{{'General.Text.PleaseWaitAuthenticating' | translate}}"></dots-loader>
                    </div>
                    <button *ngIf="!isAuthenticating" type="button" (click)="authenticate()"
                        class="btn btn-block btn-default btn-login">{{ 'Authentication.Button.Login' | translate
                        }}</button>
                    <div *ngIf="!isAuthenticating" class="links bg-white text-center">
                        <a class="register" (click)="goToRegister()" href="javascript:void(0)">{{'Register.Link.Caption'
                            | translate}}</a>
                    </div>
                    <div *ngIf="!isAuthenticating" class="links bg-white text-center">
                        <a class="register" (click)="goToResetPassword()" href="javascript:void(0)">{{'RequestPasswordReset.Link.Caption'| translate}}</a>
                    </div>

                </form>
            </div>

        </div>
    </app-standard-page>
</div>
