import { Component, Input, OnInit, Directive } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { LocalizationService } from "../../../../core/localization/localization.service";
import { Settings } from "../../../../shared/models/settings";

@Component({
    selector: 'formulation-data-quantity',
    templateUrl: './quantity.component.html',
    styles: []
})

export class FormulationDataQuantityComponent extends FormField<number> implements OnInit {

    decimalSeparator: string;

    _defaultQuantity: number;

    get defaultQuantity() { return this._defaultQuantity; }

    @Input()
    set defaultQuantity(value) {
        this._defaultQuantity = value;
        this.setDefaultQuantity();
    }

    get display(): string {
        const val = this.value ? this.value : 0;

        let result: string = val.toString().replace('.', this.decimalSeparator);
        result = result.replace(',', this.decimalSeparator);

        return result;
    }

    set display(val: string) {
        this.decimalSeparator = val.includes(',') ? ',' : val.includes('.') ? '.' : '';
        this.value = +val.replace(',', '.');
    }

    constructor(private localizationService: LocalizationService, private settings: Settings) {
        super();

        this.decimalSeparator = this.localizationService.selectedLanguage.getDecimalSeparator();
    }

    ngOnInit() {
    }

    setDefaultQuantity() {
        if (this.defaultQuantity) {
            this.value = this.defaultQuantity;
        }
    }

    onFocus(event: any) {
        event.target.select();
    }
}

@Directive()
export class AncillaryQuantityField extends FormField<number> implements OnInit {

    _defaultQuantity: number;

    get defaultQuantity() { return this._defaultQuantity; }

    @Input()
    set defaultQuantity(value) {
        this._defaultQuantity = value;
        this.setDefaultQuantity();
    }

    get display(): string {
        const val = this.value ? this.value : 0;

        let result: string = val.toString().replace('.', this.decimalSeparator);
        result = result.replace(',', this.decimalSeparator);
        return result;
    }

    set display(val: string) {
        this.decimalSeparator = val.includes(',') ? ',' : val.includes('.') ? '.' : '';
        this.value = +val.replace(',', '.');
    }

    decimalSeparator: string;

    constructor(private localizationService: LocalizationService) {
        super();

        this.decimalSeparator = this.localizationService.selectedLanguage.getDecimalSeparator();
    }

    ngOnInit() {
    }

    setDefaultQuantity() {
        if (this.defaultQuantity) {
            this.value = this.defaultQuantity;
        }
    }

    onFocus(event: any) {
        event.target.select();
    }
}
