import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Endpoints } from "../../shared/models/endpoints";
import { Credentials } from "../models/credentials";
import { LoginResponse } from "../models/login-response";
import { LoginResponseType } from "../enums/login-response-type";
import { AuthState } from "../models/auth-state";
import { String } from "typescript-string-operations";
import { RefreshAccessResponse } from "../models/refresh-access-response";
import { RefreshAccessRequest } from "../models/refresh-access-request";
import { HttpService } from 'src/app/core/http-service';

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints,
        private authState: AuthState) {
    }

    public async authenticate(credentials: Credentials): Promise<LoginResponse> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const result = await this.http.post<LoginResponse>(this.endpoints.apiLogin, JSON.stringify(credentials), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`Authentication failed.'`, err);
                    return throwError(err);
                }),
            )
            .toPromise<LoginResponse>();

        const response = new LoginResponse().deserialize(result);

        if (response.loginResponseType === LoginResponseType.Success) {
            this.onAuthenticated(response.accessToken, response.refreshToken);
        }

        return response;
    }

    public getAccessToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY);
    }

    public refreshAccess(): Observable<RefreshAccessResponse> {
        const refreshAccessRequest = new RefreshAccessRequest(this.getAccessToken(), this.getRefreshToken());

        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return this.http.post<RefreshAccessResponse>(this.endpoints.apiRefreshAccess, JSON.stringify(refreshAccessRequest), { headers });
    }

    public logout() {
        this.clearTokens();

        window.location.reload();
    }

    public onAuthenticated(accessToken: string, refreshToken: string) {
        if (String.IsNullOrWhiteSpace(accessToken)) { throw "The access token must not be null."; }
        if (String.IsNullOrWhiteSpace(refreshToken)) { throw "The refresh token must not be null."; }

        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        this.authState.isAuthenticated = true;
    }

    public async isAuthenticated(): Promise<boolean> {
        const response = await this.http.get<Response>(this.endpoints.apiAuthenticated).toPromise();

        return response && response.status === 200;
    }

    public clearTokens() {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);

        this.authState.isAuthenticated = false;
    }

    private getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }
}

