import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'address1-field',
    templateUrl: './address1.component.html',
    styles: []
})
export class Address1Field extends FormField<string> implements OnInit {

    ngOnInit() {
    }

}
