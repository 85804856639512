import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PrintingService } from "../services/printing.service";
import { Subscription } from "rxjs";
import { FormulationPrintModel } from "../models/FormulationPrintModel";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'formulation-printing-component',
    templateUrl: './printing.component.html',
    styleUrls: ['./printing.component.scss']
})
export class PrintingComponent implements OnInit, OnDestroy {

    printSub: Subscription;
    models: FormulationPrintModel[];
    logoPath = "/assets/images/printing-logo.jpg";

    constructor(private printingService: PrintingService, private changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        if(environment.theme.id === "dyo") {
            this.logoPath = "/assets/images/dyo_logo.svg";
        }

        this.printSub = this.printingService.onPrintRequested.subscribe((x: FormulationPrintModel[]) => {
            this.models = x;

            this.changeDetector.detectChanges();

            setTimeout(function () {
                window.print();
                window.print;
            }, 250);
        });

    }

    ngOnDestroy(): void {
        this.printSub.unsubscribe();
    }

}
