import { Component, Input, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { Unit } from "../../../../formulation/models/unit";

@Component({
    selector: 'formulation-data-unit',
    templateUrl: './unit.component.html',
    styles: []
})
export class FormulationDataUnitComponent extends FormField<Unit> implements OnInit {

    @Input() units: Unit[];

    ngOnInit() {
    }
}
