import { Injectable } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import { HttpService } from '../../http-service';
import {Endpoints} from "src/app/shared/models/endpoints";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {AnalyticsOptions} from "src/app/core/analytics/models/analytics-options";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsOptionsService
{

    constructor( private http: HttpService,
                private logger: NGXLogger,
                private endpoints: Endpoints,
                private analyticsOptions: AnalyticsOptions) {

    }
    async initialize(): Promise<void>
    {
        const result = await this.http.get<AnalyticsOptions>(this.endpoints.apiAnalyticsOptions)
            .pipe(
                catchError(err => {
                    this.logger.error('Failed to retrieve the analytics options.', err);
                    return throwError(err);
                }),
            ).toPromise();

        Object.assign(this.analyticsOptions, result);
    }
}
