import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AncillaryProductData } from "../models/ancillary-product-data";
import { AncillaryProductDataService } from "../services/ancillary-product-data-service";
import { AncillaryProductCriteria } from "../models/criteria/ancillary-product-criteria";
import { MixingNavigationService } from "../../formulation-mixing/services/mixing-navigation.service";
import { Router } from "@angular/router";
import { sections } from "../../shared/configuration/sections";
import { ApMixingContainer } from "../../formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import { ApMixingCriteiraGenerator } from "../../formulation-mixing/services/ap-mixing-criteira-generator";
import { AncillaryQualityField } from "./fields/quality/quality.component";
import { AncillaryProductField } from "./fields/product/product.component";
import { AncillaryVocField } from "./fields/voc/voc.component";
import { AncillaryGlossLevelField } from "./fields/gloss-level/gloss-level.component";
import { AncillaryTemperatureField } from "./fields/temperature/temperature.component";
import { AncillaryUnitField } from "./fields/unit/unit.component";
import { AncillaryQuantityField } from "./fields/quantity/quantity.component";
import { IsMobileViewPipe } from "../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ancillary-product-component',
    templateUrl: './ancillary-product.component.html',
    styleUrls: ['./ancillary-product.component.scss']
})
export class AncillaryProductComponent implements OnInit, OnDestroy {

    @ViewChild('quality') quality: AncillaryQualityField;
    @ViewChild('product') product: AncillaryProductField;
    @ViewChild('quantity') quantity: AncillaryQuantityField;
    @ViewChild('unit') unit: AncillaryUnitField;
    @ViewChild('temperature') temperature: AncillaryTemperatureField;
    @ViewChild('glossLevel') glossLevel: AncillaryGlossLevelField;
    @ViewChild('voc') voc: AncillaryVocField;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    isMobileView: boolean;
    isDataLoadError: boolean;
    isMixingError: boolean;
    isMixing: boolean;
    isLoadingData: boolean;

    clearHistory: boolean;

    criteria: AncillaryProductCriteria;

    get isLoaded() {
        return this.data.isLoaded;
    }

    constructor(private data: AncillaryProductData,
        private modal: NgbActiveModal,
        private router: Router,
        private ancillaryProductDataService: AncillaryProductDataService,
        private mixingNavigation: MixingNavigationService,
        private mixingCriteriaGenerator: ApMixingCriteiraGenerator,
        private mobileViewPipe: IsMobileViewPipe) { }

    async ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(evt => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });

        this.isMobileView = this.mobileViewPipe.transform(window);
        await this.loadRequiredData();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    retryLoadRequiredData(): void {
        this.isDataLoadError = false;

        this.loadRequiredData();
    }

    async loadRequiredData(): Promise<any> {
        try {
            if (this.data.isLoaded) { return; }

            this.isLoadingData = true;

            const data = await this.ancillaryProductDataService.getData();

            this.data = this.data.deserialize(data);

            this.data.isLoaded = true;
        }
        catch
        {
            this.isDataLoadError = true;
        }
        finally {
            this.isLoadingData = false;
        }
    }

    cancel() {
        this.modal.close(false);
    }

    async ok() {
        const container = await this.getMixingContainer();

        if (container == null) {
            return;
        }

        this.modal.close(true);

        this.mixingNavigation.navigate(container, this.clearHistory);

        const mixingUrl = `/${sections.mixing}`;

        if (this.router.url !== mixingUrl) {
            await this.router.navigate([mixingUrl]);
        }
    }

    private async getMixingContainer(): Promise<ApMixingContainer> {
        const mixingCriteria = this.mixingCriteriaGenerator.fromComponent(this);

        try {
            this.isMixingError = false;
            this.isMixing = true;

            const mixingContainer = new ApMixingContainer();

            mixingContainer.products = await this.ancillaryProductDataService.getAncillaryProductContainers(mixingCriteria);
            mixingContainer.ancillaryProductCriteria = this.criteria;
            mixingContainer.mixingCriteira = mixingCriteria;

            mixingContainer.selectedProduct = mixingContainer.products[0];

            if (mixingContainer.selectedProduct) {
                mixingContainer.thinnerRelation1 = mixingContainer.selectedProduct.thinnerRelation11;
                mixingContainer.thinnerRelation2 = mixingContainer.selectedProduct.thinnerRelation21;
            }

            return mixingContainer;
        }
        catch
        {
            this.isMixingError = true;

            return null;
        }
        finally {
            this.isMixing = false;
        }
    }

    onKeyDown(event) {
        if (event.key === "Enter") {
            this.ok();
        }
    }
}
