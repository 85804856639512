export class AnalyticsRecipeFormulationDto
{
    formulationNo: string;

    formulationDate: string;

    public constructor(init?: Partial<AnalyticsRecipeFormulationDto>)
    {
        Object.assign(this, init);
    }
}
