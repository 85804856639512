import { AncillaryProductData } from "../models/ancillary-product-data";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AncillaryProductContainer } from "../models/ancillary-product-container";
import { ApMixingCriteria } from "../../formulation-mixing/models/criteria/ancillary-product/ap-mixing-criteria";
import { MixingComponent } from "../../formulation-mixing/models/mixing-data/default/mixing-component";
import { AncillaryProductComponentsCriteria } from "../../formulation-mixing/models/criteria/ancillary-product/ancillary-product-components-criteria";
import { Endpoints } from "../../shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';

@Injectable({ providedIn: 'root' })
export class AncillaryProductDataService {
    constructor(private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints) { }

    async getData(): Promise<AncillaryProductData> {
        return await this.http.get<AncillaryProductData>(this.endpoints.apiAncillaryProductData)
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to retrieve the ancillary product data.", err);
                    return throwError(err);
                }),
            ).toPromise();
    }

    async getAncillaryProductContainers(criteria: ApMixingCriteria): Promise<AncillaryProductContainer[]> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const result = await this.http.post<AncillaryProductContainer[]>(this.endpoints.apiAncillaryProductContainers, JSON.stringify(criteria), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to retrieve the ancillary product containers.", err);
                    return throwError(err);
                }),
            ).toPromise<AncillaryProductContainer[]>();

        return result.map(x => new AncillaryProductContainer().deserialize(x));
    }

    getAncillaryProductComponentsMobile(criteria: AncillaryProductComponentsCriteria): Observable<MixingComponent[]> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return this.http.post<MixingComponent[]>(this.endpoints.apiAncillaryProductComponents, JSON.stringify(criteria), { headers });
    }

    async getAncillaryProductComponents(criteria: AncillaryProductComponentsCriteria): Promise<MixingComponent[]> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const result = await this.http.post<MixingComponent[]>(this.endpoints.apiAncillaryProductComponents, JSON.stringify(criteria), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to retrieve the ancillary product components.", err);
                    return throwError(err);
                }),
            ).toPromise();

        return result.map(x => new MixingComponent().deserialize(x));
    }
}
