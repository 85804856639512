import { Component, Input, OnInit } from '@angular/core';
import { Quality } from "../../../../formulation/models/quality";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { AncillaryProductData } from "../../../models/ancillary-product-data";
import { QualityType } from "../../../../formulation/enums/quality-type";

@Component({
    selector: 'ancillary-quality',
    templateUrl: './quality.component.html',
    styles: []
})
export class AncillaryQualityField extends FormField<Quality> implements OnInit {

    _availableQualityTypes: QualityType[];

    get availableQualityTypes() { return this._availableQualityTypes; }

    @Input()
    set availableQualityTypes(value) {
        this._availableQualityTypes = value;
        this.refresh();
    }

    filteredQualities: Quality[];

    constructor(private data: AncillaryProductData) {
        super();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        if (this.data.isLoaded) {
            this.filteredQualities = this.availableQualityTypes ? this.data.qualities.filter(x => this.availableQualityTypes.some(y => y == x.qualityNo)) : this.data.qualities;
            this.value = this.filteredQualities && this.filteredQualities.length > 0 ? this.filteredQualities[0] : null;
        }
        else {
            this.filteredQualities = null;
            this.value = null;
        }
    }
}
