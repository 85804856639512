import { throwError } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { catchError } from "rxjs/operators";
import { FormulationData } from "../models/formulation-data";
import { Endpoints } from "../../shared/models/endpoints";
import { FormulationDataQualityInfoResponse } from "../models/formulation-data-quality-info-response";
import { FormulationDataQualityInfoRequest } from "../models/formulation-data-quality-info-request";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class FormulationDataService {

    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints) { }

    async getFormulationData(): Promise<FormulationData> {
        return await this.http.get<FormulationData>(this.endpoints.apiFormulationData)
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to retrieve the formulation data.", err);
                    return throwError(err);
                }),
            ).toPromise();
    }

    async getQualityInfo(colorId: number, qualityNo?: number): Promise<FormulationDataQualityInfoResponse> {
        const criteria = new FormulationDataQualityInfoRequest();
        criteria.colorId = colorId;
        criteria.qualityNo = qualityNo;
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");
        const response = await this.http.post<FormulationDataQualityInfoResponse>(this.endpoints.apiFormulationDataQualityInfo, JSON.stringify(criteria), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to retrieve the quality info.", err);
                    return throwError(err);
                }),
            ).toPromise();
        return new FormulationDataQualityInfoResponse().deserialize(response);
    }
}
