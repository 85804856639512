<div class="flex-row d-flex">
    <!-- List View -->
    <button type="button" (click)="changeViewType(ResultsViewType.List)"
        [ngClass]="{'action-button-active': viewType === ResultsViewType.List}" class="action-button"
        ngbTooltip="{{'FormulationSearch.Tooltip.GridView' | translate}}">
        <img style="vertical-align: middle;" src="/assets/images/view-list.png" width="18" height="12" />
    </button>

    <!-- Grid View -->
    <button type="button" (click)="changeViewType(ResultsViewType.Grid)"
        [ngClass]="{'action-button-active': viewType === ResultsViewType.Grid}" class="action-button"
        style="margin-left: 7px;" ngbTooltip="{{'FormulationSearch.Tooltip.TileView' | translate}}">
        <img src="/assets/images/view-grid.png" width="18" height="18" />
    </button>

    <div class="flex-grow-1">
        <div class="total-records" *ngIf="showTotalRecords">{{totalRecordsCount.toLocaleString()}}
            {{'FormulationSearch.Text.RecordsFound' | translate}}</div>
    </div>

    <!-- Show Search Form -->
    <button type="button" *ngIf="isSearchFormCollapsed" (click)="showSearchForm()" class="action-button"
        ngbTooltip="{{'FormulationSearch.Tooltip.ShowSearchForm' | translate}}">
        <img src="/assets/images/magnify.png" width="24" height="24" />
    </button>

    <!-- Hide Search Form -->
    <button type="button" *ngIf="!isSearchFormCollapsed" (click)="closeSearchForm()" class="action-button"
        ngbTooltip="{{'FormulationSearch.Tooltip.CloseSearchForm' | translate}}">
        <img src="/assets/images/chevron-up.png" width="24" height="24" />
    </button>

    <!-- Show Mixing View -->
    <button type="button" [disabled]="selectedColor === null" class="action-button action-button-mixing"
        style="margin-left: 7px;" ngbTooltip="{{'FormulationSearch.Tooltip.WeighingView' | translate}}"
        (click)="showFormulationDataDialog()">
        <img src="/assets/images/balance.png" width="22" height="22" />
    </button>

</div>
