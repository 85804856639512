import { Injectable } from "@angular/core";
import { LocalizationService } from "../../core/localization/localization.service";
import { ApMixingContainer } from "../../formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import { FormulationPrintModel } from "../models/FormulationPrintModel";
import { MixingContainer } from "../../formulation-mixing/models/mixing-data/default/mixing-container";
import { String } from "typescript-string-operations";
import { MixingComponent } from "../../formulation-mixing/models/mixing-data/default/mixing-component";
import { formatDate } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class PrintModelGeneratorAp {
    constructor(private localizationService: LocalizationService) { }

    getPrintModel(container: ApMixingContainer): FormulationPrintModel //, componentsContainer: ApMixingMaterialsComponent): FormulationPrintModel
    {
        const mixingContainer = container.ancillaryProductCriteria.mixingContainer;

        const model = new FormulationPrintModel();

        model.dateOfPrint = this.getDateOfPrint();
        model.formulationNo = this.getFormulationNo(mixingContainer, container.ancillaryProductCriteria.isMixUp);
        model.manufacturer = this.getManufacturer(mixingContainer);
        model.colorCode = this.getColorCode(mixingContainer);
        model.variant = this.getVariant(mixingContainer);
        model.variantFlop = this.getFlopVariant(mixingContainer);
        model.model = this.getModel(mixingContainer);
        model.yearOfBuild = this.getYearOfBuild(mixingContainer);
        model.colorName = this.getColorName(mixingContainer);
        model.coating = "1";
        model.mcs = this.getMcs(mixingContainer, container.ancillaryProductCriteria.isMixUp);
        model.quality = this.getQuality(container);
        model.qualityAdditive = "-";
        model.glossLevel = this.getGlossLevel(container);
        model.quantity = this.getQuantity(container);
        model.materials = this.getMaterials(container);

        return model;
    }

    private getDateOfPrint(): string {
        return new Date().toLocaleDateString(this.localizationService.selectedLanguage.locale);
    }

    private getFormulationNo(container: MixingContainer, isMixUp: boolean) {
        if (container && !String.IsNullOrWhiteSpace(container.formulationNo)) {
            return isMixUp ? container.formulationNo + " (SM)" : container.formulationNo + " (SP)";
        }
        else {
            return "-";
        }
    }

    private getManufacturer(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.manufacturer) ? container.info.manufacturer : "-";
    }

    private getColorCode(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.colorNumber) ? container.info.colorNumber : "-";
    }

    private getVariant(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.variant) ? container.info.variant : "-";
    }

    private getFlopVariant(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.flopVariant) ? container.info.flopVariant : "-";
    }

    private getModel(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.model) ? container.info.model : "-";
    }

    private getYearOfBuild(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.yearOfBuild) ? container.info.yearOfBuild : "-";
    }

    private getColorName(container: MixingContainer) {
        return container && !String.IsNullOrWhiteSpace(container.info.colorName) ? container.info.colorName : "-";
    }

    private getMcs(container: MixingContainer, isMixUp: boolean) {
        if (container && !String.IsNullOrWhiteSpace(container.info.mcs)) {
            return isMixUp ? container.info.mcs + " (SM)" : container.info.mcs + " (SP)";
        }
        else {
            return "-";
        }
    }

    private getQuality(container: ApMixingContainer) {
        return container.mixingCriteira.quality ? container.mixingCriteira.quality.name : "-";
    }

    private getGlossLevel(container: ApMixingContainer) {
        return container.mixingCriteira.glossLevel ? container.mixingCriteira.glossLevel.localizedName : "-";
    }

    private getQuantity(container: ApMixingContainer) {
        return container.mixingCriteira.quantity.toFixed(3) + " " + container.mixingCriteira.unit.name;
    }

    private getMaterials(container: ApMixingContainer): MixingComponent[] {
        return container.childApMaterials.components;
    }
}
