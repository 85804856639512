import { NgModule } from '@angular/core';
import { SearchFormComponent } from "./components/search-form/search-form.component";
import { SearchResultsComponent } from "./components/search-results/search-results.component";
import { YearOfBuildComponent } from "./components/search-form/fields/year-of-build/year-of-build.component";
import { ManufacturerComponent } from "./components/search-form/fields/manufacturer/manufacturer.component";
import { Mcs2Component } from "./components/search-form/fields/mcs2/mcs2.component";
import { ColorNumberComponent } from "./components/search-form/fields/color-number/color-number.component";
import { Mcs3Component } from "./components/search-form/fields/mcs3/mcs3.component";
import { ColorGroupComponent } from "./components/search-form/fields/color-group/color-group.component";
import { FormulationNumberComponent } from "./components/search-form/fields/formulation-number/formulation-number.component";
import { ColorNameComponent } from "./components/search-form/fields/color-name/color-name.component";
import { ModelComponent } from "./components/search-form/fields/model/model.component";
import { SearchActionsBarComponent } from "./components/search-actions-bar/search-actions-bar.component";
import { TileViewComponent } from "./components/search-results/tile-view/tile-view.component";
import { ListViewComponent } from "./components/search-results/list-view/list-view.component";
import { QualityButtonComponent } from "./components/search-results/list-view/quality-button/quality-button.component";
import { RowDetailsComponent } from "./components/search-results/list-view/row-details/row-details.component";
import { ModelsComponent } from "./components/search-results/list-view/row-details/models/models.component";
import { ContrastColorsComponent } from "./components/search-results/list-view/row-details/contrast-colors/contrast-colors.component";
import { TileBoxComponent } from "./components/search-results/tile-view/tile-box/tile-box.component";
import { SearchComponent } from "./components/search.component";
import { SharedModule } from "../shared/shared.module";
import { RegionComponent } from './components/search-form/fields/region/region.component';
import { VocComponent } from './components/search-form/fields/voc/voc.component';
import { McsSelectComponent } from './components/search-form/fields/mcs-select/mcs-select.component';
import { OtherProductsComponent } from './components/other-products/other-products.component';
import { OtherProductsComponentTypeFieldComponent } from "./components/other-products/fields/component-type.field";

@NgModule({
    declarations: [
        SearchComponent,
        SearchFormComponent,
        SearchResultsComponent,
        YearOfBuildComponent,
        ManufacturerComponent,
        Mcs2Component,
        Mcs3Component,
        ColorGroupComponent,
        ColorNameComponent,
        ColorNumberComponent,
        FormulationNumberComponent,
        ModelComponent,
        SearchActionsBarComponent,
        TileViewComponent,
        ListViewComponent,
        QualityButtonComponent,
        RowDetailsComponent,
        ModelsComponent,
        ContrastColorsComponent,
        TileBoxComponent,
        RegionComponent,
        VocComponent,
        McsSelectComponent,
        OtherProductsComponent,
        OtherProductsComponentTypeFieldComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SearchComponent
    ]
})
export class SearchModule {

}
