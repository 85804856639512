import {
    OnInit,
    Component,
    EventEmitter, Output
} from '@angular/core';

@Component({
    selector: 'app-register-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

    @Output() step4To2Emitter = new EventEmitter<any>();

    constructor() {
        this.loadScripts();
    }

    ngOnInit(): void {
    }

    goToStep2() {
        this.step4To2Emitter.emit(null);
    }

    loadScripts() {
        const dynamicScripts = [
            'https://widget.preeco.de/v2/loader.js'
        ];
        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = true;
            node.defer = true;
            node.prefix;
            node.setAttribute("data-preeco-loader", "");
            //node.data-preeco-loader = true;
            //node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

}
