import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'app-new-password-confirm',
    templateUrl: './new-password-confirm.component.html',
    styles: []
})
export class NewPasswordConfirmComponent extends FormField<string> implements OnInit {
    ngOnInit() {
    }
}
