import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModelRow } from "../../../../../../formulation/models/model-row";
import { ContrastColorRow } from "../../../../../../formulation/models/contrast-color-row";
import { ColorRow } from "../../../../../../formulation/models/color-row";
import { ContrastColorDataService } from "../../../../../services/contrast-color-data.service";
import { ColorByContrastCriteria } from "../../../../../models/color-by-contrast-criteria";
import { ColorByContrastService } from "../../../../../services/color-by-contrast.service";
import { FormulationDataDialogService } from "../../../../../../formulation-data/services/formulation-data-dialog.service";
import { FormulationDataCriteria } from "../../../../../../formulation-data/models/formulation-data-criteria";
import { SearchType } from "../../../../../enums/search-type";
import { ErrorDialogService } from "../../../../../../core/dialogs/error-dialog.service";
import { IsMobileViewPipe } from "../../../../../../shared/pipes/is-mobile-view.pipe";
import { IsDesktopViewPipe } from "../../../../../../shared/pipes/is-desktop-view.pipe";
import { IsSmartphoneViewPipe } from "../../../../../../shared/pipes/is-smartphone-view.pipe";
import { IsPadViewPipe } from "../../../../../../shared/pipes/is-pad-view.pipe";
import { fromEvent, Observable, Subscription, throwError } from "rxjs";
import { catchError, debounceTime, share } from 'rxjs/operators';
import { getLoadingStatus } from 'src/app/shared/observables/get-loading-status';

@Component({
    selector: 'app-row-details-contrast-colors',
    templateUrl: './contrast-colors.component.html',
    styleUrls: ['./contrast-colors.component.scss']
})
export class ContrastColorsComponent implements OnInit, OnDestroy {
    @Input() color: ColorRow;
    @Input() searchType: SearchType;
    @Input() voc: string;

    isMobileView: boolean;
    height: string;
    fontsize: string;
    headerHeight: number;
    rowHeight: number;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isProcessingColor: boolean;
    isDataLoadError: boolean;
    isProcessing: boolean;

    private _model: ModelRow;

    constructor(
        private contrastColorDataService: ContrastColorDataService,
        private colorByContrastService: ColorByContrastService,
        private formulationDataDialogService: FormulationDataDialogService,
        private errorDialogService: ErrorDialogService,
        private mobileViewPipe: IsMobileViewPipe,
        private smartphoneViewPipe: IsSmartphoneViewPipe,
        private padViewPipe: IsPadViewPipe,
        private desktopViewPipe: IsDesktopViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.resizeForMobile();
        });
        this.resizeForMobile();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    resizeForMobile() {
        if (this.desktopViewPipe.transform(window)) {
            this.isMobileView = this.mobileViewPipe.transform(window);
            this.height = '200px';
            this.fontsize = '14';
            this.headerHeight = 36;
            this.rowHeight = 34;
            this.changeLineHeight('34px');
            this.changeHeaderHeight('36px');
            this.changeBoxSize('20px');
        }
        else if (this.padViewPipe.transform(window)) {
            this.isMobileView = this.mobileViewPipe.transform(window);
            this.height = '180px';
            this.fontsize = '9';
            this.headerHeight = 32;
            this.rowHeight = 30;
            this.changeLineHeight('30px');
            this.changeHeaderHeight('32px');
            this.changeBoxSize('16px');
        }
        else if (this.smartphoneViewPipe.transform(window)) {
            this.isMobileView = this.mobileViewPipe.transform(window);
            this.height = '160px';
            this.fontsize = '4';
            this.headerHeight = 26;
            this.rowHeight = 24;
            this.changeLineHeight('24px');
            this.changeHeaderHeight('26px');
            this.changeBoxSize('12px');
        }
    }

    changeBoxSize(boxSize: string) {
        document.documentElement.style.setProperty('--action-box-size', boxSize);
    }

    changeLineHeight(lineHeight: string) {
        document.documentElement.style.setProperty('--datatable-line-height', lineHeight);
    }

    changeHeaderHeight(headerHeight: string) {
        document.documentElement.style.setProperty('--datatable-header-height', headerHeight);
    }

    getRowHeight() {
        return 34;
    }

    async loadContrastColors() {
        if (this.model.contrastColorsLoaded || this.isProcessing) {
            return;
        }

        this.isDataLoadError = false;
        const contrastColors = this.contrastColorDataService.getContrastColors(this.color, this.model).pipe(share());
        const showLoadingIndicator = getLoadingStatus(contrastColors);
        contrastColors.subscribe(
            rows => {
                this.model.contrastColors = rows.map(row => new ContrastColorRow().deserialize(row));
                this.model.contrastColorsLoaded = true;
            },
            () => {
                this.isProcessing = false;
                this.isDataLoadError = true;
            });
        showLoadingIndicator.subscribe(isLoading => this.isProcessing = isLoading);
    }

    async showFormulationSearch(selectedRow: ContrastColorRow) {
        try {
            if (this.isProcessingColor) {
                return;
            }

            this.isProcessingColor = true;
            const criteria = this.getCriteria(selectedRow);
            const color = await this.colorByContrastService.getColor(criteria);
            const formulationDataCriteria = FormulationDataCriteria.fromColor(color, this.searchType, this.voc);
            this.formulationDataDialogService.showDialog(formulationDataCriteria, false);
        }
        catch (e) {
            this.isProcessingColor = false;
            this.errorDialogService.showDialog(e.message);
        }
        finally {
            this.isProcessingColor = false;
        }
    }

    getCriteria(selectedRow: ContrastColorRow): ColorByContrastCriteria {
        const criteria = new ColorByContrastCriteria();
        criteria.color = this.color;
        criteria.colorVariantIdToContrastColor = selectedRow.colorVariantIdToContrastColor;
        return criteria;
    }

    @Input()
    set model(value) {
        this._model = value;
        if (value) {
            this.loadContrastColors();
        }
    }

    get model() {
        return this._model;
    }
}
