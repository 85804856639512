import { NgModule } from '@angular/core';
import { FormulationDataComponent } from "./components/formulation-data.component";
import { SharedModule } from "../shared/shared.module";
import { FormulationDataQualityComponent } from "./components/fields/quality/quality.component";
import { FormulationDataGlossLevelComponent } from "./components/fields/gloss-level/gloss-level.component";
import { FormulationDataQuantityComponent } from "./components/fields/quantity/quantity.component";
import { FormulationDataVariantComponent } from "./components/fields/variant/variant.component";
import { FormulationDataUnitComponent } from "./components/fields/unit/unit.component";
import { FormulationDataCoatingComponent } from "./components/fields/coating/coating.component";

@NgModule({
    declarations: [
        FormulationDataComponent,
        FormulationDataQualityComponent,
        FormulationDataGlossLevelComponent,
        FormulationDataQuantityComponent,
        FormulationDataVariantComponent,
        FormulationDataUnitComponent,
        FormulationDataCoatingComponent
    ],
    entryComponents:
        [
            FormulationDataComponent
        ],
    imports:
        [
            SharedModule
        ],
    exports: [
        FormulationDataComponent
    ]
})

export class FormulationDataModule {
}
