import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { ModelSearchCriteria } from "../models/model-search-criteria";
import { catchError, timeout } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { ModelRow } from "../../formulation/models/model-row";
import { ColorRow } from "../../formulation/models/color-row";
import { Endpoints } from "../../shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class ModelDataService {

    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints) { }

    getModelsForColor(color: ColorRow): Observable<ModelRow[]> {
        const criteria = ModelSearchCriteria.fromColor(color);
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return this.http.post<ModelRow[]>(this.endpoints.apiModels, JSON.stringify(criteria), { headers }).pipe(
            timeout(30000),
            catchError(error => {
                this.logger.error(`Failed to retrieve the models for color '${color.colorName}'`, error);
                return throwError(error);
            })
        );
    }
}

