<ng-template #mcsFirstError>
    <div *ngIf="isFirstRequired">{{ 'FormulationSearch.Validation.ValidMcsNoRequired' | translate: {value: caption} }}
    </div>
</ng-template>
<ng-template #mcsSecondError>
    <div *ngIf="isSecondRequired">{{ 'FormulationSearch.Validation.ValidMcsNoRequired' | translate: {value: caption} }}
    </div>
    <div *ngIf="isSecondInvalidDigits">{{ 'FormulationSearch.Validation.ValidMcsNoDigitsRequired' | translate:{value:
        secondDigits} }}</div>
</ng-template>
<ng-template #mcsThirdError>
    <div *ngIf="isThirdRequired">{{ 'FormulationSearch.Validation.ValidMcsNoRequired' | translate: {value: caption} }}
    </div>
    <div *ngIf="isThirdInvalidDigits">{{ 'FormulationSearch.Validation.ValidMcsNoDigitsRequired' | translate:{value:
        thirdDigits} }}</div>
</ng-template>
<ng-template #mcsForthError>
    <div *ngIf="isForthRequired">{{ 'FormulationSearch.Validation.ValidMcsNoRequired' | translate: {value: caption} }}
    </div>
    <div *ngIf="isForthInvalidDigits">{{ 'FormulationSearch.Validation.ValidMcsNoDigitsRequired' | translate:{value:
        forthDigits} }}</div>
</ng-template>
<label>{{ 'FormulationSearch.Label.McsNo3' | translate }}<span *ngIf="showAsterisk"
        class="required-small">*</span></label>
<div class="d-flex">
    <ng-select style="width: 60px;" [keyDownFn]="selectOnTab" [(ngModel)]="first.value" [items]="mcsItems"
        [clearable]="false" name="mcs3-select"
        [ngClass]="{'ng-field-selected': first.isFilled, 'field-error': isFirstRequired}" [ngbTooltip]="mcsFirstError"
        tooltipClass="input-tooltip-error" [disableTooltip]="!isFirstRequired" container="body">
    </ng-select>
    <span class="p-1 dash"></span>
    <input name="mcs3-second" NumbersOnly
        [ngClass]="{'field-selected': second.isFilled, 'field-error': isSecondRequired || isSecondInvalidDigits}"
        [(ngModel)]="second.value" maxlength="1" style="width: 35px;" type="text" class="p-1 mcs-text form-control"
        [ngbTooltip]="mcsSecondError" tooltipClass="input-tooltip-error"
        [disableTooltip]="!isSecondRequired && !isSecondInvalidDigits" container="body">
    <span class="p-1 dash">-</span>
    <input name="mcs3-third" NumbersOnly (focusout)="onThirdFocusOut()"
        [ngClass]="{'field-selected': third.isFilled, 'field-error': isThirdRequired || isThirdInvalidDigits}"
        [(ngModel)]="third.value" maxlength="3" style="width: 70px;" type="text" class="p-1 mcs-text form-control"
        [ngbTooltip]="mcsThirdError" tooltipClass="input-tooltip-error"
        [disableTooltip]="!isThirdRequired && !isThirdInvalidDigits" container="body">
    <span class="p-1 dash">-</span>
    <input name="mcs3-forth" NumbersOnly (focusout)="onForthFocusOut()"
        [ngClass]="{'field-selected': forth.isFilled, 'field-error': isForthRequired || isForthInvalidDigits}"
        [(ngModel)]="forth.value" maxlength="2" style="width: 50px;" type="text" class="p-1 mcs-text form-control"
        [ngbTooltip]="mcsForthError" tooltipClass="input-tooltip-error"
        [disableTooltip]="!isForthRequired && !isForthInvalidDigits" container="body">
</div>
