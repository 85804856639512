import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ErrorComponent } from "./components/error/error.component";
import { StandardPageComponent } from "./components/standard-page/standard-page.component";
import { DotsLoaderComponent } from "./components/loaders/dots-loader/dots-loader.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AppRoutingModule } from "../app-routing.module";
import { DialogComponent } from './components/dialog/dialog.component';
import { NumbersOnlyDirective } from "./directives/numbers-only.directive";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { PrintSectionDirective } from "./directives/print-section.directive";
import { ErrorDialogComponent } from "./components/error-dialog/error-dialog.component";
import { UnsupportedBrowserComponent } from './components/unsupported-browser/unsupported-browser.component';
import { AuthDirective } from "../authentication/directives/auth-directive";
import { NgxMaskModule } from "ngx-mask";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from './../../environments/environment';
import { IsMobileViewPipe } from "../shared/pipes/is-mobile-view.pipe";
import { IsDesktopViewPipe } from "../shared/pipes/is-desktop-view.pipe";
import { IsSmartphoneViewPipe } from "../shared/pipes/is-smartphone-view.pipe";
import { IsPadViewPipe } from "../shared/pipes/is-pad-view.pipe";
import { InputMaskDirective } from './directives/input-mask.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CryptoService } from './service/crypto.service';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.cookieDomain, //'http://localhost:4200/',//,
        expiryDays: 365,
        name: 'mipa_cookieconsent_status',
        // path: '/'
    },
    //autoOpen: true,
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#0033bb',
            text: '#ffffff',
        },
    },
    theme: 'edgeless',
    type: 'opt-out',
    revokeBtn: '<div class="cc-revoke" hidden>Cookie Policy</div>',
    layout: 'my-custom-layout',
    layouts: {
        "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements: {
        messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{policy}}</a> {{and}}
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{link}}</a>
    </span>
    `,
    },
    content: {
        // will be translated in app.component.ts and translations is in i18n e.g. en.json, de.json ...
        message: 'By using our site, you acknowledge that you have read and understand our ',

        privacyPolicyHref: 'https://www.mipa-paints.com/kontakt/impressum/datenschutz/',
        tosHref: 'https://www.mipa-paints.com/kontakt/impressum/agb/',

        link: 'Terms of Service',
        policy: 'Privacy Policy',

        and: 'and our',
    }
};

@NgModule({
    declarations: [
        ErrorComponent,
        StandardPageComponent,
        DotsLoaderComponent,
        HeaderComponent,
        FooterComponent,
        DialogComponent,
        NumbersOnlyDirective,
        PrintSectionDirective,
        AuthDirective,
        InputMaskDirective,
        ErrorDialogComponent,
        NotificationDialogComponent,
        ConfirmDialogComponent,
        UnsupportedBrowserComponent,
        IsMobileViewPipe,
        IsSmartphoneViewPipe,
        IsPadViewPipe,
        IsDesktopViewPipe
    ],
    imports: [
        NgxMaskModule.forRoot(),
        NgbModule,
        NgSelectModule,
        FormsModule,
        TranslateModule,
        BrowserModule,
        CommonModule,
        NgxDatatableModule,
        AppRoutingModule,
        VirtualScrollerModule,
        NgxSliderModule,
        PdfViewerModule,
        // Cookie Consent
        NgcCookieConsentModule.forRoot(cookieConfig), //CookieService.fallbackCookieConsentConfig),
    ],
    providers: [
        IsMobileViewPipe,
        IsSmartphoneViewPipe,
        IsPadViewPipe,
        IsDesktopViewPipe,
        CryptoService
    ],
    entryComponents: [
        NotificationDialogComponent,
        ConfirmDialogComponent,
        ErrorDialogComponent
    ],
    exports: [

        // Modules
        TranslateModule,
        CommonModule,
        BrowserModule,
        NgSelectModule,
        VirtualScrollerModule,
        NgbModule,
        FormsModule,
        NgxDatatableModule,
        AppRoutingModule,
        NgxMaskModule,
        NgxSliderModule,
        NgcCookieConsentModule,
        PdfViewerModule,

        // Components
        ErrorComponent,
        StandardPageComponent,
        DotsLoaderComponent,
        ErrorDialogComponent,
        NotificationDialogComponent,
        ConfirmDialogComponent,

        // Directives
        NumbersOnlyDirective,
        PrintSectionDirective,
        AuthDirective,
        InputMaskDirective,

        // Pipes
        IsMobileViewPipe,
        IsSmartphoneViewPipe,
        IsPadViewPipe,
        IsDesktopViewPipe
    ]
})

export class SharedModule {

}
