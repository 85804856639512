import {AfterViewInit, Component, QueryList, Renderer2, ViewChild, ViewChildren, ElementRef, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import {ResetPasswordUsernameComponent} from "src/app/reset-password/request-reset/components/fields/username.component";
import {IFormField} from "src/app/shared/components/base/forms/form-field-interface";
import {RequestPasswordResetService} from "src/app/reset-password/request-reset/services/request-password-reset.service";
import {sections} from "src/app/shared/configuration/sections";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-request-password-reset',
    templateUrl: './request-password-reset.component.html',
    styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements AfterViewInit, OnInit {

    @ViewChild('username', { static: true }) username: ResetPasswordUsernameComponent;
    @ViewChild("logodiv", { static: true }) logoDiv: ElementRef;
    @ViewChildren('required') requiredFields: QueryList<IFormField>;

    hasRequirementsError: boolean;
    isProcessingError: boolean;
    processingError: string;
    isProcessing: boolean;
    isCompleted: boolean;

    constructor(private requestPasswordResetService: RequestPasswordResetService,
                private router: Router,
                private renderer: Renderer2,
                private translateService: TranslateService) {
        this.renderer.addClass(document.body, 'auth-body');
    }

    ngOnInit(): void {
        if (environment.theme.id === "mipa"){
            this.logoDiv.nativeElement.classList.add("mipa-logo");
        } else {
            this.logoDiv.nativeElement.classList.add("dyo-logo");
        }
    }
    ngAfterViewInit(): void {
        this.registerRequiredFieldsEvents();
    }

    async onKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            await this.requestPasswordReset();
        }
    }

    async requestPasswordReset() {
        if (!this.validate()) { return; }
        try {
            this.isProcessing = true;
            await this.requestPasswordResetService.requestPasswordReset(this.username.value);
            this.isCompleted = true;
        }
        catch (ex) {
            this.processingError = this.translateService.instant('General.Text.FailedToProcessRequest');
            this.isProcessingError = true;
        }
        finally {
            this.isProcessing = false;
        }
    }

    registerRequiredFieldsEvents(): void {
        this.requiredFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onRequiredFieldFilled(isfilled));
        });
    }

    onRequiredFieldFilled(isFilled: boolean) {
        if (this.hasRequirementsError && isFilled) {
            this.hasRequirementsError = false;
        }
    }

    async goToLogin(): Promise<void> {
        await this.router.navigate([`/${sections.login}`]);
    }

    private validate(): boolean {
        this.clearErrors();

        return this.validateRequirements();
    }

    private validateRequirements(): boolean {
        this.hasRequirementsError = this.requiredFields.some(x => !x.isFilled);

        return !this.hasRequirementsError;
    }

    private clearErrors(): void {
        this.hasRequirementsError = false;
        this.isProcessingError = false;
        this.processingError = null;
    }
}
