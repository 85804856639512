import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NotificationDialogComponent } from "../../shared/notification-dialog/notification-dialog.component";
import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class NotificationDialogService {
    constructor(private modalService: NgbModal, private translateService: TranslateService) {
    }

    showDialog(message: string, title: string = this.translateService.instant("General.Text.NotificationDialogTitle")) {
        const modal = this.modalService.open(NotificationDialogComponent, { windowClass: 'modal-holder', centered: true, backdrop: 'static' });

        modal.componentInstance.title = title;
        modal.componentInstance.message = message;
    }

    showConfirmDialog(method: any, message: string, title: string = this.translateService.instant("General.Text.NotificationDialogTitle")) {
        const modal = this.modalService.open(ConfirmDialogComponent, { windowClass: 'modal-holder', centered: true, backdrop: 'static' });

        modal.componentInstance.title = title;
        modal.componentInstance.message = message;
        modal.componentInstance.action = method;
    }
}
