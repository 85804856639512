import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { Quality } from "../../formulation/models/quality";
import { Unit } from "../../formulation/models/unit";
import { TemperatureRange } from "./temperature-range";
import { VocConformity } from "./voc-conformity";
import { IDeserialize } from "../../shared/interfaces/deserialize";
import { AncillaryProductGlossLevel } from "./ancillary-product-gloss-level";
import { AncillaryProductItem } from "./ancillary-product-item";
import { AncillaryProductToQuality } from "./ancillary-product-to-quality";
import { ComponentType } from "../../formulation/models/component-type";

@Directive()
@Injectable({ providedIn: 'root' })
export class AncillaryProductData implements IDeserialize {
    private _isLoaded: boolean;

    @Output() onLoaded = new EventEmitter();

    get isLoaded() { return this._isLoaded; }

    set isLoaded(value) {
        this._isLoaded = value;
        this.onLoaded.emit();
    }

    qualities: Quality[];
    products: AncillaryProductItem[];
    productToQualities: AncillaryProductToQuality[];

    units: Unit[];
    glossLevels: AncillaryProductGlossLevel[];
    temperatureRanges: TemperatureRange[];

    vocConformities: VocConformity[];
    componentTypes: ComponentType[];

    defaultQuantity: number;
    defaultUnitId: number;

    unit: Unit;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        this.qualities = this.qualities ? this.qualities.map(x => new Quality().deserialize(x)) : null;
        this.products = this.products ? this.products.map(x => new AncillaryProductItem().deserialize(x)) : null;
        this.productToQualities = this.productToQualities ? this.productToQualities.map(x => new AncillaryProductToQuality().deserialize(x)) : null;

        this.units = this.units ? this.units.map(x => new Unit().deserialize(x)) : null;
        this.glossLevels = this.glossLevels ? this.glossLevels.map(x => new AncillaryProductGlossLevel().deserialize(x)) : null;
        this.temperatureRanges = this.temperatureRanges ? this.temperatureRanges.map(x => new TemperatureRange().deserialize(x)) : null;

        this.vocConformities = this.vocConformities ? this.vocConformities.map(x => new VocConformity().deserialize(x)) : null;
        this.componentTypes = this.componentTypes ? this.componentTypes.map(x => new ComponentType().deserialize(x)) : null;

        this.setDefaultUnit();

        return this;
    }

    setDefaultUnit() {
        if (this.units && this.units.length > 0) {
            this.unit = this.defaultUnitId ? this.units.find(x => x.id == this.defaultUnitId) : this.units[0];
        }
    }
}
