import { Component, OnDestroy, OnInit } from "@angular/core";
import { MixingContainer } from "../../../models/mixing-data/default/mixing-container";
import { MixingNavigationService } from "../../../services/mixing-navigation.service";
import { MixingComponentsContainer } from "../../../models/mixing-data/default/mixing-components-container";
import { IMixingContainer } from "../../../interfaces/i-mixing-container";
import { ContainerType } from "../../../enums/container-type";
import { AncillaryProductDialogService } from "../../../../formulation-ancillary-product/services/ancillary-product-dialog-service";
import { AncillaryProductCriteriaGenerator } from "../../../../formulation-ancillary-product/services/ancillary-product-criteria-generator";
import { Remark } from "../../../models/remark";
import { TranslateService } from "@ngx-translate/core";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { NotificationDialogService } from "../../../../core/dialogs/notification-dialog.service";
import { AncillaryProductExcludedComponentService } from "../../../services/ancillary-product-excluded-component.service";
import { AncillaryProductFilter } from "../../../../formulation-ancillary-product/models/ancillary-product-filter";
import { MixingDataService } from 'src/app/formulation-mixing/services/mixing-data.service';
import { IsMobileViewPipe } from "../../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-mixing-materials',
    templateUrl: './mixing-materials.component.html',
    styleUrls: ['./mixing-materials.component.scss']
})
export class MixingMaterialsComponent extends FormField<any> implements OnInit, OnDestroy {
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    containerSub: Subscription;
    localizationSub: Subscription;
    container: MixingContainer;
    formulationNo: string;
    remarks: Remark[];
    containers: MixingComponentsContainer[];
    coatings: number[];
    isCoatingSelectorVisible: boolean;

    private _selectedCoating: number;

    constructor(
        private navigation: MixingNavigationService,
        private ancillaryProductDialogservice: AncillaryProductDialogService,
        private ancillaryProductCriteriaGenerator: AncillaryProductCriteriaGenerator,
        private notificationDialogService: NotificationDialogService,
        private ancillaryProductExcludedComponentService: AncillaryProductExcludedComponentService,
        private translate: TranslateService,
        private ancillaryProductFilter: AncillaryProductFilter,
        private readonly mixingDataService: MixingDataService,
        private mobileViewPipe: IsMobileViewPipe) {
        super();
    }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.onContainerChanged(this.navigation.currentContainer, true);
        this.containerSub = this.navigation.containerChanged.subscribe(x => this.onContainerChanged(x.newValue));
    }

    onContainerChanged(newContainer: IMixingContainer, showMultimatInfoDialog: boolean = false) {
        this.container = newContainer && newContainer.containerType === ContainerType.Default ? newContainer as MixingContainer : null;
        if (this.container) {
            this.formulationNo = this.container.formulationNo;
            this.remarks = this.container.remarks;
            this.containers = this.container.containers;
            this.selectedCoating = this.container.selectedCoating;
            this.coatings = this.container.coatings;
            this.isCoatingSelectorVisible = this.coatings && this.coatings.length > 1;
        } else {
            this.formulationNo = null;
            this.remarks = null;
            this.containers = null;
            this.selectedCoating = null;
            this.coatings = null;
            this.isCoatingSelectorVisible = false;
        }

        this.checkForMultimat(showMultimatInfoDialog);
        this.translate.onLangChange.subscribe(() => this.setLocalizedNames());
        this.setLocalizedNames();
    }

    ngOnDestroy(): void {
        this.containerSub.unsubscribe();
        if (this.localizationSub) {
            this.localizationSub.unsubscribe();
        }

        this.resizeSubscription.unsubscribe();
    }

    mixUp() {
        const excluded = this.ancillaryProductExcludedComponentService.containsExcludedComponent(this.container);
        if (excluded) {
            return;
        }

        const criteria = this.ancillaryProductCriteriaGenerator.fromMixingContainer(this.container, true);
        this.ancillaryProductFilter.componentType = null;
        this.ancillaryProductDialogservice.showDialog(criteria);
    }

    checkForMultimat(showInfoDialog: boolean = false) {
        if (!this.container
            || !this.container.selectedComponentsContainer
            || !this.container.selectedComponentsContainer.components
            || !this.container.selectedComponentsContainer.components.some(x => x.rawMaterial === 'Multi-Mat')
            || this.remarks.some(x => x.localizationPath === 'General.Text.MultiMatNotification')) {
            return;
        }

        this.remarks.push({
            localizationPath: 'General.Text.MultiMatNotification',
            caption: '',
            isLocalizable: "true"
        } as Remark);
        this.setLocalizedNames();
        if (showInfoDialog) {
            setTimeout(() => this.notificationDialogService.showDialog(this.translate.instant('General.Text.MultiMatNotification')), 200);
        }
    }

    setLocalizedNames() {
        if (!this.remarks) {
            return;
        }

        for (const i in this.remarks) {
            if (i) {
                const remark = this.remarks[i];
                if (remark.localizationPath) {
                    remark.caption = this.translate.instant(remark.localizationPath);
                }
            }
        }
    }

    async refreshVocConcentration() {
        const criteria = this.container.mixingCriteria;
        if (criteria) {
            criteria.data.selectedCoating = this.selectedCoating;
            const response = await this.mixingDataService.getMixingContainer(criteria);
            this.container.info.vocConcentration = response.info.vocConcentration;
        }
    }

    get isDyoMode() {
        return environment.theme.id === "dyo";
    }

    get isAncillaryProductEnabled() {
        return this.ancillaryProductExcludedComponentService.isAncillaryProductEnabled;
    }

    set selectedCoating(value: number) {
        this._selectedCoating = value;
        if (this.container) {
            this.container.selectedComponentsContainer = value ? this.containers.find(x => x.coating === value) : null;
            this.container.selectedCoating = value;
            this.container.seenCoatings.push(this.container.selectedCoating);
            this.refreshVocConcentration();
        }
    }

    get selectedCoating(): number {
        return this._selectedCoating;
    }
}
