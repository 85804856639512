import { IDeserialize } from "../interfaces/deserialize";

export class Language implements IDeserialize {
    code: string;
    locale: string;
    name: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }

    getDecimalSeparator(): string {
        if (!this.code) { return null; }

        return this.code == 'en' ? '.' : ',';
    }
}
