import { Component, Input, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { QualityAdditive } from "../../../../formulation/models/quality-additive";
import { Quality } from "../../../../formulation/models/quality";
import { ColorRow } from "../../../../formulation/models/color-row";

@Component({
    selector: 'app-formulation-data-variant',
    templateUrl: './variant.component.html',
    styles: []
})
export class FormulationDataVariantComponent extends FormField<QualityAdditive> {
    @Input() color: ColorRow;

    isDisabled: boolean;
    quality: Quality;
    filteredVariants: QualityAdditive[];

    private _variants: QualityAdditive[];

    onQualityChanged(quality: Quality) {
        this.quality = quality;
        this.refresh();
    }

    refresh() {
        if (this.quality && this.variants && this.variants.length > 0) {
            this.filteredVariants = this.variants.filter(x => x.qualityNo === this.quality.qualityNo);
            this.value = this.filteredVariants[0];
        }
        else {
            this.filteredVariants = null;
            this.value = null;
        }

        this.isVisible = this.filteredVariants && this.filteredVariants.length > 0;
    }

    setState(hasNoQualityAdditive: boolean) {
        this.isDisabled = hasNoQualityAdditive;
        if (this.isDisabled) {
            this.value = null;
        }
    }

    @Input()
    set variants(value) {
        this._variants = value;
        this.refresh();
    }

    get variants() {
        return this._variants;
    }
}
