import { Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormulationDataService } from "../services/formulation-data.service";
import { FormulationDataCriteria } from "../models/formulation-data-criteria";
import { FormulationData } from "../models/formulation-data";
import { FormulationDataQualityComponent } from "./fields/quality/quality.component";
import { FormulationDataVariantComponent } from "./fields/variant/variant.component";
import { FormulationDataGlossLevelComponent } from "./fields/gloss-level/gloss-level.component";
import { FormulationDataQuantityComponent } from "./fields/quantity/quantity.component";
import { FormulationDataUnitComponent } from "./fields/unit/unit.component";
import { Router } from "@angular/router";
import { MixingCriteriaGenerator } from "../../formulation-mixing/services/mixing-criteria-generator";
import { FormulationDataCoatingComponent } from "./fields/coating/coating.component";
import { MixingContainer } from "../../formulation-mixing/models/mixing-data/default/mixing-container";
import { MixingDataService } from "../../formulation-mixing/services/mixing-data.service";
import { MixingNavigationService } from "../../formulation-mixing/services/mixing-navigation.service";
import { sections } from "../../shared/configuration/sections";
import { IsMobileViewPipe } from "../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-formulation-data',
    templateUrl: './formulation-data.component.html',
    styleUrls: ['./formulation-data.component.scss']
})
export class FormulationDataComponent implements OnInit, OnDestroy {
    @Input() name;
    @ViewChild('quality') quality: FormulationDataQualityComponent;
    @ViewChild('variant') variant: FormulationDataVariantComponent;
    @ViewChild('glossLevel') glossLevel: FormulationDataGlossLevelComponent;
    @ViewChild('quantity') quantity: FormulationDataQuantityComponent;
    @ViewChild('unit') unit: FormulationDataUnitComponent;
    @ViewChild('coating') coating: FormulationDataCoatingComponent;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    isMixingError: boolean;
    isDataLoadError: boolean;
    isQuantityChange: boolean;
    criteria: FormulationDataCriteria;
    isLoadingData: boolean;
    isMixing: boolean;

    constructor(
        private modal: NgbActiveModal,
        private formulationDataService: FormulationDataService,
        public data: FormulationData,
        private mixingCriteriaGenerator: MixingCriteriaGenerator,
        private router: Router,
        private mixingDataService: MixingDataService,
        private mixingNavigation: MixingNavigationService,
        private mobileViewPipe: IsMobileViewPipe) { }

    async ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        await this.loadRequiredData();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    onKeyDown(event) {
        if (event.key === "Enter" && !this.isMixing) {
            this.ok();
        }
    }

    retryLoadRequiredData(): void {
        this.isDataLoadError = false;
        this.loadRequiredData();
    }

    async loadRequiredData(): Promise<any> {
        try {
            if (this.data.isLoaded) {
                return;
            }

            this.isLoadingData = true;
            const data = await this.formulationDataService.getFormulationData();
            this.data = this.data.deserialize(data);
            this.data.isLoaded = true;
        } catch {
            this.isDataLoadError = true;
        } finally {
            this.isLoadingData = false;
        }
    }

    cancel() {
        this.modal.close();
    }

    async ok() {

        const isValidQuantityInput = this.validateQuantityInput();
        if(!isValidQuantityInput){
            return;
        }

        const container = await this.getMixingContainer();
        if (!container || this.isMixingError) {
            return;
        }

        this.modal.close(true);
        if (this.isQuantityChange) {
            this.mixingNavigation.navigate(container);
        } else {
            this.mixingNavigation.navigate(container, true);
        }

        const mixingUrl = `/${sections.mixing}`;
        if (this.router.url !== mixingUrl) {
            this.router.navigate([mixingUrl]);
        }
    }

    private validateQuantityInput(): boolean {
        this.isMixingError = false;
        if (isNaN(this.quantity.value) || typeof (this.quantity.value) !== 'number' || this.quantity.value < 0) {
            this.isMixingError = true;
            return false;
        }
        return true;
    }

    private async getMixingContainer(): Promise<MixingContainer> {
        try {
            this.isMixingError = false;
            this.isMixing = true;
            const mixingCriteria = this.mixingCriteriaGenerator.fromComponent(this);
            const container = await this.mixingDataService.getMixingContainer(mixingCriteria);
            if (container) {
                container.formulationDataCriteria = this.criteria;
                container.mixingCriteria = mixingCriteria;
            }

            return container;
        } catch {
            this.isMixingError = true;
            return null;
        } finally {
            this.isMixing = false;
        }
    }

    get isLoaded() {
        return this.data.isLoaded;
    }
}
