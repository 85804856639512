import { Injectable } from "@angular/core";
import { AncillaryProductComponent } from "../../formulation-ancillary-product/components/ancillary-product.component";
import { ApMixingCriteria } from "../models/criteria/ancillary-product/ap-mixing-criteria";
import { SearchSettings } from "../../formulation-search/models/search-settings";

@Injectable({
    providedIn: 'root'
})
export class ApMixingCriteiraGenerator {
    constructor(private searchSettings: SearchSettings) {
    }

    fromComponent(component: AncillaryProductComponent): ApMixingCriteria {
        const criteria = new ApMixingCriteria();

        criteria.vocCountry = this.searchSettings.VOC;
        criteria.quality = component.quality.value;
        criteria.product = component.product.value;
        criteria.quantity = component.quantity.value;
        criteria.unit = component.unit.value;
        criteria.temperature = component.temperature.value;
        criteria.glossLevel = component.glossLevel.value;
        criteria.voc = component.voc.value;
        criteria.availableQualityTypes = component.quality.filteredQualities.map(x => x.qualityNo);

        return criteria;
    }
}
