import { Injectable } from "@angular/core";
import { MixingCriteria } from "../models/criteria/default/mixing-criteria";
import { FormulationDataComponent } from "../../formulation-data/components/formulation-data.component";
import { MixingCriteriaFormulationData } from "../models/criteria/default/mixing-criteria-formulation-data";
import { MixingCriteriaColor } from "../models/criteria/default/mixing-criteria-color";
import { SearchSettings } from "../../formulation-search/models/search-settings";

@Injectable({
    providedIn: 'root'
})
export class MixingCriteriaGenerator {
    constructor(private searchSettings: SearchSettings) {
    }

    fromComponent(component: FormulationDataComponent): MixingCriteria {
        const criteria = new MixingCriteria();

        criteria.searchType = component.criteria.searchType;
        criteria.color = this.getColorCriteria(component);
        criteria.data = this.getFormulationDataCriteria(component);
        criteria.availableQualityTypes = component.quality.filteredQualities.map(x => x.qualityNo);
        criteria.voc = component.criteria.voc;

        return criteria;
    }

    getColorCriteria(component: FormulationDataComponent): MixingCriteriaColor {
        const criteria = new MixingCriteriaColor();

        const color = component.criteria.color;

        criteria.colorId = color.colorId;
        criteria.formulationNo = color.formulationNo;
        criteria.compartmentNo = color.compartmentNo;
        criteria.mcsType = this.searchSettings.mcsType;
        criteria.mcs2 = color.mcs2;
        criteria.mcs3 = color.mcs3;
        criteria.manufacturerName = color.manufacturer;
        criteria.colorNo = color.colorNumber;
        criteria.colorName = color.colorName;
        criteria.variant = color.var;
        criteria.flopVariant = color.fVar;
        criteria.manufacturerModelId = color.manufacturerModelId;
        criteria.buildYearFrom = color.buildYearFrom;
        criteria.buildYearTo = color.buildYearTo;
        criteria.colorCategoryId = color.colorCategoryId;

        return criteria;
    }

    getFormulationDataCriteria(component: FormulationDataComponent): MixingCriteriaFormulationData {
        const criteria = new MixingCriteriaFormulationData();

        criteria.quality = component.quality.value;
        criteria.glossLevel = component.glossLevel.value;
        criteria.qualityAdditive = component.variant.value;
        criteria.unit = component.unit.value;

        const test: number = +component.quantity.value;
        criteria.quantity = test;
        criteria.selectedCoating = component.coating.value;

        return criteria;
    }
}
