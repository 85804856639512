import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { ModelRow } from "../../../../../../formulation/models/model-row";
import { ColorRow } from "../../../../../../formulation/models/color-row";
import { ModelDataService } from "../../../../../services/model-data.service";
import { IsMobileViewPipe } from "../../../../../../shared/pipes/is-mobile-view.pipe";
import { IsDesktopViewPipe } from "../../../../../../shared/pipes/is-desktop-view.pipe";
import { IsSmartphoneViewPipe } from "../../../../../../shared/pipes/is-smartphone-view.pipe";
import { IsPadViewPipe } from "../../../../../../shared/pipes/is-pad-view.pipe";
import { combineLatest, fromEvent, merge, Observable, of, Subscription, throwError, timer } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, mapTo, share, startWith, takeUntil, timeout } from 'rxjs/operators';
import { getLoadingStatus } from 'src/app/shared/observables/get-loading-status';

@Component({
    selector: 'app-row-details-models',
    templateUrl: './models.component.html',
    styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit, OnDestroy {
    @Input() color: ColorRow;
    @Input() isExpanded: boolean;
    @Output() selectedModelChanged = new EventEmitter<ModelRow>();

    isModelsLoadError: boolean;
    isProcessing: boolean;
    selectedModel: ModelRow;
    isMobileView: boolean;
    height: string;
    fontsize: string;
    headerHeight: number;
    rowHeight: number;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    constructor(
        private modelDataService: ModelDataService,
        private mobileViewPipe: IsMobileViewPipe,
        private smartphoneViewPipe: IsSmartphoneViewPipe,
        private padViewPipe: IsPadViewPipe,
        private desktopViewPipe: IsDesktopViewPipe) {
    }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.resizeForMobile();
        });
        this.resizeForMobile();
        this.loadModels();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    resizeForMobile() {
        if (this.desktopViewPipe.transform(window)) {
            this.isMobileView = this.mobileViewPipe.transform(window);
            this.height = '200px';
            this.fontsize = '14';
            this.headerHeight = 36;
            this.rowHeight = 34;
            this.changeLineHeight('34px');
            this.changeHeaderHeight('36px');
        }
        else if (this.padViewPipe.transform(window)) {
            this.isMobileView = this.mobileViewPipe.transform(window);
            this.height = '180px';
            this.fontsize = '9';
            this.headerHeight = 32;
            this.rowHeight = 30;
            this.changeLineHeight('30px');
            this.changeHeaderHeight('32px');
        }
        else if (this.smartphoneViewPipe.transform(window)) {
            this.isMobileView = this.mobileViewPipe.transform(window);
            this.height = '160px';
            this.fontsize = '4';
            this.headerHeight = 26;
            this.rowHeight = 24;
            this.changeLineHeight('24px');
            this.changeHeaderHeight('26px');
        }
    }

    changeLineHeight(lineHeight: string) {
        document.documentElement.style.setProperty('--datatable-line-height', lineHeight);
    }

    changeHeaderHeight(headerHeight: string) {
        document.documentElement.style.setProperty('--datatable-header-height', headerHeight);
    }

    loadModels() {
        if (this.color.modelsLoaded || this.isProcessing) {
            return;
        }

        this.isModelsLoadError = false;
        const modelRows = this.modelDataService.getModelsForColor(this.color).pipe(share());
        const showLoadingIndicator = getLoadingStatus(modelRows);
        modelRows.subscribe(
            rows => {
                this.color.models = rows.map(row => new ModelRow().deserialize(row));
                this.color.modelsLoaded = true;
            },
            () => {
                this.isProcessing = false;
                this.isModelsLoadError = true;
            });
        showLoadingIndicator.subscribe(isLoading => this.isProcessing = isLoading);
    }

    onSelect({ selected }) {
        let newSelection: ModelRow;
        if (!selected || !Array.isArray(selected) || selected.length === 0) {
            newSelection = null;
        }
        else {
            newSelection = selected[0];
        }

        this.selectedModel = newSelection;
        this.selectedModelChanged.emit(newSelection);
    }
}
