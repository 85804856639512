import { LoginResponseType } from "../enums/login-response-type";
import { IDeserialize } from "../../shared/interfaces/deserialize";

export class LoginResponse implements IDeserialize {
    loginResponseType: LoginResponseType;
    accessToken: string;
    refreshToken: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        return this;
    }
}
