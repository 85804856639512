import { ColorRow } from "../../formulation/models/color-row";

export class ModelSearchCriteria {
    manufacturerId: number;
    colorId: number;
    colorName: string;
    manufacturerModelId: number;

    static fromColor(color: ColorRow): ModelSearchCriteria {
        const criteria = new ModelSearchCriteria();

        criteria.manufacturerId = color.manufacturerId;
        criteria.manufacturerModelId = color.manufacturerModelId;
        criteria.colorName = color.colorName;
        criteria.colorId = color.colorId;

        return criteria;
    }
}
