import { Injectable } from '@angular/core';
import { catchError } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { Endpoints } from "src/app/shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';
import {AnalyticsRecipeDto} from "src/app/core/analytics/dto/common/analytics-recipe-dto";
import {AnalyticsRecipeFormulationDto} from "src/app/core/analytics/dto/common/analytics-recipe-formulation-dto";
import {FormulationHistoryItem} from "src/app/formulation-data/models/formulation-history-item";
import {CreateAnalyticsRecipeDto} from "src/app/core/analytics/dto/create-recipe/create-analytics-recipe-dto";
import {CreateAnalyticsRecipeResponseDto} from "src/app/core/analytics/dto/create-recipe/create-analytics-recipe-response-dto";
import {throwError} from "rxjs";
import {UpdateAnalyticsRecipeDto} from "src/app/core/analytics/dto/update-recipe/update-analytics-recipe-dto";
import {AnalyticsMixingContainer} from "src/app/core/analytics/models/analytics-mixing-container";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsRecipeService {

    constructor(private http: HttpService,
                private logger: NGXLogger,
                private endpoints: Endpoints) {
    }

    public async saveRecipe(container: AnalyticsMixingContainer)
    {
        if(container.analyticsRecipeId == null)
        {
            await this.createAnalyticsRecipe(container);
        }
        else
        {
            await this.updateAnalyticsRecipe(container);
        }
    }

    private async createAnalyticsRecipe(analyticsContainer: AnalyticsMixingContainer): Promise<any>
    {
        const openedAt = analyticsContainer.openedAt;
        const closedAt = analyticsContainer.closedAt;
        const recipe = this.createRecipe(analyticsContainer);
        const formulations = this.createFormulations(analyticsContainer.container.formulationHistoryItems);

        const dto = new CreateAnalyticsRecipeDto(recipe, formulations, openedAt, closedAt);

        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const response = await this.http.post<CreateAnalyticsRecipeResponseDto>(this.endpoints.apiCreateAnalyticsRecipe, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while saving the analytics recipe.`, err);
                    return throwError(err);
                }),
            ).toPromise<CreateAnalyticsRecipeResponseDto>();

        analyticsContainer.analyticsRecipeId = response.analyticsRecipeId;
    }

    private async updateAnalyticsRecipe(container: AnalyticsMixingContainer): Promise<any>
    {
        const closedAt = container.closedAt;
        const recipe = this.createRecipe(container);
        const dto = new UpdateAnalyticsRecipeDto(container.analyticsRecipeId, recipe, closedAt);

        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        await this.http.post(this.endpoints.apiUpdateAnalyticsRecipe, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while saving the analytics recipe.`, err);
                    return throwError(err);
                }),
            ).toPromise();
    }

    private createRecipe(analyticsContainer: AnalyticsMixingContainer): AnalyticsRecipeDto
    {
        const info = analyticsContainer.container.info;

        return new AnalyticsRecipeDto({
            mcs: info.mcs,
            creationDate: info.creationDate,
            colorCategoryId: info.colorCategoryId,
            colorGroup: info.colorGroup,
            colorName: info.colorName,
            colorNumber: info.colorNumber,
            dateOfChange: info.dateOfChange,
            flopVariant: info.flopVariant,
            isNew: info.isNew,
            glossLevel: info.glossLevel,
            manufacturer: info.manufacturer,
            model: info.model,
            quality: info.quality,
            quantity: info.quantity,
            updateStatus: info.updateStatus,
            variant: info.variant,
            vocConcentration: info.vocConcentration,
            yearOfBuild: info.yearOfBuild,
            manufacturerLongName: info.manufacturerLongName,
            formulationNo: analyticsContainer.container.formulationNo
        });
    }

    private createFormulations(formulations: FormulationHistoryItem[]): AnalyticsRecipeFormulationDto[]
    {
        return formulations.map(x => new AnalyticsRecipeFormulationDto({
            formulationNo: x.formulationNo,
            formulationDate: x.date
        }));
    }
}
