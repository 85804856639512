import { Injectable } from '@angular/core';
import { Endpoints } from '../../shared/models/endpoints';
import { NGXLogger } from 'ngx-logger';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { paths } from '../../shared/configuration/paths';
import { HttpService } from '../http-service';

@Injectable({
    providedIn: 'root'
})
export class EndpointsProvider {
    constructor(private http: HttpService, private logger: NGXLogger) { }

    async getApiEndpoints(): Promise<Endpoints> {
        const result = await this.http.get<Endpoints>(paths.endpointsUrl)
            .pipe(
                catchError(err => {
                    this.logger.error('Failed to retrieve the endpoints data.', err);
                    return throwError(err);
                }),
            ).toPromise();

        return result;
    }
}
