<div class="step step-1">
    <div class="row row-1">
        <div class="col-md-6">
            <country-field [showAsterisk]="true" #country #field #required>
            </country-field>
        </div>
        <div class="col-md-6">
            <language-field [showAsterisk]="true" #language #field #required>
            </language-field>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-6">
            <first-name-field [showAsterisk]="true" #firstName #field #required>
            </first-name-field>
        </div>
        <div class="col-md-6">
            <last-name-field [showAsterisk]="true" #lastName #field #required>
            </last-name-field>
        </div>
    </div>
    <div class="row row-3">
        <div class="col-md-6">
            <company-name-field [showAsterisk]="true" #companyName #field #required>
            </company-name-field>
        </div>
        <div class="col-md-6">
            <address1-field [showAsterisk]="true" #address1 #field #required>
            </address1-field>
        </div>
    </div>
    <div class="row row-4">
        <div class="col-md-6">
            <address2-field [showAsterisk]="false" #address2 #field>
            </address2-field>
        </div>
        <div class="col-md-6">
            <address3-field [showAsterisk]="false" #address3 #field>
            </address3-field>
        </div>
    </div>
    <div class="row row-5">
        <div class="col-md-6">
            <city-field [showAsterisk]="true" #city #field #required>
            </city-field>
        </div>
        <div class="col-md-6">
            <zip-field [showAsterisk]="true" #zip #field #required>
            </zip-field>
        </div>
    </div>
    <div class="row row-6">
        <div class="col-md-6">
            <company-email-field [showAsterisk]="true" #companyEmail #field #required>
            </company-email-field>
        </div>
        <div class="col-md-6">
            <phone-field [showAsterisk]="true" #phone #field #required>
            </phone-field>
        </div>
    </div>
    <div class="row row-7">
        <div class="col-md-6">
            <distributor-name-field [showAsterisk]="true" #distributorName #field #required>
            </distributor-name-field>
        </div>
        <div class="col-md-6">
            <customer-number-field [showAsterisk]="false" #customerNumber #field>
            </customer-number-field>
        </div>
    </div>
    <div *ngIf="hasRequirementsError" class="requirements-error">{{ 'Register.Fields.Required' | translate }}</div>
    <div *ngIf="isEmailInvalid" class="requirements-error">{{ 'Register.ValidEmailRequired' | translate }}</div>
    <div class="flex-row d-flex row-buttons">
        <button (click)="cancel()" type="button" class="btn btn-cancel">{{ 'Register.Button.Cancel' | translate
            }}</button>
        <div style="flex-grow: 1"></div>
        <!-- <button (click)="goToNextStep()" type="button" class="btn btn-default btn-search">{{ 'Register.Button.Next' | translate }}</button> -->


        <button (click)="goToStep2()" type="button" class="btn btn-default btn-search">{{ 'Register.Button.Next' |
            translate }}</button>
    </div>
</div>
