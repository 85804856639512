import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { ColorRow } from "../../formulation/models/color-row";
import { ColorByContrastCriteria } from "../models/color-by-contrast-criteria";
import { Endpoints } from "../../shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class ColorByContrastService {
    constructor(private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints) {
    }

    async getColor(criteria: ColorByContrastCriteria): Promise<ColorRow> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return await this.http.post<ColorRow>(this.endpoints.apiColorByContrast, JSON.stringify(criteria), { headers })
            .pipe(
                map(x => new ColorRow().deserialize(x)),
                catchError(err => {
                    this.logger.error("Failed to retrieve the color row", err);
                    return throwError(err);
                }))
            .toPromise();
    }
}
