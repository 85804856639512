<div class="general-information" *ngIf="!isMobileView">
    {{'Formulation.Caption.GeneralInformation' | translate}}
</div>
<table *ngIf="container && !isMobileView" class="info-table">
    <tr>
        <td><label>{{'Mixing.Label.ProductName' | translate}}</label></td>
        <td>
            <input style="border-width: 0" [value]="productName | removeSpPrefix" type="text" class="form-control"
                readonly>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <div class="prod-sep"></div>
        </td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Quantity' | translate}}</label></td>
        <td>
            <div class="d-flex">
                <div class="flex-grow-1">
                    <input NumbersOnly [allowDecimals]="true" [(ngModel)]="display" (focus)="onFocus($event)"
                        type="text" class="form-control">
                </div>
                <div style="margin: 0 10px"></div>
                <ng-select [(ngModel)]="selectedUnit" [selectOnTab]="false" #select
                    (keydown.tab)="handleNgSelectTabKey($event, select)" [items]="units" bindLabel="name"
                    name="units-select" style="min-width: 80px" [searchable]="false" [clearable]="false">
                </ng-select>
                <button type="button" (click)="recalculate()" class="mixing-info-button">
                    <span class="img-container">
                        <img src="/assets/images/reload.png" class="mixing-info-button-img" />
                    </span>
                </button>
            </div>
        </td>
    </tr>
    <tr *ngIf="isHardener1">
        <td><label>{{'Mixing.Label.HardenerRelation' | translate}}</label></td>
        <td>
            <div class="d-flex">
                <div class="flex-grow-1">
                    <ng-select [(ngModel)]="container.selectedProduct" [selectOnTab]="false" #select
                        (keydown.tab)="handleNgSelectTabKey($event, select)" [items]="container.products"
                        bindLabel="hardenerShortcut" name="hardener-select" [searchable]="false" [clearable]="false">
                    </ng-select>
                </div>
                <div style="margin: 0 10px"></div>
                <div class="w-25">
                    <input [value]="container.selectedProduct.hardenerRelation + '%'" type="text" class="form-control"
                        readonly>
                </div>
            </div>
        </td>
    </tr>
    <tr *ngIf="isThinner1">
        <td><label>{{'Mixing.Label.ThinnerRelation' | translate}}</label></td>
        <td *ngIf="isThinner1Fix">
            <div class="d-flex">
                <div class="flex-grow-1">
                    <input [value]="container.selectedProduct.thinner1Shortcut" type="text" class="form-control"
                        readonly>
                </div>
                <div style="margin: 0 10px"></div>
                <div class="w-25">
                    <input [value]="container.selectedProduct.thinnerRelation11 + '%'" type="text" class="form-control"
                        readonly>
                </div>
            </div>
        </td>
        <td *ngIf="!isThinner1Fix">
            <div class="d-flex custom-slider">

                <ngx-slider [(value)]="sliderValue1" [options]="sliderOptions1" (userChange)="onChange()">
                </ngx-slider>

            </div>
        </td>
    </tr>

    <tr *ngIf="isThinner2">
        <td><label>{{'Mixing.Label.ThinnerRelation2' | translate}}</label></td>
        <td *ngIf="isThinner2Fix">
            <div class="d-flex">
                <div class="flex-grow-1">
                    <input [value]="container.selectedProduct.thinner2Shortcut" type="text" class="form-control"
                        readonly>
                </div>
                <div style="margin: 0 10px"></div>
                <div class="w-25">
                    <input [value]="container.selectedProduct.thinnerRelation21 + '%'" type="text" class="form-control"
                        readonly>
                </div>
            </div>
        </td>
        <td *ngIf="!isThinner2Fix">
            <div class="d-flex custom-slider">

                <ngx-slider [(value)]="sliderValue2" [options]="sliderOptions2" (userChange)="onChange2()">
                </ngx-slider>

            </div>
        </td>
    </tr>
</table>
<form #form="ngForm" *ngIf="container && isMobileView">
    <label>{{'Mixing.Label.ProductName' | translate}}</label>
    <input style="border-width: 0" [value]="productName | removeSpPrefix" type="text" class="form-control" readonly>
    <div class="prod-sep"></div>
    <label>{{'Mixing.Label.Quantity' | translate}}</label>
    <div class="d-flex">
        <div class="flex-grow-1">
            <input #quantity (keyup)="onKeyUp(quantity)" [value]="selectedQuantity" (focus)="onFocus($event)"
                type="number" class="form-control">
        </div>
        <div style="margin: 0 10px"></div>
        <ng-select [(ngModel)]="selectedUnit" [selectOnTab]="false" #select
            (keydown.tab)="handleNgSelectTabKey($event, select)" [items]="units" bindLabel="name" name="units-select"
            style="min-width: 80px" [searchable]="false" [clearable]="false">
        </ng-select>
        <button type="button" (click)="recalculate()" class="mixing-info-button">
            <span class="img-container">
                <img src="/assets/images/reload.png" class="mixing-info-button-img" />
            </span>
        </button>
    </div>
    <div *ngIf="isHardener1">
        <label>{{'Mixing.Label.HardenerRelation' | translate}}</label>
        <div class="d-flex">
            <div class="flex-grow-1">
                <ng-select [(ngModel)]="container.selectedProduct" [selectOnTab]="false" #select
                    (keydown.tab)="handleNgSelectTabKey($event, select)" [items]="container.products"
                    bindLabel="hardenerShortcut" name="hardener-select" [searchable]="false" [clearable]="false">
                </ng-select>
            </div>
            <div style="margin: 0 10px"></div>
            <div class="w-25">
                <input [value]="container.selectedProduct.hardenerRelation + '%'" type="text" class="form-control"
                    readonly>
            </div>
        </div>
    </div>
    <div *ngIf="isThinner1">
        <label>{{'Mixing.Label.ThinnerRelation' | translate}}</label>
        <div class="d-flex" *ngIf="isThinner1Fix">
            <div class="flex-grow-1">
                <input [value]="container.selectedProduct.thinner1Shortcut" type="text" class="form-control" readonly>
            </div>
            <div style="margin: 0 10px"></div>
            <div class="w-25">
                <input [value]="container.selectedProduct.thinnerRelation11 + '%'" type="text" class="form-control"
                    readonly>
            </div>
        </div>
        <div class="d-flex custom-slider" *ngIf="!isThinner1Fix">

            <ngx-slider [(value)]="sliderValue1" [options]="sliderOptions1" (userChange)="onChange()">
            </ngx-slider>

        </div>
    </div>

    <div *ngIf="isThinner2">
        <label>{{'Mixing.Label.ThinnerRelation2' | translate}}</label>
        <div class="d-flex" *ngIf="isThinner2Fix">
            <div class="flex-grow-1">
                <input [value]="container.selectedProduct.thinner2Shortcut" type="text" class="form-control" readonly>
            </div>
            <div style="margin: 0 10px"></div>
            <div class="w-25">
                <input [value]="container.selectedProduct.thinnerRelation21 + '%'" type="text" class="form-control"
                    readonly>
            </div>
        </div>
        <div class="d-flex custom-slider" *ngIf="!isThinner2Fix">
            <ngx-slider [(value)]="sliderValue2" [options]="sliderOptions2" (userChange)="onChange2()">
            </ngx-slider>
        </div>
    </div>

</form>
