import { Injectable } from "@angular/core";
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { HttpService } from 'src/app/core/http-service';

export declare type AppConfig = { [key: string]: string };

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    static fallbackCookieConsentConfig: NgcCookieConsentConfig = {
        cookie: {
            domain: 'localhost',
            expiryDays: 365,
            name: 'mipa_cookieconsent_status_fallback'
        },
        //autoOpen: true,
        palette: {
            popup: {
                background: '#000'
            },
            button: {
                background: '#0033bb',
                text: '#ffffff',
            },
        },
        theme: 'edgeless',
        type: 'opt-out',
        revokeBtn: '<div class="cc-revoke" hidden>Cookie Policy</div>',
        layout: 'my-custom-layout',
        layouts: {
            "my-custom-layout": '{{messagelink}}{{compliance}}'
        },
        elements: {
            messagelink: `
        <span id="cookieconsent:desc" class="cc-message">{{message}}
          <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{policy}}</a> {{and}}
          <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{link}}</a>
        </span>
        `,
        },
        content: {
            // will be translated in app.component.ts and translations is in i18n e.g. en.json, de.json ...
            message: 'By using our site, you acknowledge that you have read and understand our ',

            privacyPolicyHref: 'https://www.mipa-paints.com/kontakt/impressum/datenschutz/',
            tosHref: 'https://www.mipa-paints.com/kontakt/impressum/agb/',

            link: 'Terms of Service',
            policy: 'Privacy Policy',

            and: 'and our',
        }
    };

    constructor(private readonly http: HttpService) {
    }

    getCookieConsentDomain(): Promise<AppConfig> {
        const result = this.http.get<AppConfig>("api/cookie/consent-config").toPromise();
        return result;
    }
}
