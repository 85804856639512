import { IDeserialize } from "../../shared/interfaces/deserialize";

export class FormulationDataQualityInfoResponse implements IDeserialize {
    hasNoQualityAdditive: boolean;
    hasNoGloss: boolean;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
