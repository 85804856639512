<div #logodiv class="auth-section reset-password" Auth>
    <div></div>
    <app-standard-page>
        <div class="container d-flex flex-column">
            <div class="auth">
                <div [hidden]="tokenState !== tokenValidationState.valid || isCompleted" class="reset-form">
                    <h2 class="black-text">{{ 'ResetPassword.Page.Title' | translate }}</h2>
                    <div class="reset-password-desc">{{'ResetPassword.Page.Description' | translate}}</div>
                    <form (keydown)="onKeyDown($event)" #form="ngForm" novalidate>
                        <div>
                            <app-current-password [showAsterisk]="true" #currentPassword #field #required>
                            </app-current-password>
                        </div>
                        <div>
                            <app-new-password [showAsterisk]="true" #newPassword #field #required>
                            </app-new-password>
                        </div>
                        <div>
                            <app-new-password-confirm [showAsterisk]="true" #newPasswordConfirmation #field #required>
                            </app-new-password-confirm>
                        </div>
                        <div *ngIf="hasRequirementsError" class="requirements-error">{{ 'Register.Fields.Required' | translate }}</div>
                        <div *ngIf="currentPasswordRequired" class="requirements-error">{{ 'ResetPassword.Validation.CurrentPasswordRequired' | translate }}</div>
                        <div *ngIf="passwordMinLengthError" class="requirements-error">{{ 'ResetPassword.Validation.NewPasswordLength' | translate:{count: passwordMinLength} }}</div>
                        <div *ngIf="passwordNotMaching" class="requirements-error">{{ 'ResetPassword.Validation.NewPasswordConfirmation' | translate }}</div>

                        <div *ngIf="isProcessingError" class="requirements-error">{{processingError}}</div>
                        <div *ngIf="isProcessing" class="auth-loader">
                            <dots-loader class="black-text"
                                         caption="{{'General.Text.PleaseWaitProcessing' | translate}}"></dots-loader>
                        </div>
                        <button *ngIf="!isProcessing" type="button" (click)="resetPassword()"
                                class="btn btn-block btn-default btn-reset">{{ 'ResetPassword.Button.Caption' | translate
                            }}</button>
                    </form>
                </div>

                <div *ngIf="tokenState === tokenValidationState.invalid" class="invalid-token">
                    <h2 class="black-text">{{ 'ResetPassword.Error.InvalidToken.Title' | translate }}</h2>
                    <div class="reset-password-desc">{{'ResetPassword.Error.InvalidToken.Message' | translate}}</div>
                    <div class="links bg-white text-center">
                        <a class="register" (click)="goToResetPassword()" href="javascript:void(0)">{{'RequestPasswordReset.Link.Caption'| translate}}</a>
                    </div>
                </div>

                <div *ngIf="tokenState === tokenValidationState.error" class="token-validation-error">
                    <h2 class="black-text">{{ 'ResetPassword.Error.TokenValidation.Title' | translate }}</h2>
                    <div class="reset-password-desc">{{'ResetPassword.Error.TokenValidation.Message' | translate}}</div>
                </div>

                <div *ngIf="tokenState === tokenValidationState.none || tokenState === tokenValidationState.processing" class="processing-token-validation">
                    <h2 class="black-text">{{ 'ResetPassword.TokenValidation.Processing.Title' | translate }}</h2>
                    <div class="reset-password-desc">{{'ResetPassword.TokenValidation.Processing.Message' | translate}}</div>
                </div>

                <div [hidden]="!isCompleted" class="request-success">
                    <h2 class="black-text">{{ 'ResetPassword.Success.Title' | translate }}</h2>
                    <div class="reset-password-success-desc">{{'ResetPassword.Success.Message' | translate}}</div>
                </div>
                <div *ngIf="!isProcessing" class="links bg-white text-center">
                    <a class="register" (click)="goToLogin()" href="javascript:void(0)">{{'Authentication.Button.Login'| translate}}</a>
                </div>
            </div>
        </div>
    </app-standard-page>
</div>



