<div *ngIf="!isMobileView" class="general-information">
    {{'Formulation.Caption.GeneralInformation' | translate}}
</div>
<table *ngIf="info !== null && !isMobileView" class="info-table">
    <tr>
        <td><label>{{'Mixing.Label.McsNumberStatus' | translate}}</label></td>
        <td><input [value]="info.mcsUpdateStatus()" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Manufacturer' | translate}}</label></td>
        <td><input [value]="info.manufacturerLongName" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.ColorNo' | translate}}</label></td>
        <td><input [value]="info.colorNumber" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Name' | translate}}</label></td>
        <td><input [value]="info.colorName" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Variant' | translate}}</label></td>
        <td><input [value]="info.variant" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.FlopVariant' | translate}}</label></td>
        <td><input [value]="info.flopVariant" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Model' | translate}}</label></td>
        <td><input [value]="info.model" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.YearOfBuild' | translate}}</label></td>
        <td><input [value]="info.yearOfBuild" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.ColorGroup' | translate}}</label></td>
        <td><input [value]="info.colorGroupLocalized" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Quality' | translate}}</label></td>
        <td><input [value]="info.quality" type="text" class="form-control" readonly></td>
    </tr>
    <tr *ngIf="info.glossLevel !== null">
        <td><label>{{'MaintenanceOfFormulation.Label.GlossLevel' | translate}}</label></td>
        <td><input [value]="info.glossLevelLocalized" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.Quantity' | translate}}</label></td>
        <td>
            <div class="d-flex">
                <input [value]="info.quantity" type="text" class="form-control" readonly>
                <button type="button" (click)="changeQuantity()" class="mixing-info-button">
                    <span class="img-container">
                        <img src="/assets/images/stack.png" />
                    </span>
                </button>
            </div>
        </td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.CreationDate' | translate}}</label></td>
        <td><input [value]="info.creationDate" type="text" class="form-control" readonly></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.DateOfChange' | translate}}</label></td>
        <td>
            <div class="change-date d-flex">
                <input [value]="info.dateOfChange" type="text" class="form-control" readonly>
                <button type="button" (click)="toggleFormulationHistoryVisibility()" class="mixing-info-button">
                    <span class="img-container">
                        <img src="/assets/images/calendar.png" />
                    </span>
                </button>
                <div class="new-formulation" *ngIf="info.isNew">NEW</div>
            </div>
        </td>
    </tr>
    <tr
        *ngIf="isFormulationHistoryVisible && formulationHistoryItems && formulationHistoryItems.length > 1 && !isFormulationSearch">
        <td colspan="2">
            <div class="formulation-history-ct">
                <a *ngFor="let item of formulationHistoryItems" [ngClass]="{'active': item.isSelected}"
                    href="javascript:void(0)" (click)="changeFormulation(item.formulationNo);"><span
                        class="date">{{item.date}}</span> {{item.formulationNo}}</a>
            </div>
        </td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.MinimalQuantity' | translate}}</label></td>
        <td style="position: relative"><input [value]="minimumQuantity" type="text" class="form-control" readonly><span
                class="min-quantity-required"
                *ngIf="isMinimumQuantityRequired">{{'Mixing.Info.MinimumQuantityRequiredCaution'|translate}}</span></td>
    </tr>
    <tr>
        <td><label>{{'Mixing.Label.VocConcentration' | translate}}</label></td>
        <td><input [value]="info.vocConcentration" type="text" class="form-control" readonly></td>
    </tr>
</table>
<form #form="ngForm" style="width: 100%;" novalidate *ngIf="info !== null && isMobileView">
    <label>{{'Mixing.Label.McsNumberStatus' | translate}}</label>
    <div class="d-flex">
        <div class="flex-grow-1">
            <input [value]="info.mcs" type="text" class="form-control" readonly>
        </div>
        <div style="margin: 0 10px"></div>
        <div class="flex-grow-1">
            <input [value]="info.updateStatus" type="text" class="form-control" readonly>
        </div>
    </div>
    <label>{{'Mixing.Label.Manufacturer' | translate}}</label>
    <input [value]="info.manufacturerLongName" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.ColorNo' | translate}}</label>
    <input [value]="info.colorNumber" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.Name' | translate}}</label>
    <input [value]="info.colorName" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.Variant' | translate}}</label>
    <input [value]="info.variant" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.FlopVariant' | translate}}</label>
    <input [value]="info.flopVariant" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.Model' | translate}}</label>
    <input [value]="info.model" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.YearOfBuild' | translate}}</label>
    <input [value]="info.yearOfBuild" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.ColorGroup' | translate}}</label>
    <input [value]="info.colorGroupLocalized" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.Quality' | translate}}</label>
    <input [value]="info.quality" type="text" class="form-control" readonly>
    <div *ngIf="info.glossLevel !== null">
        <label>{{'MaintenanceOfFormulation.Label.GlossLevel' | translate}}</label>
        <input [value]="info.glossLevelLocalized" type="text" class="form-control" readonly>
    </div>
    <label>{{'Mixing.Label.Quantity' | translate}}</label>
    <div class="d-flex">
        <input [value]="info.quantity" type="text" class="form-control" readonly>
        <button type="button" (click)="changeQuantity()" class="mixing-info-button">
            <span class="img-container">
                <img src="/assets/images/stack.png" />
            </span>
        </button>
    </div>
    <label>{{'Mixing.Label.CreationDate' | translate}}</label>
    <input [value]="info.creationDate" type="text" class="form-control" readonly>
    <label>{{'Mixing.Label.DateOfChange' | translate}}</label>
    <div class="change-date d-flex">
        <input [value]="info.dateOfChange" type="text" class="form-control" readonly>
        <button type="button" (click)="toggleFormulationHistoryVisibility()" class="mixing-info-button">
            <span class="img-container">
                <img src="/assets/images/calendar.png" />
            </span>
        </button>
        <div class="new-formulation" *ngIf="info.isNew">NEW</div>
    </div>
    <div *ngIf="isFormulationHistoryVisible && formulationHistoryItems && formulationHistoryItems.length > 1">
        <div>
            <div class="formulation-history-ct">
                <a *ngFor="let item of formulationHistoryItems" [ngClass]="{'active': item.isSelected}"
                    href="javascript:void(0)" (click)="changeFormulation(item.formulationNo);"><span
                        class="date">{{item.date}}</span> {{item.formulationNo}}</a>
            </div>
        </div>
    </div>
    <label>{{'Mixing.Label.MinimalQuantity' | translate}}</label>
    <div style="position: relative"><input [value]="minimumQuantity" type="text" class="form-control" readonly><span
            class="min-quantity-required"
            *ngIf="isMinimumQuantityRequired">{{'Mixing.Info.MinimumQuantityRequiredCaution'|translate}}</span>
    </div>
    <label>{{'Mixing.Label.VocConcentration' | translate}}</label>
    <input [value]="info.vocConcentration" type="text" class="form-control" readonly>
</form>
