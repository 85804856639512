import { Component, OnDestroy, OnInit } from "@angular/core";
import { MixingNavigationService } from "../../../services/mixing-navigation.service";
import { IMixingContainer } from "../../../interfaces/i-mixing-container";
import { ContainerType } from "../../../enums/container-type";
import { MixingComponent } from "../../../models/mixing-data/default/mixing-component";
import { AncillaryProductDataService } from "../../../../formulation-ancillary-product/services/ancillary-product-data-service";
import { ApMixingContainer } from "../../../models/mixing-data/ancillary-product/ap-mixing-container";
import { AncillaryProductComponentsCriteria } from "../../../models/criteria/ancillary-product/ancillary-product-components-criteria";
import { SearchSettings } from "../../../../formulation-search/models/search-settings";
import { TranslateService } from "@ngx-translate/core";
import { NotificationDialogService } from "../../../../core/dialogs/notification-dialog.service";
import { IsMobileViewPipe } from "../../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-ap-mixing-materials',
    templateUrl: './ap-mixing-materials.component.html',
    styleUrls: ['./ap-mixing-materials.component.scss']
})
export class ApMixingMaterialsComponent implements OnInit, OnDestroy {
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    isComponentsLoadingError: boolean;
    isProcessing: boolean;
    container: ApMixingContainer;
    components: MixingComponent[];
    containerSubscription: Subscription;
    productSubscription: Subscription;

    constructor(
        private navigation: MixingNavigationService,
        private ancillaryProductDataService: AncillaryProductDataService,
        private translate: TranslateService,
        private notificationDialogService: NotificationDialogService,
        private searchSettings: SearchSettings,
        private mobileViewPipe: IsMobileViewPipe) { }

    ngOnInit(): void {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.onContainerChanged(this.navigation.currentContainer);
        this.containerSubscription = this.navigation.containerChanged.subscribe(x => this.onContainerChanged(x.newValue));
    }

    onContainerChanged(newContainer: IMixingContainer) {
        this.unsubscribeOnProductChanged();
        if (newContainer && newContainer.containerType === ContainerType.AncillaryProduct) {
            this.initializeContainer(newContainer as ApMixingContainer);
        }
        else {
            this.uninitializeContainer();
        }

        this.subscribeOnProductChanged();
    }

    initializeContainer(container: ApMixingContainer) {
        this.container = container;
        this.onProductChanged();
    }

    uninitializeContainer() {
        this.container = null;
    }

    subscribeOnProductChanged() {
        if (this.container) {
            this.container.selectedProductChanged.subscribe(() => this.onProductChanged());
        }
    }

    unsubscribeOnProductChanged() {
        if (this.productSubscription) {
            this.productSubscription.unsubscribe();
        }
    }

    retryLoadComponents() {
        this.onProductChanged();
    }

    async onProductChanged() {
        if (this.container && this.container.selectedProduct) {
            this.container.thinnerRelation1 = this.container.selectedProduct.thinnerRelation11;
            this.container.thinnerRelation2 = this.container.selectedProduct.thinnerRelation21;
            if (this.isMobileView) {
                this.getComponentsMobile();
            }
            else {
                this.components = await this.getComponents();
            }
        } else {
            this.components = null;
            this.container.currentMixingComponents = null;
            if (!this.isMobileView) {
                this.components = null;
            }
        }
    }

    checkForMultimat() {
        if (!this.container || !this.container.currentMixingComponents) {
            return;
        }

        if (this.container.currentMixingComponents.some(x => x.rawMaterial === 'Multi-Mat')) {
            setTimeout(() => {
                this.notificationDialogService.showDialog(this.translate.instant('General.Text.MultiMatNotification'));
            }, 100);
        }
    }

    async getComponents(): Promise<MixingComponent[]> {
        try {
            this.isComponentsLoadingError = false;
            this.isProcessing = true;
            const criteria = new AncillaryProductComponentsCriteria();
            criteria.voc = this.searchSettings.VOC;
            criteria.container = this.container.selectedProduct;
            criteria.quantity = this.container.mixingCriteira.quantity;
            criteria.unit = this.container.mixingCriteira.unit;
            criteria.qualityShortcut = this.container.mixingCriteira.quality.shortcut;
            criteria.isMixUp = this.container.ancillaryProductCriteria.isMixUp;
            criteria.componentsContainer = this.container.ancillaryProductCriteria.componentsContainer;
            criteria.thinnerRelation1 = this.container.thinnerRelation1;
            criteria.thinnerRelation2 = this.container.thinnerRelation2;
            return await this.ancillaryProductDataService.getAncillaryProductComponents(criteria);
        }
        catch
        {
            this.isComponentsLoadingError = true;
            return null;
        }
        finally {
            this.isProcessing = false;
        }
    }

    getComponentsMobile() {
        this.isComponentsLoadingError = false;
        this.isProcessing = true;
        const criteria = new AncillaryProductComponentsCriteria();
        criteria.voc = this.searchSettings.VOC;
        criteria.container = this.container.selectedProduct;
        criteria.quantity = this.container.mixingCriteira.quantity;
        criteria.unit = this.container.mixingCriteira.unit;
        criteria.qualityShortcut = this.container.mixingCriteira.quality.shortcut;
        criteria.isMixUp = this.container.ancillaryProductCriteria.isMixUp;
        criteria.componentsContainer = this.container.ancillaryProductCriteria.componentsContainer;
        criteria.thinnerRelation1 = this.container.thinnerRelation1;
        criteria.thinnerRelation2 = this.container.thinnerRelation2;
        const result = this.ancillaryProductDataService.getAncillaryProductComponentsMobile(criteria).subscribe(
            success => {
                this.components = success;
                this.container.currentMixingComponents = this.components;
                this.checkForMultimat();
                this.isProcessing = false;
            },
            error => {
                console.error(error);
                this.isComponentsLoadingError = true;
            },
            () => result.unsubscribe()
        );
    }

    openTab(tabName: string) {
        if (document) {
            if (tabName === "components") {
                this.getComponentsMobile();
            }

            const tabcontent = document.getElementsByClassName("tabcontent");
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < tabcontent.length; i++) {
                const content = tabcontent[i] as HTMLElement;
                content.style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            const tablinks = document.getElementsByClassName("tablinks");
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(tabName).style.display = "block";
        }
    }

    ngOnDestroy(): void {
        this.containerSubscription.unsubscribe();
        if (this.productSubscription) {
            this.productSubscription.unsubscribe();
        }

        this.resizeSubscription.unsubscribe();
    }
}
