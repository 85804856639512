import { Pipe, PipeTransform } from '@angular/core';
import { MobileParameters } from 'src/app/shared/mobile-parameter';

@Pipe({
    name: 'isMobileView',
    pure: true
})

export class IsMobileViewPipe implements PipeTransform {
    public transform(value: Window, args?: any): boolean {
        return this.isMobileView(value);
    }
    isMobileView(myWindow: Window): boolean {
        let result = false;
        if (myWindow) {
            const innerHeight = myWindow.innerHeight;
            const innerWidth = myWindow.innerWidth;
            result = myWindow.innerWidth < MobileParameters.minDesktopWidth;
        }
        return result;
    }
}
