import { MixingComponent } from "./mixing-component";
import { IDeserialize } from "../../../../shared/interfaces/deserialize";

export class MixingComponentsContainer implements IDeserialize {
    coating: number;
    totalDensity: number;
    components: MixingComponent[];

    minimumQuantity: number;
    minimumQuantityUnitName: string;
    isMinimumQuantityRequired: boolean;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (this.components) {
            this.components = this.components.map(x => new MixingComponent().deserialize(x));
        }

        return this;
    }
}
