import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { ThemeModule } from "./theme/theme.module";
import { RouteReuseStrategy } from "@angular/router";
import { CacheRouteReuseStrategy } from "./routing/cache-routing-reuse-strategy";
import { lightBlueTheme } from "../themes/light-blue";
import { mintTheme } from "../themes/mint";
import { paths } from "../shared/configuration/paths";
import { AuthInterceptor } from "./authorization/auth-interceptor";
import { HttpService } from './http-service';
import { dyoTheme } from '../themes/dyo';
import { environment } from "../../environments/environment";

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: null, //HttpLoaderFactory,
                deps: [HttpService]
            }
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: paths.logsUrl,
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR
        }),
        ThemeModule.forRoot({
            themes: environment.theme.id === "mipa" ? [lightBlueTheme, mintTheme] : [dyoTheme],
            active: environment.theme.id === "mipa" ? lightBlueTheme : dyoTheme
        }),        
    ],
    providers: [{
        provide: RouteReuseStrategy,
        useClass: CacheRouteReuseStrategy
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }],
    exports: [
        HttpClientModule,
        TranslateModule,
        LoggerModule,
        ThemeModule,
    ]
})

export class CoreModule {
}
