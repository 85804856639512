import { Component, Input, OnInit } from '@angular/core';
import { ColorRow } from "../../../../../formulation/models/color-row";

@Component({
    selector: 'tile-box',
    templateUrl: './tile-box.component.html',
    styleUrls: ['./tile-box.component.scss']
})
export class TileBoxComponent implements OnInit {

    @Input() color: ColorRow;

    constructor() { }

    ngOnInit() {
    }

}
