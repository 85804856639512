import { Injectable } from "@angular/core";
import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { AuthenticationService } from "../../authentication/services/authentication.service";
import { NGXLogger } from "ngx-logger";
import { RefreshAccessResponse } from "../../authentication/models/refresh-access-response";
import { RefreshAccessResponseType } from "../../authentication/enums/refresh-access-response-type";

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService, private logger: NGXLogger) { }

    isRefreshingToken = false;

    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

        return next.handle(this.addTokenToRequest(request, this.authService.getAccessToken()))
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        return this.handle401Error(request, next);
                    }
                    else {
                        return throwError(err);
                    }
                }));
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return token ? request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : request;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            return this.onStartRefreshing(request, next);
        }
        else {
            return this.onIsRefreshingToken(request, next);
        }
    }

    private onStartRefreshing(request: HttpRequest<any>, next: HttpHandler) {
        this.isRefreshingToken = true;

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);

        return this.authService.refreshAccess()
            .pipe(
                switchMap((response: RefreshAccessResponse) => {
                    if (response && response.responseType == RefreshAccessResponseType.success) {
                        this.authService.onAuthenticated(response.accessToken, response.refreshToken);

                        return next.handle(this.addTokenToRequest(request, response.accessToken));
                    }

                    return <any>this.authService.logout();
                }),
                catchError(err => {
                    this.logger.error(`Failed to refresh the tokens.'`, err);

                    return <any>this.authService.logout();

                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
    }

    private onIsRefreshingToken(request: HttpRequest<any>, next: HttpHandler): any {
        this.isRefreshingToken = false;

        return this.tokenSubject
            .pipe(filter(token => token != null),
                take(1),
                switchMap(token =>
                    next.handle(this.addTokenToRequest(request, token))));
    }
}
