import { Unit } from "../../../../formulation/models/unit";
import { Quality } from "../../../../formulation/models/quality";
import { GlossLevel } from "../../../../formulation/models/gloss-level";
import { QualityAdditive } from "../../../../formulation/models/quality-additive";

export class MixingCriteriaFormulationData {
    quality: Quality;
    glossLevel: GlossLevel;
    qualityAdditive: QualityAdditive;
    unit: Unit;
    quantity: number;
    selectedCoating: number;
}
