import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeSpPrefix'
})
export class RemoveSpPrefixPipe implements PipeTransform {

    transform(ancillaryProductName: string): string {
        if (ancillaryProductName.startsWith("SP ")) {
            return ancillaryProductName.substring(3);
        }

        return ancillaryProductName;
    }

}
