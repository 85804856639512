import { MixingComponent } from "../../formulation-mixing/models/mixing-data/default/mixing-component";

export class FormulationPrintModel {
    formulationNo: string;
    dateOfPrint: string;
    manufacturer: string;
    colorCode: string;
    variant: string;
    variantFlop: string;
    model: string;
    yearOfBuild: string;
    colorName: string;
    coating: string;
    mcs: string;
    quality: string;
    qualityAdditive: string;
    glossLevel: string;
    quantity: string;
    materials: MixingComponent[];
    additionalInfo: string[];
}
