import { Pipe, PipeTransform } from '@angular/core';
import { MobileParameters } from 'src/app/shared/mobile-parameter';

@Pipe({
    name: 'isSmartphoneView',
    pure: true
})

export class IsSmartphoneViewPipe implements PipeTransform {
    public transform(value: Window, args?: any): boolean {
        return this.isSmartphoneView(value);
    }
    isSmartphoneView(myWindow: Window): boolean {
        let result = false;
        if (myWindow) {
            const innerHeight = myWindow.innerHeight;
            const innerWidth = myWindow.innerWidth;
            result = myWindow.innerWidth <= MobileParameters.minPadWidth;
        }
        return result;
    }
}
