export default class RegisterRequest {
    constructor(
        public username: string,
        public password: string,
        public countryCode: string,
        public languageCode: string,
        public firstName: string,
        public lastName: string,
        public company: string,
        public address1: string,
        public address2: string,
        public address3: string,
        public city: string,
        public zip: string,
        public companyEmail: string,
        public phone: string,
        public customerNumber: string,
        public distributorName: string,
    ) {
    }
}
