export interface IInputMaskOptions {
    prefix?: string;
    suffix?: string;
}
export class InputMaskOptions implements IInputMaskOptions {
    prefix?: string;
    suffix?: string;

    constructor(data?: IInputMaskOptions) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) { (<any>this)[property] = (<any>data)[property]; }
            }
        }
    }
}
