export class MixingCriteriaColor {
    colorId: number;
    formulationNo: string;
    compartmentNo: string;
    mcsType: number;
    mcs2: string;
    mcs3: string;
    manufacturerName: string;
    colorNo: string;
    colorName: string;
    variant: string;
    flopVariant: string;
    manufacturerModelId: number;
    buildYearFrom: number;
    buildYearTo: number;
    colorCategoryId: number;

    // Mcs: string;
}
