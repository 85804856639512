import { Injectable } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import { environment } from "../../../environments/environment";
import { LocalizationService } from "../localization/localization.service";
import { SettingsService } from "../settings/settings.service";
import { ThemeService } from "../theme/theme.service";
import { EndpointsProvider } from "../endpoints/endpoints-provider";
import { Endpoints } from "../../shared/models/endpoints";
import { CookieService, AppConfig } from 'src/app/formulation-search/services/cookie-service';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import {AnalyticsOptionsService} from "src/app/core/analytics/services/analytics-options.service";
import {SessionService} from "src/app/core/session/services/session.service";

@Injectable({
    providedIn: 'root'
})
export class AppInitializer {

    private config: AppConfig;

    public errors = new Array<string>();

    public hasErrors = () => this.errors.length !== 0;

    constructor(private settingsService: SettingsService,
        private localizationService: LocalizationService,
        private logger: NGXLogger,
        private themeService: ThemeService,
        private endpointsProvider: EndpointsProvider,
        private endpoints: Endpoints,
        private readonly cookieService: CookieService,
        private analyticsOptionsService: AnalyticsOptionsService,
        private sessionService: SessionService
    ) { }

    public async initializeApplication(config: NgcCookieConsentConfig) {

        this.cookieService.getCookieConsentDomain().then(x => {
            this.config = x;
            config.cookie.domain = this.config.cookieDomain;
        });

        this.setApplicationTheme();

        await this.getApiEndpoints();

        await this.getAnalyticsOptions();

        await this.startSession();

        this.loadAvailableLanguages();

        this.settingsService.loadSettings();

        if (this.settingsService.settings.language !== environment.defaultLanguage)
        {
            await this.loadPreferredLanguage();
        }

        this.logger.info("Application successfully initialized");

        return Promise.resolve();
    }

    private setApplicationTheme() {
        this.themeService.setDefaultTheme();
    }

    private loadAvailableLanguages() {
        this.localizationService.loadAvailableLanguages();
    }

    private async loadPreferredLanguage() {
        try {
            const language = await this.localizationService.getLanguageFromCode(this.settingsService.settings.language);

            if (language) {
                await this.localizationService.changeLanguage(language);
            }
        } catch (e) {
            this.logger.error(e);
        }
    }

    private async getApiEndpoints() {
        try
        {
            const endpoints = await this.endpointsProvider.getApiEndpoints();

            Object.assign(this.endpoints, endpoints);
        }
        catch(e)
        {
            this.logger.error(e);
            this.errors.push("Failed to retrieve the API endpoints.");
        }
    }

    private async getAnalyticsOptions()
    {
        try
        {
            await this.analyticsOptionsService.initialize();
        }
        catch(e)
        {
            this.logger.error(e);
            this.errors.push("Failed to retrieve the analytics options.");
        }
    }

    private async startSession()
    {
        try
        {
            await this.sessionService.startSession();
        }
        catch(e)
        {
            this.logger.error(e);
            this.errors.push("An error has occurred while starting the session.");
        }
    }
}
