import { Component, Input, OnInit } from '@angular/core';
import { Manufacturer } from "../../../../../formulation/models/manufacturer";
import { FormField } from "../../../../../shared/components/base/forms/form-field";
import { String } from "typescript-string-operations";

@Component({
    selector: 'manufacturer-field',
    templateUrl: './manufacturer.component.html',
    styleUrls: []
})
export class ManufacturerComponent extends FormField<Manufacturer> implements OnInit {

    @Input() manufacturers: Manufacturer[];

    ngOnInit() {
    }

    startsWithSearchFn(searchText: string, manufacturer: Manufacturer) {
        if (String.IsNullOrWhiteSpace(searchText)) { return true; }

        return manufacturer.name.toLowerCase().startsWith(searchText.toLowerCase());
    }
}
