import { IDeserialize } from "../../shared/interfaces/deserialize";

export class ComponentType implements IDeserialize {
    id: number;
    name: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        return this;
    }
}
