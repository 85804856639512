import { IDeserialize } from "../../shared/interfaces/deserialize";

export class GlossLevel implements IDeserialize {
    id: number;
    coreSystemId: number;
    qualityNo: number;
    componentId: number;
    name: string;
    percentage: number;
    qualityAdditiveId: number;
    sort: number;
    localizedName: string;
    localizationPath: string;

    deserialize(input: any) {
        Object.assign(<any>this, input);
        return this;
    }
}
