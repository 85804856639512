import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {HttpService} from "src/app/core/http-service";
import {NGXLogger} from "ngx-logger";
import {Endpoints} from "src/app/shared/models/endpoints";
import RequestPasswordResetDto from "src/app/reset-password/request-reset/dtos/request-password-reset-dto";
import {httpJsonHeaders} from "src/app/core/utils/http-utils";

@Injectable({providedIn: 'root'})
export class RequestPasswordResetService
{
    constructor(
        private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints
    ) {
    }

    public async requestPasswordReset(username: string): Promise<void>
    {
        const dto = new RequestPasswordResetDto(username);
        const headers = httpJsonHeaders();

        await this.http.post(this.endpoints.apiRequestPasswordReset, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while requesting the password reset.`, err);
                    return throwError(err);
                }),
            )
            .toPromise();
    }
}
