<div class="register-section" Auth>
    <div></div>
    <app-standard-page>
        <div class="container d-flex flex-column">

            <div class="register">
                <h2>{{ 'Register.View.Title' | translate }}
                    <span class="step-no" *ngIf="step == steps.step1">1/2</span>
                    <span class="step-no" *ngIf="step == steps.step2">2/2</span>
                </h2>

                <div class="steps-container">
                    <form #form="ngForm" novalidate>
                        <!-- <app-register-step1 #step1 (nextStep)="nextStep()" [hidden]="step == steps.step2"></app-register-step1>
                        <app-register-step2 [isProcessingError]="isProcessingError" [processingErrorMessage]="processingErrorMessage" [isProcessing]="isProcessing" (register)="register()" #step2 (previousStep)="previousStep()" [hidden]="step == steps.step1"></app-register-step2> -->

                        <app-register-step1 #step1 (step1To2Emitter)="goToStep2()" [hidden]="step != steps.step1">
                        </app-register-step1>
                        <app-register-step2 [isProcessingError]="isProcessingError"
                            [processingErrorMessage]="processingErrorMessage" [isProcessing]="isProcessing"
                            (register)="register()" #step2 (step2To1Emitter)="goToStep1()"
                            (step2To3Emitter)="goToStep3()" (step2To4Emitter)="goToStep4()"
                            [hidden]="step != steps.step2"></app-register-step2>
                        <app-register-license #step3 (step3To2Emitter)="goToStep2()" [hidden]="step != steps.step3">
                        </app-register-license>
                        <app-register-privacy #step4 (step4To2Emitter)="goToStep2()" [hidden]="step != steps.step4">
                        </app-register-privacy>
                    </form>
                </div>
            </div>

        </div>
    </app-standard-page>
</div>
