import {Component, OnDestroy, OnInit} from '@angular/core';
import {sections} from "../../../shared/configuration/sections";
import {Router} from "@angular/router";
import {MixingNavigationService} from "../../services/mixing-navigation.service";
import {AncillaryProductDialogService} from "../../../formulation-ancillary-product/services/ancillary-product-dialog-service";
import {AncillaryProductCriteriaGenerator} from "../../../formulation-ancillary-product/services/ancillary-product-criteria-generator";
import {IMixingContainer} from "../../interfaces/i-mixing-container";
import {ContainerType} from "../../enums/container-type";
import {AncillaryProductCriteria} from "../../../formulation-ancillary-product/models/criteria/ancillary-product-criteria";
import {MixingContainer} from "../../models/mixing-data/default/mixing-container";
import {ApMixingContainer} from "../../models/mixing-data/ancillary-product/ap-mixing-container";
import {PrintingService} from "../../../formulation-printing/services/printing.service";
import {AncillaryProductExcludedComponentService} from "../../services/ancillary-product-excluded-component.service";
import {AncillaryProductFilter} from "../../../formulation-ancillary-product/models/ancillary-product-filter";
import {IsMobileViewPipe} from "../../../shared/pipes/is-mobile-view.pipe";
import {fromEvent, Observable, Subscription} from "rxjs";
import {debounceTime} from 'rxjs/operators';
import {NotificationDialogService} from "../../../core/dialogs/notification-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from 'src/environments/environment';
import {AnalyticsMixingContainerService} from "src/app/core/analytics/services/analytics-mixing-container.service";
import {AnalyticsApMixingContainerService} from "src/app/core/analytics/services/analytics-ap-mixing-container.service";

@Component({
    selector: 'app-mixing-actions',
    templateUrl: './mixing-actions.component.html',
    styleUrls: ['./mixing-actions.component.scss']
})
export class MixingActionsComponent implements OnInit, OnDestroy {
    isMobileView: boolean;
    canNavigateBack: boolean;
    container: IMixingContainer;
    containerSubscription: Subscription;
    navigationSubscription: Subscription;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    constructor(
        private router: Router,
        public navigation: MixingNavigationService,
        private ancillaryProductDialogservice: AncillaryProductDialogService,
        private ancillaryProductCriteriaGenerator: AncillaryProductCriteriaGenerator,
        private ancillaryProductExcludedComponentService: AncillaryProductExcludedComponentService,
        private ancillaryProductFilter: AncillaryProductFilter,
        private printingService: PrintingService,
        private mobileViewPipe: IsMobileViewPipe,
        private translate: TranslateService,
        private notificationDialogService: NotificationDialogService,
        private analyticsMixingContainerService: AnalyticsMixingContainerService,
        private analyticsApMixingContainerService: AnalyticsApMixingContainerService) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.onContainerChanged(this.navigation.currentContainer);
        this.containerSubscription = this.navigation.containerChanged.subscribe(x => this.onContainerChanged(x.newValue));
        this.navigationSubscription = this.navigation.canNavigateBackChanged.subscribe(canNavigateBack => this.canNavigateBack = canNavigateBack);
    }

    coatingNotification(method: any) {
        const mixingContainer = this.container && this.container.containerType === ContainerType.Default ? this.container as MixingContainer : null;

        if (mixingContainer && mixingContainer.coatings.length > 1
            && !mixingContainer.coatings.every(x => mixingContainer.seenCoatings.some(y => y === x))) {
            this.notificationDialogService.showConfirmDialog(method, this.translate.instant('Navigation.Message.NotAllCoatingsSeen'));
        }
        else {
            method();
        }
    }

    navigateBack() {
        this.coatingNotification(() => this.doNavigateBack());
    }

    navigateToSearch() {
        this.coatingNotification(() => this.doNavigateToSearch());
    }

    doNavigateBack() {
        if (this.canNavigateBack) {
            this.navigation.navigateBack();
        }
        else {
            this.navigation.clear();
            this.router.navigate([`/${sections.search}`]);
        }
    }

    doNavigateToSearch() {
        this.navigation.clear();
        this.router.navigate([`/${sections.search}`], { queryParams: { clear: true } });
    }

    showAncillaryProductDialog() {
        if (this.container == null) {
            return;
        }

        let criteria: AncillaryProductCriteria;
        if (this.container.containerType === ContainerType.Default) {
            if (this.containsExcludedComponent()) {
                return;
            }

            criteria = this.ancillaryProductCriteriaGenerator.fromMixingContainer(this.container as MixingContainer, false);
        }
        else {
            criteria = this.ancillaryProductCriteriaGenerator.fromAncillaryProductMixingContainer(this.container as ApMixingContainer);
        }

        this.ancillaryProductFilter.componentType = null;
        this.ancillaryProductDialogservice.showDialog(criteria);
    }

    containsExcludedComponent(): boolean {
        return this.ancillaryProductExcludedComponentService.containsExcludedComponent(this.container as MixingContainer);
    }

    ngOnDestroy(): void {
        this.containerSubscription.unsubscribe();
        this.navigationSubscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

    async printFormulation(): Promise<void>
    {
        if(this.container.containerType === ContainerType.Default)
        {
            await this.analyticsMixingContainerService.saveMixingContainer(this.container as MixingContainer);
        }
        else if (this.container.containerType === ContainerType.AncillaryProduct)
        {
            await this.analyticsApMixingContainerService.saveApMixingContainer(this.container as ApMixingContainer);
        }

        await this.printingService.print(this.container);
    }

    private onContainerChanged(container: IMixingContainer) {
        this.container = container;
        this.ancillaryProductExcludedComponentService.isAncillaryProductEnabled = true;
    }

    get isDyoMode() {
        return environment.theme.id === "dyo";
    }

    get isAncillaryProductEnabled() {
        return this.ancillaryProductExcludedComponentService.isAncillaryProductEnabled;
    }
}
