import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { UserManagementComponent } from "./components/user-management.component";

@NgModule({
    declarations: [
        UserManagementComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        UserManagementComponent
    ]
})
export class UserManagementModule {

}
