import { IDeserialize } from "../../shared/interfaces/deserialize";

export class QualityAdditive implements IDeserialize {
    id: number;
    coreSystemId: number;
    qualityNo: number;
    componentId: number;
    name: string;
    percentage: number;
    isWithoutAdditive: boolean;

    deserialize(input: any) {
        Object.assign(<any>this, input);
        return this;
    }
}
