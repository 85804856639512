import {
    OnInit,
    Component,
    EventEmitter, Output
} from '@angular/core';

@Component({
    selector: 'app-register-license',
    templateUrl: './license.component.html',
    styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {

    @Output() step3To2Emitter = new EventEmitter<any>();

    pdfSource = "/assets/license.pdf";
    constructor() { }

    ngOnInit(): void {
    }

    goToStep2() {
        this.step3To2Emitter.emit(null);
    }

}
