import { Injectable, Inject } from '@angular/core';
import { THEMES, ACTIVE_THEME, Theme } from './symbols';
import { ColorService } from "./color.service";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    //onThemeChanged = new EventEmitter<ThemeChangedEventArgs>();
    themes: Theme[];
    selectedTheme: Theme;

    constructor(@Inject(THEMES) themes: Theme[],
        @Inject(ACTIVE_THEME) theme: Theme,
        private colorService: ColorService) {
        this.themes = themes;
        this.selectedTheme = theme;
    }

    setDefaultTheme() {
        let themeName = environment.theme.id === "mipa" ? "light-blue" : "dyo";
        const theme = this.getThemeByName(themeName);

        if (theme) {
            this.setTheme(theme);
        }
    }

    setTheme(theme: Theme) {
        const oldTheme = this.selectedTheme;

        this.selectedTheme = theme;

        this.updateStyles(oldTheme, this.selectedTheme);
        //this.onThemeChanged.emit(new ThemeChangedEventArgs(oldTheme, theme));
    }

    getThemeByName(name: string): Theme {
        const theme = this.themes.find(t => t.name === name);

        if (!theme) {
            throw new Error(`Theme not found: '${name}'`);
        }

        return theme;
    }

    updateStyles(oldTheme: Theme, newTheme: Theme) {
        if (oldTheme) {
            document.body.classList.remove(this.getClassName(oldTheme));
        }

        this.updatePalette(newTheme);
        this.updateProperties(newTheme);

        document.body.classList.add(this.getClassName(newTheme));
    }

    updatePalette(theme: Theme) {
        for (const key in theme.palette) {
            if (theme.palette.hasOwnProperty(key)) {
                document.body.style.setProperty(`--${key}`, theme.palette[key]);
            }
        }
    }

    updateProperties(theme: Theme) {
        for (const key in theme.properties) {
            if (theme.properties.hasOwnProperty(key)) {
                document.body.style.setProperty(`--${key}`, this.getProcessedValue(key, theme));
            }
        }
    }

    getClassName(theme: Theme): string {
        return `${theme.name}-theme`;
    }


    getProcessedValue(key: string, theme: Theme): string {
        const value = theme.properties[key];

        if (value.startsWith('argb')) {
            return this.getArgbValue(value, theme.palette);
        }

        return value;
    }

    getArgbValue(value: string, palette: any): string {
        const parts = this.getValueParts(value);

        const color = palette[parts[0].trim()];

        return this.colorService.hexToRgba(color, parts[1].trim());
    }


    getValueParts(value: string) {
        value = value.substring(value.indexOf('(') + 1);
        value = value.substring(0, value.indexOf(')')).trim();

        return value.split(',');
    }

}
