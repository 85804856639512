<app-standard-page>
    <div class="container d-flex flex-column">
        <ul class="search-tabs-mobile" *ngIf="isMobileView">
            <li>
                <a (click)="selectTab(0)" [ngClass]="{'active': selectedIndex === 0}" href="javascript:void(0)">
                    {{'FormulationSearch.View.Title' | translate}}
                </a>
            </li>
            <li [hidden]="isDyoMode">
                <a (click)="selectTab(1)" [ngClass]="{'active': selectedIndex === 1}" href="javascript:void(0)">
                    {{'OtherProducts.View.Title' | translate}}
                </a>
            </li>
        </ul>
        <ul class="search-tabs" *ngIf="!isMobileView">
            <li>
                <a (click)="selectTab(0)" [ngClass]="{'active': selectedIndex === 0}" href="javascript:void(0)">
                    {{'FormulationSearch.View.Title' | translate}}
                </a>
            </li>
            <li  [hidden]="isDyoMode">
                <a (click)="selectTab(1)" [ngClass]="{'active': selectedIndex === 1}" href="javascript:void(0)">
                    {{'OtherProducts.View.Title' | translate}}
                </a>
            </li>
        </ul>

        <app-other-products [hidden]="selectedIndex !== 1"></app-other-products>

        <app-search-form #searchForm
            (resultsUpdated)="searchResults.onResultsUpdated($event);actions.onResultsUpdated($event)"
            (searching)="searchResults.onSearching($event)" [hidden]="searchForm.isCollapsed || selectedIndex !== 0">
        </app-search-form>

        <div class="horizontal-splitter" [hidden]="searchForm.isCollapsed || selectedIndex === 1"></div>

        <app-search-actions-bar #actions [hidden]="selectedIndex === 1" [currentSearchType]="searchResults.searchType"
            [voc]="searchResults.voc" [selectedColor]="searchResults.selectedColor" [viewType]="searchResults.viewType"
            [isSearchFormCollapsed]="searchForm.isCollapsed"
            (onToggleSearchFormVisibility)="searchForm.onSearchFormToggle($event)"
            (onToggleSearchFormVisibility)="searchResults.onSearchFormToggle($event)"
            (onViewTypeChanged)="searchResults.onViewTypeChanged($event)"></app-search-actions-bar>

        <div class="flex-grow" [hidden]="selectedIndex === 1">
            <app-search-results #searchResults [searchTabIsSelected]="selectedIndex === 0"></app-search-results>
        </div>
    </div>
</app-standard-page>
