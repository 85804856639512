import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'password-confirm-field',
    templateUrl: './password-confirm.component.html',
    styles: []
})
export class PasswordConfirmField extends FormField<string> implements OnInit {
    ngOnInit() {
    }
}
