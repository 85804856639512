import { ManufacturerModel } from "./manufacturer-model";
import { IDeserialize } from "../../shared/interfaces/deserialize";

export class Manufacturer implements IDeserialize {
    public id: number;
    public manufacturerGroupId: number;
    public shortcut: string;
    public name: string;
    public models: Array<ManufacturerModel>;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (this.models) {
            this.models = this.models.map(x => new ManufacturerModel().deserialize(x));
        }

        return this;
    }
}
