<div class="tile color-none" *ngIf="!color.hasColor && !color.isTwoTone">
    <svg style='width: 100%; height: 100%;'>
        <line x1="0" y1="100%" x2="100%" y2="0"></line>
        <line x1="0" y1="0" x2="100%" y2="100%"></line>
    </svg>
</div>

<div class="tile color-solid" *ngIf="color.hasColor && !color.isTwoTone && !color.isMetalic"
    [ngStyle]="{'background-color': 'rgb(' + color.color45 + ')'}"></div>

<div class="tile color-gradient" *ngIf="color.hasColor && color.isMetalic"
    [ngStyle]="{'background-image': 'linear-gradient(to right, rgb(' + color.color45 + ') 0%, rgb(' + color.color25 + ') 60%, rgb(' + color.color75 + ') 100%)'}">
    <div class="noise"></div>
</div>

<div class="tile color-two-tone" *ngIf="color.isTwoTone">
    <div class="two-tone-img"></div>
</div>
