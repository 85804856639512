export class SearchCriteria {
    manufacturerId: number;
    manufacturerGroupId: number;
    modelId: number;
    colorNumber: string;
    colorName: string;

    mcs2: string;
    mcs3: string;

    formulationNumber: string;
    yearOfBuild: string;
    colorGroupId: number;

    mcsType: number;
    regionId: number;
    voc: string;
}
