import { Injectable } from "@angular/core";
import { MixingContainer } from "../models/mixing-data/default/mixing-container";
import { NotificationDialogService } from "../../core/dialogs/notification-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class AncillaryProductExcludedComponentService {
    constructor(private notificationDialogService: NotificationDialogService,
        private translate: TranslateService,) { }

    public isAncillaryProductEnabled: boolean;


    containsExcludedComponent(container: MixingContainer): boolean {
        if (!container.selectedComponentsContainer || !container.selectedComponentsContainer.components) { return false; }

        const excluded = ["1K-HS-Convert", "1K-Convert", "Multi-Mat", "Str-Add fein", "C-gloss", "C-mat", "CPE", "CMS", "2K-AC matt", "2K-HS matt", "WBC-Vicrom", "BC-Vicrom", "Binder-NC", "Binder-AC", "Binder-TC", "Binder-PUR"]; //,"WBC B 2K-Deck gl", "WBC B 2K-Deck matt", "WBC B 2K-Planen", "WBC B 2K-Leder sgl", "WBC B 2K-Leder matt", "WBC B 2K-Reiss", "WBC B 2K-Soft", "WBC B 2K-Struktur"];

        const material = container.selectedComponentsContainer.components.filter(x => excluded.includes(x.rawMaterial))[0];

        if (material) {
            this.notificationDialogService.showDialog(this.translate.instant('Mixing.Message.AncillaryProductExcludedComponent', { value: material.rawMaterial }));
            this.isAncillaryProductEnabled = false;

            return true;
        }

        return false;
    }
}
