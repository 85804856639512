<app-search-results-list-view #listView [searchType]="searchType" [qualities]="qualities" [colors]="colors" [voc]="voc"
    [noResultsFound]="noResultsFound" [selectedColor]="selectedColor" [isSearchFormInvisible]="isSearchFormInvisible"
    (selectedColorChanged)="onSelectedColorChanged($event)" *ngIf="isListView">
</app-search-results-list-view>

<app-search-results-tile-view #gridView [searchType]="searchType" [colors]="colors" [voc]="voc"
    [noResultsFound]="noResultsFound" [isSearchFormInvisible]="isSearchFormInvisible"
    (selectedColorChanged)="onSelectedColorChanged($event)" *ngIf="!isListView"
    [searchTabIsSelected]="searchTabIsSelected">
</app-search-results-tile-view>
