import { Component, ViewChild, OnDestroy, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { MixingNavigationService } from "../services/mixing-navigation.service";
import { ContainerType } from "../enums/container-type";
import { IMixingContainer } from "../interfaces/i-mixing-container";
import { ApMixingMaterialsComponent } from './mixing-materials/ancillary-product/ap-mixing-materials.component';
import { MixingInformationComponent } from './mixing-information/default/mixing-information.component';
import { IsMobileViewPipe } from "../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { MixingMaterialsComponent } from './mixing-materials/default/mixing-materials.component';

@Component({
    selector: 'app-mixing',
    templateUrl: './mixing.component.html',
    styleUrls: ['./mixing.component.scss']
})
export class MixingComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(ApMixingMaterialsComponent) childApMaterials: ApMixingMaterialsComponent;
    @ViewChild(MixingInformationComponent) childInformation: MixingInformationComponent;
    @ViewChild(MixingMaterialsComponent) mixingMaterialsComponent: MixingMaterialsComponent;

    containerTypes = ContainerType;
    container: IMixingContainer;
    containerSubscription: Subscription;
    isMobileView: boolean;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    constructor(
        private router: Router,
        private navigation: MixingNavigationService,
        private mobileViewPipe: IsMobileViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.initialize();
        this.containerSubscription = this.navigation.containerChanged.subscribe(x => this.onContainerChanged(x.newValue));
    }

    ngAfterViewInit() {
        this.container.childInformation = this.childInformation;
        this.container.childApMaterials = this.childApMaterials;
        if (this.isMobileView) {
            this.openTab("components");
        }
    }

    initialize() {
        if (this.navigation.currentContainer == null) {
            this.router.navigate(['/search']);
        } else {
            this.container = this.navigation.currentContainer;
        }
    }

    ngOnDestroy() {
        this.containerSubscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

    openTab(tabName: string) {
        if (document) {
            if (this.container.containerType === ContainerType.AncillaryProduct && tabName === "information") {
                this.container.childApMaterials = this.childApMaterials;
            }

            if (this.container.containerType === ContainerType.AncillaryProduct && tabName === "components") {
                this.container.childApMaterials.getComponentsMobile();
            }

            const tabcontent = document.getElementsByClassName("tabcontent");
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < tabcontent.length; i++) {
                const content = tabcontent[i] as HTMLElement;
                content.style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            const tablinks = document.getElementsByClassName("tablinks");
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(tabName).style.display = "block";
            document.getElementById(tabName + "Button").className += " active";

            if (this.container.containerType === ContainerType.Default && tabName === "components") {
                // This is necessary to trigger a re-render of the ngx-datatable. Otherwise it is invisible until the window is resized (or similar events).
                if (this.mixingMaterialsComponent) {
                    this.mixingMaterialsComponent.container.selectedComponentsContainer.components = [...this.mixingMaterialsComponent.container.selectedComponentsContainer.components];
                }
            }
        }
    }

    private onContainerChanged(container: IMixingContainer) {
        if (container.isBackEvent) {
            container.isBackEvent = false;
            this.container = container;
            if (this.isMobileView) {
                this.openTab("components");
            }
        }
        else {
            container.childInformation = this.childInformation;
            container.childApMaterials = this.childApMaterials;
            this.container = container;
        }

    }
}
