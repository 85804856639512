import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'phone-field',
    templateUrl: './phone.component.html',
    styles: []
})
export class PhoneField extends FormField<string> implements OnInit {

    ngOnInit() {
    }

}
