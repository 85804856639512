import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { RegisterComponent } from "./components/register.component";
import { Step1Component } from "./components/step1/step1.component";
import { Step2Component } from "./components/step2/step2.component";
import { LicenseComponent } from "./components/license/license.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { CountryField } from "./components/step1/fields/country.component";
import { LanguageField } from "./components/step1/fields/language.component";
import { FirstNameField } from "./components/step1/fields/first-name.component";
import { LastNameField } from "./components/step1/fields/last-name.component";
import { CompanyNameField } from "./components/step1/fields/company-name.component";
import { Address1Field } from "./components/step1/fields/address1.component";
import { CityField } from "./components/step1/fields/city.component";
import { CompanyEmailField } from "./components/step1/fields/company-email.component";
import { CustomerNumberField } from "./components/step1/fields/customer-number.component";
import { DistributorNameField } from "./components/step1/fields/distributor-name.component";
import { PhoneField } from "./components/step1/fields/phone.component";
import { ZipField } from "./components/step1/fields/zip.component";
import { Address2Field } from "./components/step1/fields/address2.component";
import { Address3Field } from "./components/step1/fields/address3.component";
import { UsernameField } from "./components/step2/fields/username.component";
import { PasswordField } from "./components/step2/fields/password.component";
import { PasswordConfirmField } from "./components/step2/fields/password-confirm.component";
import { TermsField } from "./components/step2/fields/terms.component";
import { PrivacyField } from "./components/step2/fields/privacy-field.component";



@NgModule({
    declarations: [
        RegisterComponent,
        Step1Component,
        Step2Component,
        LicenseComponent,
        PrivacyComponent,
        CountryField,
        LanguageField,
        Address1Field,
        Address2Field,
        Address3Field,
        CityField,
        CompanyEmailField,
        CompanyNameField,
        CustomerNumberField,
        DistributorNameField,
        FirstNameField,
        LastNameField,
        PhoneField,
        ZipField,
        UsernameField,
        PasswordField,
        PasswordConfirmField,
        TermsField,
        PrivacyField
    ],
    imports:
        [
            SharedModule
        ],
    exports: [
        RegisterComponent,
    ]
})

export class RegisterModule {
}
