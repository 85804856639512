import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { GlossLevel } from "../../../../formulation/models/gloss-level";
import { QualityAdditive } from "../../../../formulation/models/quality-additive";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-formulation-data-gloss-level',
    templateUrl: './gloss-level.component.html',
    styles: []
})
export class FormulationDataGlossLevelComponent extends FormField<GlossLevel> implements OnInit, OnDestroy {
    localizationSub: Subscription;
    isDisabled: boolean;
    variant: QualityAdditive;
    filteredGlossLevels: GlossLevel[];

    private _glossLevels: GlossLevel[];

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.localizationSub = this.translate.onLangChange.subscribe(() => this.setLocalizedNames());
    }

    onVariantChanged(variant: QualityAdditive) {
        this.variant = variant;
        this.refresh();
    }

    refresh() {
        if (this.variant && this.glossLevels && this.glossLevels.length > 0) {
            this.filteredGlossLevels = this.glossLevels.filter(x => x.qualityAdditiveId === this.variant.id);
            if (this.filteredGlossLevels.length > 0) {
                this.filteredGlossLevels.splice(0, 0, this.getHighGlossItem());
            }

            this.value = this.filteredGlossLevels[0];
        }
        else {
            this.filteredGlossLevels = null;
            this.value = null;
        }

        this.isVisible = this.filteredGlossLevels && this.filteredGlossLevels.length > 0;
    }

    getHighGlossItem() {
        return this.glossLevels.find(x => x.id === 0);
    }

    setLocalizedNames() {
        if (!this.glossLevels) {
            return;
        }

        // eslint-disable-next-line guard-for-in
        for (const i in this.glossLevels) {
            const glossLevel = this.glossLevels[i];
            glossLevel.localizedName = this.translate.instant(glossLevel.localizationPath);
        }
    }

    ngOnDestroy(): void {
        if (this.localizationSub) {
            this.localizationSub.unsubscribe();
        }
    }

    setState(hasNoGloss: boolean) {
        this.isDisabled = hasNoGloss;
        if (this.isDisabled) {
            this.value = null;
        }
    }

    @Input()
    set glossLevels(value) {
        this._glossLevels = value;
        if (this.glossLevels) {
            this.setLocalizedNames();
        }

        this.refresh();
    }

    get glossLevels() {
        return this._glossLevels;
    }
}
