export const sections =
{
    login: "login",
    search: "search",
    mixing: "mixing",
    appError: "app-error",
    unsupportedBrowser: "unsupported-browser",
    searchNew: "search-new",
    register: "register",
    userManagement: "user-management",
    requestPasswordReset: "reset-password",
    performPasswordReset: "reset-password/:token"
};
