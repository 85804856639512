import { NgModule } from '@angular/core';
import { MixingComponent } from "./components/mixing.component";
import { MixingActionsComponent } from "./components/mixing-actions/mixing-actions.component";
import { MixingInformationComponent } from "./components/mixing-information/default/mixing-information.component";
import { MixingMaterialsComponent } from "./components/mixing-materials/default/mixing-materials.component";
import { SharedModule } from "../shared/shared.module";
import { ApMixingMaterialsComponent } from "./components/mixing-materials/ancillary-product/ap-mixing-materials.component";
import { ApMixingInformationComponent } from "./components/mixing-information/ancillary-product/ap-mixing-information.component";
import { FormulationPrintingModule } from "../formulation-printing/formulation-printing.module";
import { RemoveSpPrefixPipe } from '../shared/pipes/remove-sp-prefix.pipe';

@NgModule({
    declarations: [
        MixingComponent,
        MixingActionsComponent,
        MixingInformationComponent,
        MixingMaterialsComponent,
        ApMixingMaterialsComponent,
        ApMixingInformationComponent,
        RemoveSpPrefixPipe
    ],
    imports: [
        SharedModule,
        FormulationPrintingModule
    ]
})
export class MixingModule { }
