import { Component, OnInit } from '@angular/core';
import { VocConformity } from "../../../models/voc-conformity";
import { AncillaryProductData } from "../../../models/ancillary-product-data";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { AncillaryProductItem } from "../../../models/ancillary-product-item";
import { Quality } from "../../../../formulation/models/quality";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'ancillary-voc',
    templateUrl: './voc.component.html',
    styles: []
})
export class AncillaryVocField extends FormField<VocConformity> implements OnInit {

    filteredVocConformities: VocConformity[];
    product: AncillaryProductItem;
    quality: Quality;

    constructor(private data: AncillaryProductData, private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(x => {
            this.setLocalizedNames();
        });



        this.refresh();
    }

    onProductChanged(product: AncillaryProductItem) {
        this.product = product;

        this.refresh();
    }

    onQualityChanged(quality: Quality) {
        this.quality = quality;

        this.refresh();
    }

    refresh() {
        if (!this.quality || !this.product) { return; }

        const productQualities = this.data.productToQualities.filter(x => x.qualityNo == this.quality.qualityNo).map(x => x.ancillaryProductId);
        const isVocComplianceList = this.data.products.filter(x => productQualities.some(x => x === this.product.ancillaryProductId) && x.componentId == this.product.componentId).map(x => x.isVocCompliance);

        if (isVocComplianceList.length > 1) {
            this.filteredVocConformities = this.data.vocConformities;
        }
        else if (isVocComplianceList.length == 1 && isVocComplianceList[0] == true) {
            this.filteredVocConformities = this.data.vocConformities.filter(x => x.id == 1);
        }
        else {
            this.filteredVocConformities = this.data.vocConformities.filter(x => x.id == 0);
        }

        this.setLocalizedNames();

        this.value = this.filteredVocConformities[0];
    }

    setLocalizedNames() {
        if (!this.filteredVocConformities) { return; }

        for (const i in this.filteredVocConformities) {
            const voc = this.filteredVocConformities[i];

            voc.localizedName = this.translate.instant(voc.localizationPath);
        }
    }
}
