<div class="tiles-view">
    <div class="colors-missing-msg" *ngIf="someColorsNotAvailable">{{'FormulationSearch.Text.SomeColorsNotAvailable' | translate}}</div>

    <virtual-scroller #scroll (vsUpdate)="onScroll()" [items]="colors"
        [ngStyle]="{'height': (isSearchFormInvisible) ? '70vh' : '26vh'}">
        <div (click)="selectColor(color)" (dblclick)="onTileDoubleClick()"
            [ngClass]="{'tile-selected': color === selectedColor}" class="flex-item tile-container"
            *ngFor="let color of scroll.viewPortItems">
            <tile-box [color]="color"></tile-box>
            <div class="tile-content">
                <div>{{color.manufacturer}}</div>
                <div>{{color.colorNumber}}</div>
                <div class="color-name">{{color.colorName}}</div>
                <div><span>{{color.var}}</span><span *ngIf="color.fVar"><span
                            class="var-sep">/</span><span>{{color.fVar}}</span></span></div>
                <div *ngIf="isFormulationSearch">{{color.formulationNo}}</div>
                <div *ngIf="!isFormulationSearch && searchSettings.mcsType === 2">
                    {{color.mcs2}}
                </div>
                <div *ngIf="!isFormulationSearch && searchSettings.mcsType === 3">
                    <span>{{color.mcs3}}</span><span *ngIf="color.compartmentNo"> ({{color.compartmentNo}})</span>
                </div>
            </div>
        </div>
    </virtual-scroller>

    <div class="no-results-found" *ngIf="noResultsFound">{{'FormulationSearch.Text.NoResultsFound' | translate}}</div>
</div>
