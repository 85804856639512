import { AfterViewInit, Component, QueryList, Renderer2, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { IFormField } from "../../shared/components/base/forms/form-field-interface";
import { AuthenticationService } from "../services/authentication.service";
import { Credentials } from "../models/credentials";
import { LoginResponse } from "../models/login-response";
import { LoginResponseType } from "../enums/login-response-type";
import { Router } from "@angular/router";
import { UsernameField } from "./fields/username/username.component";
import { PasswordField } from "./fields/password/password.component";
import { sections } from "../../shared/configuration/sections";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements AfterViewInit {

    @ViewChild('username', { static: true }) username: UsernameField;
    @ViewChild('password', { static: true }) password: PasswordField;
    @ViewChild("logodiv", { static: true }) logoDiv: ElementRef;
    @ViewChildren('required') requiredFields: QueryList<IFormField>;

    hasRequirementsError: boolean;
    isAuthError: boolean;
    authError: string;
    isAuthenticating: boolean;

    constructor(private authenticationService: AuthenticationService,
        private router: Router,
        private renderer: Renderer2) {
        this.renderer.addClass(document.body, 'auth-body');
    }

    ngOnInit():void {
        if (environment.theme.id === "mipa"){
            this.logoDiv.nativeElement.classList.add("mipa-logo")
        } else {
            this.logoDiv.nativeElement.classList.add("dyo-logo")
        }
    }
    ngAfterViewInit(): void {
        this.registerRequiredFieldsEvents();
    }

    async onKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            await this.authenticate();
        }
    }

    async authenticate() {
        if (!this.validate()) { return; }

        try {
            this.isAuthenticating = true;

            const credentials = new Credentials(this.username.value, this.password.value);

            const response = await this.authenticationService.authenticate(credentials);

            this.processAuthResponse(response);
        }
        catch (ex) {
            this.authError = "Authentication failed. An unknown error has occurred.";
            this.isAuthError = true;
        }
        finally {
            this.isAuthenticating = false;
        }
    }

    async goToResetPassword(): Promise<void> {
        await this.router.navigate([`/${sections.requestPasswordReset}`]);
    }

    private processAuthResponse(loginResponse: LoginResponse) {
        try {
            switch (loginResponse.loginResponseType) {
                case LoginResponseType.Success:
                    {
                        this.router.navigate(['/']);
                        break;
                    }
                case LoginResponseType.InvalidCredentials:
                    {
                        this.onInvalidCredentials();
                        break;
                    }
                case LoginResponseType.NotActivated:
                    {
                        this.onNotActivated();
                        break;
                    }
                case LoginResponseType.ServerError:
                default:
                    {
                        this.onServerError();
                        break;
                    }
            }
        }
        catch (ex) {
            this.onServerError();
        }
    }

    private onServerError() {
        this.authError = "An error has occurred while trying to authenticate";
        this.isAuthError = true;
    }

    private onNotActivated() {
        this.authError = "The account is not yet activated. Please click the link in the email.";
        this.isAuthError = true;
    }

    private onInvalidCredentials() {
        this.authError = "Authentication error - invalid credentials";
        this.isAuthError = true;
    }

    private validate(): boolean {
        this.clearErrors();

        if (!this.validateRequirements()) { return false; }

        return true;
    }

    registerRequiredFieldsEvents(): void {
        this.requiredFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onRequiredFieldFilled(isfilled));
        });
    }

    onRequiredFieldFilled(isFilled: boolean) {
        if (this.hasRequirementsError && isFilled) {
            this.hasRequirementsError = false;
        }
    }

    private validateRequirements(): boolean {
        this.hasRequirementsError = this.requiredFields.some(x => !x.isFilled);

        return !this.hasRequirementsError;
    }

    private clearErrors(): void {
        this.hasRequirementsError = false;
        this.isAuthError = false;
        this.authError = null;
    }

    goToRegister() {
        this.router.navigate([`/${sections.register}`]);
    }
}
