import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'privacy-field',
    templateUrl: './privacy-field.component.html',
    styles: []
})
export class PrivacyField extends FormField<boolean> implements OnInit {

    ngOnInit() {
    }
}
