import { SearchType } from "../../../../formulation-search/enums/search-type";
import { MixingCriteriaFormulationData } from "./mixing-criteria-formulation-data";
import { MixingCriteriaColor } from "./mixing-criteria-color";
import { QualityType } from "../../../../formulation/enums/quality-type";

export class MixingCriteria {
    color: MixingCriteriaColor;
    data: MixingCriteriaFormulationData;
    availableQualityTypes: QualityType[];
    searchType: SearchType;
    voc: string;
    formulationNoFromHistory: string;
}
