import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { SearchResult } from "../../models/search-result";
import { ResultsViewType } from "../../enums/results-view-type";
import { ColorRow } from "../../../formulation/models/color-row";
import { FormulationDataCriteria } from "../../../formulation-data/models/formulation-data-criteria";
import { SearchType } from "../../enums/search-type";
import { FormulationDataDialogService } from "../../../formulation-data/services/formulation-data-dialog.service";
import { AncillaryProductDialogService } from "../../../formulation-ancillary-product/services/ancillary-product-dialog-service";
import { NotificationDialogService } from "../../../core/dialogs/notification-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { IsMobileViewPipe } from "../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search-actions-bar',
    templateUrl: './search-actions-bar.component.html',
    styleUrls: ['./search-actions-bar.component.scss']
})
export class SearchActionsBarComponent implements OnInit, OnDestroy {

    @Output() onViewTypeChanged = new EventEmitter<ResultsViewType>();
    @Output() onToggleSearchFormVisibility = new EventEmitter<boolean>();

    @Input() viewType: ResultsViewType;
    @Input() isSearchFormCollapsed: boolean;

    @Input() selectedColor: ColorRow;
    @Input() currentSearchType: SearchType;
    @Input() voc: string;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    isMobileView: boolean;

    showTotalRecords: boolean;
    totalRecordsCount: number;

    ResultsViewType = ResultsViewType;

    constructor(private formulationDataDialogService: FormulationDataDialogService,
        private notificationDialogService: NotificationDialogService,
        private translate: TranslateService,
        private ancillaryProductDialogService: AncillaryProductDialogService,
        private cd: ChangeDetectorRef,
        private mobileViewPipe: IsMobileViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(evt => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });

        this.isMobileView = this.mobileViewPipe.transform(window);
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    changeViewType(viewType: ResultsViewType) {
        this.viewType = viewType;

        this.onViewTypeChanged.emit(viewType);
    }

    closeSearchForm() {
        this.onToggleSearchFormVisibility.emit(false);
    }

    showSearchForm() {
        this.onToggleSearchFormVisibility.emit(true);
    }

    onResultsUpdated(searchResult: SearchResult) {
        if (searchResult == null || searchResult.colors == null) {
            this.showTotalRecords = false;
        } else {

            this.totalRecordsCount = searchResult.colors.length;


            this.showTotalRecords = true;
        }
    }

    showFormulationDataDialog() {
        if (this.selectedColor) {
            if (this.selectedColor.isTwoTone) {
                this.notificationDialogService.showDialog(this.translate.instant('FormulationTwoTone.Label.TwoToneInfo'));
            } else {
                const criteria = FormulationDataCriteria.fromColor(this.selectedColor, this.currentSearchType, this.voc);
                this.formulationDataDialogService.showDialog(criteria, false);
            }
        }
    }

    showAncillaryProductDialog() {
        this.ancillaryProductDialogService.showDialog();
    }

    // get isMobileView(): boolean {
    //     let result = false;
    //     if (window) {
    //         result = window.innerWidth < MobileParameters.windowWidth;

    //     }
    //     return result;
    // }
}
