import { IMixingContainer } from "../../../interfaces/i-mixing-container";
import { ContainerType } from "../../../enums/container-type";
import { AncillaryProductCriteria } from "../../../../formulation-ancillary-product/models/criteria/ancillary-product-criteria";
import { AncillaryProductContainer } from "../../../../formulation-ancillary-product/models/ancillary-product-container";
import { ApMixingCriteria } from "../../criteria/ancillary-product/ap-mixing-criteria";
import { EventEmitter } from "@angular/core";
import { MixingComponent } from "../default/mixing-component";
import { ApMixingMaterialsComponent } from '../../../components/mixing-materials/ancillary-product/ap-mixing-materials.component';
import { MixingInformationComponent } from '../../../components/mixing-information/default/mixing-information.component';

export class ApMixingContainer implements IMixingContainer {
    readonly containerType: ContainerType;
    readonly isAncillaryProductMixEnabled: boolean;

    tabName: string;
    isBackEvent: boolean;
    childApMaterials: ApMixingMaterialsComponent;
    childInformation: MixingInformationComponent;

    analyticsItemId?: number;
    openedAt?: Date;
    closedAt?: Date;

    selectedProductChanged = new EventEmitter<AncillaryProductContainer>();

    _selectedProduct: AncillaryProductContainer;

    ancillaryProductCriteria: AncillaryProductCriteria;
    mixingCriteira: ApMixingCriteria;

    products: AncillaryProductContainer[];

    thinnerRelation1: number;
    thinnerRelation2: number;

    currentMixingComponents: MixingComponent[];

    get selectedProduct() { return this._selectedProduct; }

    set selectedProduct(value: AncillaryProductContainer) {
        this._selectedProduct = value;
        this.selectedProductChanged.emit(value);
    }

    constructor() {
        this.containerType = ContainerType.AncillaryProduct;
        this.isAncillaryProductMixEnabled = false;
    }
}
