import { Component, Input, OnInit } from '@angular/core';
import { String } from "typescript-string-operations";

@Component({
    selector: 'dots-loader',
    templateUrl: './dots-loader.component.html',
    styleUrls: ['./dots-loader.component.scss']
})
export class DotsLoaderComponent implements OnInit {

    @Input() caption: string;

    get isCaptionAvailable() {
        return !String.IsNullOrWhiteSpace(this.caption);
    }

    constructor() { }

    ngOnInit() {
    }

}
