export class ResetPasswordDto
{
    validateOnly: boolean;
    token: string;
    currentPassword: string;
    newPassword: string;

    public constructor(init?: Partial<ResetPasswordDto>)
    {
        Object.assign(this, init);
    }
}
