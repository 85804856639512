<nav class="navbar navbar-top navbar-expand-md bg-faded justify-content-center">
    <div class="container" *ngIf="!isMobileView">

        <a class="nav-logo" href="/">
            <span>
                <img [src]="logoPath" />
            </span>
        </a>

        <div class="navbar-collapse w-100" id="collapsingNavbar3">
            <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                <li *ngIf="authState.isAuthenticated && isAdmin" class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="goToUserManagement()">{{
                        'Header.Caption.UserManagement' | translate }}</a>
                </li>
                <li class="nav-item dropdown" (mouseover)="logoBinding = '/assets/images/language-white-24.png'"
                    (mouseleave)="setLogoBindingByStyle()">
                    <a class="nav-link dropdown-toggle" href="#" id="languagesDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img #blue class="lang2-logo" [src]="logoBinding" alt="Languages" />
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let language of languages;" class="dropdown-item" href="javascript:void(0)"
                            [ngClass]="{'dropdown-item-active': language.isSelected}"
                            (click)="changeLanguage(language);">
                            {{language.name}}
                        </a>
                    </div>
                </li>
                <li class="nav-item dropdown" *ngIf="allowThemeChange">
                    <a class="nav-link dropdown-toggle" href="#" id="themesDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Theme
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let theme of themes;" class="dropdown-item" href="javascript:void(0)"
                            [ngClass]="{'dropdown-item-active': theme.name == selectedTheme.name}"
                            (click)="changeTheme(theme)">
                            {{theme.caption}}
                        </a>
                    </div>
                </li>
                <li *ngIf="authState.isAuthenticated" class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="logout()">{{ 'Header.Caption.Logout' |
                        translate }}</a>
                </li>
            </ul>
        </div>
    </div>


    <div class="container" *ngIf="isMobileView">

        <a class="nav-logo-mobile" href="/">
            <span>
                <img [src]="logoPath" />
            </span>
        </a>
        <div class="nav-menu-mobile nav-font-mobile justify-content-end">

            <div class="nav-item dropdown" (mouseover)="logoBinding = '/assets/images/language-white-24.png'"
                (mouseleave)="setLogoBindingByStyle()">
                <a class="nav-link dropdown-toggle" href="#" id="languagesDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img #blue class="lang2-logo-mobile" [src]="logoBinding" alt="Languages" />
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a *ngFor="let language of languages;" class="dropdown-item nav-font-mobile"
                        href="javascript:void(0)" [ngClass]="{'dropdown-item-active': language.isSelected}"
                        (click)="changeLanguage(language);">
                        {{language.name}}
                    </a>
                </div>
            </div>

            <div *ngIf="authState.isAuthenticated" class="nav-item">
                <a class="nav-link" href="javascript:void(0)" (click)="logout()">{{ 'Header.Caption.Logout' | translate
                    }}</a>
            </div>
        </div>
    </div>

</nav>
