import { Component, OnInit } from '@angular/core';
import { AncillaryProductItem } from "../../../models/ancillary-product-item";
import { AncillaryProductGlossLevel } from "../../../models/ancillary-product-gloss-level";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { AncillaryProductData } from "../../../models/ancillary-product-data";
import { Quality } from "../../../../formulation/models/quality";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'ancillary-gloss-level',
    templateUrl: './gloss-level.component.html',
    styles: []
})
export class AncillaryGlossLevelField extends FormField<AncillaryProductGlossLevel> implements OnInit {


    product: AncillaryProductItem;
    quality: Quality;

    filteredGlossLevels: AncillaryProductGlossLevel[];

    constructor(private data: AncillaryProductData, private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(x => {
            this.setLocalizedNames();
        });

        this.setLocalizedNames();

        this.refresh();
    }

    onProductChanged(product: AncillaryProductItem) {
        this.product = product;

        this.refresh();
    }

    onQualityChanged(quality: Quality) {
        this.quality = quality;

        this.refresh();
    }

    refresh(): void {
        if (!this.product || !this.quality) { return; }

        const ancillaryIsGloss = this.data.componentTypes.some(x => x.id == this.product.componentTypeId && x.name == 'Klarlack');

        this.isVisible = !ancillaryIsGloss;

        if (ancillaryIsGloss) {
            this.value = null;
        }
        else {
            const glossLevels = this.data.glossLevels.filter(x => x.qualityNo == this.quality.qualityNo);

            this.filteredGlossLevels = glossLevels.length > 0 ? glossLevels : this.data.glossLevels.filter(x => x.isDefault);

            this.value = this.filteredGlossLevels[0];
        }
    }

    setLocalizedNames() {
        if (!this.filteredGlossLevels) { return; }

        for (const i in this.filteredGlossLevels) {
            const glossLevel = this.filteredGlossLevels[i];

            glossLevel.localizedName = this.translate.instant(glossLevel.localizationPath);
        }
    }
}
