import { Component, OnInit, OnDestroy } from '@angular/core';
import { Language } from "../../models/language";
import { SettingsService } from "../../../core/settings/settings.service";
import { LocalizationService } from "../../../core/localization/localization.service";
import { ThemeService } from "../../../core/theme/theme.service";
import { Theme } from "../../../core/theme/symbols";
import { environment } from "../../../../environments/environment";
import { AuthState } from "../../../authentication/models/auth-state";
import { AuthenticationService } from "../../../authentication/services/authentication.service";
import { JwtHelper } from "../../../core/jwt/jwt-helper";
import { sections } from "../../configuration/sections";
import { Router } from "@angular/router";
import { IsMobileViewPipe } from "../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {

    isMobileView: boolean;
    isAdmin: boolean;
    logoBinding: string;
    logoPath = "/assets/images/logo_mipa_pcs.jpg"

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    get languages(): Language[] {
        return this.localization.languages;
    }

    get themes(): Theme[] {
        return this.themeService.themes;
    }

    get selectedTheme(): Theme {
        return this.themeService.selectedTheme;
    }

    get allowThemeChange(): boolean {
        return !environment.production;
    }

    constructor(private localization: LocalizationService,
        private settingsService: SettingsService,
        private themeService: ThemeService,
        private authenticationService: AuthenticationService,
        public authState: AuthState,
        private router: Router,
        private jwtHelper: JwtHelper,
        private mobileViewPipe: IsMobileViewPipe) {
        this.localization = localization;
        this.setLogoBindingByStyle();
    }

    ngOnInit() {
        if (environment.theme.id === "dyo") {
            this.logoPath = "/assets/images/dyo_logo.svg"
        }

        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(evt => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });

        this.isMobileView = this.mobileViewPipe.transform(window);

        const token = this.authenticationService.getAccessToken();

        if (token != null) {
            const accessToken = this.jwtHelper.getAccessToken(token);

            this.isAdmin = accessToken.admin && accessToken.admin == 1;
        }

        return;
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    public changeLanguage(language: Language): void {
        this.localization.changeLanguage(language);

        this.saveLanguagePreferences(language);
    }

    private saveLanguagePreferences(language: Language): void {
        this.settingsService.settings.language = language.code;
        this.settingsService.saveSettings();
    }

    private changeTheme(theme: Theme) {
        this.themeService.setTheme(theme);
        this.setLogoBindingByStyle();
    }

    public logout() {
        this.authenticationService.logout();
    }

    goToUserManagement() {
        this.router.navigate([`/${sections.userManagement}`]);
    }

    setLogoBindingByStyle() {
        if (environment.theme.id === "dyo"){
            this.logoBinding = '/assets/images/language-dyo-24.png';
        } else {
            if (this.selectedTheme.name === "mint") {
                this.logoBinding = '/assets/images/language-mint-24.png';
            } else {
                this.logoBinding = '/assets/images/language-blue-24.png';
            }
        }        
    }
}
