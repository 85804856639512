<app-standard-page>
    <div class="user-mgmt container d-flex flex-column">
        <h3>{{ 'Header.Caption.UserManagement' | translate }}</h3>
        <div *ngIf="isInitialLoad" class="loading-users">
            <dots-loader caption="{{'Customers.Loading.Message' | translate}}"></dots-loader>
        </div>
        <div *ngIf="isLoadingError" class="users-load-error">{{'Customers.Loading.Error' | translate}}</div>
        <div class="list-view">

            <input type="text" class="searching-input" (keyup.enter)='filterDatatable($event)'
                placeholder="{{'Customers.Input.Placeholder' | translate}}">
                <div *ngIf="isLoadingError" class="users-load-error">{{'Customers.Loading.Error' | translate}}</div>
            <ngx-datatable
                [externalSorting]="true"
                (sort)="onSort($event)"
                [sorts]="[{prop: 'customer.username', dir: 'asc'}]"
                #table style="height: 70vh;margin-bottom: 40px;" [rows]="customers" [columnMode]="'force'"
                headerHeight="36" class="colors-table" [rowHeight]="getRowHeight()" [scrollbarV]="true"
                [virtualization]="true" (scroll)="onScroll($event)" [loadingIndicator]="isLoading" >

                <ngx-datatable-column headerClass="results-header" prop="customer.username"
                    name="{{'Customers.Column.Username' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.customer.username}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column minWidth="200" headerClass="results-header" prop="customer.companyEmail" [sortable]="false"
                    name="{{'Customers.Column.Email' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.customer.companyEmail}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass="results-header" prop="name" [sortable]="false"
                    name="{{'Customers.Column.Name' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.name}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass="results-header" prop="customer.company" [sortable]="false"
                    name="{{'Register.Label.CompanyName' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.customer.company}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass="results-header" prop="customer.city" [sortable]="false"
                    name="{{'Register.Label.City' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.customer.city}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass="results-header" prop="customer.distributorName" [sortable]="false"
                    name="{{'Register.Label.DistributorName' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.customer.distributorName}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass="results-header" prop="countryName"
                    name="{{'Register.Label.Country' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.countryName}} ({{row.customer.countryCode}})
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column headerClass="results-header" prop="lastLogin" [sortable]="false"
                    name="{{'Customers.Column.LastLogin' | translate }}">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        {{row.lastLogin}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column maxWidth="70" headerClass="results-header" prop="bc" name="" [sortable]="false">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <button class="row-btn btn-ban" (click)="confirmBan(row)" [disabled]="row.isProcessing"
                            *ngIf="!row.isAdministrator && !row.isBanned">
                            {{'Customers.Button.Ban' | translate}}
                        </button>
                        <button class="row-btn btn-ban" (click)="confirmUnban(row)" [disabled]="row.isProcessing"
                            *ngIf="!row.isAdministrator && row.isBanned">
                            {{'Customers.Button.Unban' | translate}}
                        </button>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column maxWidth="70" headerClass="results-header" prop="bc" name="" [sortable]="false">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <button class="row-btn btn-delete" [disabled]="row.isProcessing" *ngIf="!row.isAdministrator"
                            (click)="confirmDelete(row)">
                            {{'Customers.Button.Delete' | translate}}
                        </button>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>

        </div>

    </div>
</app-standard-page>
