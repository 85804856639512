import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { ColorRow } from "../../formulation/models/color-row";
import { catchError, timeout } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { ModelRow } from "../../formulation/models/model-row";
import { ContrastColorRow } from "../../formulation/models/contrast-color-row";
import { ContrastColorSearchCriteria } from "../models/contrast-color-search-criteria";
import { Endpoints } from "../../shared/models/endpoints";
import { SearchSettings } from "../models/search-settings";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class ContrastColorDataService {

    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints, private searchSettings: SearchSettings) { }

    getContrastColors(color: ColorRow, model: ModelRow): Observable<ContrastColorRow[]> {
        const criteria = ContrastColorSearchCriteria.create(color, model, this.searchSettings.mcsType);
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return this.http.post<ContrastColorRow[]>(this.endpoints.apiContrastColors, JSON.stringify(criteria), { headers }).pipe(
            timeout(30000),
            catchError(err => {
                this.logger.error(`Failed to retrieve the contrast colors for model '${model.model}'`, err);
                return throwError(err);
            })
        );

    }
}
