import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppInitializer } from "../../../core/app-initializer/app-initializer";

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    constructor(public appInitializer: AppInitializer, private router: Router) { }

    ngOnInit() {
        if (!this.appInitializer.hasErrors()) {
            this.router.navigate(["/"]);
        }
    }
}
