<ng-template #note>
    <div class="note-tip">
        <table>
            <tr *ngIf="criteria.isMcsVisible">
                <td>{{'MaintenanceOfFormulation.Label.MCSNumber' | translate}}:</td>
                <td>{{criteria.mcs}}</td>
            </tr>
            <tr *ngIf="criteria.isManufacturerVisible">
                <td>{{'Mixing.Label.Manufacturer' | translate}}:</td>
                <td>{{criteria.manufacturer}}</td>
            </tr>
            <tr *ngIf="criteria.isColorNoVisible">
                <td>{{'Mixing.Label.ColorNo' | translate}}:</td>
                <td>{{criteria.colorNo}}</td>
            </tr>
        </table>
    </div>
</ng-template>

<div class="ancillary-product" *ngIf="!isMobileView">
    <div class="d-flex dialog-header">
        <img class="dialog-icon" src="/assets/images/spray-gun.png" />
        <div class="title flex-grow-1">{{ 'AncillaryProductMix.View.Title' | translate }}</div>
        <button type="button" class="btn-close-modal" (click)="cancel()"><img src="/assets/images/x-16.png" /></button>
    </div>
    <div class="dialog-content">
        <form (keydown)="onKeyDown($event)" #form="ngForm" novalidate *ngIf="isLoaded">
            <table>
                <tr>
                    <th colspan="2">
                        <h2 class="top-caption">{{ 'AncillaryProductMix.Label.MainData' | translate }}</h2>
                    </th>
                </tr>

                <!-- Quality -->
                <tr>
                    <td><label>{{ 'AncillaryProductMix.Label.Quality' | translate }}</label></td>
                    <td>
                        <ancillary-quality #quality [availableQualityTypes]="criteria?.availableQualityTypes"
                            (onChanged)="product.onQualityChanged($event);
                                                        glossLevel.onQualityChanged($event);
                                                        voc.onQualityChanged($event)">
                        </ancillary-quality>
                    </td>
                </tr>

                <!-- Product -->
                <tr>
                    <td><label>{{ 'AncillaryProductMix.Label.AncillaryProduct' | translate }}</label></td>
                    <td>
                        <ancillary-product #product (onChanged)="glossLevel.onProductChanged($event);
                                                        voc.onProductChanged($event)">
                        </ancillary-product>
                    </td>
                </tr>

                <tr>
                    <th colspan="2">
                        <h2 class="middle-caption">{{ 'AncillaryProductMix.Label.Quantity' | translate }}</h2>
                    </th>
                </tr>

                <tr>
                    <td class="quantity" colspan="2">

                        <table>
                            <tr>
                                <td><label>{{ 'AncillaryProductMix.Label.Quantity' | translate }}</label></td>
                                <td>
                                    <ancillary-quantity #quantity [value]="data.defaultQuantity"
                                        [defaultQuantity]="criteria?.quantity">
                                    </ancillary-quantity>
                                </td>
                                <td></td>
                                <td><label>{{ 'AncillaryProductMix.Label.Unit' | translate }}</label></td>
                                <td>
                                    <ancillary-unit #unit [units]="data.units" [value]="data.unit"
                                        [defaultUnitId]="criteria?.selectedUnitId">
                                    </ancillary-unit>
                                </td>
                            </tr>
                        </table>

                        <!-- Quantity -->
                    </td>
                </tr>

                <tr>
                    <th colspan="2">
                        <h2 class="middle-caption">{{ 'AncillaryProductMix.Label.ExtendedData' | translate }}</h2>
                    </th>
                </tr>

                <!-- Temperature -->
                <tr>
                    <td><label>{{ 'AncillaryProductMix.Label.TemperatureRange' | translate }}</label></td>
                    <td>
                        <ancillary-temperature #temperature></ancillary-temperature>
                    </td>
                </tr>

                <!-- Gloss Level -->
                <tr [hidden]="!glossLevel.isVisible">
                    <td><label>{{ 'AncillaryProductMix.Label.DegreeOfGloss' | translate }}</label></td>
                    <td>
                        <ancillary-gloss-level #glossLevel></ancillary-gloss-level>
                    </td>
                </tr>

                <!-- VOC -->
                <tr>
                    <td><label>{{ 'AncillaryProductMix.Label.VocConformities' | translate }}</label></td>
                    <td>
                        <ancillary-voc #voc></ancillary-voc>
                    </td>
                </tr>
            </table>

            <div *ngIf="isMixing" class="formulation-data-loader flex-row-reverse d-flex">
                <dots-loader caption="{{'General.Text.PleaseWaitProcessing' | translate}}"></dots-loader>
            </div>

            <div *ngIf="isMixingError" class="mixing-error flex-row-reverse d-flex">
                {{'General.Text.FailedToProcessRequest' | translate}}
            </div>

            <div *ngIf="!isMixing" class="buttons flex-row-reverse d-flex">
                <button type="button" (click)="ok()" class="btn btn-default btn-ok">{{ 'FormulationData.Button.OK' |
                    translate }}</button>
                <button type="button" (click)="cancel()" class="btn btn-cancel">{{ 'FormulationData.Button.Cancel' |
                    translate }}</button>
            </div>
            <div *ngIf="criteria.isNoteVisible" class="note" [ngbTooltip]="note" tooltipClass="input-tooltip-note"><img
                    src="/assets/images/message.png"></div>
        </form>
        <div *ngIf="isLoadingData" class="formulation-data-loader">
            <dots-loader caption="{{'General.Text.LoadingRequiredData' | translate}}"></dots-loader>
        </div>
        <div class="data-load-error" *ngIf="isDataLoadError">
            <p>{{'General.Text.LoadRequiredDataError' | translate}}</p>
            <a class="click-message" href="javascript:void(0)"
                (click)="retryLoadRequiredData()">{{'General.Text.ClickToRetry' | translate}}</a>
        </div>
    </div>
</div>
<div class="ancillary-product-mobile" *ngIf="isMobileView">
    <div class="d-flex dialog-header">
        <img class="dialog-icon" src="/assets/images/spray-gun.png" />
        <div class="title flex-grow-1">{{ 'AncillaryProductMix.View.Title' | translate }}</div>
        <button type="button" class="btn-close-modal" (click)="cancel()"><img src="/assets/images/x-16.png" /></button>
    </div>
    <div class="dialog-content">
        <form (keydown)="onKeyDown($event)" #form="ngForm" novalidate *ngIf="isLoaded">
            <h2 class="top-caption">{{ 'AncillaryProductMix.Label.MainData' | translate }}</h2>

            <!-- Quality -->
            <label>{{ 'AncillaryProductMix.Label.Quality' | translate }}</label>
            <ancillary-quality #quality [availableQualityTypes]="criteria?.availableQualityTypes" (onChanged)="product.onQualityChanged($event);
                                                        glossLevel.onQualityChanged($event);
                                                        voc.onQualityChanged($event)">
            </ancillary-quality>

            <!-- Product -->
            <label>{{ 'AncillaryProductMix.Label.AncillaryProduct' | translate }}</label>
            <ancillary-product #product (onChanged)="glossLevel.onProductChanged($event); voc.onProductChanged($event)">
            </ancillary-product>

            <h2 class="middle-caption">{{ 'AncillaryProductMix.Label.Quantity' | translate }}</h2>

            <div class="quantity" colspan="2">
                <label>{{ 'AncillaryProductMix.Label.Quantity' | translate }}</label>
                <ancillary-quantity #quantity [value]="data.defaultQuantity" [defaultQuantity]="criteria?.quantity">
                </ancillary-quantity>
                <label>{{ 'AncillaryProductMix.Label.Unit' | translate }}</label>
                <ancillary-unit #unit [units]="data.units" [value]="data.unit"
                    [defaultUnitId]="criteria?.selectedUnitId">
                </ancillary-unit>

                <!-- Quantity -->
            </div>

            <h2 class="middle-caption">{{ 'AncillaryProductMix.Label.ExtendedData' | translate }}</h2>

            <!-- Temperature -->
            <label>{{ 'AncillaryProductMix.Label.TemperatureRange' | translate }}</label>
            <ancillary-temperature #temperature></ancillary-temperature>

            <!-- Gloss Level -->
            <label>{{ 'AncillaryProductMix.Label.DegreeOfGloss' | translate }}</label>
            <ancillary-gloss-level #glossLevel></ancillary-gloss-level>

            <!-- VOC -->
            <label>{{ 'AncillaryProductMix.Label.VocConformities' | translate }}</label>
            <ancillary-voc #voc></ancillary-voc>

            <div *ngIf="isMixing" class="formulation-data-loader flex-row-reverse d-flex">
                <dots-loader caption="{{'General.Text.PleaseWaitProcessing' | translate}}"></dots-loader>
            </div>

            <div *ngIf="isMixingError" class="mixing-error flex-row-reverse d-flex">
                {{'General.Text.FailedToProcessRequest' | translate}}
            </div>

            <div *ngIf="!isMixing" class="buttons flex-row-reverse d-flex">
                <button type="button" (click)="ok()" class="btn btn-default btn-ok">{{ 'FormulationData.Button.OK' |
                    translate }}</button>
                <button type="button" (click)="cancel()" class="btn btn-cancel">{{ 'FormulationData.Button.Cancel' |
                    translate }}</button>
            </div>
            <div *ngIf="criteria.isNoteVisible && !isMobileView" class="note" [ngbTooltip]="note"
                tooltipClass="input-tooltip-note"><img src="/assets/images/message.png"></div>
        </form>
        <div *ngIf="isLoadingData" class="formulation-data-loader">
            <dots-loader caption="{{'General.Text.LoadingRequiredData' | translate}}"></dots-loader>
        </div>
        <div class="data-load-error" *ngIf="isDataLoadError">
            <p>{{'General.Text.LoadRequiredDataError' | translate}}</p>
            <a class="click-message" href="javascript:void(0)"
                (click)="retryLoadRequiredData()">{{'General.Text.ClickToRetry' | translate}}</a>
        </div>
    </div>
</div>
