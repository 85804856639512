import { Injectable } from "@angular/core";
import { LocalizationService } from "../../core/localization/localization.service";
import { MixingContainer } from "../../formulation-mixing/models/mixing-data/default/mixing-container";
import { FormulationPrintModel } from "../models/FormulationPrintModel";
import { formatDate } from "@angular/common";
import { String } from "typescript-string-operations";
import { MixingComponentsContainer } from "../../formulation-mixing/models/mixing-data/default/mixing-components-container";
import { MixingDataService } from 'src/app/formulation-mixing/services/mixing-data.service';

@Injectable({
    providedIn: 'root'
})
export class PrintModelGenerator {
    constructor(private localizationService: LocalizationService, private readonly mixingDataService: MixingDataService,) { }

    getPrintModels(container: MixingContainer): FormulationPrintModel[] {
        const models: FormulationPrintModel[] = [];

        //container.selectedCoating = selectedCoating;
        // if (container) {
        //     container.selectedComponentsContainer = selectedCoating ? container.containers.find(x => x.coating === selectedCoating) : null;
        //     container.selectedCoating = selectedCoating;
        //     const criteria = container.mixingCriteria;
        //     if (criteria) {
        //         criteria.data.selectedCoating = selectedCoating;
        //         const response = await this.mixingDataService.getMixingContainer(criteria);
        //         container.info.vocConcentration = response.info.vocConcentration;
        //     }
        // }

        for (const i in container.containers) {
            const model = this.getPrintModel(container, container.containers[i]);

            models.push(model);
        }

        return models;
    }

    private getPrintModel(container: MixingContainer, componentsContainer: MixingComponentsContainer) {
        const model = new FormulationPrintModel();
        //container.set

        model.dateOfPrint = this.getDateOfPrint();
        model.formulationNo = this.getValue(container.formulationNo);
        model.manufacturer = this.getValue(container.info.manufacturer);
        model.colorCode = this.getValue(container.info.colorNumber);
        model.variant = this.getValue(container.info.variant);
        model.variantFlop = this.getValue(container.info.flopVariant);
        model.model = this.getValue(container.info.model);
        model.yearOfBuild = this.getValue(container.info.yearOfBuild);
        model.colorName = this.getValue(container.info.colorName);
        model.coating = componentsContainer.coating.toString();
        model.mcs = this.getValue(container.info.mcs);
        model.quality = this.getQuality(container);
        model.qualityAdditive = this.getQualityAdditive(container);
        model.glossLevel = this.getGlossLevel(container);
        model.quantity = this.getQuantity(container);
        model.materials = componentsContainer.components;
        model.additionalInfo = this.getAdditionalInfo(container);

        return model;
    }

    private getDateOfPrint(): string {
        return new Date().toLocaleDateString(this.localizationService.selectedLanguage.locale);
    }

    private getQuality(container: MixingContainer) {
        return container.mixingCriteria.data.quality ? container.mixingCriteria.data.quality.name : "-";
    }

    private getQualityAdditive(container: MixingContainer) {
        return container.mixingCriteria.data.qualityAdditive ? container.mixingCriteria.data.qualityAdditive.name : "-";
    }

    private getGlossLevel(container: MixingContainer) {
        return container.mixingCriteria.data.glossLevel ? container.mixingCriteria.data.glossLevel.localizedName : "-";
    }

    private getAdditionalInfo(container: MixingContainer) {
        return container.remarks ? container.remarks.map(x => x.caption) : null;
    }

    private getQuantity(container: MixingContainer) {
        return container.mixingCriteria.data.quantity.toFixed(3) + " " + container.mixingCriteria.data.unit.name;
    }


    private getValue(value: string) {
        return !String.IsNullOrWhiteSpace(value) ? value : "-";
    }
}
