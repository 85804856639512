import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { Endpoints } from "../../shared/models/endpoints";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { GetCustomersResponse } from "../models/get-customers-response";
import { ToggleBanResponse } from "../models/toggle-ban-response";
import { ToggleBanRequest } from "../models/toggle-ban-request";
import { DeleteCustomerResponse } from "../models/delete-customer-response";
import { DeleteCustomerRequest } from "../models/delete-customer-request";
import { HttpService } from 'src/app/core/http-service';


@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints) {
    }

    async getCustomers(skip: number, take: number, searchTerm?: string, orderBy?: string, orderAscending?: boolean): Promise<GetCustomersResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("skip", skip);
        httpParams = httpParams.set("take", take);
        httpParams = httpParams.set("orderBy", orderBy);
        httpParams = httpParams.set("orderAscending", orderAscending);
        if(searchTerm !== null && searchTerm !== undefined && searchTerm !==''){
            httpParams = httpParams.set("searchTerm", searchTerm);
        }
        const response = await this.http.get<GetCustomersResponse>(this.endpoints.apiCustomersGet, {params: httpParams })
            .pipe(
                map(x => new GetCustomersResponse().deserialize(x)),
                catchError(err => {
                    this.logger.error("Failed to retrieve the customers.", err);
                    return throwError(err);
                }),
            ).toPromise();

        if (response != null && response.success) {
            return new GetCustomersResponse().deserialize(response);
        }
        else {
            return null;
        }
    }

    async toggleCustomerBan(request: ToggleBanRequest): Promise<ToggleBanResponse> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const response = await this.http.post<ToggleBanResponse>(this.endpoints.apiCustomersBan, JSON.stringify(request), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to toggle the ban state.", err);
                    return throwError(err);
                }),
            ).toPromise<ToggleBanResponse>();

        if (response != null && response.success) {
            return new ToggleBanResponse().deserialize(response);
        }
        else {
            return null;
        }
    }

    async deleteCustomer(request: DeleteCustomerRequest): Promise<DeleteCustomerResponse> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const response = await this.http.post<DeleteCustomerResponse>(this.endpoints.apiCustomersDelete, JSON.stringify(request), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to delete the customer.", err);
                    return throwError(err);
                }),
            ).toPromise<DeleteCustomerResponse>();

        if (response != null && response.success) {
            return new DeleteCustomerResponse().deserialize(response);
        }
        else {
            return null;
        }
    }
}
