import { AncillaryProductContainer } from "../../../../formulation-ancillary-product/models/ancillary-product-container";
import { Unit } from "../../../../formulation/models/unit";
import { MixingComponentsContainer } from "../../mixing-data/default/mixing-components-container";

export class AncillaryProductComponentsCriteria {
    container: AncillaryProductContainer;
    quantity: number;
    unit: Unit;
    qualityShortcut: string;
    isMixUp: boolean;
    voc: string;

    componentsContainer: MixingComponentsContainer;

    thinnerRelation1: number;
    thinnerRelation2: number;
}
