<label>{{'FormulationSearch.Label.MCSType' | translate}}</label>

<div style="margin-top: 5px">
    <div class="custom-control custom-radio mcs-radio">
        <input [value]="true" [(ngModel)]="value" type="radio" class="custom-control-input" id="defaultChecked"
            name="defaultExampleRadios">
        <label class="custom-control-label" for="defaultChecked">{{mcs2Text}}</label>
    </div>

    <div class="custom-control custom-radio mcs-radio">
        <input [value]="false" [(ngModel)]="value" type="radio" class="custom-control-input" id="defaultUnchecked"
            name="defaultExampleRadios" checked>
        <label class="custom-control-label" for="defaultUnchecked">{{mcs3Text}}</label>
    </div>
</div>
