import { EventEmitter, Injectable } from "@angular/core";
import { FormulationPrintModel } from "../models/FormulationPrintModel";
import { MixingContainer } from "../../formulation-mixing/models/mixing-data/default/mixing-container";
import { ApMixingContainer } from "../../formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import { IMixingContainer } from "../../formulation-mixing/interfaces/i-mixing-container";
import { PrintModelGenerator } from "./print-model-generator";
import { PrintModelGeneratorAp } from "./print-model-generator-ap";

@Injectable({
    providedIn: 'root'
})
export class PrintingService {
    onPrintRequested = new EventEmitter<FormulationPrintModel[]>();

    constructor(private modelGenerator: PrintModelGenerator,
        private modelGeneratorAp: PrintModelGeneratorAp) { }

    async print(container: IMixingContainer) {
        let models: FormulationPrintModel[];

        if (container instanceof MixingContainer) {
            models = await this.modelGenerator.getPrintModels(container);
        }
        else if (container instanceof ApMixingContainer) {
            models = [this.modelGeneratorAp.getPrintModel(container)];
        }
        else {
            throw Error(`Printing for container type '${typeof (container)}' is not implemented`);
        }

        this.onPrintRequested.emit(models);
    }
}
