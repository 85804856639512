import { Component, Input, OnInit } from '@angular/core';
import { String } from "typescript-string-operations";
import { FormField } from "../../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'mcs3-field',
    templateUrl: './mcs3.component.html',
    styleUrls: ['./mcs3.component.scss']
})
export class Mcs3Component extends FormField<string> implements OnInit {

    private _isEnabled: boolean;

    get isEnabled() {
        return this._isEnabled;
    }

    @Input()
    set isEnabled(value) {
        this._isEnabled = value;

        if (!value) {
            this.clear();
        }
    }

    @Input() mcsItems: string[];

    caption = 'MCS3';

    isFirstRequired: boolean;
    isSecondRequired: boolean;
    isThirdRequired: boolean;
    isForthRequired: boolean;

    isSecondInvalidDigits: boolean;
    isThirdInvalidDigits: boolean;
    isForthInvalidDigits: boolean;

    secondDigits = 1;
    thirdDigits = 3;
    forthDigits = 2;

    first = new FormField<string>();
    second = new FormField<number>();
    third = new FormField<number>();
    forth = new FormField<number>();

    ngOnInit() {
        this.first.onChanged.subscribe(x => this.onFieldChanged());
        this.second.onChanged.subscribe(x => this.onFieldChanged());
        this.third.onChanged.subscribe(x => this.onFieldChanged());
        this.forth.onChanged.subscribe(x => this.onFieldChanged());
    }

    onFieldChanged(): void {
        this.validateIfError();

        this.value = this.getCombinedValue();
    }

    clear(): void {
        this.first.clear();
        this.second.clear();
        this.third.clear();
        this.forth.clear();
    }

    get isFilled(): boolean {
        return this.isAtleastOneFilled();
    }

    validateIfError(): void {
        if (this.hasError) {
            this.validate();
        }
    }

    validate(): boolean {
        this.validateFirst();

        this.validateSecond();

        this.validateThird();

        this.validateForth();

        this.setValidationState();

        return !this.hasError;
    }

    validateFirst() {
        this.clearFirstErrors();

        if (this.isAtleastOneFilled()) {
            this.isFirstRequired = !this.first.isFilled;
        }
    }

    validateSecond() {
        this.clearSecondErrors();

        if (this.isAtleastOneFilled()) {
            this.isSecondRequired = !this.second.isFilled;

            if (this.isSecondRequired) { return; }

            this.isSecondInvalidDigits = this.second.value.toString().length != this.secondDigits;
        }
    }

    validateThird() {
        this.clearThirdErrors();

        if (this.isAtleastOneFilled()) {
            this.isThirdRequired = !this.third.isFilled;

            if (this.isThirdRequired) { return; }

            this.isThirdInvalidDigits = this.third.value.toString().length != this.thirdDigits;
        }
    }

    validateForth() {
        this.clearForthErrors();

        if (this.isAtleastOneFilled()) {
            this.isForthRequired = !this.forth.isFilled;

            if (this.isForthRequired) { return; }

            this.isForthInvalidDigits = this.forth.value.toString().length != this.forthDigits;
        }
    }

    isAtleastOneFilled(): boolean {
        return !String.IsNullOrWhiteSpace(this.first.value) ||
            this.second.isFilled ||
            this.third.isFilled ||
            this.forth.isFilled;
    }

    clearFirstErrors(): void {
        this.isFirstRequired = false;
    }

    clearSecondErrors(): void {
        this.isSecondRequired = false;
        this.isSecondInvalidDigits = false;
    }

    clearThirdErrors(): void {
        this.isThirdRequired = false;
        this.isThirdInvalidDigits = false;
    }

    clearForthErrors(): void {
        this.isForthRequired = false;
        this.isForthInvalidDigits = false;
    }

    setValidationState(): void {
        this.hasError = this.isFirstRequired ||
            this.isSecondRequired || this.isSecondInvalidDigits ||
            this.isThirdRequired || this.isThirdInvalidDigits ||
            this.isForthRequired || this.isForthInvalidDigits;
    }

    getCombinedValue(): string {
        if (this.hasError || !this.isAtleastOneFilled()) { return null; }

        return `${this.first.value}${this.second.value}-${this.third.value}-${this.forth.value}`;
    }

    onThirdFocusOut() {
        if (this.third.isFilled) {
            this.third.setValue(this.pad(this.third.value, this.thirdDigits));
        }

        this.onFieldChanged();
    }

    onForthFocusOut() {
        if (this.forth.isFilled) {
            this.forth.setValue(this.pad(this.forth.value, this.forthDigits));
        }

        this.onFieldChanged();
    }

    pad(n, width) {
        const prefix = '0';

        n = n + '';

        return n.length >= width ? n : new Array(width - n.length + 1).join(prefix) + n;
    }
}
