import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'company-name-field',
    templateUrl: './company-name.component.html',
    styles: []
})
export class CompanyNameField extends FormField<string> implements OnInit {

    ngOnInit() {
    }

}
