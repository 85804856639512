<div class="row-details">
    <div class="details-header">
        <div class="details-header-cell">{{'FormulationSearch.Caption.Models' | translate}}</div>
        <div class="details-header-cell">{{'FormulationSearch.Caption.ContrastColorOfModel' | translate}}</div>
    </div>
    <div class="row details-content">
        <div class="col-xl-5 col-lg-5 col-md-5 details-content-cell details-models">
            <app-row-details-models #models [isExpanded]="isExpanded" [color]="color"></app-row-details-models>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-7 details-content-cell details-contrast-colors">
            <app-row-details-contrast-colors #contrastColors [voc]="voc" [searchType]="searchType" [color]="color"
                [model]="models.selectedModel"></app-row-details-contrast-colors>
        </div>
    </div>
</div>
