import { Component } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'auth-username',
    templateUrl: './username.component.html',
    styles: []
})
export class UsernameField extends FormField<string>
{
}
