import {AnalyticsAncillaryProductDto} from "src/app/core/analytics/dto/common/analytics-ancillary-product-dto";

export class CreateAnalyticsAncillaryProductDto
{
    constructor(
        public ancillaryProduct: AnalyticsAncillaryProductDto,
        public openedAt: Date,
        public closedAt?: Date) {
    }
}
