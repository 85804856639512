<div class="step step-1">
    <div class="row row-1">
        <div class="col-md-6">
            <username-field [showAsterisk]="true" #username #field #required>
            </username-field>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-6">
            <password-field [showAsterisk]="true" #password #field #required>
            </password-field>
        </div>
        <div class="col-md-6">
            <password-confirm-field [showAsterisk]="true" #passwordConfirm #field #required>
            </password-confirm-field>
        </div>
    </div>
    <div class="row row-3">
        <div class="col-md-6">
            <terms-field [showAsterisk]="true" #terms #field #required>
            </terms-field>
        </div>
        <div class="col-md-6">
            <button [disabled]="isProcessing" (click)="goToStep3()" type="button" class="btn btn-cancel">{{
                'Imprint.Label.TermsOfService' | translate }}</button>

        </div>
    </div>
    <div class="row row-4" [hidden]="isDyoMode">
        <div class="col-md-6">
            <privacy-field [showAsterisk]="true" #privacy #field #required>
            </privacy-field>
        </div>
        <div class="col-md-6">
            <button [disabled]="isProcessing" (click)="goToStep4()" type="button" class="btn btn-cancel">{{
                'Imprint.Label.PrivacyPolicy' | translate }}</button>
        </div>
    </div>
    <div *ngIf="hasRequirementsError" class="requirements-error">{{ 'Register.Fields.Required' | translate }}</div>
    <div *ngIf="termsNotAgreed" class="requirements-error">{{ 'Register.NotAgreedToTerms' | translate }}</div>
    <div *ngIf="privacyNotAgreed" class="requirements-error">{{ 'Register.NotAgreedToPrivacy' | translate }}</div>
    <div *ngIf="passwordMinLengthError" class="requirements-error">{{ 'Register.PasswordMinLengthError' | translate }}
    </div>
    <div *ngIf="passwordNotMaching" class="requirements-error">{{ 'Register.PasswordNotMatchingError' | translate }}
    </div>
    <div class="flex-row d-flex row-buttons">
        <button [disabled]="isProcessing" (click)="cancel()" type="button" class="btn btn-cancel">{{
            'Register.Button.Cancel' | translate }}</button>
        <!-- <button [disabled]="isProcessing" (click)="goToStep3()" type="button" class="btn btn-cancel">Terms of Use</button> -->
        <div style="flex-grow: 1"></div>
        <!-- <button [disabled]="isProcessing" (click)="goToPreviousStep()" type="button" class="btn btn-cancel">{{ 'Register.Button.Previous' | translate }}</button> -->


        <button [disabled]="isProcessing" (click)="goToStep1()" type="button" class="btn btn-cancel">{{
            'Register.Button.Previous' | translate }}</button>
        <!-- <button (click)="goToStep3()" type="button" class="btn btn-cancel">License</button> -->



        <button [disabled]="isProcessing" style="margin-left: 15px" (click)="submit()" type="button"
            class="btn btn-default btn-search">{{ 'Register.Button.Submit' | translate }}</button>
    </div>
    <div *ngIf="isProcessing" style="margin-top:20px" class="register-processing">
        <dots-loader caption="{{'Register.Message.PleaseWait' | translate}}"></dots-loader>
    </div>
    <div *ngIf="isProcessingError" class="requirements-error">{{processingErrorMessage}}</div>
</div>
