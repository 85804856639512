import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormField } from "../../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'mcs-select-field',
    templateUrl: './mcs-select.component.html',
    styleUrls: ['./mcs-select.component.scss']

})
export class McsSelectComponent extends FormField<boolean> implements OnInit {

    mcs2Text = "MCS 2";
    mcs3Text = "MCS 3"
    ngOnInit() {
        this.value = false;
        if (environment.theme.id === "dyo") {
            this.mcs2Text = "DRS 2";
            this.mcs3Text = "DRS 3";
        }
    }

}
