import { String } from "typescript-string-operations";
import { MixingComponentsContainer } from "../../../formulation-mixing/models/mixing-data/default/mixing-components-container";
import { QualityType } from "../../../formulation/enums/quality-type";
import { MixingContainer } from "../../../formulation-mixing/models/mixing-data/default/mixing-container";

export class AncillaryProductCriteria {
    componentsContainer: MixingComponentsContainer;

    selectedQualityNo: number;
    quantity: number;
    selectedUnitId: number;

    isMixUp: boolean;

    mcs: string;
    manufacturer: string;
    colorNo: string;
    availableQualityTypes: QualityType[];
    mixingContainer: MixingContainer;

    get isNoteVisible(): boolean {
        return this.isManufacturerVisible || this.isColorNoVisible;
    }

    get isManufacturerVisible(): boolean {
        return !String.IsNullOrWhiteSpace(this.manufacturer);
    }

    get isColorNoVisible(): boolean {
        return !String.IsNullOrWhiteSpace(this.colorNo);
    }
}
