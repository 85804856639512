import { IDeserialize } from "../../shared/interfaces/deserialize";

export class AncillaryProductItem implements IDeserialize {
    ancillaryProductId: number;
    componentId: number;
    componentTypeId: number;
    shortcut: string;
    name: string;
    isVocCompliance: boolean;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
