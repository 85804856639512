import { Component, OnInit } from '@angular/core';
import { String } from "typescript-string-operations";
import Country from "../../../models/country";
import Language from "../../../models/language";
import Languages from "../../../constants/languages";
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'language-field',
    templateUrl: './language.component.html',
    styleUrls: []
})
export class LanguageField extends FormField<Language> implements OnInit {

    public languages;

    ngOnInit() {
        this.languages = Languages.list;
    }

    startsWithSearchFn(searchText: string, country: Country) {
        if (String.IsNullOrWhiteSpace(searchText)) { return true; }

        return country.name.toLowerCase().startsWith(searchText.toLowerCase());
    }
}
