import { AfterViewInit, Directive, HostBinding, OnDestroy } from "@angular/core";

@Directive({
    selector: '[print-section]',
})
export class PrintSectionDirective implements AfterViewInit, OnDestroy {
    @HostBinding('class.print-section') private printSection = true;
    private style: any;

    public ngAfterViewInit() {
        this.style = document.createElement('style');
        this.style.type = 'text/css';
        this.style.innerText = `
        @media print
        {
          .pagebreak { page-break-before: always; }

          app-standard-page
          {
            display: none;
          }
          .print-section
          {
            font-size: 22px;
            display: block!important;
          }
          .print-section, .print-section *
          {
            visibility: visible;
          }
          .print-section {
            width: 100%;
          }
          @page { size: auto;  margin: 0; }
        }`;

        document.head.appendChild(this.style);
    }

    public ngOnDestroy() {
        document.head.removeChild(this.style);
    }
}
