import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class AnalyticsOptions
{
    // The amount of time users have to wait between searches
    searchThrottleSeconds: number;

    // The amount of time users have to spend on the recipe details page before the save-analytics action is triggered
    recipeViewTriggerSeconds: number;

    // The amount of time users have to spend on the ancillary-product details page before the save-analytics action is triggered
    ancillaryProductViewTriggerSeconds: number;

    // The delay between property editing and saving in recipe view and ready-to-spray view
    propertyEditDelaySeconds: number;
}
