import {uniqueId} from "src/app/core/utils/unique-identifier";
import Timer from "tiny-timer";
import {ApMixingContainer} from "src/app/formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import {AnalyticsOptions} from "src/app/core/analytics/models/analytics-options";

export class AnalyticsApMixingContainer
{
    readonly identifier = uniqueId();
    readonly timer = new Timer();
    readonly analyticsOptions: AnalyticsOptions;

    container: ApMixingContainer;
    analyticsAncillaryProductId?: number;
    componentId: number;
    openedAt?: Date;
    closedAt?: Date;
    isTrackerRunning: boolean;
    isModified: boolean;

    constructor(container: ApMixingContainer, analyticsOptions: AnalyticsOptions)
    {
        this.container = container;
        this.componentId = container.selectedProduct.mainComponentId;
        this.analyticsOptions = analyticsOptions;
        this.openedAt = new Date();

        this.subscribeToProductChanges();
    }

    onSelectedProductChanged(): void {
        this.stopTracking();
        this.isModified = true;
        this.startPropertyChangedTracking();
    }

    onContainerUpdated(newContainer: ApMixingContainer): void {
        this.stopTracking();
        this.unsubscribeFromProductChanges();

        this.container = newContainer;
        this.isModified = true;

        this.subscribeToProductChanges();
        this.startPropertyChangedTracking();
    }

    startPropertyChangedTracking(): void {
        if (this.analyticsOptions.propertyEditDelaySeconds  > 0) {
            this.timer.start(this.analyticsOptions.propertyEditDelaySeconds * 1000);
            this.isTrackerRunning = true;
        }
    }

    startTimeOnViewTracking(): void
    {
        this.stopTracking();

        this.timer.start(this.analyticsOptions.ancillaryProductViewTriggerSeconds * 1000);
        this.isTrackerRunning = true;
    }

    stopTracking(): void {
        if (this.isTrackerRunning) {
            this.timer.stop();
            this.isTrackerRunning = false;
        }
    }

    unsubscribeFromProductChanges(): void {
        this.container.selectedProductChanged.unsubscribe();
    }

    subscribeToProductChanges(): void {
        this.container.selectedProductChanged.subscribe(() => {
            this.onSelectedProductChanged();
        });
    }
}
