import { NgModule } from '@angular/core';
import { PrintingComponent } from './components/printing.component';
import { SharedModule } from "../shared/shared.module";

@NgModule({
    declarations: [
        PrintingComponent],
    imports: [
        SharedModule
    ],
    exports: [
        PrintingComponent
    ]
})

export class FormulationPrintingModule {

}
