import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class HttpService {
    constructor(private readonly httpClient: HttpClient) {}

    public get<T>(
        relativeUrl: string,
        options?: {
            headers?: HttpHeaders | {
                [header: string]: string | string[];
            };
            observe?: "body";
            params?: HttpParams | {
                [param: string]: string | string[];
            };
            reportProgress?: boolean;
            responseType?: "json";
            withCredentials?: boolean;
        }): Observable<T> {
        options = options || {};
        if(options.withCredentials === undefined) {
            options.withCredentials = true;
        }
        return this.httpClient.get<T>(this.createUrl(relativeUrl), options);
    }

    public post<T>(
        relativeUrl: string,
        body?: any,
        options?: {
            headers?: HttpHeaders | {
                [header: string]: string | string[];
            };
            observe?: "body";
            params?: HttpParams | {
                [param: string]: string | string[];
            };
            reportProgress?: boolean;
            responseType?: "json";
            withCredentials?: boolean;
        }): Observable<T> {
        options = options || {};
        if(options.withCredentials === undefined) {
            options.withCredentials = true;
        }
        return this.httpClient.post<T>(this.createUrl(relativeUrl), body, options);
    }

    public createUrl(relativeUrl: string): string {
        return new URL(relativeUrl, environment.backendUrl ?? window.location.toString()).toString();
    }
}
