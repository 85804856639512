import { Injectable } from "@angular/core";
import { AncillaryProductCriteria } from "../models/criteria/ancillary-product-criteria";
import { MixingContainer } from "../../formulation-mixing/models/mixing-data/default/mixing-container";
import { ApMixingContainer } from "../../formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import { SearchSettings } from "../../formulation-search/models/search-settings";

@Injectable({
    providedIn: 'root'
})
export class AncillaryProductCriteriaGenerator {
    constructor(private searchSettings: SearchSettings) {
    }

    fromMixingContainer(container: MixingContainer, isMixUp: boolean): AncillaryProductCriteria {
        const criteria = new AncillaryProductCriteria();

        criteria.mixingContainer = container;

        //criteria.availableQualityTypes = container.mixingCriteria.availableQualityTypes;
        criteria.availableQualityTypes = container.mixingCriteria.availableQualityTypes.filter(y => y === container.mixingCriteria.data.quality.qualityNo);
        //this.filteredQualities = this.availableQualityTypes ? this.data.qualities.filter(x => this.availableQualityTypes.some(y => y == x.qualityNo)) : this.data.qualities;

        criteria.componentsContainer = container.selectedComponentsContainer;

        if (container.formulationDataCriteria && container.formulationDataCriteria.quality) {
            criteria.selectedQualityNo = container.formulationDataCriteria.quality;
        }

        criteria.quantity = container.mixingCriteria.data.quantity;
        criteria.selectedUnitId = container.mixingCriteria.data.unit.id;

        criteria.isMixUp = isMixUp;

        if (this.searchSettings.mcsType === 2) {
            criteria.mcs = container.mixingCriteria.color.mcs2;
        }
        else if (this.searchSettings.mcsType === 3) {
            criteria.mcs = container.mixingCriteria.color.mcs3;
        }

        criteria.manufacturer = container.mixingCriteria.color.manufacturerName;
        criteria.colorNo = container.mixingCriteria.color.colorNo;

        return criteria;
    }

    fromAncillaryProductMixingContainer(container: ApMixingContainer): AncillaryProductCriteria {
        const criteria = new AncillaryProductCriteria();

        // TODO: check if componentsContainer is required here as well

        criteria.quantity = container.mixingCriteira.quantity;
        criteria.selectedUnitId = container.mixingCriteira.unit.id;
        criteria.availableQualityTypes = container.mixingCriteira.availableQualityTypes;

        criteria.isMixUp = false;

        return criteria;
    }
}
