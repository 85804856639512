import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-notification-dialog',
    templateUrl: './notification-dialog.component.html',
    styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

    title: string;
    message = "";

    constructor(protected modal: NgbActiveModal) { }

    ngOnInit() {
    }

    close() {
        this.modal.close();
    }
}
