export class CoatingSearchCriteria {
    formulationNo: string;
    qualityNo: number;
    colorId: number;

    static fromFormulationNo(formulationNo: string): CoatingSearchCriteria {
        const criteria = new CoatingSearchCriteria();

        criteria.formulationNo = formulationNo;

        return criteria;
    }

    static fromQualityNo(qualityNo: number, colorId: number): CoatingSearchCriteria {
        const criteria = new CoatingSearchCriteria();

        criteria.qualityNo = qualityNo;
        criteria.colorId = colorId;

        return criteria;
    }
}
