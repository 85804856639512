import { throwError } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { catchError } from "rxjs/operators";
import { CoatingSearchCriteria } from "../models/coating-search-criteria";
import { Endpoints } from "../../shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class CoatingsDataService {

    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints) {
    }

    async getCoatings(criteria: CoatingSearchCriteria): Promise<number[]> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return await this.http.post<number[]>(this.endpoints.apiCoatings, JSON.stringify(criteria), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error("Failed to retrieve the coatings data.", err);
                    return throwError(err);
                }),
            ).toPromise();
    }
}
