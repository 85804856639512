import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IsMobileViewPipe } from "../../../../../shared/pipes/is-mobile-view.pipe";
import { IsDesktopViewPipe } from "../../../../../shared/pipes/is-desktop-view.pipe";
import { IsSmartphoneViewPipe } from "../../../../../shared/pipes/is-smartphone-view.pipe";
import { IsPadViewPipe } from "../../../../../shared/pipes/is-pad-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'quality-button',
    templateUrl: './quality-button.component.html',
    styleUrls: ['./quality-button.component.scss']
})
export class QualityButtonComponent implements OnInit, OnDestroy {

    isMobileView: boolean;
    height: string;
    fontsize: string;
    headerHeight: number;
    rowHeight: number;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    @Input() value: number;

    constructor(
        private mobileViewPipe: IsMobileViewPipe,
        private smartphoneViewPipe: IsSmartphoneViewPipe,
        private padViewPipe: IsPadViewPipe,
        private desktopViewPipe: IsDesktopViewPipe) {
    }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(evt => {
            this.resizeForMobile();
        });

        this.resizeForMobile();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    resizeForMobile() {
        if (this.desktopViewPipe.transform(window)) {
            // this.isMobileView = this.mobileViewPipe.transform(window);
            // this.height= '200px';
            // this.fontsize = '14';
            // this.headerHeight = 36;
            // this.rowHeight = 34;
            // this.changeLineHeight('34px');
            // this.changeHeaderHeight('36px');
            // this.changeBoxSize('20px');
            this.changeCssProperty('--button-quality-size', '26px');
            this.changeCssProperty('--button-quality-radius', '13px');

            this.changeCssProperty('--button-quality-inner-green-width', '14px');
            this.changeCssProperty('--button-quality-inner-green-padding-top', '0px');
            this.changeCssProperty('--button-quality-green-line-height', '13px');

            this.changeCssProperty('--button-quality-inner-yellow-width', '14px');
            this.changeCssProperty('--button-quality-inner-yellow-padding-top', '0px');
            this.changeCssProperty('--button-quality-yellow-line-height', '12px');

            this.changeCssProperty('--button-quality-inner-red-width', '14px');
            this.changeCssProperty('--button-quality-inner-red-padding-top', '0px');
            this.changeCssProperty('--button-quality-red-line-height', '10px');

            this.changeCssProperty('--button-quality-rm-line-height', '13px');
            this.changeCssProperty('--button-quality-rm-font-size', '10px');
        }
        else if (this.padViewPipe.transform(window)) {
            // this.isMobileView = this.mobileViewPipe.transform(window);
            // this.height= '180px';
            // this.fontsize = '9';
            // this.headerHeight = 32;
            // this.rowHeight = 30;
            // this.changeLineHeight('30px');
            // this.changeHeaderHeight('32px');
            // this.changeBoxSize('16px');
            this.changeCssProperty('--button-quality-size', '16px');
            this.changeCssProperty('--button-quality-radius', '8px');

            this.changeCssProperty('--button-quality-inner-green-width', '8px');
            this.changeCssProperty('--button-quality-inner-green-padding-top', '3px');
            this.changeCssProperty('--button-quality-green-line-height', '8px');

            this.changeCssProperty('--button-quality-inner-yellow-width', '8px');
            this.changeCssProperty('--button-quality-inner-yellow-padding-top', '2px');
            this.changeCssProperty('--button-quality-yellow-line-height', '8px');

            this.changeCssProperty('--button-quality-inner-red-width', '8px');
            this.changeCssProperty('--button-quality-inner-red-padding-top', '1px');
            this.changeCssProperty('--button-quality-red-line-height', '8px');

            this.changeCssProperty('--button-quality-rm-line-height', '9px');
            this.changeCssProperty('--button-quality-rm-font-size', '7px');
        }
        else if (this.smartphoneViewPipe.transform(window)) {
            // this.isMobileView = this.mobileViewPipe.transform(window);
            // this.height= '160px';
            // this.fontsize = '6';
            // this.headerHeight = 26;
            // this.rowHeight = 24;
            // this.changeLineHeight('24px');
            // this.changeHeaderHeight('26px');
            // this.changeBoxSize('12px');


            this.changeCssProperty('--button-quality-size', '12px');
            this.changeCssProperty('--button-quality-radius', '6px');

            this.changeCssProperty('--button-quality-inner-green-width', '6px');
            this.changeCssProperty('--button-quality-inner-green-padding-top', '1px');
            this.changeCssProperty('--button-quality-green-line-height', '6px');

            this.changeCssProperty('--button-quality-inner-yellow-width', '6px');
            this.changeCssProperty('--button-quality-inner-yellow-padding-top', '1px');
            this.changeCssProperty('--button-quality-yellow-line-height', '6px');

            this.changeCssProperty('--button-quality-inner-red-width', '6px');
            this.changeCssProperty('--button-quality-inner-red-padding-top', '0px');
            this.changeCssProperty('--button-quality-red-line-height', '6px');

            this.changeCssProperty('--button-quality-rm-line-height', '6px');
            this.changeCssProperty('--button-quality-rm-font-size', '4px');
        }
    }



    changeCssProperty(property: string, value: string) {
        document.documentElement.style.setProperty(property, value);
    }

    // changeBoxSize(boxSize: string) {
    //     document.documentElement.style.setProperty('--action-box-size', boxSize);
    // }

    // changeLineHeight(lineHeight: string) {
    //     document.documentElement.style.setProperty('--datatable-line-height', lineHeight);
    // }

    // changeHeaderHeight(headerHeight: string) {
    //     document.documentElement.style.setProperty('--datatable-header-height', headerHeight);
    // }
}
