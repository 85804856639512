import { Component } from '@angular/core';
import {FormField} from "src/app/shared/components/base/forms/form-field";

@Component({
    selector: 'app-reset-password-username',
    templateUrl: './username.component.html',
    styles: []
})
export class ResetPasswordUsernameComponent extends FormField<string>
{
}

