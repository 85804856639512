import { Component } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'auth-password',
    templateUrl: './password.component.html',
    styles: []
})
export class PasswordField extends FormField<string>
{
}
