import { IDeserialize } from "../../shared/interfaces/deserialize";

export class AncillaryProduct implements IDeserialize {
    id: number;
    fieldOfApplicationId: number;
    temperatureRangeId: number;
    componentId: number;
    hardenerComponentId: number;
    hardenerRelation: number;
    hardenerRelation2: number;
    hardenerRelation3: number;
    isVocCompliance: boolean;
    glossLevelId: number;
    thinnerComponent1Id: number;
    thinnerComponent2Id: number;
    thinnerRelation11: number;
    thinnerRelation12: number;
    thinnerRelation13: number;
    thinnerRelation21: number;
    thinnerRelation22: number;
    thinnerRelation23: number;
    name: string;
    sort: number;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
