import { IDeserialize } from "../../../../shared/interfaces/deserialize";

export class MixingComponent implements IDeserialize {
    color: string;
    rawMaterial: string;
    weightInGrammText: string;
    totalWeightInGrammText: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        return this;
    }
}
