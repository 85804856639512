import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { Language } from "../../shared/models/language";
import languagesJson from "../../../assets/languages.json";
import languagesDYOJson from "../../../assets/languagesDyo.json";

@Injectable({
    providedIn: 'root'
})

export class LocalizationService {
    public selectedLanguage: Language | null;

    public languages: Language[];

    constructor(private translate: TranslateService,) {
    }

    loadAvailableLanguages() {

        if (environment.theme.id === "dyo") {
            this.languages = languagesDYOJson.map(x => new Language().deserialize(x))
        } else {
            this.languages = languagesJson.map(x => new Language().deserialize(x))
        }

        this.translate.addLangs(this.languages.map(x => x.code));
        this.translate.setDefaultLang(environment.defaultLanguage);
        this.selectedLanguage = this.languages.find(x => x.code == environment.defaultLanguage);
    }

    getLanguageFromCode(languageCode: string): Language {
        const language = this.languages.find(x => x.code == languageCode);

        if (!language) {
            throw new Error(`The language [${languageCode}] was not found in the languages list.`);
        }

        return language;
    }

    changeLanguage(language: Language) {
        this.translate.use(language.code);
        this.selectedLanguage = language;
    }
}
