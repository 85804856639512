import { IDeserialize } from "../../shared/interfaces/deserialize";
import { CustomerBundle } from "./customer-bundle";

export class GetCustomersResponse implements IDeserialize {
    customers: CustomerBundle[];
    success: boolean;
    totalCount: number;


    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (this.customers) {
            this.customers = this.customers.map(x => new CustomerBundle().deserialize(x));
        }

        return this;
    }
}
