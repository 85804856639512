import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Endpoints } from "../../shared/models/endpoints";
import RegisterRequest from "../models/register.request";
import { RegisterResponse } from "../models/register.response";
import { HttpService } from 'src/app/core/http-service';
import { CryptoService } from "../../shared/service/crypto.service";

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    constructor(private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints,
        private cryptoService: CryptoService) {
    }

    public async register(request: RegisterRequest): Promise<RegisterResponse> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const encryptedRegisterRequest = new RegisterRequest(
            this.cryptoService.encrypt(request.username),
            this.cryptoService.encrypt(request.password),
            this.cryptoService.encrypt(request.countryCode),
            this.cryptoService.encrypt(request.languageCode),
            this.cryptoService.encrypt(request.firstName),
            this.cryptoService.encrypt(request.lastName),
            this.cryptoService.encrypt(request.company),
            this.cryptoService.encrypt(request.address1),
            this.cryptoService.encrypt(request.address2),
            this.cryptoService.encrypt(request.address3),
            this.cryptoService.encrypt(request.city),
            this.cryptoService.encrypt(request.zip),
            this.cryptoService.encrypt(request.companyEmail),
            this.cryptoService.encrypt(request.phone),
            this.cryptoService.encrypt(request.customerNumber),
            this.cryptoService.encrypt(request.distributorName)
        );

        console.log(encryptedRegisterRequest);

        const result = await this.http.post<RegisterResponse>(this.endpoints.apiRegister, JSON.stringify(encryptedRegisterRequest), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`Registration failed.'`, err);
                    return throwError(err);
                }),
            )
            .toPromise<RegisterResponse>();

        return new RegisterResponse().deserialize(result);
    }
}

