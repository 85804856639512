import { IDeserialize } from "../../shared/interfaces/deserialize";
import { Customer } from "./customer";
import Countries from "../../register/constants/countries";

export class CustomerBundle implements IDeserialize {
    customer: Customer;
    lastLogin: string;

    get name(): string {
        return `${this.customer.firstName} ${this.customer.lastName}`;
    }

    get countryName(): string {
        let result = "";

        Countries.list.forEach(x => {
            if (x.code == this.customer.countryCode) {
                result = x.name;


            }
        });
        return result;
    }

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
