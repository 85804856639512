import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { ComponentType } from "../models/component-type";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-other-components-component-type',
    templateUrl: './component-type.field.html',
    styleUrls: []
})
export class OtherProductsComponentTypeFieldComponent extends FormField<ComponentType> implements OnInit {
    items: ComponentType[];

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => this.setLocalizedNames());
        this.translate.onDefaultLangChange.subscribe(() => this.setLocalizedNames());
    }

    setLocalizedNames() {
        this.items = [
            new ComponentType(9, this.translate.instant("OtherProducts.Type.Filler")),
            new ComponentType(2, this.translate.instant("OtherProducts.Type.Clearcoat")),
            new ComponentType(7, this.translate.instant("OtherProducts.Type.Topcoat")),
            new ComponentType(10, this.translate.instant("OtherProducts.Type.Primer")),
        ];
        this.value = this.items[0];
    }
}
