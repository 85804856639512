import { IDeserialize } from "../../shared/interfaces/deserialize";
import { Manufacturer } from "../../formulation/models/manufacturer";
import { ColorCategory } from "../../formulation/models/color-category";
import { Region } from "../../formulation/models/region";

export class SearchFormData implements IDeserialize {
    public manufacturers: Manufacturer[];

    public regions: Region[];

    public colorCategories: ColorCategory[];

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (this.manufacturers) {
            this.manufacturers = this.manufacturers.map(x => new Manufacturer().deserialize(x));
        }

        if (this.regions) {
            this.regions = this.regions.map(x => new Region().deserialize(x));
        }

        if (this.colorCategories) {
            this.colorCategories = this.colorCategories.map(x => new ColorCategory().deserialize(x));
        }

        return this;
    }
}
