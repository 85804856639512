<div class="error-container">
    <img class="error-image" src="/assets/images/error-icon.png" />
    <p class="error-title">Application Initialization Failed</p>
    <p style="font-weight: 600">Possible list of errors:</p>
    <ul class="error-list">
        <li *ngFor="let error of appInitializer.errors">
            {{ error }}
        </li>
    </ul>
    <a class="click-message" href="/">Please click here to reload the application</a>
</div>
