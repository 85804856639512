import {MixingContainer} from "src/app/formulation-mixing/models/mixing-data/default/mixing-container";
import {uniqueId} from "src/app/core/utils/unique-identifier";
import Timer from "tiny-timer";

export class AnalyticsMixingContainer
{
    readonly identifier = uniqueId();
    readonly timer = new Timer();

    container: MixingContainer;
    analyticsRecipeId?: number;
    openedAt?: Date;
    closedAt?: Date;
    isTrackerRunning: boolean;
    isModified: boolean;

    constructor(container: MixingContainer)
    {
        this.container = container;
        this.openedAt = new Date();
    }

    startPropertyChangedTracking(container: MixingContainer, updateDelaySeconds: number): void {
        this.stopTracking();

        this.container = container;
        this.isModified = true;

        if (updateDelaySeconds  > 0) {
            this.timer.start(updateDelaySeconds * 1000);
            this.isTrackerRunning = true;
        }
    }

    startTimeOnViewTracking(durationSeconds: number): void
    {
        this.stopTracking();

        this.timer.start(durationSeconds * 1000);
        this.isTrackerRunning = true;
    }

    stopTracking(): void {
        if (this.isTrackerRunning) {
            this.timer.stop();
            this.isTrackerRunning = false;
        }
    }
}
