import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { TemperatureRange } from "../../../models/temperature-range";
import { AncillaryProductData } from "../../../models/ancillary-product-data";

@Component({
    selector: 'ancillary-temperature',
    templateUrl: './temperature.component.html',
    styles: []
})
export class AncillaryTemperatureField extends FormField<TemperatureRange> implements OnInit {

    constructor(public data: AncillaryProductData) {
        super();
    }

    ngOnInit() {
        this.value = this.data ? this.data.temperatureRanges[0] : null;
    }
}
