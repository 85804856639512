import { IDeserialize } from "../../shared/interfaces/deserialize";

export class TemperatureRange implements IDeserialize {
    id: number;
    from: number;
    to: number;
    display: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (!this.display) {
            this.display = `${this.from}°C - ${this.to}°C`;
        }
        return this;
    }
}
