import { IDeserialize } from "../../shared/interfaces/deserialize";
import { Quality } from "../../formulation/models/quality";
import { GlossLevel } from "../../formulation/models/gloss-level";
import { Unit } from "../../formulation/models/unit";
import { QualityAdditive } from "../../formulation/models/quality-additive";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class FormulationData implements IDeserialize {
    isLoaded: boolean;

    qualities: Quality[];
    variants: QualityAdditive[];
    glossLevels: GlossLevel[];
    units: Unit[];

    defaultUnitId: number;
    defaultQuantity: number;

    unit: Unit;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (this.qualities) {
            this.qualities = this.qualities.map(x => new Quality().deserialize(x));
        }

        if (this.variants) {
            this.variants = this.variants.map(x => new QualityAdditive().deserialize(x));
        }

        if (this.glossLevels) {
            this.glossLevels = this.glossLevels.map(x => new GlossLevel().deserialize(x));
        }

        if (this.units) {
            this.units = this.units.map(x => new Unit().deserialize(x));
            this.setDefaultUnit();
        }

        return this;
    }

    setDefaultUnit() {
        if (this.units && this.units.length > 0) {
            this.unit = this.defaultUnitId ? this.units.find(x => x.id == this.defaultUnitId) : this.units[0];
        }
    }
}
