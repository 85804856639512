import { SearchComponent } from "./formulation-search/components/search.component";
import { MixingComponent } from "./formulation-mixing/components/mixing.component";
import { ErrorComponent } from "./shared/components/error/error.component";
import { sections } from "./shared/configuration/sections";
import { UnsupportedBrowserComponent } from "./shared/components/unsupported-browser/unsupported-browser.component";
import { AuthenticationComponent } from "./authentication/components/authentication.component";
import { AuthGuard } from "./core/authorization/auth-guard";
import { RegisterComponent } from "./register/components/register.component";
import { UserManagementComponent } from "./customers/components/user-management.component";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {RequestPasswordResetComponent} from "src/app/reset-password/request-reset/components/request-password-reset.component";
import {ResetPasswordComponent} from "src/app/reset-password/perform-reset/components/reset-password.component";

const routes: Routes = [
    { path: '', redirectTo: `/${sections.search}`, pathMatch: 'full' },
    { path: sections.search, component: SearchComponent, canActivate: [AuthGuard] },
    { path: sections.mixing, component: MixingComponent, canActivate: [AuthGuard] },
    { path: sections.login, component: AuthenticationComponent },
    { path: sections.register, component: RegisterComponent },
    { path: sections.requestPasswordReset, component: RequestPasswordResetComponent },
    { path: sections.performPasswordReset, component: ResetPasswordComponent },
    { path: sections.appError, component: ErrorComponent },
    { path: sections.unsupportedBrowser, component: UnsupportedBrowserComponent },
    { path: sections.userManagement, component: UserManagementComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
