import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { AncillaryProductComponent } from './components/ancillary-product.component';
import { AncillaryQualityField } from './components/fields/quality/quality.component';
import { AncillaryProductField } from './components/fields/product/product.component';
import { AncillaryQuantityField } from './components/fields/quantity/quantity.component';
import { AncillaryUnitField } from './components/fields/unit/unit.component';
import { AncillaryTemperatureField } from './components/fields/temperature/temperature.component';
import { AncillaryGlossLevelField } from './components/fields/gloss-level/gloss-level.component';
import { AncillaryVocField } from './components/fields/voc/voc.component';

@NgModule({
    declarations: [
        AncillaryProductComponent,
        AncillaryQualityField,
        AncillaryProductField,
        AncillaryQuantityField,
        AncillaryUnitField,
        AncillaryTemperatureField,
        AncillaryGlossLevelField,
        AncillaryVocField
    ],
    entryComponents:
        [
            AncillaryProductComponent
        ],
    imports:
        [
            SharedModule
        ],
    exports: [
        AncillaryProductComponent
    ]
})

export class AncillaryProductModule {

}
