import { IDeserialize } from "../../shared/interfaces/deserialize";

export class Region implements IDeserialize {
    id: number;
    name: string;
    localizedName: string;
    localizationPath: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
