import {AnalyticsAncillaryProductDto} from "src/app/core/analytics/dto/common/analytics-ancillary-product-dto";

export class UpdateAnalitycsAncillaryProductDto
{
    constructor(
        public analyticsAncillaryProductId: number,
        public ancillaryProduct: AnalyticsAncillaryProductDto,
        public closedAt?: Date) {
    }
}
