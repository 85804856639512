import { Component, OnInit } from '@angular/core';
import { Quality } from "../../../../formulation/models/quality";
import { AncillaryProductItem } from "../../../models/ancillary-product-item";
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { AncillaryProductData } from "../../../models/ancillary-product-data";
import { AncillaryProductFilter } from "../../../models/ancillary-product-filter";

@Component({
    selector: 'ancillary-product',
    templateUrl: './product.component.html',
    styles: []
})
export class AncillaryProductField extends FormField<AncillaryProductItem> implements OnInit {
    filteredProducts: AncillaryProductItem[];
    quality: Quality;

    constructor(private data: AncillaryProductData, private filter: AncillaryProductFilter) {
        super();
    }

    ngOnInit() {
        this.refresh();
    }

    onQualityChanged(quality: Quality) {
        this.quality = quality;
        this.refresh();
    }

    refresh(): void {
        if (!this.quality) { return; }

        const productIds = this.data.productToQualities.filter(x => x.qualityNo == this.quality.qualityNo).map(x => x.ancillaryProductId);

        this.filteredProducts = this.data.products.filter(x => productIds.some(y => x.ancillaryProductId == y) && (this.filter.componentType == null || x.componentTypeId == this.filter.componentType.id))
            .sort((a, b) => a.componentId - b.componentId);

        this.setSelectedProduct();
    }

    // TODO: is possible that the selected product needs to be set from the selected gloss level, if available

    setSelectedProduct() {
        if (this.filteredProducts && this.filteredProducts.length > 0 && this.data.componentTypes && this.quality) {
            let product = this.findProduct('Decklack');

            if (!product) {
                product = this.findProduct('Basislack/Paste');
            }

            this.value = product ? product : this.filteredProducts[0];
        }
        else {
            this.value = null;
        }
    }

    findProduct(componentName: string): AncillaryProductItem {
        const type = this.data.componentTypes.find(x => x.name == componentName);

        if (!type) { return null; }

        return this.filteredProducts.find(x => x.componentTypeId == type.id && x.shortcut.indexOf(this.quality.shortcut) >= 0);
    }
}
