<ngx-datatable #tableMaterials class="materials-table" [scrollbarH]="isMobileView" [rows]="components"
    [columnMode]="'flex'" headerHeight="40" [rowHeight]="'34'">

    <!-- Color -->
    <ngx-datatable-column *ngIf="!isMobileView" [maxWidth]="30" [minWidth]="30" [width]="30" [sortable]="false"
        headerClass="materials-header" name="" prop="color" [flexGrow]="1">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div *ngIf="value !== null" class="box-color" [ngStyle]="{'background-color': 'rgb(' + value + ')'}"></div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Raw Material -->
    <ngx-datatable-column *ngIf="!isMobileView" [width]="240" [minWidth]="240" class="border-right" [sortable]="false"
        headerClass="materials-header" prop="rawMaterial" [flexGrow]="1"
        name="{{'Formulation.Caption.RawMaterial' | translate }}">
    </ngx-datatable-column>

    <!-- Share -->
    <ngx-datatable-column *ngIf="!isMobileView" headerClass="materials-header" [minWidth]="50" prop="weightInGrammText"
        [sortable]="false" [flexGrow]="3" name="{{'Formulation.Caption.ShareG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Cumulative -->
    <ngx-datatable-column *ngIf="!isMobileView" headerClass="materials-header" [minWidth]="100" [sortable]="false"
        prop="totalWeightInGrammText" [flexGrow]="3" name="{{'Formulation.Caption.CumulativeG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Color mobile -->
    <ngx-datatable-column *ngIf="isMobileView" [maxWidth]="10" [flexGrow]="1" headerClass="materials-header" name=""
        [sortable]="false" prop="color">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div *ngIf="value !== null" class="box-color-mobile" [ngStyle]="{'background-color': 'rgb(' + value + ')'}">
            </div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Raw Material mobile -->
    <ngx-datatable-column *ngIf="isMobileView" [minWidth]="120" [flexGrow]="2" headerClass="materials-header"
        [sortable]="false" prop="rawMaterial" name="{{'Formulation.Caption.RawMaterial' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div class="ml-2" style="text-align: left">{{value}}</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Share mobile -->
    <ngx-datatable-column *ngIf="isMobileView" headerClass="materials-header" [minWidth]="70" [flexGrow]="1"
        [sortable]="false" prop="weightInGrammText" name="{{'Formulation.Caption.ShareG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Cumulative mobile -->
    <ngx-datatable-column *ngIf="isMobileView" headerClass="materials-header" [minWidth]="80" [flexGrow]="1"
        [sortable]="false" prop="totalWeightInGrammText" name="{{'Formulation.Caption.CumulativeG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
<div class="data-load-error" *ngIf="isComponentsLoadingError">
    <p>{{'General.Text.LoadRequiredDataError' | translate}}</p>
    <a class="click-message" href="javascript:void(0)" (click)="retryLoadComponents()">{{'General.Text.ClickToRetry' |
        translate}}</a>
</div>
