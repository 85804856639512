import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Settings {
    language: string;

    constructor() { }

}
