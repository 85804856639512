<ngx-datatable #tableModels [ngStyle]="{'font-size': fontsize+'px', 'height': height}" class="contrast-colors-table"
    [rows]="model?.contrastColors" [columnMode]="'force'" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
    [scrollbarV]="true" [virtualization]="true" [selectionType]="'single'">
    <!-- Type -->
    <ngx-datatable-column headerClass="contrast-colors-header" prop="typeString" [flexGrow]="1"
        name="{{'FormulationSearch.Column.Type' | translate }}">
    </ngx-datatable-column>

    <!-- MCS No. -->
    <ngx-datatable-column headerClass="contrast-colors-header" prop="mcs" [flexGrow]="2"
        name="{{'FormulationSearch.Column.McsNo' | translate }}">
    </ngx-datatable-column>

    <!-- Color No. -->
    <ngx-datatable-column headerClass="contrast-colors-header" prop="colorCode" [flexGrow]="2"
        name="{{'FormulationSearch.Column.ColorNumber' | translate }}">
    </ngx-datatable-column>

    <!-- Color Name -->
    <ngx-datatable-column headerClass="contrast-colors-header" prop="colorName" [flexGrow]="3"
        name="{{'FormulationSearch.Column.ColorName' | translate }}">
    </ngx-datatable-column>

    <!-- Mixing -->
    <ngx-datatable-column headerClass="contrast-colors-header" prop="id" [flexGrow]="1" name="">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <button type="button" (click)="showFormulationSearch(row)" class="action-button action-button-mixing"
                ngbTooltip="{{'FormulationSearch.Tooltip.WeighingView' | translate}}" container="body">
                <img src="/assets/images/balance.png" class="action-img" />
            </button>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
<div class="contrast-colors-data-error row-details-data-error" *ngIf="isDataLoadError">
    <p>{{'General.Text.LoadRequiredDataError' | translate}}</p>
    <a class="click-message" href="javascript:void(0)" (click)="loadContrastColors()">{{'General.Text.ClickToRetry' |
        translate}}</a>
</div>
<div *ngIf="isProcessing" class="row-details-data-loader">
    <dots-loader></dots-loader>
</div>
