import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColorRow } from "../../../../formulation/models/color-row";
import { QualityType } from "../../../../formulation/enums/quality-type";
import { SearchType } from "../../../enums/search-type";
import { FormulationDataCriteria } from "../../../../formulation-data/models/formulation-data-criteria";
import { FormulationDataDialogService } from "../../../../formulation-data/services/formulation-data-dialog.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NavigationEnd, Router } from "@angular/router";
import { sections } from "../../../../shared/configuration/sections";
import { String as StringOperations } from "typescript-string-operations";
import { SearchSettings } from "../../../models/search-settings";
import { TranslateService } from "@ngx-translate/core";
import { IsMobileViewPipe } from "../../../../shared/pipes/is-mobile-view.pipe";
import { IsDesktopViewPipe } from "../../../../shared/pipes/is-desktop-view.pipe";
import { IsSmartphoneViewPipe } from "../../../../shared/pipes/is-smartphone-view.pipe";
import { IsPadViewPipe } from "../../../../shared/pipes/is-pad-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-search-results-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnDestroy {
    @Input() colors: ColorRow[];
    @Input() selectedColor: ColorRow;
    @Input() searchType: SearchType;
    @Input() qualities: QualityType[];
    @Input() noResultsFound: boolean;
    @Input() voc: string;
    @Output() selectedColorChanged = new EventEmitter<ColorRow>();
    @ViewChild('table', { static: true }) table: DatatableComponent;

    qualityTypes = QualityType;
    searchTypes = SearchType;
    navigationSub: Subscription;
    verticalOffset: number;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    isPadView: boolean;
    isDesktopView: boolean;
    isSmartphoneView: boolean;
    height: string;
    fontsize: string;
    headerHeight: number;
    rowHeight: number;
    detailHeight: number;
    colorNameWidth: number;
    formulationWidth: number;
    mcs3Width: number;
    manufacturerWidth: number;
    lastToggledRow: any;

    private _isSearchFormInvisible: boolean;

    constructor(
        private formulationDataDialogService: FormulationDataDialogService,
        private router: Router,
        public searchSettings: SearchSettings,
        private translate: TranslateService,
        private mobileViewPipe: IsMobileViewPipe,
        private smartphoneViewPipe: IsSmartphoneViewPipe,
        private padViewPipe: IsPadViewPipe,
        private desktopViewPipe: IsDesktopViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.resizeForMobile();
        });
        this.navigationSub = this.router.events.subscribe(x => {
            if (x && x instanceof NavigationEnd && !StringOperations.IsNullOrWhiteSpace(x.url) && x.url === `/${sections.search}`) {
                this.onNavigatedTo();
            }
        });
        this.translate.onLangChange.subscribe(() => this.setLocalizedNames());
        this.resizeForMobile();
    }

    ngOnDestroy() {
        this.navigationSub.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

    resizeForMobile() {
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.isDesktopView = this.desktopViewPipe.transform(window);
        this.isPadView = this.padViewPipe.transform(window);
        this.isSmartphoneView = this.smartphoneViewPipe.transform(window);

        if (this.isDesktopView) {
            this.fontsize = '14';
            this.headerHeight = 36;
            this.rowHeight = 34;
            this.height = (this.isSearchFormInvisible) ? '70vh' : '26vh';
            this.detailHeight = 200;
            this.formulationWidth = 500;
            this.colorNameWidth = 250;
            this.mcs3Width = 200;
            this.manufacturerWidth = 200;
            this.changeLineHeight('34px');
            this.changeExpandLineHeight('24px');
            this.changeExpandTwoToneLineHeight('28px');
            this.changeBoxSize('20px');
        }
        else if (this.isPadView) {
            this.fontsize = '9';
            this.headerHeight = 32;
            this.rowHeight = 30;
            this.height = (this.isSearchFormInvisible) ? '70vh' : '26vh';
            this.detailHeight = 200;
            this.formulationWidth = 300;
            this.colorNameWidth = 200;
            this.mcs3Width = 180;
            this.manufacturerWidth = 180;
            this.changeLineHeight('30px');
            this.changeExpandLineHeight('18px');
            this.changeExpandTwoToneLineHeight('24px');
            this.changeBoxSize('16px');
        }
        else if (this.isSmartphoneView) {
            this.fontsize = '4';
            this.headerHeight = 26;
            this.rowHeight = 24;
            this.height = (this.isSearchFormInvisible) ? '70vh' : '26vh';
            this.detailHeight = 300;
            this.formulationWidth = 260;
            this.colorNameWidth = 180;
            this.mcs3Width = 160;
            this.manufacturerWidth = 160;
            this.changeLineHeight('24px');
            this.changeExpandLineHeight('9px');
            this.changeExpandTwoToneLineHeight('18px');
            this.changeBoxSize('8px');
        }
    }

    refreshDetailsRow() {
        const row = this.table.rows.filter((item: ColorRow) => item.isExpanded === true);
        this.toggleExpandRow(row);
    }

    changeBoxSize(boxSize: string) {
        document.documentElement.style.setProperty('--color-box-size', boxSize);
    }

    changeLineHeight(lineHeight: string) {
        document.documentElement.style.setProperty('--datatable-row-line-height', lineHeight);
    }

    changeExpandLineHeight(lineHeight: string) {
        document.documentElement.style.setProperty('--datatable-expand-line-height', lineHeight);
    }

    changeExpandTwoToneLineHeight(lineHeight: string) {
        document.documentElement.style.setProperty('--datatable-expand-two-tone-line-height', lineHeight);
    }

    toggleExpandRow(row: ColorRow) {
        const isExpanded = row.isExpanded;
        this.table.rowDetail.collapseAllRows();
        this.table.rows.forEach((item: ColorRow) => item.isExpanded = false);
        if (!isExpanded) {
            this.table.rowDetail.toggleExpandRow(row);
        }

        row.isExpanded = !isExpanded;
    }

    onSelect({ selected }) {
        let newSelection: ColorRow;
        if (!selected || !Array.isArray(selected) || selected.length === 0) {
            newSelection = null;
        }
        else {
            newSelection = selected[0];
        }

        this.selectedColorChanged.emit(newSelection);
    }

    onActivate(event) {
        if (event.type === 'dblclick') {
            this.toggleExpandRow(event.row);
            this.lastToggledRow = event.row;
        }
    }

    getRowClass(row: ColorRow) {
        return row != null && row.isExpanded ? "row-expanded" : "";
    }

    showFormulationDataDialog(color: ColorRow, qualityType: QualityType) {
        if (!this.canShowFormulationData(color, qualityType)) {
            return;
        }

        const criteria = FormulationDataCriteria.fromQuality(color, qualityType, this.searchType, this.voc);
        this.formulationDataDialogService.showDialog(criteria, false);
    }

    canShowFormulationData(color: ColorRow, qualityType: QualityType): boolean {
        switch (qualityType) {
            case QualityType.OC: return color.oc && color.oc < 3;
            case QualityType.BC: return color.bc && color.bc < 3;
            case QualityType.PUR: return color.pur && color.pur < 3;
            case QualityType.WBC: return color.wbc && color.wbc < 3;
            case QualityType.PURHS: return color.purhs && color.purhs < 3;
        }
    }

    onScroll(event: MouseEvent) {
        if (event && event.offsetY) {
            this.verticalOffset = event.offsetY;
        }
    }

    onNavigatedTo() {
        if (this.table && this.verticalOffset) {
            this.table.element.getElementsByTagName('datatable-body')[0].scrollTop = this.verticalOffset;
        }

        this.isMobileView = this.mobileViewPipe.transform(window);
    }

    setLocalizedNames() {
        if (!this.colors) {
            return;
        }

        const synonymColorNameHeader = this.translate.instant('FormulationSearch.Text.SynonymColorNames');
        this.colors.forEach(color => {
            color.varTooltip = this.translate.instant(`Variant.Name.${color.var.replace(".", "_")}`);
            if (color.synonymColorNameString !== null) {
                color.synonymColorNameAndHeaderString = `${synonymColorNameHeader}: <br \>${color.synonymColorNameString}`;
            } else {
                color.synonymColorNameAndHeaderString = `${synonymColorNameHeader}: <br \><center>-</center>`;
            }
            if(color.fVar){
                const flopVariant = color.fVar.replace('.','_');
                color.fVarTooltip = this.translate.instant(`Variant.Name.${flopVariant}`);
            }

        });
    }

    @Input()
    set isSearchFormInvisible(value: boolean) {
        this._isSearchFormInvisible = value;
        this.height = (this.isSearchFormInvisible ? '70vh' : '26vh');
        setTimeout(() => window.dispatchEvent(new Event('resize')), 50);
    }

    get isSearchFormInvisible(): boolean {
        return this._isSearchFormInvisible;
    }

    get isOCQuality() {
        this.table.recalculateColumns();
        return this.qualities != null && this.qualities.some(x => x === QualityType.OC);
    }

    get isBCQuality() {
        return this.qualities != null && this.qualities.some(x => x === QualityType.BC);
    }

    get isPURQuality() {
        if (environment.theme.id === "dyo") {
            return false;
        }

        return this.qualities != null && this.qualities.some(x => x === QualityType.PUR);
    }

    get isWBCQuality() {
        return this.qualities != null && this.qualities.some(x => x === QualityType.WBC);
    }

    get isPURHSQuality() {
        if (environment.theme.id === "dyo") {
            return false;
        }
        
        return this.qualities != null && this.qualities.some(x => x === QualityType.PURHS);
    }
}
