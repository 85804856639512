import { ColorRow } from "../../formulation/models/color-row";
import { QualityType } from "../../formulation/enums/quality-type";
import { SearchType } from "../../formulation-search/enums/search-type";

export class FormulationDataCriteria {
    color: ColorRow;
    quality: QualityType;
    searchType: SearchType;
    voc: string;

    static fromColor(color: ColorRow, searchType: SearchType, voc: string): FormulationDataCriteria {
        const criteria = new FormulationDataCriteria();

        criteria.color = color;
        criteria.searchType = searchType;
        criteria.voc = voc;

        return criteria;
    }

    static fromQuality(color: ColorRow, quality: QualityType, searchType: SearchType, voc: string): FormulationDataCriteria {
        const criteria = new FormulationDataCriteria();

        criteria.color = color;
        criteria.quality = quality;
        criteria.searchType = searchType;
        criteria.voc = voc;

        return criteria;
    }
}
