import { Injectable } from '@angular/core';
import { catchError } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { Endpoints } from "src/app/shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';
import {throwError} from "rxjs";
import {AnalyticsAncillaryProductDto} from "src/app/core/analytics/dto/common/analytics-ancillary-product-dto";
import {ApMixingContainer} from "src/app/formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import {CreateAnalyticsAncillaryProductDto} from "src/app/core/analytics/dto/create-ancillary-product/create-analytics-ancillary-product-dto";
import {CreateAnalyticsAncillaryProductResponseDto} from "src/app/core/analytics/dto/create-ancillary-product/create-analytics-ancillary-product-response-dto";
import {UpdateAnalitycsAncillaryProductDto} from "src/app/core/analytics/dto/update-ancillary-product/update-analitycs-ancillary-product-dto";
import {AnalyticsApMixingContainer} from "src/app/core/analytics/models/analytics-ap-mixing-container";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsAncillaryProductService {

    constructor(private http: HttpService,
                private logger: NGXLogger,
                private endpoints: Endpoints) {
    }

    public async saveAncillaryProduct(container: AnalyticsApMixingContainer)
    {
        if(container.analyticsAncillaryProductId == null)
        {
            await this.createAnalyticsAncillaryProduct(container);
        }
        else
        {
            await this.updateAnalyticsAncillaryProduct(container);
        }
    }

    private async createAnalyticsAncillaryProduct(analyticsContainer: AnalyticsApMixingContainer): Promise<any>
    {
        const openedAt = analyticsContainer.openedAt;
        const closedAt = analyticsContainer.closedAt;
        const product = this.createAncillaryProduct(analyticsContainer.container);

        const dto = new CreateAnalyticsAncillaryProductDto(product, openedAt, closedAt);

        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        const response = await this.http.post<CreateAnalyticsAncillaryProductResponseDto>(this.endpoints.apiCreateAnalyticsAncillaryProduct, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while saving the analytics ancillary product.`, err);
                    return throwError(err);
                }),
            ).toPromise<CreateAnalyticsAncillaryProductResponseDto>();

        analyticsContainer.analyticsAncillaryProductId = response.analyticsAncillaryProductId;
    }

    private async updateAnalyticsAncillaryProduct(analyticsContainer: AnalyticsApMixingContainer): Promise<any>
    {
        const closedAt = analyticsContainer.closedAt;
        const product = this.createAncillaryProduct(analyticsContainer.container);
        const dto = new UpdateAnalitycsAncillaryProductDto(analyticsContainer.analyticsAncillaryProductId, product, closedAt);

        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        await this.http.post(this.endpoints.apiUpdateAnalyticsAncillaryProduct, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while saving the analytics ancillary product.`, err);
                    return throwError(err);
                }),
            ).toPromise();
    }

    private createAncillaryProduct(container: ApMixingContainer): AnalyticsAncillaryProductDto
    {
        const product = container.selectedProduct;

        return new AnalyticsAncillaryProductDto({
            ancillaryProductId: product.ancillaryProduct.id,
            ancillaryProductName: product.ancillaryProductName,
            glossDensity: product.glossDensity,
            glossLevelId: product.glossLevelId,
            glossLevelShortcut: product.glossLevelShortcut,
            glossRelation: product.glossRelation,
            glossRgb: product.glossRgb,
            glossVoc: product.glossVoc,
            hardenerComponentId: product.hardenerComponentId,
            hardenerDensity: product.hardenerDensity,
            hardenerRelation: product.hardenerRelation,
            hardenerRelation2: product.hardenerRelation2,
            hardenerRelation3: product.hardenerRelation3,
            hardenerRgb: product.hardenerRgb,
            hardenerShortcut: product.hardenerShortcut,
            hardenerVoc: product.hardenerVoc,
            mainComponentDensity: product.mainComponentDensity,
            mainComponentId: product.mainComponentId,
            mainComponentRgb: product.mainComponentRgb,
            mainComponentShortcut: product.mainComponentShortcut,
            mainComponentVoc: product.mainComponentVoc,
            thinner1Density: product.thinner1Density,
            thinner1Rgb: product.thinner1Rgb,
            thinner1Shortcut: product.thinner1Shortcut,
            thinner1Voc: product.thinner1Voc,
            thinner2Density: product.thinner2Density,
            thinner2Rgb: product.thinner2Rgb,
            thinner2Shortcut: product.thinner2Shortcut,
            thinner2Voc: product.thinner2Voc,
            thinnerComponent1Id: product.thinnerComponent1Id,
            thinnerComponent2Id: product.thinnerComponent2Id,
            thinnerRelation11: product.thinnerRelation11,
            thinnerRelation12: product.thinnerRelation12,
            thinnerRelation13: product.thinnerRelation13,
            thinnerRelation21: product.thinnerRelation21,
            thinnerRelation22: product.thinnerRelation22,
            thinnerRelation23: product.thinnerRelation23
        });
    }
}
