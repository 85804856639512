import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { Quality } from "../../../../formulation/models/quality";
import { ColorRow } from "../../../../formulation/models/color-row";
import { QualityType } from "../../../../formulation/enums/quality-type";
import { FormulationDataVariantComponent } from "../variant/variant.component";
import { FormulationDataGlossLevelComponent } from "../gloss-level/gloss-level.component";
import { Subscription } from "rxjs";
import { FormulationDataService } from "../../../services/formulation-data.service";

@Component({
    selector: 'app-formulation-data-quality',
    templateUrl: './quality.component.html',
    styles: []
})
export class FormulationDataQualityComponent extends FormField<Quality> implements OnInit, OnDestroy {
    @Input() qualityType: QualityType;
    @Input() variantComponent: FormulationDataVariantComponent;
    @Input() glossLevelComponent: FormulationDataGlossLevelComponent;

    subscription: Subscription;
    filteredQualities: Quality[];

    private _qualities: Quality[];
    private _color: ColorRow;

    constructor(private formulationDataService: FormulationDataService) {
        super();
    }

    ngOnInit() {
        this.subscription = this.onChanged.subscribe(x => this.onQualityChanged(x)); // TODO: emitting more than once? why not?
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    filterQualities() {
        if (!this.qualities || this.qualities.length === 0 || !this.color) {
            this.filteredQualities = null;
        }
        else {
            this.filteredQualities = this.qualities.filter(x => this.isQualityAvailable(x));
        }

        this.value = this.filteredQualities && this.filteredQualities.length > 0 ? this.filteredQualities[0] : null;
    }

    async refreshVariantAndGlossLevel(quality: Quality) {
        const response = await this.formulationDataService.getQualityInfo(this.color.colorId, quality.qualityNo);
        this.variantComponent.setState(response.hasNoQualityAdditive);
        this.glossLevelComponent.setState(response.hasNoGloss);
        this.variantComponent.onQualityChanged(quality);
    }

    onQualityChanged(quality: Quality) {
        this.refreshVariantAndGlossLevel(quality);
    }

    private isQualityAvailable(quality: Quality): boolean {
        if (this.qualityType && quality.qualityNo !== this.qualityType) {
            return false;
        }

        switch (quality.qualityNo) {
            case 1: return this.color.oc && this.color.oc > 0 && this.color.oc < 3;
            case 2: return this.color.bc && this.color.bc > 0 && this.color.bc < 3;
            case 4: return this.color.pur && this.color.pur > 0 && this.color.pur < 3;
            case 5: return this.color.wbc && this.color.wbc > 0 && this.color.wbc < 3;
            case 6: return this.color.purhs && this.color.purhs > 0 && this.color.purhs < 3;
            default: return false;
        }
    }

    @Input()
    set qualities(value) {
        this._qualities = value;
        this.filterQualities();
        if (this.value) {
            this.refreshVariantAndGlossLevel(this.value);
        }
    }

    get qualities() {
        return this._qualities;
    }

    @Input()
    set color(value) {
        this._color = value;
        this.filterQualities();
        if (this.value) {
            this.refreshVariantAndGlossLevel(this.value);
        }
    }
    get color() {
        return this._color;
    }
}
