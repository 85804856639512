import { Component, OnInit } from '@angular/core';
import {FormField} from "src/app/shared/components/base/forms/form-field";

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styles: []
})
export class NewPasswordComponent extends FormField<string> implements OnInit {

    ngOnInit() {
    }

}
