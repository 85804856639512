import { IDeserialize } from "../../shared/interfaces/deserialize";

export class AccessToken implements IDeserialize {
    sub: string;
    jti: string;
    admin: number;
    iat: number;
    exp: number;
    iss: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
