<div class="navbar navbar-footer fixed-bottom">
    <div class="container">
        <ul class="navbar-footer2">
            <li><a href="{{ 'Imprint.Links.TermsOfService' | translate }}" target="_blank"
                    rel="noopener noreferrer">{{ 'Imprint.Label.TermsOfService' | translate }}</a></li>
            <li><a href="{{ 'Imprint.Links.Contact' | translate }}" target="_blank" rel="noopener noreferrer">{{
                    'Imprint.Label.Contact' | translate }}</a></li>
            <li><a href="{{ 'Imprint.Links.Imprint' | translate }}" target="_blank" rel="noopener noreferrer">{{
                    'Imprint.Label.Imprint' | translate }}</a></li>
            <li><a href="{{ 'Imprint.Links.Disclaimer' | translate }}" target="_blank"
                    rel="noopener noreferrer">{{ 'Imprint.Label.Disclaimer' | translate }}</a></li>
            <li><a href="{{ 'Imprint.Links.PrivacyPolicy' | translate }}" target="_blank" rel="noopener noreferrer">{{
                    'Imprint.Label.PrivacyPolicy' | translate }}</a></li>
            <li>{{'Imprint.Label.Version' |translate}} {{version}}</li>
        </ul>

    </div>
</div>
