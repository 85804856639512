import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ColorRow } from "../../../../../formulation/models/color-row";
import { SearchType } from "../../../../enums/search-type";
import { IsDesktopViewPipe } from "../../../../../shared/pipes/is-desktop-view.pipe";
import { IsSmartphoneViewPipe } from "../../../../../shared/pipes/is-smartphone-view.pipe";
import { IsPadViewPipe } from "../../../../../shared/pipes/is-pad-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search-row-details',
    templateUrl: './row-details.component.html',
    styleUrls: ['./row-details.component.scss']
})
export class RowDetailsComponent implements OnInit, OnDestroy {
    @Input() color: ColorRow;
    @Input() searchType: SearchType;
    @Input() isExpanded: boolean;
    @Input() voc: string;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;

    constructor(
        private smartphoneViewPipe: IsSmartphoneViewPipe,
        private padViewPipe: IsPadViewPipe,
        private desktopViewPipe: IsDesktopViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.resizeForMobile();
        });
        this.resizeForMobile();
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    resizeForMobile() {
        if (this.desktopViewPipe.transform(window)) {
            this.changeFontSize('14');
            this.changeLineHeight('36px');
            this.changeDetailHeight('200px');
        }
        else if (this.padViewPipe.transform(window)) {
            this.changeFontSize('9');
            this.changeLineHeight('32px');
            this.changeDetailHeight('180px');
        }
        else if (this.smartphoneViewPipe.transform(window)) {
            this.changeFontSize('4');
            this.changeLineHeight('26px');
            this.changeDetailHeight('160px');
        }
    }

    changeLineHeight(lineHeight: string) {
        document.documentElement.style.setProperty('--header-line-height', lineHeight);
    }

    changeDetailHeight(detailHeight: string) {
        document.documentElement.style.setProperty('--detail-height', detailHeight);
    }

    changeFontSize(fontSize: string) {
        document.documentElement.style.setProperty('--header-font-size', fontSize);
    }
}
