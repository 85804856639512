<ng-template #errorTooltip>
    <div *ngIf="isValidBuildYearRequired">{{ 'FormulationSearch.Validation.ValidBuildYearRequired' | translate }}</div>
    <div *ngIf="isValidBuildYearRangeRequired">{{ 'FormulationSearch.Validation.ValidBuildYearRangeRequired' | translate
        }}</div>
</ng-template>

<label>{{ 'FormulationSearch.Label.YearOfBuild' | translate }}</label>
<input NumbersOnly [ngClass]="{'field-selected': isFilled, 'field-error': hasError}" name="year-of-manufacture"
    [(ngModel)]="value" type="text" pattern="\d*" maxlength="4" class="form-control" data-toggle="tooltip"
    [ngbTooltip]="errorTooltip" tooltipClass="input-tooltip-error" [disableTooltip]="!hasError" container="body" />
