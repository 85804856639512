import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ColorRow } from "../../formulation/models/color-row";
import { IsMobileViewPipe } from "../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { SearchResultsComponent } from './search-results/search-results.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() colors: ColorRow[];
    @ViewChild(SearchResultsComponent) searchResultsComponent: SearchResultsComponent;

    selectedIndex = 0;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;

    constructor(private cd: ChangeDetectorRef, private mobileViewPipe: IsMobileViewPipe) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    onSearching(isSearching: boolean): void {
        if (isSearching) {
            this.colors = null;
        }
    }

    onResultsUpdated(colors: ColorRow[]): void {
        this.colors = colors;
    }

    selectTab(index: number) {
        if (this.selectedIndex !== index) {
            this.selectedIndex = index;

            /**
             * Apparently, Chrome (in comparison to Firefox) does not set scroll positions on hidden elements.
             * Therefore, this code snippet manually restores the scroll position of the search tab, when
             * users started a search, changed to the "Other Products" tab, selected one, pressed the back button
             * and switched back to the search tab.
             */
            if (index === 0) {
                this.searchResultsComponent.scrollToPreviousPosition();
            }
        }
    }

    get isDyoMode() {
        return environment.theme.id === "dyo";
    }
}
