import { IDeserialize } from "../../shared/interfaces/deserialize";

export class AncillaryProductToQuality implements IDeserialize {
    id: number;
    ancillaryProductId: number;
    qualityNo: number;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        return this;
    }
}
