import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { String as StringUtils } from "typescript-string-operations";
import { IFormField } from "./form-field-interface";
import { NgSelectComponent } from "@ng-select/ng-select";

@Directive()
export class FormField<T> implements IFormField {
    @Input() showAsterisk: boolean;
    @Output() onChanged: EventEmitter<T> = new EventEmitter<T>();
    @Output() onFilled: EventEmitter<boolean> = new EventEmitter<boolean>();

    isVisible: boolean;
    hasError = false;

    protected _value: T;

    public isValueNullOrEmpty(): boolean {
        if (this.value == null) {
            return true;
        }

        return StringUtils.IsNullOrWhiteSpace(this.value.toString());
    }

    clear(): void {
        this.value = null;
    }

    validate(): boolean {
        return true;
    }

    setValue(val: T) {
        this._value = val;
    }

    /**
     * This function selects the current value on a tab key press without preventing the shift of the focus to the next element.
     * Unfortunately, the native [selectOnTab] option of the ng-select prevents this focus movement.
     */
    selectOnTab(this: NgSelectComponent, event: KeyboardEvent) {
        if (event.key === "Tab") {
            if (this.isOpen) {
                if (this.itemsList.markedItem) {
                    this.toggleItem(this.itemsList.markedItem);
                }
                else if (this.addTag) {
                    this.selectTag();
                }
            }
        }

        return true;
    }

    @Input()
    public set value(val: T) {
        this._value = val;
        this.onFilled.emit(!this.isValueNullOrEmpty());
        this.onChanged.emit(val);
    }

    public get value(): T {
        return this._value;
    }

    get isFilled(): boolean {
        return !this.isValueNullOrEmpty();
    }
}
