import { Component, OnInit } from '@angular/core';
import { FormField } from "../../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'year-of-build-field',
    templateUrl: './year-of-build.component.html',
    styleUrls: []
})
export class YearOfBuildComponent extends FormField<string> implements OnInit {

    isValidBuildYearRequired: boolean;
    isValidBuildYearRangeRequired: boolean;

    ngOnInit() {
        this.onChanged.subscribe(x => {
            if (this.hasError) {
                this.validate();
            }
        });
    }

    validate(): boolean {
        if (this.markAsValidIfEmpty()) { return true; }

        try {
            const year = Number(this.value);

            this.isValidBuildYearRequired = isNaN(year);

            if (this.isValidBuildYearRequired) { return false; }

            this.isValidBuildYearRangeRequired = year < 1900 || year > 2099;
        }
        finally {
            this.setValidationState();
        }

        return !this.hasError;
    }

    markAsValidIfEmpty(): boolean {
        if (this.isValueNullOrEmpty()) {
            this.isValidBuildYearRequired = false;
            this.isValidBuildYearRangeRequired = false;

            this.setValidationState();

            return true;
        }

        return false;
    }

    setValidationState(): void {
        this.hasError = this.isValidBuildYearRequired || this.isValidBuildYearRangeRequired;
    }
}
