import { Directive, OnInit, Input } from '@angular/core';
import { InputMaskOptions } from '../interfaces/input-mask-options';

@Directive({
    selector: '[InputMask]'
})
export class InputMaskDirective implements OnInit {

    @Input() options: InputMaskOptions;

    constructor() {

    }

    ngOnInit() {

    }

}
