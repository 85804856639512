export class AnalyticsAncillaryProductDto
{
    mainComponentId: number;

    mainComponentShortcut: string;

    mainComponentVoc: number;

    mainComponentDensity: number;

    mainComponentRgb: number;

    glossLevelId: number;

    glossLevelShortcut: string;

    glossDensity: number;

    glossRelation: number;

    glossVoc: number;

    glossRgb: number;

    hardenerComponentId: number;

    hardenerDensity: number;

    hardenerShortcut: string;

    hardenerRelation: number;

    hardenerRelation2: number;

    hardenerRelation3: number;

    hardenerVoc: number;

    hardenerRgb: number;

    thinnerComponent1Id: number;

    thinner1Density: number;

    thinnerComponent2Id: number;

    thinner2Density: number;

    thinner1Shortcut: string;

    thinner2Shortcut: string;

    thinnerRelation11: number;

    thinnerRelation12: number;

    thinnerRelation13: number;

    thinnerRelation21: number;

    thinnerRelation22: number;

    thinnerRelation23: number;

    thinner1Voc: number;

    thinner2Voc: number;

    thinner1Rgb: number;

    thinner2Rgb: number;

    ancillaryProductId: number;

    ancillaryProductName: string;

    public constructor(init?: Partial<AnalyticsAncillaryProductDto>)
    {
        Object.assign(this, init);
    }
}
