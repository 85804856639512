import { Component, Input, OnInit } from '@angular/core';
import { FormField } from "../../../../../shared/components/base/forms/form-field";
import { Region } from "../../../../../formulation/models/region";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'region-field',
    templateUrl: './region.component.html',
    styles: []
})
export class RegionComponent extends FormField<Region> implements OnInit {

    private _regions: Region[];

    get regions() { return this._regions; }

    @Input()
    set regions(value) {
        this._regions = value;

        this.setLocalizedNames();

        this.value = this._regions ? this._regions[0] : null;
    }

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(x => {
            this.setLocalizedNames();
        });
    }

    setLocalizedNames() {
        if (!this._regions) { return; }

        for (const i in this._regions) {
            const region = this._regions[i];

            region.localizedName = this.translate.instant(region.localizationPath);
        }
    }
}
