import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { AuthenticationComponent } from "./components/authentication.component";
import { UsernameField } from "./components/fields/username/username.component";
import { PasswordField } from "./components/fields/password/password.component";
@NgModule({
    declarations: [
        AuthenticationComponent,
        UsernameField,
        PasswordField
    ],
    imports:
        [
            SharedModule
        ],
    exports: [
        AuthenticationComponent
    ]
})

export class AuthenticationModule {
}
