<div class="form-print-container" *ngIf="models">
    <div *ngFor="let model of models; let i=index" class="form-print">
        <table class="t1">
            <tr>
                <td>{{'Printing.Label.FormulationNo' | translate}}:</td>
                <td>{{model.formulationNo}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.DateOfPrint' | translate}}:</td>
                <td>{{model.dateOfPrint}}</td>
            </tr>
        </table>

        <img class="logo" [src]="logoPath"/>

        <table class="t2">
            <tr>
                <td>{{'Printing.Label.Manufacturer' | translate}}:</td>
                <td>{{model.manufacturer}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.ColorCode' | translate}}:</td>
                <td>{{model.colorCode}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.Variant' | translate}}:</td>
                <td>{{model.variant}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.VariantFlop' | translate}}:</td>
                <td>{{model.variantFlop}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.Model' | translate}}:</td>
                <td>{{model.model}}</td>
            </tr>
            <tr>
                <td [innerHTML]="('Printing.Label.YearOfBuild' | translate) + ':'"></td>
                <td>{{model.yearOfBuild}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.ColorName' | translate}}:</td>
                <td>{{model.colorName}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.Coating' | translate}}:</td>
                <td>{{model.coating}}</td>
            </tr>
        </table>

        <table class="t3">
            <tr>
                <td>{{'Printing.Label.Mcs' | translate}}:</td>
                <td>{{model.mcs}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.Quality' | translate}}:</td>
                <td>{{model.quality}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.QualityAdditive' | translate}}:</td>
                <td>{{model.qualityAdditive}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.GlossLevel' | translate}}:</td>
                <td>{{model.glossLevel}}</td>
            </tr>
            <tr>
                <td>{{'Printing.Label.Quantity' | translate}}:</td>
                <td>{{model.quantity}}</td>
            </tr>
        </table>

        <table class="t4" *ngIf="model.materials">
            <thead>
                <tr class="head">
                    <td>{{'Printing.Label.RawMaterial' | translate }}</td>
                    <td>{{'Printing.Label.QuantityG' | translate }}</td>
                    <td>{{'Printing.Label.CumulativeG' | translate }}</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let material of model.materials">
                    <td>{{material.rawMaterial}}</td>
                    <td>{{material.weightInGrammText}} g</td>
                    <td>{{material.totalWeightInGrammText}} g</td>
                </tr>
            </tbody>
        </table>


        <div class="additional" *ngIf="model.additionalInfo">
            <div class="head">{{'Printing.Label.AdditionalInformation' | translate}}:</div>
            <p *ngFor="let remark of model.additionalInfo">{{remark}}</p>
        </div>

        <div class="comment">{{'Printing.Label.Comment' | translate}}:</div>
        <div class="pagebreak" *ngIf="i < models.length - 1"></div>
    </div>
</div>
