import { QualityType } from "../../formulation/enums/quality-type";
import { IDeserialize } from "../../shared/interfaces/deserialize";
import { ColorRow } from "../../formulation/models/color-row";
import { SearchType } from "../enums/search-type";
import { TranslateService } from "@ngx-translate/core";

export class SearchResult implements IDeserialize {
    colors: ColorRow[];

    searchType: SearchType;

    qualities: QualityType[];

    voc: string;

    constructor(
        private translate: TranslateService) { }

    deserialize(input: any) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        Object.assign(<any>this, input);

        if (this.colors) {
            this.colors = this.colors.map(x => new ColorRow().deserialize(x));

            // eslint-disable-next-line guard-for-in
            for (const i in this.colors) {
                const colorRow = this.colors[i];
                if (colorRow.var) {
                    const variant = colorRow.var.replace('.', '_');
                    this.colors[i].varTooltip = this.translate.instant(`Variant.Name.${variant}`);
                }

                if(colorRow.fVar){
                    const flopVariant = colorRow.fVar.replace('.','_');
                    this.colors[i].fVarTooltip = this.translate.instant(`Variant.Name.${flopVariant}`);
                }

                const synonymColorNameHeader = this.translate.instant('FormulationSearch.Text.SynonymColorNames');

                if (this.colors[i].synonymColorNameString) {
                    this.colors[i].synonymColorNameAndHeaderString = `${synonymColorNameHeader}: <br \>${this.colors[i].synonymColorNameString}`;
                }
            }
        }

        return this;
    }
}
