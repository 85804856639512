import { Component, OnInit, ViewChild } from '@angular/core';
import { AncillaryProductFilter } from "../../../formulation-ancillary-product/models/ancillary-product-filter";
import { OtherProductsComponentTypeFieldComponent } from "./fields/component-type.field";
import { AncillaryProductDialogService } from "../../../formulation-ancillary-product/services/ancillary-product-dialog-service";

@Component({
    selector: 'app-other-products',
    templateUrl: './other-products.component.html',
    styleUrls: ['./other-products.component.scss']
})
export class OtherProductsComponent implements OnInit {
    @ViewChild('componentType', { static: true }) componentType: OtherProductsComponentTypeFieldComponent;

    constructor(private ancillaryProductFilter: AncillaryProductFilter, private ancillaryProductDialogService: AncillaryProductDialogService) { }

    ngOnInit() { }

    showAncillaryProductDialog() {
        this.ancillaryProductFilter.componentType = this.componentType.value;
        this.ancillaryProductDialogService.showDialog();
    }
}
