import {EventEmitter, Injectable} from "@angular/core";
import Stack from "ts-data.stack";
import {ContainerChangedEventArgs} from "../events/container-changed-event-args";
import {IMixingContainer} from "../interfaces/i-mixing-container";
import {MixingContainer} from "src/app/formulation-mixing/models/mixing-data/default/mixing-container";
import {ContainerType} from "src/app/formulation-mixing/enums/container-type";
import {AnalyticsRecipeService} from "src/app/core/analytics/services/analytics-recipe.service";
import {AnalyticsMixingContainerService} from "src/app/core/analytics/services/analytics-mixing-container.service";
import {AnalyticsApMixingContainerService} from "src/app/core/analytics/services/analytics-ap-mixing-container.service";
import {ApMixingContainer} from "src/app/formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";

@Injectable({
    providedIn: 'root'
})
export class MixingNavigationService {
    canNavigateBack: boolean;
    containerChanged = new EventEmitter<ContainerChangedEventArgs>();
    canNavigateBackChanged = new EventEmitter<boolean>();
    containers = new Stack<IMixingContainer>();
    currentContainer: IMixingContainer;

    constructor(
        private analyticsRecipeService: AnalyticsRecipeService,
        private analyticsMixingContainerService: AnalyticsMixingContainerService,
        private analyticsApMixingContainerService: AnalyticsApMixingContainerService,
    ) {
        this.containerChanged.subscribe(x => this.triggerAnalyticsUpdate(x.oldValue, x.newValue));
    }

    async navigate(container: IMixingContainer, clearHistory: boolean = false): Promise<void> {
        if (clearHistory) {
            await this.clear();
        }

        const oldContainer = this.currentContainer;

        if (oldContainer) {
            this.containers.push(oldContainer);
        }

        this.currentContainer = container;
        this.containerChanged.emit(new ContainerChangedEventArgs(oldContainer, this.currentContainer));
        this.setCanNavigateBack();
    }

    navigateBack(): void {
        if (this.containers.isEmpty()) {
            return;
        }

        const oldContainer = this.currentContainer;
        this.currentContainer = this.containers.pop();
        this.currentContainer.isBackEvent = true;
        this.containerChanged.emit(new ContainerChangedEventArgs(oldContainer, this.currentContainer));
        this.setCanNavigateBack();
    }

    setCanNavigateBack() {
        this.canNavigateBack = !this.containers.isEmpty();
        this.canNavigateBackChanged.emit(this.canNavigateBack);
    }

    async clear(): Promise<void> {
        await this.analyticsMixingContainerService.clear();
        await this.analyticsApMixingContainerService.clear();

        const oldContainer = this.currentContainer;
        this.currentContainer = null;
        this.containers = new Stack<IMixingContainer>();
        this.containerChanged.emit(new ContainerChangedEventArgs(oldContainer, this.currentContainer));
        this.canNavigateBack = false;
    }

    async triggerAnalyticsUpdate(oldContainer: IMixingContainer, newContainer: IMixingContainer): Promise<void>
    {
        if (this.isAnyMixingContainer([oldContainer, newContainer])) {
            await this.analyticsMixingContainerService.onContainerChanged(
                oldContainer?.containerType === ContainerType.Default ? oldContainer as MixingContainer : null,
                newContainer?.containerType === ContainerType.Default ? newContainer as MixingContainer : null,
            );
        }

        if (this.isAnyAncillaryProductContainer([oldContainer, newContainer])) {
            await this.analyticsApMixingContainerService.onContainerChanged(
                oldContainer?.containerType === ContainerType.AncillaryProduct ? oldContainer as ApMixingContainer : null,
                newContainer?.containerType === ContainerType.AncillaryProduct ? newContainer as ApMixingContainer : null,
            );
        }
    }

    isAnyMixingContainer(containers: IMixingContainer[]): boolean {
        return containers.some(x => x != null && x.containerType === ContainerType.Default);
    }

    isAnyAncillaryProductContainer(containers: IMixingContainer[]): boolean {
        return containers.some(x => x != null && x.containerType === ContainerType.AncillaryProduct);
    }
}
