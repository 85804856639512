import {AnalyticsRecipeFormulationDto} from "src/app/core/analytics/dto/common/analytics-recipe-formulation-dto";
import {AnalyticsRecipeDto} from "src/app/core/analytics/dto/common/analytics-recipe-dto";

export class CreateAnalyticsRecipeDto
{
    constructor(
        public recipe: AnalyticsRecipeDto,
        public formulations: AnalyticsRecipeFormulationDto[],
        public openedAt: Date,
        public closedAt?: Date) {
    }
}
