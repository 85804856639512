import { IDeserialize } from "../../shared/interfaces/deserialize";

export class Remark implements IDeserialize {
    caption: string;
    localizationPath: string;
    isLocalizable: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
