import { Theme } from "../core/theme/symbols";

export const dyoTheme: Theme =
{
    name: "dyo",
    caption: "DYO",
    palette: {
        foreground: '#505050',
        'foreground-light-5': '#545454',
        'foreground-light-10': '#585858',
        'foreground-light-15': '#5E5E5E',
        dyo: '#749B2E',
        'dyo-light-5': '#37BB9A',
        'dyo-light-10': '#3AC5A3',
        'dyo-light-15': '#3ED1AC',
        'dyo-light-20': '#42DEB7',
        'dyo-light-25': '#46EDC4',
        'dyo-dark-5': '#32A98B',
        'dyo-dark-10': '#2FA084',
        'dyo-dark-15': '#2D977C',
        'dyo-dark-20': '#2A8E75',
        'dyo-dark-25': '#27856E',
        'dark-gray': '#3e3e46',
        'dark-gray-light': '#585864',
        'light-gray': '#e1e2e4',
        'light-gray-2': '#cfd4dc',
        green: '#5bc27f',
        red: '#dd5068',
        yellow: '#efbf4b',
    },
    properties: {

        // General
        'global-foreground': 'var(--foreground)',
        'horizontal-splitter-color': 'var(--light-gray)',
        'link-foreground': 'var(--dyo)',

        // Header
        'header-background': '#ffffff',
        'header-item-foreground': 'var(--dyo)',
        'header-item-hover-background': 'var(--dyo)',
        'header-item-hover-foreground': '#ffffff',
        'header-menu-popup-shadow-color': '#d0d0d0',

        // Menus
        'dropdown-item-foreground': 'var(--foreground)',
        'dropdown-item-active-background': 'var(--dyo)',
        'dropdown-item-active-foreground': '#ffffff',
        'dropdown-item-hover-background': '#f1f1f2',
        'dropdown-item-hover-foreground': '#303030',

        // Input Focus
        'input-focus-foreground': 'var(--foreground)',
        'input-focus-background': '#ffffff',

        // Buttons
        'button-default-background': 'var(--dyo)',
        'button-default-foreground': '#ffffff',
        'button-default-border-color': 'var(--dyo)',
        'button-default-focus-shadow-color': 'argb(dyo, 0.3)',

        'button-cancel-background': 'var(--dark-gray-light)',
        'button-cancel-foreground': '#ffffff',
        'button-cancel-border-color': 'var(--dark-gray-light)',
        'button-cancel-focus-shadow-color': 'argb(dark-gray-light, 0.3)',

        'action-button-background': 'var(--dark-gray-light)',
        'action-button-foreground': '#ffffff',
        'action-button-disabled-background': 'var(--dark-gray-light)',
        'action-button-mixing-background': 'var(--dyo)',
        'action-button-ancillary-background': 'var(--dyo)',
        'action-button-active-background': 'var(--dyo)',

        'error-dialog-button-background': 'var(--dyo)',
        'error-dialog-button-foreground': '#ffffff',

        // Forms
        'filled-field-border-color': 'var(--dyo)',
        'field-required-background': 'var(--dyo)',
        'field-required-foreground': '#ffffff',

        'input-border-color': 'var(--light-gray)',
        'input-focus-border-color': 'argb(dyo, 0.75)',
        'input-focus-border-shadow-color': 'argb(dyo, 0.25)',
        'input-disabled-background-color': 'argb(light-gray, 0.3)',
        'input-select-disabled-arrow-color': 'argb(light-gray, 0.6)',
        'input-select-selected-item-background': 'var(--dyo)',
        'input-select-selected-item-foreground': '#ffffff',

        // Tooltips
        'tooltip-background': 'var(--dark-gray)',
        'tooltip-foreground': '#ffffff',
        'tooltip-error-background': 'var(--red)',

        // Retry
        //'retry-error-message-color': 'var(--red)',

        // App Error Component
        'app-error-title-color': 'var(--red)',
        'app-error-click-message-color': 'var(--dyo-dark-15)',

        // Datatable
        'datatable-header-background': 'var(--dyo)',
        'datatable-active-row-background': 'argb(dark-gray-light, 0.1)',
        'datatable-row-details-header-background': 'var(--dark-gray-light)',
        'datatable-row-expanded-background': 'argb(dyo, 0.1)',

        // Dialogs
        'dialog-header-background': 'var(--dyo)',
        'dialog-header-foreground': '#ffffff',
        'dialog-caption-foreground': 'var(--foreground-light-10)',

        // Loaders
        'dots-loader-dot-color': 'var(--dyo)',

        // Search
        'formulation-search-title-foreground': 'var(--dyo)',
        'formulation-search-title-border-color': 'var(--dyo)',

        // Search Form
        'btn-clear-foreground': '#ffffff',
        'btn-clear-background': 'var(--action-button-background);',
        'search-error-mesage-color': 'var(--red)',
        'mcs-type-background': 'var(--dyo)',
        'radio-button-color': 'var(--dyo)',

        // Search Results
        'expand-arrow-color': 'var(--light-gray-2)',
        'tile-selected-foreground': 'var(--dyo)',
        'tile-selected-border-color': 'var(--dyo)',
        'tiles-view-border-color': 'var(--light-gray)',
        'models-table-border-color': 'var(--dyo)',
        'colors-table-border-color': 'var(--dyo)',
        'colors-table-header-separator-color': 'var(--dyo)',
        'no-color-line-color': 'var(--light-gray)',
        'two-tone-image': 'url(/assets/images/two-tone-dyo.png)',

        // Quality Buttons
        'button-quality-red-background': 'var(--red)',
        'button-quality-yellow-background': 'var(--yellow)',
        'button-quality-green-background': 'var(--dyo)',
        'button-quality-rm-background': 'var(--dark-gray-light)',
        'button-quality-foreground': '#ffffff',

        // Formulation Data
        'formulation-data-note-background': 'var(--red)',

        // Mixing Action Buttons
        'mixing-action-button-print-background': 'var(--dyo)',
        'mixing-action-button-ancillary-product-background': 'var(--dark-gray-light)',

        // Mixing View
        'mixing-vertical-splitter-color': 'var(--dyo)',
        'mixing-vertical-splitter-header-color': 'var(--dark-gray-light)',
        'mixing-container-border-color': 'var(--dyo)',
        'mixing-button-formulation-search-background': 'var(--dyo)',
        'mixing-minimum-quantity-required-foreground': 'var(--green)',

        // Mixing Materials
        'mixing-materials-table-header-background': 'var(--dyo)',
        'mixing-materials-formulation-no-background': 'var(--dark-gray-light)',
        'mixing-materials-formulation-no-foreground': '#ffffff',
        'mixing-general-information-header-background': 'var(--dyo)',
        'mixing-general-information-header-foreground': '#ffffff',
        'mixing-coating-selector-background': 'var(--light-gray)',
        'mixing-coating-selector-foreground': 'var(--foreground)',
        'mixing-general-information-quality-button-background': 'var(--dyo)',
        'mixing-general-information-new-formulation-background': 'var(--dark-gray-light)',
        'mixing-general-information-new-formulation-foreground': '#ffffff',

        // Ancillary Product
        'ancillary-product-note-background': 'var(--dyo)',
        'ancillary-product-mix-button-background': 'var(--dyo)',

        // Authentication
        'authentication-background': 'var(--dyo)',
        'authentication-foreground': '#ffffff',
        'authentication-label-color': '#d6f8ef',
        'authentication-error-border-color': '#ff7898'
    }
};
