import { ModelRow } from "../../formulation/models/model-row";
import { ColorRow } from "../../formulation/models/color-row";

export class ContrastColorSearchCriteria {
    colorVariantId: number;
    mcsType: number;
    oc: number;
    bc: number;
    wbc: number;
    pur: number;
    purhs: number;

    static create(color: ColorRow, model: ModelRow, mcsType: number): ContrastColorSearchCriteria {
        const criteria = new ContrastColorSearchCriteria();

        criteria.mcsType = mcsType;
        criteria.colorVariantId = model.colorVariantId;
        criteria.oc = color.oc;
        criteria.bc = color.bc;
        criteria.pur = color.pur;
        criteria.wbc = color.wbc;
        criteria.purhs = color.purhs;

        return criteria;
    }
}
