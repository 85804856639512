import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { String } from "typescript-string-operations";
import { catchError } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { RequestInfo } from "../models/request-info";
import { NGXLogger } from "ngx-logger";
import { Endpoints } from "../../../shared/models/endpoints";
import { HttpService } from '../../http-service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {


    constructor(private router: Router,
        private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints) {

    }

    initialize(): void {
        this.router.events.subscribe(x => {
            if (x && x instanceof NavigationEnd && !String.IsNullOrWhiteSpace(x.urlAfterRedirects)) {
                this.saveRequestInfo(x.urlAfterRedirects);
            }
        });
    }

    public async saveRequestInfo(path: string): Promise<any> {
        const info = new RequestInfo(path);

        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        this.http.post(this.endpoints.analytics, JSON.stringify(info), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`Failed to save the request info for path '${path}'`, err);
                    return null;
                }),
            ).toPromise();
    }
}
