import { Component, Input, OnInit } from '@angular/core';
import { ColorCategory } from "../../../../../formulation/models/color-category";
import { FormField } from "../../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'color-group-field',
    templateUrl: './color-group.component.html',
    styles: []
})
export class ColorGroupComponent extends FormField<ColorCategory> implements OnInit {
    @Input() colorCategories: ColorCategory[];

    ngOnInit() {
    }
}
