import {Injectable} from "@angular/core";
import {AnalyticsOptions} from "src/app/core/analytics/models/analytics-options";
import {NGXLogger} from "ngx-logger";
import {AnalyticsAncillaryProductService} from "src/app/core/analytics/services/analytics-ancillary-product.service";
import {ApMixingContainer} from "src/app/formulation-mixing/models/mixing-data/ancillary-product/ap-mixing-container";
import {AnalyticsApMixingContainer} from "src/app/core/analytics/models/analytics-ap-mixing-container";
import {MixingContainer} from "src/app/formulation-mixing/models/mixing-data/default/mixing-container";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsApMixingContainerService
{
    containers: AnalyticsApMixingContainer[] = [];

    constructor(
        private analyticsOptions: AnalyticsOptions,
        private analyticsAncillaryProductService: AnalyticsAncillaryProductService,
        private logger: NGXLogger) {
    }

    async onContainerChanged(oldMixingContainer: ApMixingContainer, newMixingContainer: ApMixingContainer): Promise<void>
    {
        const oldAnalyticsContainer = this.findAnalyticsContainer(oldMixingContainer);
        const newAnalyticsContainer = this.findAnalyticsContainer(newMixingContainer);

        if (this.isSameContainer(oldAnalyticsContainer, newAnalyticsContainer))
        {
            await this.updateContainer(newAnalyticsContainer, newMixingContainer);
            return;
        }

        if (oldAnalyticsContainer != null)
        {
            await this.finalizeContainer(oldAnalyticsContainer);
        }

        if (newAnalyticsContainer != null)
        {
            this.continueTracking(newAnalyticsContainer);
        }
        else if (newMixingContainer != null)
        {
            this.createContainer(newMixingContainer);
        }
    }

    isSameContainer(oldAnalyticsContainer: AnalyticsApMixingContainer, newAnalyticsContainer: AnalyticsApMixingContainer): boolean
    {
        return oldAnalyticsContainer != null &&
               newAnalyticsContainer != null &&
               oldAnalyticsContainer.identifier === newAnalyticsContainer.identifier;
    }

    findAnalyticsContainer(container: ApMixingContainer): AnalyticsApMixingContainer
    {
        if (container == null)
        {
            return null;
        }

        return this.containers.find(x => x.componentId === container.selectedProduct.mainComponentId);
    }

    async updateContainer(analyticsContainer: AnalyticsApMixingContainer, apMixingContainer: ApMixingContainer): Promise<void>
    {
        if (apMixingContainer.isBackEvent)
        {
            this.logEvent(analyticsContainer, `Navigated to container from 'back' event, saving closed-at if required.`);
            await this.saveClosedAtIfRequired(analyticsContainer);
        }
        else
        {
            this.logEvent(analyticsContainer, `Container property changed, starting timer to update.`);
            analyticsContainer.onContainerUpdated(apMixingContainer);
        }
    }

    async finalizeContainer(analyticsContainer: AnalyticsApMixingContainer): Promise<void>
    {
        if (analyticsContainer.analyticsAncillaryProductId == null && !analyticsContainer.isModified)
        {
            analyticsContainer.stopTracking();
            this.logEvent(analyticsContainer, `Stopped tracking the time-on-view.`);
        }
        else
        {
            if (analyticsContainer.closedAt == null || analyticsContainer.isModified) {

                this.logEvent(analyticsContainer, `Setting the container closed-at date.`);

                analyticsContainer.stopTracking();
                analyticsContainer.closedAt = new Date();

                await this.saveAnalyticsContainer(analyticsContainer);
            }
        }

        this.logEvent(analyticsContainer, `Navigated away from container.`);
    }

    continueTracking(analyticsContainer: AnalyticsApMixingContainer): void
    {
        if(analyticsContainer.analyticsAncillaryProductId == null)
        {
            this.logEvent(analyticsContainer, `Repeat navigation to unsaved view, restarting time-on-view tracking.`);
            analyticsContainer.startTimeOnViewTracking();
        }
        else
        {
            this.logEvent(analyticsContainer, `Repeat navigation to already-saved view, ignoring.`);
        }
    }

    createContainer(mixingContainer: ApMixingContainer)
    {
        const analyticsContainer = new AnalyticsApMixingContainer(mixingContainer, this.analyticsOptions);

        this.logEvent(analyticsContainer, `Created new analytics-ap-mixing-container.`);

        analyticsContainer.timer.on('tick', (milliseconds) => {
            this.logEvent(analyticsContainer, `Saving analytics in ${milliseconds / 1000} seconds.`);
        });

        analyticsContainer.timer.on('done', async () => {
            await this.saveAnalyticsContainer(analyticsContainer);
        });

        this.containers.push(analyticsContainer);

        analyticsContainer.startTimeOnViewTracking();
    }

    async saveClosedAtIfRequired(analyticsContainer: AnalyticsApMixingContainer): Promise<void>
    {
        if (analyticsContainer.isModified || (analyticsContainer.closedAt == null && analyticsContainer.analyticsAncillaryProductId != null))
        {
            this.logEvent(analyticsContainer, `Saving the container closed-at date.`);

            analyticsContainer.stopTracking();
            analyticsContainer.closedAt = new Date();

            await this.saveAnalyticsContainer(analyticsContainer);
        }
        else if(analyticsContainer.isTrackerRunning) {
            analyticsContainer.stopTracking();
            this.logEvent(analyticsContainer, `Stopped tracking the time-on-view.`);
        }
    }

    async saveApMixingContainer(mixingContainer: ApMixingContainer): Promise<void>
    {
        const analyticsContainer = this.findAnalyticsContainer(mixingContainer);

        if (analyticsContainer)
        {
            analyticsContainer.stopTracking();
            await this.saveAnalyticsContainer(analyticsContainer);
        }
    }

    async saveAnalyticsContainer(analyticsContainer: AnalyticsApMixingContainer): Promise<void>
    {
        this.logEvent(analyticsContainer, `Saving the mixing-container analytics.`);

        await this.analyticsAncillaryProductService.saveAncillaryProduct(analyticsContainer);

        analyticsContainer.isModified = false;

        this.logEvent(analyticsContainer, `Completed saving the analytics.`);
    }

    async clear(): Promise<void>
    {
        for (const analyticsContainer of this.containers)
        {
            analyticsContainer.unsubscribeFromProductChanges();
            await this.saveClosedAtIfRequired(analyticsContainer);
        }

        this.containers = [];
    }

    logEvent(container: AnalyticsApMixingContainer, event: string): void
    {
        this.logger.debug(`[${container.identifier}] [AP] ${event}`);
    }
}
