import { NgModule } from '@angular/core';
import {SharedModule} from "src/app/shared/shared.module";
import {RequestPasswordResetComponent} from "src/app/reset-password/request-reset/components/request-password-reset.component";
import {ResetPasswordUsernameComponent} from "src/app/reset-password/request-reset/components/fields/username.component";

@NgModule({
    declarations: [
        RequestPasswordResetComponent,
        ResetPasswordUsernameComponent
    ],
    imports:
        [
            SharedModule
        ],
    exports: [
        RequestPasswordResetComponent
    ]
})

export class RequestPasswordResetModule {
}

