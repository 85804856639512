import {AfterViewInit, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from 'src/environments/environment';
import {IFormField} from "src/app/shared/components/base/forms/form-field-interface";
import {sections} from "src/app/shared/configuration/sections";
import {TranslateService} from "@ngx-translate/core";
import {ResetPasswordService} from "src/app/reset-password/perform-reset/services/reset-password.service";
import {TokenValidationState} from "src/app/reset-password/perform-reset/enums/token-validation-state";
import {CurrentPasswordComponent} from "src/app/reset-password/perform-reset/components/fields/current-password.component";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements AfterViewInit, OnInit {

    passwordMinLength = 8;

    @ViewChild('currentPassword', { static: true }) currentPassword: CurrentPasswordComponent;
    @ViewChild('newPassword', { static: true }) newPassword: CurrentPasswordComponent;
    @ViewChild('newPasswordConfirmation', { static: true }) newPasswordConfirmation: CurrentPasswordComponent;
    @ViewChild("logodiv", { static: true }) logoDiv: ElementRef;
    @ViewChildren('required') requiredFields: QueryList<IFormField>;

    token: string;

    hasRequirementsError: boolean;
    isProcessingError: boolean;
    processingError: string;
    isProcessing: boolean;
    isCompleted: boolean;
    currentPasswordRequired: boolean;
    passwordMinLengthError: boolean;
    passwordNotMaching: boolean;

    isTokenValid: boolean;
    isTokenValidationError: boolean;

    tokenState: TokenValidationState = TokenValidationState.none;
    tokenValidationState = TokenValidationState;

    constructor(private resetPasswordService: ResetPasswordService,
                private router: Router,
                private renderer: Renderer2,
                private translateService: TranslateService,
                private route: ActivatedRoute) {
        this.renderer.addClass(document.body, 'auth-body');
    }

    ngOnInit(): void {
        if (environment.theme.id === "mipa"){
            this.logoDiv.nativeElement.classList.add("mipa-logo");
        } else {
            this.logoDiv.nativeElement.classList.add("dyo-logo");
        }
        this.route.params.subscribe(x => {
            this.onTokenChanged(x.token);
        });

    }
    ngAfterViewInit(): void {
        this.registerRequiredFieldsEvents();
    }

    async onKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            await this.resetPassword();
        }
    }

    async resetPassword() {
        if (!this.validate()) { return; }
        try {
            this.isProcessing = true;

            const response = await this.resetPasswordService.resetPassword(this.token, this.currentPassword.value, this.newPassword.value);

            if (response.invalidToken) {
                this.tokenState = TokenValidationState.invalid;
            }
            else if (response.invalidPassword) {
                this.processingError = this.translateService.instant('ResetPassword.Error.InvalidPassword');
                this.isProcessingError = true;
            }
            else {
                this.isCompleted = true;
            }
        }
        catch (ex) {
            this.processingError = this.translateService.instant('General.Text.FailedToProcessRequest');
            this.isProcessingError = true;
        }
        finally {
            this.isProcessing = false;
        }
    }

    registerRequiredFieldsEvents(): void {
        this.requiredFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onRequiredFieldFilled(isfilled));
        });
    }

    onRequiredFieldFilled(isFilled: boolean) {
        if (this.hasRequirementsError && isFilled) {
            this.hasRequirementsError = false;
        }
    }

    async goToLogin(): Promise<void> {
        await this.router.navigate([`/${sections.login}`]);
    }

    async goToResetPassword(): Promise<void> {
        await this.router.navigate([`/${sections.requestPasswordReset}`]);
    }

    private validate(): boolean {
        this.clearErrors();

        return this.validateRequirements() &&
            this.validateCurrentPassword() &&
            this.validateNewPasswordLength() &&
            this.validatePasswordConfirmation();
    }

    private validateRequirements(): boolean {
        this.hasRequirementsError = this.requiredFields.some(x => !x.isFilled);

        return !this.hasRequirementsError;
    }

    private clearErrors(): void {
        this.hasRequirementsError = false;
        this.isProcessingError = false;
        this.processingError = null;
        this.currentPasswordRequired = false;
        this.passwordMinLengthError = false;
        this.passwordNotMaching = false;
    }

    private validateCurrentPassword(): boolean {
        this.currentPasswordRequired = !this.currentPassword.isFilled;
        return !this.currentPasswordRequired;
    }

    private validateNewPasswordLength() {
        this.passwordMinLengthError = !this.newPassword.isFilled || this.newPassword.value.length < this.passwordMinLength;
        return !this.passwordMinLengthError;
    }

    private validatePasswordConfirmation() {
        this.passwordNotMaching = !this.newPassword.isFilled || !this.newPasswordConfirmation.isFilled || this.newPassword.value !== this.newPasswordConfirmation.value;
        return !this.passwordNotMaching;
    }

    private async onTokenChanged(token: string): Promise<void> {
        this.token = token;

        try {
            this.tokenState = TokenValidationState.processing;

            const isTokenValid = await this.resetPasswordService.validateToken(token);

            if (isTokenValid) {
                this.tokenState = TokenValidationState.valid;
            }
            else {
                this.tokenState = TokenValidationState.invalid;
            }
        }
        catch (e) {
            this.tokenState = TokenValidationState.error;
        }
    }
}
