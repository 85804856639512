import { Directive, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({ selector: '[Auth]' })
export class AuthDirective implements OnDestroy, AfterViewInit {
    ngAfterViewInit() {
        document.querySelector('body').classList.add('auth-body');

    }
    ngOnDestroy(): void {
        document.querySelector('body').classList.remove('auth-body');
    }
}
