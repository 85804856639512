import { throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { catchError, map } from "rxjs/operators";
import { SearchFormData } from "../models/search-form-data";
import { Endpoints } from "../../shared/models/endpoints";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class SearchFormDataService {

    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints) {
    }

    async getSearchFormData(): Promise<SearchFormData> {
        return await this.http.get<SearchFormData>(this.endpoints.apiSearchFormData)
            .pipe(
                map(x => new SearchFormData().deserialize(x)),
                catchError(err => {
                    this.logger.error("Failed to retrieve the search form data.", err);
                    return throwError(err);
                }),
            ).toPromise();
    }
}
