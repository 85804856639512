import { NgModule } from '@angular/core';
import {SharedModule} from "src/app/shared/shared.module";
import {NewPasswordComponent} from "src/app/reset-password/perform-reset/components/fields/new-password.component";
import {NewPasswordConfirmComponent} from "src/app/reset-password/perform-reset/components/fields/new-password-confirm.component";
import {ResetPasswordComponent} from "src/app/reset-password/perform-reset/components/reset-password.component";
import {CurrentPasswordComponent} from "src/app/reset-password/perform-reset/components/fields/current-password.component";

@NgModule({
    declarations: [
        ResetPasswordComponent,
        CurrentPasswordComponent,
        NewPasswordComponent,
        NewPasswordConfirmComponent
    ],
    imports:
        [
            SharedModule
        ],
    exports: [
        ResetPasswordComponent
    ]
})

export class ResetPasswordModule {
}
