import { AfterViewInit, Component, EventEmitter, OnInit, OnDestroy, Output, QueryList, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { SearchResult } from "../../models/search-result";
import { ColorGroupComponent } from "./fields/color-group/color-group.component";
import { ColorNumberComponent } from "./fields/color-number/color-number.component";
import { SearchFormDataService } from "../../services/search-form-data.service";
import { FormulationNumberComponent } from "./fields/formulation-number/formulation-number.component";
import { SearchCriteria } from "../../models/search-criteria";
import { IFormField } from "../../../shared/components/base/forms/form-field-interface";
import { YearOfBuildComponent } from "./fields/year-of-build/year-of-build.component";
import { ColorNameComponent } from "./fields/color-name/color-name.component";
import { ModelComponent } from "./fields/model/model.component";
import { SearchService } from "../../services/search-service";
import { Manufacturer } from "../../../formulation/models/manufacturer";
import { Mcs2Component } from "./fields/mcs2/mcs2.component";
import { ColorCategory } from "../../../formulation/models/color-category";
import { ManufacturerComponent } from "./fields/manufacturer/manufacturer.component";
import { Mcs3Component } from "./fields/mcs3/mcs3.component";
import { McsSelectComponent } from "./fields/mcs-select/mcs-select.component";
import { Region } from "../../../formulation/models/region";
import { RegionComponent } from "./fields/region/region.component";
import { SearchSettings } from "../../models/search-settings";
import { TranslateService } from "@ngx-translate/core";
import { IsPadViewPipe } from "../../../shared/pipes/is-pad-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import {AnalyticsOptions} from "src/app/core/analytics/models/analytics-options";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() resultsUpdated = new EventEmitter<SearchResult>();
    @Output() searching = new EventEmitter<boolean>();
    @ViewChild('manufacturer', { static: true }) manufacturer: ManufacturerComponent;
    @ViewChild('model') model: ModelComponent;
    @ViewChild('mcs2') mcs2: Mcs2Component;
    @ViewChild('mcs3') mcs3: Mcs3Component;
    @ViewChild('yearOfBuild', { static: true }) yearOfBuild: YearOfBuildComponent;
    @ViewChild('formulationNumber') formulationNumber: FormulationNumberComponent;
    @ViewChild('colorGroup', { static: true }) colorGroup: ColorGroupComponent;
    @ViewChild('colorNumber', { static: true }) colorNumber: ColorNumberComponent;
    @ViewChild('colorName', { static: true }) colorName: ColorNameComponent;
    @ViewChild('mcsSelect', { static: true }) mcsSelect: McsSelectComponent;
    @ViewChild('region', { static: true }) region: RegionComponent;
    @ViewChild('searchButton') searchButton: ElementRef;
    @ViewChildren('field') fields: QueryList<IFormField>;
    @ViewChildren('required') requiredFields: QueryList<IFormField>;
    @ViewChildren('dependency') dependencyFields: QueryList<IFormField>;

    isPadView: boolean;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isCollapsed: boolean;
    isLoaded: boolean;
    isSearching: boolean;
    isSearchThrottled: boolean;
    hasRequirementsError: boolean;
    hasDependenciesError: boolean;
    hasFieldErrors: boolean;
    isFormDataError: boolean;
    isSearchError: boolean;
    manufacturers: Manufacturer[];
    colorCategories: ColorCategory[];
    regions: Region[];
    mcsItems: string[] = ["", "B", "O", "SB", "SO"];

    constructor(
        private searchFormDataService: SearchFormDataService,
        private searchService: SearchService,
        private searchSettings: SearchSettings,
        private translate: TranslateService,
        private padViewPipe: IsPadViewPipe,
        private analyticsOptions: AnalyticsOptions) { }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isPadView = this.padViewPipe.transform(window);
        });
        this.isPadView = this.padViewPipe.transform(window);
        this.loadRequiredData();
        this.translate.onLangChange.subscribe(() => this.setLocalizedColorCategoryNames());
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    retryLoadRequiredData(): void {
        this.isFormDataError = false;
        this.isLoaded = false;
        this.loadRequiredData();
    }

    async loadRequiredData(): Promise<any> {
        try {
            const data = await this.searchFormDataService.getSearchFormData();
            if (data == null) {
                return;
            }

            this.manufacturers = data.manufacturers;
            this.colorCategories = data.colorCategories;
            this.colorCategories.forEach(category => category.name = this.translate.instant(`ColorCategory.Id.${category.id}`));
            this.regions = data.regions;
            this.isLoaded = true;
        } catch {
            this.isFormDataError = !this.isLoaded;
        }
    }

    ngAfterViewInit(): void {
        this.registerManufacturerChangedEvent();
        this.registerRequiredFieldsEvents();
        this.registerDependencyFieldsEvents();
    }

    registerManufacturerChangedEvent(): void {
        this.manufacturer.onChanged.subscribe(manufacturer => {
            this.model.clear();
            this.model.isEnabled = manufacturer != null && manufacturer.models != null && manufacturer.models.length !== 0;
        });
    }

    registerRequiredFieldsEvents(): void {
        this.requiredFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onRequiredFieldFilled(isfilled));
        });
    }

    registerDependencyFieldsEvents(): void {
        this.dependencyFields.forEach(x => {
            x.onFilled.subscribe(isfilled => this.onDependencyFieldFille(isfilled));
        });
    }

    onRequiredFieldFilled(isFilled: boolean) {
        if (this.hasRequirementsError && isFilled) {
            this.hasRequirementsError = false;
        }
    }

    onDependencyFieldFille(isFilled: boolean) {
        if (this.hasDependenciesError && isFilled) {
            this.hasDependenciesError = false;
        }
    }

    async search(): Promise<any> {
        if (!this.validate()) {
            return;
        }

        try {
            this.onSearchStarting();
            const criteria = this.getSearchCriteria();
            const response = await this.trySearch(criteria);
            this.searchSettings.mcsType = this.mcsSelect.value ? 2 : 3;
            this.resultsUpdated.emit(response);
        } catch {
            this.isSearchError = true;
        } finally {
            this.onSearchEnding();
        }
    }

    async trySearch(criteria: SearchCriteria): Promise<SearchResult>
    {
        const tooManyRequestsStatus = 429;
        const tries = 3;
        const delayFactor = 2000;

        for(let i = 0; i < tries; i++) {

            if(i > 0) {
                await new Promise(resolve => setTimeout(resolve, i * delayFactor));
            }

            try {
                return await this.searchService.getSearchResult(criteria);
            }
            catch (e)
            {
                if(!(e instanceof HttpErrorResponse) || e.status !== tooManyRequestsStatus) {
                    throw e;
                }
            }
        }

        throw new Error(`Failed to perform the search request after ${tries} tries.`);
    }

    onSearchStarting(): void {
        this.isSearchError = false;
        this.isSearching = true;
        this.searching.emit(true);
    }

    onSearchEnding(): void {
        this.startSearchThrottle();
        this.isSearching = false;
        if (!this.isSearchError) {
            this.isCollapsed = true;
        }

        this.searching.emit(false);
    }

    startSearchThrottle(): void
    {
        this.isSearchThrottled = true;

        const throttleFor = this.analyticsOptions.searchThrottleSeconds * 1000;

        setTimeout(() =>
        {
            this.isSearchThrottled = false;
        },
        throttleFor + 250);
    }

    clearForm(): void {
        this.resultsUpdated.emit(null);
        this.isSearchError = false;
        this.fields.forEach(x => x.clear());
        this.clearErrors();
        this.validateFields();
    }

    onSearchFormToggle(isVisible: boolean) {
        this.isCollapsed = !isVisible;
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.searchButton.nativeElement.focus(); // necessary because inputs like the color number only apply their mask (and consequently the wildcard suffix) on losing focus
            this.search();
        }
    }

    setLocalizedColorCategoryNames() {
        this.loadRequiredData();
    }

    private clearErrors(): void {
        this.hasRequirementsError = false;
        this.hasDependenciesError = false;
        this.hasFieldErrors = false;
    }

    private validate(): boolean {
        this.clearErrors();
        if (!this.validateRequirements()) {
            return false;
        }

        if(!this.validateIfAtLeastOneParameterIsSet()){
            return false;
        }

        if (!this.validateDependencies()) {
            return false;
        }

        return this.validateFields();
    }

    private validateIfAtLeastOneParameterIsSet(): boolean {

        for(const field of this.fields){
            switch(true){
                case field instanceof ColorNumberComponent:
                    if(this.validateValidInput(field)){
                        return true;
                    }
                    break;
                case field instanceof ColorNameComponent:
                    if(this.validateValidInput(field)){
                        return true;
                    }
                    break;
                case field instanceof FormulationNumberComponent:
                    if(this.validateValidInput(field)){
                        return true;
                    }
                    break;
                default:
                    if(field.isFilled){
                        return true;
                    }
            }
        }
        this.hasRequirementsError = true;
        return false;
    }

    private validateValidInput(field: any): boolean{
        if(!field.isFilled){
            return false;
        }
        const trimedFieldValue =(field.value as string).trim();
        if(trimedFieldValue !== '*' && trimedFieldValue !== ''){
            return true;
        }
        return false;
    }

    private validateFields(): boolean {
        const fieldsWithError = this.fields.filter(x => !x.validate());
        this.hasFieldErrors = fieldsWithError.length !== 0;
        return !this.hasFieldErrors;
    }

    private validateRequirements(): boolean {
        this.hasRequirementsError = !this.requiredFields.some(x => x.isFilled);
        return !this.hasRequirementsError;
    }

    private validateDependencies(): boolean {
        this.hasDependenciesError = (this.yearOfBuild.isFilled || this.colorGroup.isFilled) && !this.dependencyFields.some(x => x.isFilled);
        return !this.hasDependenciesError;
    }

    private getSearchCriteria(): SearchCriteria {
        const criteria = new SearchCriteria();
        criteria.regionId = this.region.value.id;

        if (this.manufacturer.isFilled) {
            criteria.manufacturerId = this.manufacturer.value.id;
            criteria.manufacturerGroupId = this.manufacturer.value.manufacturerGroupId;
        }

        if (this.model.isFilled) {
            criteria.modelId = this.model.value.id;
        }

        if (this.colorNumber.isFilled) {
            criteria.colorNumber = this.colorNumber.value.trim();
        }

        if (this.colorName.isFilled) {
            criteria.colorName = this.colorName.value.trim();
        }

        if (this.formulationNumber.isFilled) {
            criteria.formulationNumber = this.formulationNumber.value.trim();
        }

        if (this.yearOfBuild.isFilled) {
            criteria.yearOfBuild = this.yearOfBuild.value.trim();
        }

        if (this.colorGroup.isFilled) {
            criteria.colorGroupId = this.colorGroup.value.id;
        }

        if (this.mcsSelect.value) {
            criteria.mcsType = 2;
            if (this.mcs2.isFilled) {
                criteria.mcs2 = this.mcs2.value;
            }
        } else {
            criteria.mcsType = 3;
            if (this.mcs3.isFilled) {
                criteria.mcs3 = this.mcs3.value;
            }
        }

        return criteria;
    }
}
