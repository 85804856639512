import { combineLatest, merge, Observable, timer } from "rxjs";
import { distinctUntilChanged, mapTo, startWith, takeUntil } from "rxjs/operators";

/**
 * Creates an observable that emits a loading status based on the following conditions:
 * - emits ```false``` until 1 second passed (no loading indicator)
 * - emits ```true``` until the data observable emits a value, but at earliest after 2 seconds
 * (therefore the loading indicator is displayed at least for 1 second and does not appear as a flicker)
 *
 * @param data The observable that delivers data (e.g. the result of an HTTP request)
 * @returns An observable that emits ```true``` or ```false```
 */
export const getLoadingStatus = (data: Observable<any[]>) => merge(
    // "true" if the request takes longer than 1 second
    timer(1000).pipe(mapTo(true), takeUntil(data)),
    // "false" when the response is here, but earliest in 2 seconds
    // => when the loading indicator is shown, it is shown at least 1 second so that it isn't perceived as a flicker
    combineLatest([data, timer(2000)]).pipe(mapTo(false))
).pipe(
    startWith(false),
    distinctUntilChanged()
);
