import { Component, Input, OnInit } from '@angular/core';
import { ManufacturerModel } from "../../../../../formulation/models/manufacturer-model";
import { FormField } from "../../../../../shared/components/base/forms/form-field";

@Component({
    selector: 'model-field',
    templateUrl: './model.component.html',
    styles: []
})
export class ModelComponent extends FormField<ManufacturerModel> implements OnInit {

    @Input() models: ManufacturerModel[];

    isEnabled: boolean;

    ngOnInit() {
    }
}
