import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from './app.component';
import { AppInitializer } from "./core/app-initializer/app-initializer";
import { SearchModule } from "./formulation-search/search.module";
import { MixingModule } from "./formulation-mixing/mixing.module";
import { FormulationDataModule } from "./formulation-data/formulation-data.module";
import { AncillaryProductModule } from "./formulation-ancillary-product/ancillary-product.module";
import { FormulationPrintingModule } from "./formulation-printing/formulation-printing.module";
import { AuthenticationModule } from "./authentication/authentication.module";
import { AnalyticsService } from "./core/analytics/services/analytics.service";
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { RegisterModule } from "./register/register.module";
import { UserManagementModule } from "./customers/user-management.module";
import { HttpService } from './core/http-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {RequestPasswordResetModule} from "src/app/reset-password/request-reset/request-password-reset.module";
import {ResetPasswordModule} from "src/app/reset-password/perform-reset/reset-password.module";

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        // Core
        CoreModule,
        SharedModule,

        // Authentication
        AuthenticationModule,
        RegisterModule,
        RequestPasswordResetModule,
        ResetPasswordModule,

        // User management
        UserManagementModule,

        // Features
        FormulationPrintingModule,
        SearchModule,
        MixingModule,
        FormulationDataModule,
        AncillaryProductModule,

        // // Cookie Consent
        //NgcCookieConsentModule.forRoot(CookieService.fallbackCookieConsentConfig),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoaderFactory,
              deps: [HttpClient]
            }
          })
    ],
    providers: [
        AppInitializer,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApplication,
            deps: [AppInitializer, HttpService, NgcCookieConsentConfig],
            multi: true
        }],
    bootstrap: [AppComponent],
    entryComponents: []
})

export class AppModule {
    constructor(private readonly analytics: AnalyticsService) {
        analytics.initialize();
    }
}

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function initializeApplication(initializer: AppInitializer, http: HttpService, config: NgcCookieConsentConfig) {
    return async () => await initializer.initializeApplication(config);
}

