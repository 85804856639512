<div id="formulation-search">
    <form (keydown)="onKeyDown($event)" #form="ngForm" novalidate [hidden]="!isLoaded">
        <div class="row row-1">
            <div class="col-md-6">
                <mcs-select-field #mcsSelect></mcs-select-field>
            </div>
            <div class="col-md-6">
                <region-field [regions]="regions" #region></region-field>
            </div>
        </div>

        <div class="row row-2">
            <div class="col-md-6">
                <manufacturer-field [showAsterisk]="hasRequirementsError || hasDependenciesError"
                    [manufacturers]="manufacturers" #manufacturer #field #required #dependency>
                </manufacturer-field>
            </div>
            <div class="col-md-3">
                <color-number-field [showAsterisk]="hasRequirementsError || hasDependenciesError" #colorNumber #field
                    #required #dependency>
                </color-number-field>
            </div>
            <div class="col-md-3">
                <color-name-field [showAsterisk]="hasRequirementsError || hasDependenciesError" #colorName #field
                    #required #dependency>
                </color-name-field>
            </div>
        </div>

        <div class="row row-3" *ngIf="!isPadView">
            <div class="col-md-3">
                <model-field [models]="manufacturer?.value?.models" #model #field>
                </model-field>
            </div>
            <div class="col-md-3">
                <mcs2-field [mcsItems]="mcsItems" [showAsterisk]="hasRequirementsError" [isEnabled]="mcsSelect.value"
                    [hidden]="!mcsSelect.value" #mcs2 #field #required></mcs2-field>
                <mcs3-field [mcsItems]="mcsItems" [showAsterisk]="hasRequirementsError" [isEnabled]="!mcsSelect.value"
                    [hidden]="mcsSelect.value" #mcs3 #field #required></mcs3-field>
            </div>
            <div class="col-md-6">
                <formulation-number-field [showAsterisk]="hasRequirementsError || hasDependenciesError"
                    #formulationNumber #field #required #dependency>
                </formulation-number-field>
            </div>
        </div>

        <div class="row row-3" *ngIf="isPadView">
            <div class="col-md-2">
                <model-field [models]="manufacturer?.value?.models" #model #field>
                </model-field>
            </div>
            <div class="col-md-4">
                <mcs2-field [mcsItems]="mcsItems" [showAsterisk]="hasRequirementsError" [isEnabled]="mcsSelect.value"
                    [hidden]="!mcsSelect.value" #mcs2 #field #required></mcs2-field>
                <mcs3-field [mcsItems]="mcsItems" [showAsterisk]="hasRequirementsError" [isEnabled]="!mcsSelect.value"
                    [hidden]="mcsSelect.value" #mcs3 #field #required></mcs3-field>
            </div>
            <div class="col-md-6">
                <formulation-number-field [showAsterisk]="hasRequirementsError || hasDependenciesError"
                    #formulationNumber #field #required #dependency>
                </formulation-number-field>
            </div>
        </div>

        <div class="row row-4">
            <div class="col-md-3">
                <year-of-build-field #yearOfBuild #field></year-of-build-field>
            </div>
            <div class="col-md-3">
                <color-group-field [colorCategories]="colorCategories" #colorGroup #field></color-group-field>
            </div>
        </div>

        <div class="flex-row">
            <p *ngIf="hasRequirementsError" class="error-message"
                [innerHTML]="'FormulationSearch.Validation.ValidateRequirements' | translate"></p>
            <p *ngIf="hasDependenciesError" class="error-message"
                [innerHTML]="'FormulationSearch.Validation.ValidateDependencies' | translate"></p>
            <p *ngIf="isSearchError" class="error-message">{{'FormulationSearch.Text.SearchError' | translate}}</p>
        </div>

        <div class="flex-row d-flex" *ngIf="!isSearching">
            <button [disabled]="isSearchThrottled" type="button" (click)="search()" class="btn btn-default btn-search" #searchButton>
                {{ 'FormulationSearch.Button.Search' | translate }}
            </button>
            <button type="button" (click)="clearForm()" class="btn btn-cancel">
                {{ 'FormulationSearch.Button.Clear' | translate }}
            </button>
        </div>
    </form>

    <div *ngIf="!isLoaded && !isFormDataError" class="search-form-data-loader">
        <dots-loader caption="{{'FormulationSearch.Text.LoadingSearchFormData' | translate}}"></dots-loader>
    </div>

    <dots-loader class="search-loader" *ngIf="isSearching"
        caption="{{'FormulationSearch.Text.PleaseWaitSearching' | translate}}"></dots-loader>

    <div class="data-error" *ngIf="isFormDataError">
        <p>{{'FormulationSearch.Text.SearchFormDataError' | translate}}</p>
        <a class="click-message" href="javascript:void(0)" (click)="retryLoadRequiredData()">
            {{'General.Text.ClickToRetry' | translate}}
        </a>
    </div>
</div>
