import { IDeserialize } from "../../shared/interfaces/deserialize";

export class Unit implements IDeserialize {
    id: number;
    name: string;
    remark: string;
    conversionFactor: number;
    isVolume: boolean;
    isImperialUnit: boolean;

    deserialize(input: any) {
        Object.assign(<any>this, input);
        return this;
    }
}
