import { RelationContainer } from "./relation-container";
import { AncillaryProduct } from "./ancillary-product";
import { IDeserialize } from "../../shared/interfaces/deserialize";

export class AncillaryProductContainer implements IDeserialize {
    ancillaryProduct: AncillaryProduct;
    mainComponentId: number;
    mainComponentShortcut: string;
    mainComponentVoc: number;
    mainComponentDensity: number;
    mainComponentRgb: number;
    glossLevelId: number;
    glossLevelShortcut: string;
    glossDensity: number;
    glossRelation: number;
    glossVoc: number;
    glossRgb: number;
    hardenerComponentId: number;
    hardenerDensity: number;
    hardenerShortcut: string;
    hardenerRelation: number;
    hardenerRelation2: number;
    hardenerRelation3: number;
    hardenerVoc: number;
    hardenerRgb: number;
    thinnerComponent1Id: number;
    thinner1Density: number;
    thinnerComponent2Id: number;
    thinner2Density: number;
    thinner1Shortcut: string;
    thinner2Shortcut: string;
    selectedThinnerRelation1: RelationContainer;
    selectedThinnerRelation2: RelationContainer;
    thinnerRelation11: number;
    thinnerRelation12: number;
    thinnerRelation13: number;
    thinnerRelation21: number;
    thinnerRelation22: number;
    thinnerRelation23: number;
    thinner1Voc: number;
    thinner2Voc: number;
    thinner1Rgb: number;
    thinner2Rgb: number;
    ancillaryProductName: string;

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        this.ancillaryProduct = this.ancillaryProduct ? new AncillaryProduct().deserialize(this.ancillaryProduct) : null;
        this.selectedThinnerRelation1 = this.selectedThinnerRelation1 ? new RelationContainer().deserialize(this.selectedThinnerRelation1) : null;
        this.selectedThinnerRelation2 = this.selectedThinnerRelation2 ? new RelationContainer().deserialize(this.selectedThinnerRelation2) : null;

        return this;
    }
}
