import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { SearchResult } from "../models/search-result";
import { SearchCriteria } from "../models/search-criteria";
import { Endpoints } from "../../shared/models/endpoints";
import { TranslateService } from "@ngx-translate/core";
import { HttpService } from 'src/app/core/http-service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {


    constructor(private http: HttpService, private logger: NGXLogger, private endpoints: Endpoints, private translate: TranslateService) { }

    async getSearchResult(criteria: SearchCriteria): Promise<SearchResult> {
        const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

        return await this.http.post<SearchResult>(this.endpoints.apiSearch, JSON.stringify(criteria), { headers })
            .pipe(map(x => new SearchResult(this.translate).deserialize(x)), catchError(err => {
                this.logger.error("Formulation search error", err);
                return throwError(err);
            }))
            .toPromise();
    }
}
