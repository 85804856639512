import { IDeserialize } from "../../shared/interfaces/deserialize";

export class ContrastColorRow implements IDeserialize {
    id: number;
    colorVariantId: number;
    colorVariantIdToContrastColor: number;
    colorCode: string;
    colorName: string;
    contrastType: number;
    colorId: number;
    qualityNo: number;
    mcs: string;
    mcs2: string;
    mcs3: string;
    fullMcs3: string;
    compartmentNo: string;

    get typeString() {
        if (!this.contrastType || typeof this.contrastType !== 'number') {
            return null;
        }

        switch (this.contrastType) {
            case 1: return "CC";
            default: return "INT";
        }
    }

    deserialize(input: any) {
        Object.assign(this as any, input);
        return this;
    }
}
