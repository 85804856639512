import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[NumbersOnly]'
})
export class NumbersOnlyDirective {
    private decimalCounter = 0;
    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @Input() allowDecimals = false;
    @Input() decimalSeparator = '.';
    @Input() decimalSeparatorAlt = ',';
    //@Input() decimalSeparatorA2 = ',';

    inputElement: HTMLElement;

    constructor(public el: ElementRef) {
        this.inputElement = el.nativeElement;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (
            this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
            (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
            (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
            (e.key === 'x' && e.metaKey === true) || // Allow: Cmd+X (Mac)
            (this.allowDecimals && (e.key === this.decimalSeparator || e.key === this.decimalSeparatorAlt) && this.decimalCounter < 1) // Allow: only one decimal point
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault();
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(e: KeyboardEvent) {
        if (!this.allowDecimals) {
            return;
        } else {

            const separators = [`${this.decimalSeparator}`, `${this.decimalSeparatorAlt}`];
            //this.decimalCounter = this.el.nativeElement.value.split(this.decimalSeparator).length - 1;
            this.decimalCounter = this.el.nativeElement.value.split(new RegExp('[' + separators.join('') + ']', 'g')).length - 1;
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput: string = event.clipboardData.getData('text/plain');

        if (!this.allowDecimals) {
            document.execCommand(
                'insertText',
                false,
                pastedInput.replace(/[^0-9]/g, '')
            );
        } else if (this.isValidDecimal(pastedInput)) {
            document.execCommand(
                'insertText',
                false,
                pastedInput.replace(`/[^0-9${this.decimalSeparator}${this.decimalSeparatorAlt}]/g`, '')
            );
        }
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        const textData = event.dataTransfer.getData('text');
        this.inputElement.focus();

        if (!this.allowDecimals) {
            document.execCommand(
                'insertText',
                false,
                textData.replace(/[^0-9]/g, '')
            );
        } else if (this.isValidDecimal(textData)) {
            document.execCommand(
                'insertText',
                false,
                textData.replace(`/[^0-9${this.decimalSeparator}${this.decimalSeparatorAlt}]/g`, '')
            );
        }
    }

    isValidDecimal(string: string): boolean {
        const separators = [`${this.decimalSeparator}`, `${this.decimalSeparatorAlt}`];
        //return string.split(this.decimalSeparator).length <= 2;
        return string.split(new RegExp('[' + separators.join('') + ']', 'g')).length <= 2;
    }
}
