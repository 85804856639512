import { MixingInfo } from "./mixing-info";
import { MixingComponentsContainer } from "./mixing-components-container";
import { IDeserialize } from "../../../../shared/interfaces/deserialize";
import { FormulationDataCriteria } from "../../../../formulation-data/models/formulation-data-criteria";
import { IMixingContainer } from "../../../interfaces/i-mixing-container";
import { ContainerType } from "../../../enums/container-type";
import { MixingCriteria } from "../../criteria/default/mixing-criteria";
import { Remark } from "../../remark";
import { EventEmitter } from "@angular/core";
import { FormulationHistoryItem } from "../../../../formulation-data/models/formulation-history-item";
import { ApMixingMaterialsComponent } from '../../../components/mixing-materials/ancillary-product/ap-mixing-materials.component';
import { MixingInformationComponent } from '../../../components/mixing-information/default/mixing-information.component';

export class MixingContainer implements IMixingContainer, IDeserialize {
    private _selectedComponentsContainer: MixingComponentsContainer;

    selectedComponentsContainerChanged = new EventEmitter<MixingComponentsContainer>();

    formulationDataCriteria: FormulationDataCriteria;
    mixingCriteria: MixingCriteria;

    containerType: ContainerType;
    isAncillaryProductMixEnabled: boolean;

    tabName: string;
    isBackEvent: boolean;
    childApMaterials: ApMixingMaterialsComponent;
    childInformation: MixingInformationComponent;

    formulationNo: string;
    remarks: Remark[];
    containers: MixingComponentsContainer[];
    info: MixingInfo;
    selectedCoating: number;
    coatings: number[];
    seenCoatings: number[];
    formulationHistoryItems: FormulationHistoryItem[];

    get selectedComponentsContainer(): MixingComponentsContainer {
        return this._selectedComponentsContainer;
    }

    set selectedComponentsContainer(value: MixingComponentsContainer) {
        this._selectedComponentsContainer = value;

        this.selectedComponentsContainerChanged.emit(value);
    }

    constructor() {
        this.isAncillaryProductMixEnabled = true;
        this.containerType = ContainerType.Default;
    }

    deserialize(input: any): this {
        Object.assign(<any>this, input);

        if (this.info) {
            this.info = new MixingInfo().deserialize(this.info);
        }

        if (this.containers) {
            this.containers = this.containers.map(x => new MixingComponentsContainer().deserialize(x));
        }
        if (this.remarks) {
            this.remarks = this.remarks.map(x => new Remark().deserialize(x));
        }

        if (this.formulationHistoryItems) {
            this.formulationHistoryItems = this.formulationHistoryItems.map(x => new FormulationHistoryItem().deserialize(x));
        }

        return this;
    }
}
