import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {HttpService} from "src/app/core/http-service";
import {NGXLogger} from "ngx-logger";
import {Endpoints} from "src/app/shared/models/endpoints";
import {httpJsonHeaders} from "src/app/core/utils/http-utils";
import {ResetPasswordDto} from "src/app/reset-password/perform-reset/dtos/reset-password-dto";
import ResetPasswordResponseDto from "src/app/reset-password/perform-reset/dtos/reset-password-response-dto";

@Injectable({providedIn: 'root'})
export class ResetPasswordService
{
    constructor(
        private http: HttpService,
        private logger: NGXLogger,
        private endpoints: Endpoints
    ) {
    }

    public async validateToken(token: string): Promise<boolean>
    {
        const dto = new ResetPasswordDto({token, validateOnly: true});
        const headers = httpJsonHeaders();

        const response = await this.http.post<ResetPasswordResponseDto>(this.endpoints.apiResetPassword, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while validating the token.`, err);
                    return throwError(err);
                }),
            )
            .toPromise<ResetPasswordResponseDto>();

        return !response.invalidToken;
    }

    public async resetPassword(token: string, currentPassword: string, newPassword: string): Promise<ResetPasswordResponseDto>
    {
        const dto = new ResetPasswordDto({token, currentPassword, newPassword});
        const headers = httpJsonHeaders();

        return await this.http.post<ResetPasswordResponseDto>(this.endpoints.apiResetPassword, JSON.stringify(dto), { headers })
            .pipe(
                catchError(err => {
                    this.logger.error(`An error has occurred while resetting the password.`, err);
                    return throwError(err);
                }),
            )
            .toPromise<ResetPasswordResponseDto>();
    }
}
