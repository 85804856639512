import { Component, Input, OnInit } from '@angular/core';
import { FormField } from "../../../../shared/components/base/forms/form-field";
import { Quality } from "../../../../formulation/models/quality";
import { CoatingsDataService } from "../../../services/coatings-data.service";
import { CoatingSearchCriteria } from "../../../models/coating-search-criteria";
import { ColorRow } from "../../../../formulation/models/color-row";
import { String } from "typescript-string-operations";

@Component({
    selector: 'formulation-data-coating',
    templateUrl: './coating.component.html',
    styles: []
})
export class FormulationDataCoatingComponent extends FormField<number> implements OnInit {

    isDataLoadError: boolean;
    isLoaded: boolean;

    coatings: number[];

    _quality: Quality;
    _color: ColorRow;

    get quality() {
        return this._quality;
    }

    @Input()
    set quality(value) {
        if (value == this._quality) { return; }

        this._quality = value;

        this.refresh();
    }

    get color() {
        return this._color;
    }

    @Input()
    set color(value) {
        if (value == this._color) { return; }

        this._color = value;

        this.refresh();
    }

    constructor(private coatingsDataService: CoatingsDataService) {
        super();
    }

    ngOnInit() {
    }

    refresh() {
        if (this.color && this.quality && this.quality.hasCoatings) {
            this.loadCoatings();
        }
        else {
            this.onNoCoatings();
        }
    }

    async loadCoatings() {
        try {
            this.onLoadingData();

            const criteria = this.getCoatingSearchCriteria();

            this.coatings = await this.coatingsDataService.getCoatings(criteria);

            this.value = this.coatings && this.coatings.length > 0 ? this.coatings[0] : null;

            this.onLoadingDataCompleted(true);
        }
        catch
        {
            this.onLoadingDataCompleted(false);
        }
    }

    onNoCoatings() {
        this.coatings = null;
        this.value = null;
        this.isVisible = false;
    }

    getCoatingSearchCriteria(): CoatingSearchCriteria {
        if (!String.IsNullOrWhiteSpace(this.color.formulationNo)) {
            return CoatingSearchCriteria.fromFormulationNo(this.color.formulationNo);
        }
        else {
            return CoatingSearchCriteria.fromQualityNo(this.quality.qualityNo, this.color.colorId);
        }
    }

    onLoadingData() {
        this.coatings = null;
        this.value = null;
        this.isDataLoadError = false;
        this.isLoaded = false;
    }

    onLoadingDataCompleted(success: boolean) {
        if (success) {
            this.isLoaded = true;
            this.isVisible = this.coatings && this.coatings.length > 1;
        }
        else {
            this.isDataLoadError = true;
        }
    }
}
