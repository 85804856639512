import { CanActivate, Router } from "@angular/router";
import { sections } from "../../shared/configuration/sections";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../../authentication/services/authentication.service";

import { JwtHelper } from "../jwt/jwt-helper";
import { AuthState } from "../../authentication/models/auth-state";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private jwtHelper: JwtHelper,
        private router: Router,
        private authenticationService: AuthenticationService,
        private authState: AuthState) {
    }

    async canActivate() {
        const token = this.authenticationService.getAccessToken();

        if (token != null) {
            if (!this.jwtHelper.isTokenExpired(token)) {
                this.authState.isAuthenticated = true;

                return true;
            }
            else {
                if (await this.authenticationService.isAuthenticated()) {
                    return true;
                }
            }
        }

        this.authenticationService.clearTokens();

        this.router.navigate([`/${sections.login}`]);

        return false;
    }
}
