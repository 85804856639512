import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { Settings } from "../../shared/models/settings";

const LOCAL_STORAGE_KEY = "userSettings";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    public settings: Settings;

    constructor() { }

    loadSettings() {
        const item = window.localStorage.getItem(LOCAL_STORAGE_KEY);

        this.settings = item != null ? JSON.parse(item) : this.generateDefaultSettings();
    }

    generateDefaultSettings(): Settings {
        const settings = new Settings();

        settings.language = environment.defaultLanguage;

        return settings;
    }

    saveSettings(): void {
        const json = JSON.stringify(this.settings);

        window.localStorage.setItem(LOCAL_STORAGE_KEY, json);
    }
}
