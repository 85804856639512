/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class CryptoService {

    constructor() { }

    encrypt(msg: string) {
        // will have this at C# as well.
        const encryptionKey = "SDAFaeojdfga723ma6sd4tPadI345dsfea5312lz7p63qe3a345215633";

        const keySize = 256;
        // random salt
        const salt = CryptoJS.lib.WordArray.random(16);
        // to generate key
        const key = CryptoJS.PBKDF2(encryptionKey, salt, {
            keySize: keySize / 32,
            iterations: 1000,
        });
        // random IV
        const iv = CryptoJS.lib.WordArray.random(128 / 8);
        // will attach link where you can find these
        const encrypted = CryptoJS.AES.encrypt(msg, key, {
            iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        });

        // Convert Lib.WordArray to ByteArray so we can combine them like Concat
        const saltwords = this.wordArrayToByteArray(salt);
        const ivwords = this.wordArrayToByteArray(iv);
        const cryptedText = this.wordArrayToByteArray(encrypted.ciphertext);
        // combine everything together in ByteArray.
        const header = saltwords.concat(ivwords).concat(cryptedText);
        // Now convert to WordArray.
        const headerWords = this.byteArrayToWordArray(header);
        // Encode this to sent to server
        const encodedString = CryptoJS.enc.Base64.stringify(headerWords);
        return encodedString;
    }

    wordArrayToByteArray(wordArray) {
        if (wordArray.hasOwnProperty("sigBytes") && wordArray.hasOwnProperty("words")) {
            length = wordArray.sigBytes;
            wordArray = wordArray.words;
        }

        const result = [];
        let bytes; let i = 0;

        while (length > 0) {
            bytes = this.wordToByteArray(wordArray[i], Math.min(4, length));
            length -= bytes.length;
            result.push(bytes);
            i++;
        }
        return [].concat.apply([], result);
    }

    byteArrayToWordArray(ba) {
        const wa = [];
        let i;

        for (i = 0; i < ba.length; i++) {
            wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
        }

        return CryptoJS.lib.WordArray.create(wa);
    }

    wordToByteArray(word, length) {
        const ba = [];
        const xFF = 0xff;

        if (length > 0) { ba.push(word >>> 24); }
        if (length > 1) { ba.push((word >>> 16) & xFF); }
        if (length > 2) { ba.push((word >>> 8) & xFF); }
        if (length > 3) { ba.push(word & xFF); }

        return ba;
    }
}
