<!-- Green / 1 -->
<button type="button" *ngIf="value == 1" class="button-quality button-quality-green" container="body">
    <img src="/assets/images/check-16.png" class="button-quality-inner-green">
    <!-- width="14" height="14"> -->
</button>

<!-- Yellow / 2 -->
<button type="button" *ngIf="value == 2" class="button-quality button-quality-yellow"
    ngbTooltip="{{'FormulationSearch.Tooltip.Imprecise' | translate}}" container="body">
    <img src="/assets/images/circle-16.png" class="button-quality-inner-yellow">
    <!-- width="14" height="14"> -->
</button>

<!-- RM / 3 -->
<button type="button" *ngIf="value == 3" class="button-quality button-quality-rm"
    ngbTooltip="{{'FormulationSearch.Tooltip.ReadyMixed' | translate}}" container="body">RM</button>

<!-- Red / 4 -->
<button type="button" *ngIf="value == 4" class="button-quality button-quality-red"
    ngbTooltip="{{'FormulationSearch.Tooltip.NotAdjustable' | translate}}" container="body">
    <img src="/assets/images/x-16.png" class="button-quality-inner-red">
    <!-- width="12" height="12"> -->
</button>
