import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ColorRow } from "../../../formulation/models/color-row";
import { ResultsViewType } from "../../enums/results-view-type";
import { QualityType } from "../../../formulation/enums/quality-type";
import { SearchResult } from "../../models/search-result";
import { SearchType } from "../../enums/search-type";
import { IsMobileViewPipe } from "../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { ListViewComponent } from './list-view/list-view.component';
import { TileViewComponent } from './tile-view/tile-view.component';

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: []
})
export class SearchResultsComponent implements OnInit, OnDestroy {
    @Input() searchTabIsSelected: boolean;
    @ViewChild(ListViewComponent) listViewComponent: ListViewComponent;
    @ViewChild(TileViewComponent) tileViewComponent: TileViewComponent;

    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isMobileView: boolean;
    selectedColor: ColorRow;
    colors: ColorRow[];
    viewType = ResultsViewType.List;
    searchType = SearchType.Normal;
    qualities: QualityType[];
    voc: string;
    isSearchFormInvisible: boolean;

    constructor(private mobileViewPipe: IsMobileViewPipe) { }

    onViewTypeChanged(viewType: ResultsViewType) {
        this.selectedColor = null;
        this.viewType = viewType;
    }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    onSearching(isSearching: boolean): void {
        if (isSearching) {
            this.colors = null;
            this.selectedColor = null;
        }
    }

    onSearchFormToggle(isVisible: boolean) {
        this.isSearchFormInvisible = !isVisible;
    }

    onResultsUpdated(searchResult: SearchResult): void {
        if (searchResult != null) {
            this.colors = searchResult.colors;
            this.searchType = searchResult.searchType;
            this.qualities = searchResult.qualities;
            this.voc = searchResult.voc;
            this.isSearchFormInvisible = true;
        } else {
            this.colors = null;
            this.selectedColor = null;
            this.searchType = SearchType.Normal;
            this.qualities = null;
            this.voc = null;
        }
    }

    onSelectedColorChanged(color: ColorRow) {
        this.selectedColor = color;
    }

    scrollToPreviousPosition() {
        // For whatever reason it does not work properly without the timeouts.
        if (this.isListView) {
            setTimeout(() => this.listViewComponent.onNavigatedTo());
        } else {
            setTimeout(() => this.tileViewComponent.onNavigatedTo());
        }
    }

    get isListView(): boolean {
        return this.viewType === ResultsViewType.List;
    }

    get noResultsFound(): boolean {
        return this.colors != null && this.colors.length === 0;
    }
}
