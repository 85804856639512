<div class="formulation-no">
    <span>{{formulationNo}}</span>
</div>

<ul *ngIf="remarks && remarks.length !== 0" class="remarks">
    <li *ngFor="let remark of remarks">
        {{remark.caption}}
    </li>
</ul>

<ngx-datatable #tableMaterials [rows]="container?.selectedComponentsContainer?.components" [columnMode]="'flex'"
    [scrollbarH]="isMobileView" headerHeight="35" [rowHeight]="'34'">

    <!-- Color -->
    <ngx-datatable-column *ngIf="!isMobileView" [maxWidth]="30" [minWidth]="30" [width]="30"
        headerClass="materials-header" name="" prop="color" [flexGrow]="1" [sortable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div *ngIf="value !== null" class="box-color" [ngStyle]="{'background-color': 'rgb(' + value + ')'}"></div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Raw Material -->
    <ngx-datatable-column *ngIf="!isMobileView" [minWidth]="240" headerClass="materials-header" prop="rawMaterial"
        [sortable]="false" [flexGrow]="1" name="{{'Formulation.Caption.RawMaterial' | translate }}">
    </ngx-datatable-column>

    <!-- Share -->
    <ngx-datatable-column *ngIf="!isMobileView" headerClass="materials-header" [minWidth]="50" prop="weightInGrammText"
        [sortable]="false" [flexGrow]="3" name="{{'Formulation.Caption.ShareG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Cumulative -->
    <ngx-datatable-column *ngIf="!isMobileView" headerClass="materials-header" [minWidth]="100" [sortable]="false"
        prop="totalWeightInGrammText" [flexGrow]="3" name="{{'Formulation.Caption.CumulativeG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Color mobile -->
    <ngx-datatable-column *ngIf="isMobileView" [maxWidth]="10" [flexGrow]="1" headerClass="materials-header" name=""
        [sortable]="false" prop="color">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div *ngIf="value !== null" class="box-color-mobile" [ngStyle]="{'background-color': 'rgb(' + value + ')'}">
            </div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Raw Material mobile -->
    <ngx-datatable-column *ngIf="isMobileView" [minWidth]="120" [flexGrow]="2" headerClass="materials-header"
        [sortable]="false" prop="rawMaterial" name="{{'Formulation.Caption.RawMaterial' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div class="ml-2" style="text-align: left">{{value}}</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Share mobile -->
    <ngx-datatable-column *ngIf="isMobileView" headerClass="materials-header" [minWidth]="70" [flexGrow]="1"
        [sortable]="false" prop="weightInGrammText" name="{{'Formulation.Caption.ShareG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>

    <!-- Cumulative mobile -->
    <ngx-datatable-column *ngIf="isMobileView" headerClass="materials-header" [minWidth]="80" [flexGrow]="1"
        [sortable]="false" prop="totalWeightInGrammText" name="{{'Formulation.Caption.CumulativeG' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <div style="text-align: right">{{value}} g</div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<div class="btn-ap-mixture-container" [hidden]="isDyoMode">
    <button type="button" (click)="mixUp()" [disabled]="!isAncillaryProductEnabled"
        class="btn-ap-mixture d-inline-flex">
        <span class="img-container">
            <img class="img-drop" src="/assets/images/spray-gun.png" />
        </span>
        <span class="text">{{'Formulation.Caption.AncillaryProductMix' | translate}}</span>
    </button>
</div>

<div *ngIf="isCoatingSelectorVisible" class="coating-selector">
    <div class="coatings-caption"><label>Coating</label></div>
    <ng-select #coating [selectOnTab]="false" #select (keydown.tab)="handleNgSelectTabKey($event, select)"
        [(ngModel)]="selectedCoating" [items]="coatings" [searchable]="false" [clearable]="false" name="coating-select">
    </ng-select>
</div>
