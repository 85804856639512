<div class="ub-container">
    <h3>Upgrade browser for full Mipa Mix Online experience</h3>
    <p>It looks like you may be using a web browser version that we don’t support. Make sure you’re using the most
        recent version of your browser, or try using one of these supported browsers, to get the full Mipa Mix Online
        experience.</p>
    <ul class="browser-list">
        <li><a href="https://www.google.com/intl/en/chrome/browser/desktop/" target="_blank" rel="noopener noreferrer"
                title="Download Chrome"> <img src="/assets/images/browsers/chrome.png" alt=""><span>Chrome</span></a>
        </li>
        <li><a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer"
                title="Download Firefox"> <img src="/assets/images/browsers/firefox.png" alt=""><span>Firefox</span></a>
        </li>
        <li><a href="https://www.opera.com/download" target="_blank" rel="noopener noreferrer" title="Download Opera">
                <img src="/assets/images/browsers/opera.png" alt=""><span>Opera</span></a></li>
        <li><a href="https://support.apple.com/en-us/HT204416" target="_blank" rel="noopener noreferrer"
                title="Download Safari"> <img src="/assets/images/browsers/safari.png" alt=""><span>Safari</span></a>
        </li>
        <li><a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener noreferrer"
                title="Download Edge"> <img src="/assets/images/browsers/edge.png" alt=""><span>Edge</span></a></li>
    </ul>
</div>
