<div print-section class="formulation-print-section">
    <formulation-printing-component #printing></formulation-printing-component>
</div>
<app-standard-page>
    <div class="container d-flex flex-column">

        <app-mixing-actions #actions></app-mixing-actions>

        <div *ngIf="container && !isMobileView" class="d-flex mixing-container">
            <div class="mixing-materials">
                <app-mixing-materials *ngIf="container.containerType === containerTypes.Default"></app-mixing-materials>
                <app-ap-mixing-materials [hidden]="container.containerType !== containerTypes.AncillaryProduct">
                </app-ap-mixing-materials>
            </div>
            <div class="splitter"></div>
            <div class="mixing-info flex-grow-1">
                <app-mixing-information *ngIf="container.containerType === containerTypes.Default">
                </app-mixing-information>
                <app-ap-mixing-information *ngIf="container.containerType === containerTypes.AncillaryProduct">
                </app-ap-mixing-information>
            </div>
        </div>
        <div *ngIf="container && isMobileView" class="d-flex mixing-container flex-column">
            <div class="tab">
                <button id="componentsButton" class="tablinks" (click)="openTab('components')">
                    {{'MixingMobile.Tab.Components' | translate}}
                </button>
                <button id="informationButton" class="tablinks" (click)="openTab('information')">
                    {{'MixingMobile.Tab.Information' | translate}}
                </button>
            </div>
            <div id="components" class="tabcontent">
                <div>
                    <app-mixing-materials *ngIf="container.containerType === containerTypes.Default">
                    </app-mixing-materials>
                    <app-ap-mixing-materials [hidden]="container.containerType !== containerTypes.AncillaryProduct">
                    </app-ap-mixing-materials>
                </div>
            </div>

            <div id="information" class="tabcontent">
                <div class="mixing-info-mobile">
                    <app-mixing-information *ngIf="container.containerType === containerTypes.Default">
                    </app-mixing-information>
                    <app-ap-mixing-information *ngIf="container.containerType === containerTypes.AncillaryProduct">
                    </app-ap-mixing-information>
                </div>
            </div>
        </div>

    </div>
</app-standard-page>
