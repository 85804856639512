import { IDeserialize } from "../../../../shared/interfaces/deserialize";

export class MixingInfo implements IDeserialize {
    manufacturer: string;
    manufacturerLongName: string;
    mcs: string;
    updateStatus: string;
    colorNumber: string;
    colorName: string;
    variant: string;
    flopVariant: string;
    model: string;
    yearOfBuild: string;
    colorGroup: string;
    colorGroupLocalized: string;
    colorCategoryId: number;
    quality: string;
    glossLevel: string;
    glossLevelLocalized: string;
    quantity: number;
    isNew: boolean;
    creationDate: string;
    dateOfChange: string;
    minimumQuantity: number;
    vocConcentration: string;
    public mcsUpdateStatus(){
        if (this.updateStatus) {
            return this.mcs + " " + this.updateStatus;
        }
        
        return this.mcs;
    }

    deserialize(input: any): this {
        Object.assign(<any>this, input);
        return this;
    }
}
