import { Injectable } from "@angular/core";
import { FormulationDataCriteria } from "../models/formulation-data-criteria";
import { FormulationDataComponent } from "../components/formulation-data.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class FormulationDataDialogService {
    constructor(private modalService: NgbModal) {
    }

    showDialog(criteria: FormulationDataCriteria, isQuantityChange: boolean) {
        const modal = this.modalService.open(FormulationDataComponent, {
            windowClass: 'modal-holder',
            centered: true,
            backdrop: 'static',
        });
        modal.componentInstance.criteria = criteria;
        modal.componentInstance.isQuantityChange = isQuantityChange;
    }
}
