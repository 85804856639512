import Language from "../models/language";

export default class Languages {
    public static list: Language[] = [
        {
            code: "en",
            name: "English"
        },
        {
            code: "de",
            name: "Deutsch"
        },
        {
            code: "fr",
            name: "Français"
        },
        {
            code: "it",
            name: "Italiano"
        },
        {
            code: "es",
            name: "Español"
        },
        {
            code: "fi",
            name: "Suomalainen"
        },
        {
            code: "pl",
            name: "Polski"
        },
        {
            code: "ru",
            name: "Русский"
        },
        {
            code: "sl",
            name: "Slovenski"
        },
        {
            code: "sk",
            name: "Slovenský"
        },
        {
            code: "ko",
            name: "한국의"
        }
    ];
}
