import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { LocalizationService } from "./core/localization/localization.service";
import { SettingsService } from "./core/settings/settings.service";
import { AppInitializer } from "./core/app-initializer/app-initializer";
import { sections } from "./shared/configuration/sections";
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

declare function isBrowserSupported(): boolean;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;

    constructor(@Inject(DOCUMENT) private document: Document,
        private localizationService: LocalizationService,
        private settingsService: SettingsService,
        private appInitializer: AppInitializer,
        private router: Router,
        private logger: NGXLogger,
        private ccService: NgcCookieConsentService,
        private translateService: TranslateService) {

    }

    ngOnInit(): void {
        this.setLocalizedNames();

        // subscribe to cookieconsent observables to react to main events
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.initializeSubscription = this.ccService.initialize$.subscribe(
            (event: NgcInitializeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });


        if (!isBrowserSupported()) {
            this.router.navigate([`/${sections.unsupportedBrowser}`]);
        }

        if (this.appInitializer.hasErrors()) {
            this.router.navigate([`/${sections.appError}`]);
        }
        else {
            this.logger.info("The application was initialized.");
        }

        this.document.body.classList.add('loaded');


        this.translateService.onLangChange.subscribe(x => {
            this.setLocalizedNames();
        });
    }

    setLocalizedNames() {
        this.translateService//
            .get(['CookieConsent.Cookie.Header', 'CookieConsent.Cookie.Message', 'CookieConsent.Cookie.Dismiss', 'CookieConsent.Cookie.Allow', 'CookieConsent.Cookie.Deny', 'CookieConsent.Cookie.Link', 'CookieConsent.Cookie.Policy', 'CookieConsent.Cookie.And'])
            .subscribe(data => {

                this.ccService.getConfig().content = this.ccService.getConfig().content || {};
                // Override default messages with the translated ones
                this.ccService.getConfig().content.header = data['CookieConsent.Cookie.Header'];
                this.ccService.getConfig().content.message = data['CookieConsent.Cookie.Message'];
                this.ccService.getConfig().content.dismiss = data['CookieConsent.Cookie.Dismiss'];
                this.ccService.getConfig().content.allow = data['CookieConsent.Cookie.Allow'];
                this.ccService.getConfig().content.deny = data['CookieConsent.Cookie.Deny'];
                this.ccService.getConfig().content.link = data['CookieConsent.Cookie.Link'];
                this.ccService.getConfig().content.policy = data['CookieConsent.Cookie.Policy'];
                this.ccService.getConfig().content.and = data['CookieConsent.Cookie.And'];

                this.ccService.destroy();//remove previous cookie bar (with default messages)
                this.ccService.init(this.ccService.getConfig()); // update config with translated messages
            });
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }
}
