import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AncillaryProductCriteria } from "../models/criteria/ancillary-product-criteria";
import { AncillaryProductComponent } from "../components/ancillary-product.component";

@Injectable({
    providedIn: 'root'
})
export class AncillaryProductDialogService {
    constructor(private modalService: NgbModal) {
    }

    showDialog(criteria?: AncillaryProductCriteria) {
        const modal = this.modalService.open(AncillaryProductComponent, { windowClass: 'modal-holder', centered: true, backdrop: 'static' });

        if (criteria) {
            modal.componentInstance.criteria = criteria;
        }
        else {
            modal.componentInstance.criteria = new AncillaryProductCriteria();
            modal.componentInstance.clearHistory = true;
        }
    }
}
