import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorDialogComponent } from "../../shared/components/error-dialog/error-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ErrorDialogService {
    constructor(private modalService: NgbModal, private translateService: TranslateService) {
    }

    showDialog(message: string, title: string = this.translateService.instant("General.Text.ErrorDialogTitle")) {
        const modal = this.modalService.open(ErrorDialogComponent, { windowClass: 'modal-holder', centered: true, backdrop: 'static' });

        modal.componentInstance.title = title;
        modal.componentInstance.message = message;
    }
}
