import { ModelRow } from "./model-row";
import { IDeserialize } from "../../shared/interfaces/deserialize";

export class ColorRow implements IDeserialize {
    colorId: number;
    colorCategoryId: number;
    isPb: boolean;
    isTwoTone: boolean;
    isCC: boolean;
    isInt: boolean;
    isIntern: boolean;
    region: number;
    colorVariantId: number;
    buildYearFrom: number;
    buildYearTo: number;
    manufacturer: string;
    manufacturerId: number;
    var: string;
    varTooltip: string;
    fVar: string;
    fVarTooltip: string;
    variantId: number;
    variantIdFlop: number;
    colorName: string;
    synonymColorNameString: string;
    synonymColorNameAndHeaderString: string;
    colorNumber: string;
    mcs2: string;
    mcs3: string;
    sf: string;
    compartmentNo: string;
    color25: string;
    color45: string;
    color75: string;
    hasColor: boolean;
    oc: number;
    bc: number;
    pur: number;
    wbc: number;
    purhs: number;
    formulationNo: string;
    isArchive: boolean;
    manufacturerModelId: number;
    isMetalic: boolean;
    models: ModelRow[];
    modelsLoaded: boolean;
    isExpanded = false;

    deserialize(input: any): this {
        Object.assign(this as any, input);
        return this;
    }

}
