<div (keydown)="onKeyDown($event)">
    <div class="d-flex dialog-header">
        <img class="dialog-icon" src="/assets/images/drop.png" />
        <div class="title flex-grow-1">{{ 'FormulationData.View.Title' | translate }}</div>
        <button type="button" class="btn-close-modal" (click)="cancel()"><img src="/assets/images/x-16.png" /></button>
    </div>
    <div class="dialog-content">
        <form #form="ngForm" novalidate [hidden]="!isLoaded" *ngIf="!isMobileView">
            <table>
                <tr>
                    <th colspan="2">
                        <h2 class="top-caption">{{ 'FormulationData.Label.FormulationDetails' | translate }}</h2>
                    </th>
                </tr>
                <tr>
                    <td><label>{{ 'FormulationData.Label.Quality' | translate }}</label></td>
                    <td>
                        <app-formulation-data-quality #quality [qualityType]="criteria.quality" [color]="criteria.color"
                            [qualities]="data.qualities" [variantComponent]="variant"
                            [glossLevelComponent]="glossLevel">
                        </app-formulation-data-quality>
                    </td>
                </tr>
                <tr [hidden]="!variant.isVisible || variant.isDisabled">
                    <td><label>{{ 'FormulationData.Label.Variant' | translate }}</label></td>
                    <td>
                        <app-formulation-data-variant #variant [color]="criteria.color" [variants]="data.variants"
                            (onChanged)="glossLevel.onVariantChanged($event)">
                        </app-formulation-data-variant>
                    </td>
                </tr>
                <tr [hidden]="!glossLevel.isVisible || glossLevel.isDisabled">
                    <td><label>{{ 'FormulationData.Label.DegreeOfGloss' | translate }}</label></td>
                    <td>
                        <app-formulation-data-gloss-level #glossLevel [glossLevels]="data.glossLevels">
                        </app-formulation-data-gloss-level>
                    </td>
                </tr>
                <tr [hidden]="!coating.isVisible">
                    <td><label>{{ 'FormulationData.Label.Coating' | translate }}</label></td>
                    <td>
                        <formulation-data-coating #coating [color]="criteria.color" [quality]="quality.value">
                        </formulation-data-coating>
                    </td>
                </tr>
                <tr>
                    <th style="padding-bottom:0" colspan="2">
                        <h2 style="margin-bottom: 0" class="middle-caption">
                            {{ 'FormulationData.Label.QuantitySeparatorTitle' | translate }}</h2>
                    </th>
                </tr>
                <tr>
                    <td><label>{{ 'FormulationData.Label.Quantity' | translate }}</label></td>
                    <td>
                        <formulation-data-quantity #quantity [value]="data.defaultQuantity"></formulation-data-quantity>
                    </td>
                </tr>
                <tr>
                    <td><label>{{ 'FormulationData.Label.Unit' | translate }}</label></td>
                    <td>
                        <formulation-data-unit #unit [units]="data.units" [value]="data.unit"></formulation-data-unit>
                    </td>
                </tr>
                <tr>
                    <th colspan="2">
                        <h2 class="middle-caption">{{ 'FormulationData.Text.Note' | translate }}</h2>
                    </th>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="note-tip" [innerHTML]="'FormulationData.Text.MinimumQuantityNote' | translate">
                        </div>
                    </td>
                </tr>
            </table>

            <div *ngIf="isMixing" class="formulation-data-loader flex-row-reverse d-flex">
                <dots-loader caption="{{'General.Text.PleaseWaitProcessing' | translate}}"></dots-loader>
            </div>

            <div *ngIf="isMixingError" class="mixing-error flex-row-reverse d-flex">
                {{'General.Text.FailedToProcessRequest' | translate}}
            </div>

            <div *ngIf="!isMixing" class="buttons flex-row-reverse d-flex">
                <button type="button" (click)="ok()" class="btn btn-default btn-ok">{{ 'FormulationData.Button.OK' |
                    translate }}</button>
                <button type="button" (click)="cancel()" class="btn btn-cancel">{{ 'FormulationData.Button.Cancel' |
                    translate }}</button>
            </div>
        </form>
        <form #form="ngForm" novalidate [hidden]="!isLoaded" *ngIf="isMobileView">
            <h2 class="top-caption">{{ 'FormulationData.Label.FormulationDetails' | translate }}</h2>
            <label>{{ 'FormulationData.Label.Quality' | translate }}</label>
            <app-formulation-data-quality #quality [qualityType]="criteria.quality" [color]="criteria.color"
                [qualities]="data.qualities" [variantComponent]="variant" [glossLevelComponent]="glossLevel">
            </app-formulation-data-quality>

            <div [hidden]="!variant.isVisible || variant.isDisabled">
                <label>{{ 'FormulationData.Label.Variant' | translate }}</label>
                <app-formulation-data-variant #variant [color]="criteria.color" [variants]="data.variants"
                    (onChanged)="glossLevel.onVariantChanged($event)">
                </app-formulation-data-variant>
            </div>
            <div [hidden]="!glossLevel.isVisible || glossLevel.isDisabled">
                <label>{{ 'FormulationData.Label.DegreeOfGloss' | translate }}</label>
                <app-formulation-data-gloss-level #glossLevel [glossLevels]="data.glossLevels">
                </app-formulation-data-gloss-level>
            </div>
            <div [hidden]="!coating.isVisible">
                <label>{{ 'FormulationData.Label.Coating' | translate }}</label>
                <formulation-data-coating #coating [color]="criteria.color" [quality]="quality.value">
                </formulation-data-coating>
            </div>
            <h2 style="margin-bottom: 0" class="middle-caption">
                {{ 'FormulationData.Label.QuantitySeparatorTitle' | translate }}</h2>
            <label>{{ 'FormulationData.Label.Quantity' | translate }}</label>
            <formulation-data-quantity #quantity [value]="data.defaultQuantity"></formulation-data-quantity>
            <label>{{ 'FormulationData.Label.Unit' | translate }}</label>
            <formulation-data-unit #unit [units]="data.units" [value]="data.unit"></formulation-data-unit>
            <h2 class="middle-caption">{{ 'FormulationData.Text.Note' | translate }}</h2>
            <div class="note-tip inner-content-width"
                [innerHTML]="'FormulationData.Text.MinimumQuantityNote' | translate"></div>

            <div *ngIf="isMixing" class="formulation-data-loader flex-row-reverse d-flex">
                <dots-loader caption="{{'General.Text.PleaseWaitProcessing' | translate}}"></dots-loader>
            </div>

            <div *ngIf="isMixingError" class="mixing-error flex-row-reverse d-flex">
                {{'General.Text.FailedToProcessRequest' | translate}}
            </div>

            <div *ngIf="!isMixing" class="buttons flex-row-reverse d-flex">
                <button type="button" (click)="ok()" class="btn btn-default btn-ok">{{ 'FormulationData.Button.OK' |
                    translate }}</button>
                <button type="button" (click)="cancel()" class="btn btn-cancel">{{ 'FormulationData.Button.Cancel' |
                    translate }}</button>
            </div>
        </form>
        <div *ngIf="isLoadingData" class="formulation-data-loader">
            <dots-loader caption="{{'General.Text.LoadingRequiredData' | translate}}"></dots-loader>
        </div>
        <div class="data-load-error" *ngIf="isDataLoadError">
            <p>{{'General.Text.LoadRequiredDataError' | translate}}</p>
            <a class="click-message" href="javascript:void(0)"
                (click)="retryLoadRequiredData()">{{'General.Text.ClickToRetry' | translate}}</a>
        </div>
    </div>
</div>
