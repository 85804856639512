import { Component, OnInit, Input } from '@angular/core';
import { FormField } from "../../../../../shared/components/base/forms/form-field";
import { InputMaskOptions } from "../../../../../shared/interfaces/input-mask-options";

@Component({
    selector: 'color-name-field',
    templateUrl: './color-name.component.html',
    styles: []
})
export class ColorNameComponent extends FormField<string> implements OnInit {
    @Input('inputMask') options: InputMaskOptions = new InputMaskOptions({
        suffix: '*'
    });

    onFocus() {
        this.value = this.clearMask(this.value).toString();
    }

    onBlur() {
        this.value = this.applyMask(this.value);
    }

    get display(): string {
        return this.value;
    }

    set display(val: string) {
        this.value = val;
    }

    ngOnInit() {
    }

    applyMask(value: string): string {
        const val = value ? value.endsWith(this.options.suffix) ? value : value + this.options.suffix : "";
        return val;
    }

    clearMask(value: string): string {
        const val = value ? value.length >= this.options.suffix.length ? value.substr(0, value.length - this.options.suffix.length) : value : "";
        return val;
    }
}
