import { Component, OnDestroy, OnInit } from "@angular/core";
import { MixingNavigationService } from "../../../services/mixing-navigation.service";
import { MixingInfo } from "../../../models/mixing-data/default/mixing-info";
import { FormulationDataCriteria } from "../../../../formulation-data/models/formulation-data-criteria";
import { FormulationDataDialogService } from "../../../../formulation-data/services/formulation-data-dialog.service";
import { IMixingContainer } from "../../../interfaces/i-mixing-container";
import { ContainerType } from "../../../enums/container-type";
import { MixingContainer } from "../../../models/mixing-data/default/mixing-container";
import { MixingComponentsContainer } from "../../../models/mixing-data/default/mixing-components-container";
import { NotificationDialogService } from "../../../../core/dialogs/notification-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { sections } from "../../../../shared/configuration/sections";
import { FormulationHistoryItem } from "../../../../formulation-data/models/formulation-history-item";
import { MixingDataService } from "../../../services/mixing-data.service";
import { Router } from "@angular/router";
import { LocalizationService } from "../../../../core/localization/localization.service";
import { IsMobileViewPipe } from "../../../../shared/pipes/is-mobile-view.pipe";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-mixing-information',
    templateUrl: './mixing-information.component.html',
    styleUrls: ['./mixing-information.component.scss']
})
export class MixingInformationComponent implements OnInit, OnDestroy {
    isMobileView: boolean;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    isFormulationHistoryVisible: boolean;
    info: MixingInfo;
    formulationDataCriteria: FormulationDataCriteria;
    subscription: Subscription;
    selectedContainerSubscription: Subscription;
    currentContainer: MixingContainer;
    minimumQuantity: string;
    isMinimumQuantityRequired: boolean;
    formulationHistoryItems: FormulationHistoryItem[];
    isFormulationSearch: boolean;
    decimalSeparator: string;
    formulationNo: string;

    constructor(
        private navigation: MixingNavigationService,
        private formulationDataDialogService: FormulationDataDialogService,
        private notificationDialogService: NotificationDialogService,
        private mixingDataService: MixingDataService,
        private mixingNavigation: MixingNavigationService,
        private router: Router,
        private translate: TranslateService,
        private localizationService: LocalizationService,
        private mobileViewPipe: IsMobileViewPipe) {
        this.decimalSeparator = this.localizationService.selectedLanguage.getDecimalSeparator();
    }

    ngOnInit() {
        this.resizeObservable = fromEvent(window, 'orientationchange');
        this.resizeSubscription = this.resizeObservable.pipe(debounceTime(100)).subscribe(() => {
            this.isMobileView = this.mobileViewPipe.transform(window);
        });
        this.isMobileView = this.mobileViewPipe.transform(window);
        this.translate.onLangChange.subscribe(() => this.setLocalizedNames());
        this.onContainerChanged(this.navigation.currentContainer);
        this.subscription = this.navigation.containerChanged.subscribe(x => this.onContainerChanged(x.newValue));
    }

    changeQuantity(): void {
        this.formulationDataDialogService.showDialog(this.formulationDataCriteria, true);
    }

    onContainerChanged(newContainer: IMixingContainer) {
        this.currentContainer = newContainer && newContainer.containerType === ContainerType.Default ? newContainer as MixingContainer : null;
        if (this.currentContainer && this.currentContainer.containerType === ContainerType.Default) {
            this.info = this.currentContainer.info;
            this.isFormulationSearch = this.currentContainer.mixingCriteria.searchType === 1;
            this.formulationDataCriteria = this.currentContainer.formulationDataCriteria;
            this.onComponentsContainerChanged(this.currentContainer.selectedComponentsContainer);
            this.selectedContainerSubscription = this.currentContainer.selectedComponentsContainerChanged.subscribe(x => this.onComponentsContainerChanged(x));
            this.formulationHistoryItems = this.currentContainer.formulationHistoryItems;
            this.setLocalizedNames();
        }
        else {
            this.info = null;
            this.formulationDataCriteria = null;
            this.formulationHistoryItems = null;
            if (this.selectedContainerSubscription) {
                this.selectedContainerSubscription.unsubscribe();
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

    toggleFormulationHistoryVisibility() {
        this.isFormulationHistoryVisible = !this.isFormulationHistoryVisible;
    }

    setLocalizedNames() {
        this.info.colorGroupLocalized = this.translate.instant(`ColorCategory.Id.${this.info.colorCategoryId}`);
        this.info.glossLevelLocalized = this.translate.instant(`GlossLevel.Text.${this.info.glossLevel}`);
    }

    async changeFormulation(formulationNo: string) {
        this.formulationNo = formulationNo;
        const container = await this.getContainerForFormulation(formulationNo);
        this.mixingNavigation.navigate(container);
        const mixingUrl = `/${sections.mixing}`;
        if (this.router.url !== mixingUrl) {
            await this.router.navigate([mixingUrl]);
        }
    }

    private async getContainerForFormulation(formulationNo: string): Promise<MixingContainer> {
        const mixingCriteria = this.currentContainer.mixingCriteria;
        mixingCriteria.formulationNoFromHistory = formulationNo;
        const container = await this.mixingDataService.getMixingContainer(mixingCriteria);

        if (container) {
            container.formulationDataCriteria = this.currentContainer.formulationDataCriteria;
            container.mixingCriteria = this.currentContainer.mixingCriteria;
        }

        return container;
    }

    private onComponentsContainerChanged(container: MixingComponentsContainer) {
        this.minimumQuantity = container.minimumQuantity > 0
            ? `${container.minimumQuantity.toFixed(2).replace('.', this.decimalSeparator).replace(',', this.decimalSeparator)} ${container.minimumQuantityUnitName}`
            : null;
        this.isMinimumQuantityRequired = container.isMinimumQuantityRequired;
        if (this.isMinimumQuantityRequired) {
            setTimeout(() => {
                this.notificationDialogService.showDialog(this.translate.instant('Mixing.Info.MinimumQuantityRequiredMessage'));
            }, 200);
        }
    }
}
